import { ViewFactory } from 'wdc-cube-react'

import { TheConnectionScope } from '../tc_scopes'
import { TheConnectionView } from './tc_view'

import { HeaderBarScope } from '../tc_scopes'
import { ToolbarView } from './tc_header-bar_view'

import { SearchByPictureScope } from '../tc_scopes'
import { SearchByPictureView } from './tc_search-by-picture_view'

export function registerViews(rv = ViewFactory.register) {
    rv(TheConnectionScope, TheConnectionView)
    rv(HeaderBarScope, ToolbarView)
    rv(SearchByPictureScope, SearchByPictureView)
}
