import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'

import { RangeOperator } from '../sb_types'

import { Observable, observe, Scope, Logger } from 'wdc-cube'

import { FCClassContext, classToFComponent } from 'src/utils/views'
import { ChildProcessWithoutNullStreams } from 'child_process'

const LOG = Logger.get('DateRangeInput')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
        minHeight: 44
    },
    selectInput: {},
    datePicker: {
        '& .MuiInputBase-input': {
            width: '78pt',
            padding: '5px 14px 5px 14px'
        }
    }
})

const HBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    columnGap: 5,
    alignItems: 'flex-end'
})

let INSTANCE_COUNT = 1

@Observable
export class DateRangeInputScope extends Scope {
    @observe() operator = RangeOperator.is
    @observe() value?: Dayjs
    @observe() toValue?: Dayjs

    requestFocusOnInput = false

    onEnter = Scope.ASYNC_ACTION
}

type DateRangeInputProps = {
    className?: string
    scope: DateRangeInputScope
}

class DateRangeInputClass implements FCClassContext<DateRangeInputProps> {
    // :: Fields
    uid: string
    classes!: ReturnType<typeof useStyles>['classes']
    scope!: DateRangeInputScope
    fromInputElm?: HTMLInputElement | null
    toInputElm?: HTMLInputElement | null

    constructor() {
        this.uid = `sb-dt-range-${INSTANCE_COUNT++}`
    }

    // :: Methods

    onSyncState({ scope }: DateRangeInputProps) {
        this.classes = useStyles().classes
        this.scope = scope

        if (this.fromInputElm && scope.requestFocusOnInput) {
            this.fromInputElm.focus()
        }
    }

    render({ className }: DateRangeInputProps) {
        const { classes, scope } = this

        const isRange = scope.operator === RangeOperator.in_range || scope.operator === RangeOperator.out_of_range

        return (
            <div className={clsx(classes.view, className)}>
                <FormControl variant="standard" size="small">
                    <Select
                        className={classes.selectInput}
                        labelId={this.uid}
                        defaultValue={`${RangeOperator.is}`}
                        value={`${scope.operator}`}
                        onChange={this.handleSelectChange}
                    >
                        <MenuItem value={RangeOperator.is_less_then}>Menor que</MenuItem>
                        <MenuItem value={RangeOperator.is_less_then_or_equal_to}>Menor que ou igual</MenuItem>
                        <MenuItem value={RangeOperator.is_greater_then}>Maior que</MenuItem>
                        <MenuItem value={RangeOperator.is_greater_then_or_equal_to}>Maior que ou igual</MenuItem>
                        <MenuItem value={RangeOperator.is}>É igual</MenuItem>
                        <MenuItem value={RangeOperator.is_not}>Não é igual</MenuItem>
                        <MenuItem value={RangeOperator.in_range}>No interválo</MenuItem>
                        <MenuItem value={RangeOperator.out_of_range}>Fora do interválo</MenuItem>
                    </Select>
                </FormControl>
                {isRange === false && (
                    <DatePicker
                        className={classes.datePicker}
                        formatDensity="dense"
                        inputRef={this.fromInputRef}
                        value={scope.value ?? ''}
                        onChange={this.handleFromValueChange}
                        slotProps={{
                            textField: {
                                onKeyDown: this.handleKeyDown
                            }
                        }}
                    />
                )}

                {isRange === true && (
                    <HBox>
                        <DatePicker
                            className={classes.datePicker}
                            formatDensity="dense"
                            inputRef={this.fromInputRef}
                            label="De"
                            value={scope.value ?? ''}
                            onChange={this.handleFromValueChange}
                            slotProps={{
                                textField: {
                                    onKeyDown: this.handleKeyDown
                                }
                            }}
                        />
                        <DatePicker
                            className={classes.datePicker}
                            formatDensity="dense"
                            label="Até"
                            value={scope.toValue ?? ''}
                            onChange={this.handleToValueChange}
                            slotProps={{
                                textField: {
                                    onKeyDown: this.handleKeyDown
                                }
                            }}
                        />
                    </HBox>
                )}
            </div>
        )
    }

    readonly fromInputRef = (elm: HTMLInputElement | null) => {
        this.fromInputElm = elm
        if (elm && this.scope.requestFocusOnInput) {
            this.scope.requestFocusOnInput = false
            elm.focus()
        }
    }

    readonly toInputRef = (elm: HTMLInputElement | null) => {
        this.toInputElm = elm
    }

    readonly handleSelectChange = (event: SelectChangeEvent) => {
        LOG.debug('handleSelectChange:', event)
        const val = Number.parseInt(event.target.value)
        if (!Number.isNaN(val)) {
            this.scope.operator = val
            this.scope.requestFocusOnInput = true
        }
    }

    readonly handleFromValueChange = (newVal: unknown | ChildProcessWithoutNullStreams) => {
        const dayJsVal = newVal as Dayjs
        this.scope.value = dayJsVal
    }

    readonly handleToValueChange = (newVal: unknown | null) => {
        const dayJsVal = newVal as Dayjs
        this.scope.toValue = dayJsVal
    }

    readonly handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            this.scope.onEnter().catch(LOG.caught)
        }
    }

    readonly setInputFocus = (cb?: () => void) => {
        setTimeout(() => {
            try {
                this.fromInputElm && this.fromInputElm.focus()
                if (cb) {
                    cb()
                }
            } catch (e: unknown) {
                LOG.error('focusing', e)
            }
        }, 200)
    }
}

export const DateRangeInput = classToFComponent(DateRangeInputClass, React)
export default DateRangeInput
