import type { SearchOption } from '../sb_types'

import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { AutocompleteGetTagProps } from '@mui/base/useAutocomplete'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles()((theme) => ({
    tagChip: {
        display: 'flex',
        alignItems: 'center',
        height: '24px',
        margin: '2px',
        lineHeight: '22px',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa',
        border: `1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'}`,
        borderRadius: '2px',
        boxSizing: 'content-box',
        padding: '0 4px 0 10px',
        outline: 0,
        overflow: 'hidden',

        '&:focus': {
            borderColor: theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff',
            backgroundColor: theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'
        },

        '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },

        '& svg': {
            fontSize: '12px',
            cursor: 'pointer',
            padding: '4px'
        }
    }
}))

type AutocompleteTagProps = ReturnType<AutocompleteGetTagProps>

type TagChipProps = AutocompleteTagProps & {
    className?: string
    option: SearchOption
    onRemoveTag?: (code: number) => Promise<void>
}

export default function TagChip(props: TagChipProps) {
    const { className, option, emitDelete, classes, tagProps } = TagChipContext.use(props)
    const { label, text, hint } = option

    const shortened = text.length > 20
    const shortnedLabel = `${label}: ${shortened ? text.substring(0, 20) + '...' : text}`

    const bodyEl = (
        <div className={clsx(classes.tagChip, className)} {...tagProps}>
            <span>{shortnedLabel}</span>
            <CloseIcon onClick={emitDelete} />
        </div>
    )

    if (shortened) {
        return <Tooltip title={hint ?? text}>{bodyEl}</Tooltip>
    }

    return bodyEl
}

class TagChipContext {
    static create = () => new TagChipContext()

    static use(props: TagChipProps) {
        const me = React.useMemo(TagChipContext.create, [])
        me.#doUse(props)
        return me
    }

    // :: Public Fields
    className?: string
    option!: SearchOption
    tagProps!: Partial<AutocompleteTagProps>
    classes!: ReturnType<typeof useStyles>['classes']
    onRemoveTag?: (code: number) => Promise<void>

    // :: Private Fields
    #tagIndex!: number
    #tagDelete?: TagChipProps['onDelete']

    #doUse(props: TagChipProps) {
        const { className, option, onRemoveTag, onDelete, ...tagProps } = props
        this.className = className
        this.option = option
        this.tagProps = tagProps
        this.classes = useStyles().classes
        this.onRemoveTag = onRemoveTag
        this.#tagIndex = props['data-tag-index']
        this.#tagDelete = onDelete
        return this
    }

    // :: Event Emissors

    emitDelete = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        this.#tagDelete && this.#tagDelete(event)
        this.onRemoveTag && this.onRemoveTag(this.#tagIndex)
    }
}
