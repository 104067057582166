import { ViewFactory } from 'wdc-cube-react'

import { TheMapScope } from '../tm_scopes'
import { TheMapView } from './tm_view'

import { TheMapHeaderScope } from '../tm_scopes'
import { TheMapHeaderView } from './tm_header-bar_view'

export function registerViews(rv = ViewFactory.register) {
    rv(TheMapScope, TheMapView)
    rv(TheMapHeaderScope, TheMapHeaderView)
}
