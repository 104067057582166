import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFaixasScope } from './tcm_scopes'
import { TheConsumerMarketService, type EmpresaFilter, JunctionMode, NumberOperator } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasNoGrupo')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasNoGrupo extends Presenter<ChartFaixasScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.empresasNoGrupo)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.empresasNoGrupo
        this.scope.onItemClicked = this.__onEmpresasNoGrupoClicked.bind(this)

        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.totalEmpresasNoGrupo.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_no_grupo: true,
                filter: filter
            })

            const data = {
                qtd_1: 0,
                qtd_2_to_5: 0,
                qtd_6_to_10: 0,
                qtd_11_to_INF: 0
            }

            for (const bucket of resp.total_de_empresas_no_grupo?.buckets ?? []) {
                switch (bucket.key as keyof typeof data) {
                    case 'qtd_1':
                        data.qtd_1 += bucket.doc_count
                        break
                    case 'qtd_2_to_5':
                        data.qtd_2_to_5 += bucket.doc_count
                        break
                    case 'qtd_6_to_10':
                        data.qtd_6_to_10 += bucket.doc_count
                        break
                    case 'qtd_11_to_INF':
                        data.qtd_11_to_INF += bucket.doc_count
                        break
                }
            }

            this.scope.dataset = [
                [data.qtd_11_to_INF, '11 ou mais', false],
                [data.qtd_6_to_10, '6 a 10', false],
                [data.qtd_2_to_5, '2 a 5', false],
                [data.qtd_1, '1', false]
            ]

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.totalEmpresasNoGrupo

        const markRow = (rowIdx: number, value: boolean) => {
            const row = this.scope.dataset[rowIdx]
            row && (row[2] = value)
        }

        markRow(0, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.gte}-11`))
        markRow(1, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-6:11`))
        markRow(2, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-2:6`))
        markRow(3, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.lte}-1`))

        this.scope.update()
    }

    private async __onEmpresasNoGrupoClicked(rowIdx: number) {
        const qtdeEmpresaGrupoPresenter = this.owner.filterPane.totalEmpresaGrupo
        switch (rowIdx) {
            case 0:
                qtdeEmpresaGrupoPresenter.toggleFrom(JunctionMode.OR, 11, true)
                break
            case 1:
                qtdeEmpresaGrupoPresenter.toggleRange(JunctionMode.OR, 6, 11)
                break
            case 2:
                qtdeEmpresaGrupoPresenter.toggleRange(JunctionMode.OR, 2, 6)
                break
            case 3:
                qtdeEmpresaGrupoPresenter.toggleTo(JunctionMode.OR, 1, true)
                break
        }
        qtdeEmpresaGrupoPresenter.publish()
        this.__markSelected()

        this.__parent.fetchData(qtdeEmpresaGrupoPresenter.size ? 'totalDeEmpresasNoGrupo' : '')
    }
}
