import React from 'react'
import clsx from 'clsx'
import { Logger, events } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'

import { makeStyles } from 'tss-react/mui'
import * as L from 'leaflet'
import { Menu, Item, useContextMenu, ShowContextMenuParams } from 'react-contexify'
import { MapContainer, TileLayer } from 'react-leaflet'

import TheSelectionBarView from './tm_selection-bar_view'

import { TheMapScope } from '../tm_scopes'

const LOG = Logger.get('TM-VIEW')

const MENU_ID = 'bf9ee3a7-c3bd-410c-b15c-753758356c0d'

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>

// Links:
//  * https://react-leaflet.js.org/docs/start-setup/

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        minHeight: 600
    },
    map: {
        display: 'flex',
        flexGrow: 1
    },
    selectionBar: {
        position: 'absolute',
        right: 20,
        top: 20,
        zIndex: 1000
    }
})

type TheMapViewProps = {
    className?: string
    scope: TheMapScope
}

export const TheMapView = class TheMapViewCtx {
    static #create = () => new TheMapViewCtx()

    static view(props: TheMapViewProps) {
        return React.useMemo(TheMapViewCtx.#create, []).#render(props)
    }

    scope!: TheMapScope
    mapRef: L.Map | null = null
    doShowMenu?: (params: MakeOptional<ShowContextMenuParams<unknown>, 'id'>) => void

    // :: Public

    readonly #setMapRef = (value: L.Map | null) => {
        if (this.mapRef !== value) {
            this.mapRef = value
            this.scope.onMapChanged(value).catch(LOG.caught)
        }
    }

    readonly #showMenu = (event: events.MouseEvent) => {
        if (this.doShowMenu) {
            this.doShowMenu({
                event: event as MouseEvent,
                props: {}
            })
        }
    }

    readonly #handleMarkCopyDataToClipboard = () => {
        this.scope.onMarkCopyDataToClipboard().catch(LOG.caught)
    }

    readonly #handleMarkShowDetailsClipboard = () => {
        this.scope.onMarkShowDetailsClipboard().catch(LOG.caught)
    }

    #render(props: TheMapViewProps) {
        const scope = (this.scope = props.scope)
        const { classes } = useStyles()

        bindUpdate(React, scope)

        this.doShowMenu = useContextMenu({ id: MENU_ID }).show
        this.scope.showMarkMenu = this.#showMenu

        return (
            <div className={clsx(classes.view, props.className)}>
                <MapContainer className={classes.map} scrollWheelZoom={true} ref={this.#setMapRef}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer>
                {!!scope.selectionBar && (
                    <TheSelectionBarView className={classes.selectionBar} scope={scope.selectionBar} />
                )}
                <Menu id={MENU_ID}>
                    <Item onClick={this.#handleMarkCopyDataToClipboard}>Copiar informações</Item>
                    <Item onClick={this.#handleMarkShowDetailsClipboard}>Detalhar</Item>
                </Menu>
            </div>
        )
    }
}.view
