import type { SearchOption } from './sb_types'
import type { FilterType } from './sb_types'
import type { FilterOption } from './sb_types'

import { Observable, observe, Scope, NOOP_VOID, events } from 'wdc-cube'
import { v4 as uuid } from 'uuid'

type BaseEvent = events.BaseEvent
type KeyPressEvent = events.KeyPressEvent

export type { SearchOption, BaseEvent, KeyPressEvent }

@Observable
export class SearchBoxScope extends Scope {
    readonly uid = uuid()

    @observe() value = ''
    @observe() filterId: FilterType = '_'
    @observe() filterOptions: FilterOption[] = []
    @observe() options: SearchOption[] = []
    @observe() selecteds: SearchOption[] = []
    @observe() applyLabel = ''

    @observe() loading = false
    requestFocusOnInput = false

    repaintAll = NOOP_VOID

    onInputChange = Scope.SYNC_ACTION_TWO<BaseEvent, string>()
    onChange = Scope.SYNC_ACTION_THREE<BaseEvent, (string | SearchOption)[], string>()
    onKeyUp = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
    onKeyDown = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
    onPasteText = Scope.SYNC_ACTION_TWO<string, { accept: boolean }>()
    onFilterChanged = Scope.ASYNC_ACTION_ONE<FilterType>()
    onRemoveTag = Scope.ASYNC_ACTION_ONE<number>()
    onClosePopup = Scope.SYNC_ACTION
    onApply = Scope.ASYNC_ACTION
}
