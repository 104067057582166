import { ViewFactory } from 'wdc-cube-react'

import { RDStationScope } from '../rd_scopes'
import { RDStationView } from './rd_view'

import { HeaderScope } from '../rd_scopes'
import { HeaderView } from './rd-header'

export function registerViews(rv = ViewFactory.register) {
    rv(RDStationScope, RDStationView)
    rv(HeaderScope, HeaderView)
}
