//eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

const ProgressContainer = styled('div')({
    position: 'absolute',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    right: '50%',
    top: '50%'
})

const CenteredCircularProgress = styled(CircularProgress)({
    //alignSelf: 'center',
    alignItems: 'center',
    width: 50,
    height: 50
})

export default function TcmProgress() {
    return (
        <ProgressContainer>
            <CenteredCircularProgress />
        </ProgressContainer>
    )
}
