export type SearchMode = 'MAP' | 'GRAPH' | 'ACTING'

enum Operators {
    contains = 1,
    does_not_contain = 2,
    starts_with = 3,
    does_not_start_with = 4,
    is = 5,
    is_not = 6,
    is_blank = 7,
    is_not_blank = 8,
    is_empty = 9,
    is_not_empty = 10,
    is_in = 11,
    is_not_in = 12,
    is_less_then = 13,
    is_less_then_or_equal_to = 14,
    is_greater_then = 15,
    is_greater_then_or_equal_to = 16,
    in_range = 17,
    out_of_range = 18
}

export enum RangeOperator {
    is_less_then = Operators.is_less_then,
    lt = Operators.is_less_then,
    is_less_then_or_equal_to = Operators.is_less_then_or_equal_to,
    lte = Operators.is_less_then_or_equal_to,
    is_greater_then = Operators.is_greater_then,
    gt = Operators.is_greater_then,
    is_greater_then_or_equal_to = Operators.is_greater_then_or_equal_to,
    gte = Operators.is_greater_then_or_equal_to,
    is = Operators.is,
    is_not = Operators.is_not,
    is_blank = Operators.is_blank,
    is_not_blank = Operators.is_not_blank,
    in_range = Operators.in_range,
    out_of_range = Operators.out_of_range
}

export type DateTimeFilter = {
    operator: RangeOperator
    value?: string | number
    to?: string | number
}

export type SearchOption = {
    type: number
    filterType?: FilterType
    id: string
    label: string
    text: string
    hint?: string
}

export type SearchPagingDTO = {
    entries: SearchOption[]
    meta: {
        totalItems: number
        itemsPerPage: number
        totalPages: number
        currentPage: number
    }
}

export type FilterOption = {
    key: FilterType
    label: string
    compliance?: boolean
}

export type FilterType =
    | '_'
    | 'empresa'
    | 'cnpj'
    | 'emp.endereco'
    | 'emp.cidade'
    | 'emp.bairro'
    | 'emp.rua'
    | 'emp.cep'
    | 'cnae'
    | 'socio'
    | 'pessoa'
    | 'pes.cpf'
    | 'pes.cbo'
    | 'ato.cbo'
    | 'ato'
    | 'cid10'
    | 'pes.endereco'
    | 'pes.cidade'
    | 'pes.bairro'
    | 'pes.rua'
    | 'pes.cep'
    | 'estab'
    | 'cnes'
    | 'estab.endereco'
    | 'estab.cidade'
    | 'estab.bairro'
    | 'estab.rua'
    | 'estab.cep'
    | 'med.esp'
    | 'med.crm'
    | 'med.crm.uf'
    | 'ops.emp'
    | 'ops.prf'
    | 'crm.dta.inscricao'

type EntityTypeArgs = {
    code: number
    tag: string
    isAddress?: boolean
    isAddressZip?: boolean
    isAddressStreet?: boolean
    isAddressNeighborhood?: boolean
    isAddressCounty?: boolean
}

export class EntityType {
    #code: number
    #tag: string
    #isAddress: boolean
    #isAddressZip: boolean
    #isAddressStreet: boolean
    #isAddressNeighborhood: boolean
    #isAddressCounty: boolean

    constructor(args: EntityTypeArgs) {
        this.#code = args.code
        this.#tag = args.tag
        this.#isAddress = args.isAddress ?? false
        this.#isAddressZip = args.isAddressZip ?? false
        this.#isAddressStreet = args.isAddressStreet ?? false
        this.#isAddressNeighborhood = args.isAddressNeighborhood ?? false
        this.#isAddressCounty = args.isAddressCounty ?? false
    }

    public get code() {
        return this.#code
    }

    public get tag() {
        return this.#tag
    }

    public get isAddress() {
        return this.#isAddress
    }

    public get isAddressZip() {
        return this.#isAddressZip
    }

    public get isAddressStreet() {
        return this.#isAddressStreet
    }

    public get isAddressNeighborhood() {
        return this.#isAddressNeighborhood
    }

    public get isAddressCounty() {
        return this.#isAddressCounty
    }
}

export const EntityTypeID = {
    CNAE: new EntityType({ code: 3, tag: 'cnae' }),
    CBO: new EntityType({ code: 15, tag: 'cbo' }),
    CID: new EntityType({ code: 22, tag: 'cid' }),
    ATO: new EntityType({ code: 16, tag: 'ato' }),
    ATO_CBO: new EntityType({ code: 29, tag: 'ato.cbo' }),
    ESPMED: new EntityType({ code: 24, tag: 'esp.med' }),
    MED_CRM: new EntityType({ code: 30, tag: 'crm' }),
    MED_CRM_UF: new EntityType({ code: 25, tag: 'crm.uf' }),
    MED_CRM_DATE: new EntityType({ code: 28, tag: 'crm.dta.inscricao' }),

    company: new EntityType({ code: 1, tag: 'empresa' }),
    companyAddress: new EntityType({ code: 2, tag: 'emp.end', isAddress: true }),
    companyZip: new EntityType({ code: 5, tag: 'emp.cep', isAddressZip: true }),
    companyStreet: new EntityType({ code: 8, tag: 'emp.rua', isAddressStreet: true }),
    companyNeighborhood: new EntityType({ code: 6, tag: 'emp.bairro', isAddressNeighborhood: true }),
    companyCounty: new EntityType({ code: 7, tag: 'emp.cidade', isAddressCounty: true }),

    companyPartner: new EntityType({ code: 4, tag: 'sócio' }),

    cnesCompany: new EntityType({ code: 23, tag: 'estab' }),
    cnesAddress: new EntityType({ code: 17, tag: 'estab.end', isAddress: true }),
    cnesZip: new EntityType({ code: 18, tag: 'estab.zip', isAddressZip: true }),
    cnesStreet: new EntityType({ code: 19, tag: 'estab.rua', isAddressStreet: true }),
    cnesNeighborhood: new EntityType({ code: 20, tag: 'estab.bairro', isAddressNeighborhood: true }),
    cnesCounty: new EntityType({ code: 21, tag: 'estab.cidade', isAddressCounty: true }),

    person: new EntityType({ code: 9, tag: 'pessoa' }),
    personAddress: new EntityType({ code: 10, tag: 'pes.end', isAddress: true }),
    personZip: new EntityType({ code: 11, tag: 'pes.cep', isAddressZip: true }),
    personStreet: new EntityType({ code: 14, tag: 'pes.rua', isAddressStreet: true }),
    personNeighborhood: new EntityType({ code: 12, tag: 'pes.bairro', isAddressNeighborhood: true }),
    personCounty: new EntityType({ code: 13, tag: 'pes.cidade', isAddressCounty: true }),

    empOps: new EntityType({ code: 26, tag: 'ops.emp' }),
    prfOps: new EntityType({ code: 27, tag: 'ops.med' })
}

export type SBCriteria = Partial<{
    empNonActives: boolean
    pesNonActives: boolean
    pesCrmDataInscricao: DateTimeFilter[]
}>

export type TextualSearchFilters = {
    texts?: string[]

    // Endereco de Empresa
    empEnderecoTexts?: string[]
    empEnderecos?: string[]
    empCepTexts?: string[]
    empCepCodes?: string[]
    empCeps?: string[]
    empLogradouroTexts?: string[]
    empLogradouros?: string[]
    empBairroTexts?: string[]
    empBairros?: string[]
    empMunicipioTexts?: string[]
    empMunicipios?: string[]

    // Endereco de Pessoa
    pesEnderecoTexts?: string[]
    pesEnderecos?: string[]
    pesCepTexts?: string[]
    pesCepCodes?: string[]
    pesCeps?: string[]
    pesLogradouroTexts?: string[]
    pesLogradouros?: string[]
    pesBairroTexts?: string[]
    pesBairros?: string[]
    pesMunicipioTexts?: string[]
    pesMunicipios?: string[]
    pesCboTexts?: string[]
    pesCboCodes?: string[]
    pesCbos?: string[]

    // Endereco de Pessoa
    cnesEnderecoTexts?: string[]
    cnesEnderecos?: string[]
    cnesCepTexts?: string[]
    cnesCepCodes?: string[]
    cnesCeps?: string[]
    cnesLogradouroTexts?: string[]
    cnesLogradouros?: string[]
    cnesBairroTexts?: string[]
    cnesBairros?: string[]
    cnesMunicipioTexts?: string[]
    cnesMunicipios?: string[]

    empresaTexts?: string[]
    cnpjTexts?: string[]
    cnpjs?: string[]
    socioTexts?: string[]
    socios?: string[]
    cpfTexts?: string[]
    cpfs?: string[]
    cnaeTexts?: string[]
    cnaeCodes?: string[]
    cnaes?: string[]
    cidTexts?: string[]
    cids?: string[]
    atoTexts?: string[]
    atos?: string[]
    atoCboTexts?: string[]
    atoCboCodes?: string[]
    atoCbos?: string[]
    pessoas?: string[]
    pessoaTexts?: string[]
    estabelecimentos?: string[]
    estabelecimentoSaudeTexts?: string[]
    espMeds?: string[]
    espMedTexts?: string[]
    crmCodes?: string[]
    crmTexts?: string[]
    crmUfs?: string[]
    crmUfTexts?: string[]
    
    // Operadora Saude
    empOpsCodes?: string[]
    empOpsTexts?: string[]

    esaOpsCodes?: string[]
    esaOpsTexts?: string[]

    prfOpsCodes?: string[]
    prfOpsTexts?: string[]
}

export type TextualSearchRequest = {
    mode?: string
    compliance?: boolean
    global?: boolean
    nonActives?: boolean

    text?: string
    itensPerPage?: number

    empresa?: boolean
    cnpj?: boolean

    socio?: boolean
    cpf?: boolean

    pessoa?: boolean
    pessoaCpf?: boolean

    cnae?: boolean
    cid?: boolean
    ato?: boolean
    cboPes?: boolean
    cboAto?: boolean
    especialidadeMedica?: boolean
    crmUf?: boolean

    estabelecimentoSaude?: boolean
    crm?: boolean
    cnes?: boolean

    // Endereço sócio e empresa
    empEndereco?: boolean
    empCep?: boolean
    empLogradouro?: boolean
    empBairro?: boolean
    empMunicipio?: boolean

    // Endereço pessoas
    pesEndereco?: boolean
    pesCep?: boolean
    pesLogradouro?: boolean
    pesBairro?: boolean
    pesMunicipio?: boolean

    // Endereço estabelecimentos de saúde
    cnesEndereco?: boolean
    cnesCep?: boolean
    cnesLogradouro?: boolean
    cnesBairro?: boolean
    cnesMunicipio?: boolean

    // Operadora Saude
    empOps?: boolean
    esaOps?: boolean
    prfOps?: boolean

    filters?: TextualSearchFilters
    criteria?: SBCriteria
}
