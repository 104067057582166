import React from 'react'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'
import 'react-contexify/dist/ReactContexify.css'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import AddBoxIcon from '@mui/icons-material/AddBox'
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import HubIcon from '@mui/icons-material/Hub'

import { HoverBarScope } from '../tc_scopes'

const LOG = Logger.get('TC-HOVERBAR')

export type HoverBarViewProps = {
    className?: string
    scope: HoverBarScope
}

export const HoverBarView = function ({ className, scope }: HoverBarViewProps) {
    bindUpdate(React, scope)

    const handleOnLabelOnOffClicked = React.useCallback(() => scope.onToggleLabels().catch(LOG.caught), [scope])
    const handleOnSizeOnOffClicked = React.useCallback(() => scope.onToggleGraphSize().catch(LOG.caught), [scope])
    const handleOnZoomOutClicked = React.useCallback(() => scope.onZoomOut().catch(LOG.caught), [scope])
    const handleOnZoomInClicked = React.useCallback(() => scope.onZoomIn().catch(LOG.caught), [scope])

    return (
        <div className={className}>
            <Tooltip title={scope.labelEnabled ? 'Ocultar textos' : 'Mostrar textos'}>
                <IconButton onClick={handleOnLabelOnOffClicked}>
                    {scope.labelEnabled && <FormatListBulletedIcon sx={{ zoom: 1.5 }} />}
                    {!scope.labelEnabled && <BlurOnIcon sx={{ zoom: 1.5 }} />}
                </IconButton>
            </Tooltip>
            <Tooltip title={scope.allNodesEnabled ? 'Todos os nodos possíveis' : 'Nodos reduzidos'}>
                <IconButton onClick={handleOnSizeOnOffClicked}>
                    {scope.allNodesEnabled && <HubIcon sx={{ zoom: 1.5 }} />}
                    {!scope.allNodesEnabled && <AcUnitIcon sx={{ zoom: 1.5 }} />}
                </IconButton>
            </Tooltip>
            <IconButton onClick={handleOnZoomInClicked}>
                <AddBoxIcon sx={{ zoom: 1.5 }} />
            </IconButton>
            <IconButton onClick={handleOnZoomOutClicked}>
                <IndeterminateCheckBox sx={{ zoom: 1.5 }} />
            </IconButton>
        </div>
    )
}

export default HoverBarView
