import * as L from 'leaflet'
import * as turf from '@turf/turf'
import * as utils from './utils'
import { type GeoLocation } from '../tcm_service'
import FilterManager from '../tcm_filter_manager'

import BaseSelectionModeBehaviour from './selection_mode_behaviour'

export default class AreaSelectionModeBehaviour extends BaseSelectionModeBehaviour {
    locationList: GeoLocation[] = []

    override onBeforeScopeUpdate() {
        this.scope.slider.visible = false
    }

    override applyFilter(mgr: FilterManager): void {
        mgr.localizacaoPolygons.clear()
        if (this.locationList.length > 1) {
            mgr.localizacaoPolygons.add(this.locationList)
        }
    }

    override setZoom(value: number): void {
        if (value > 15) {
            this.scope.slider.value = 50
        } else if (value > 10) {
            this.scope.slider.value = 100
        } else {
            this.scope.slider.value = 1000
        }
    }

    override setMode(value: number): void {
        const scope = this.scope
        scope.mode = 'polygon'
        scope.slider.unit = 'm'
        scope.slider.min = 10
        scope.slider.max = 10000
        scope.slider.step = 10
        this.setZoom(value)
    }

    override clear() {
        super.clear()
        this.locationList.length = 0
        this.owner.filterManager.localizacaoPolygons.clear()
    }

    override hasFilter() {
        return this.locationList.length > 0
    }

    addLocation(location: GeoLocation) {
        this.locationList.push(location)
    }

    preparePoints() {
        const { scope } = this

        if (this.locationList.length == 2) {
            const line = turf.lineString([...this.locationList.map((v) => [v.lon, v.lat])])
            const bboxPolygon = turf.bboxPolygon(turf.bbox(line))

            if (bboxPolygon && bboxPolygon.geometry) {
                const coordinate = bboxPolygon.geometry.coordinates[0] ?? []
                this.locationList = coordinate.map((p) => {
                    return {
                        lat: p[1],
                        lon: p[0]
                    }
                })
            }
        }

        {
            const points = turf.featureCollection(this.locationList.map((v) => turf.point([v.lon, v.lat])))
            const convex = turf.convex(points)

            if (convex && convex.geometry) {
                const coordinate = convex.geometry.coordinates[0] ?? []
                this.locationList = coordinate.map((p) => {
                    return {
                        lat: p[1],
                        lon: p[0]
                    }
                })
            }

            this.locationList = utils.removeDuplicatedLocations(this.locationList)
        }
        scope.update()
    }

    doDraw(map: L.Map) {
        if (this.locationList.length === 1) {
            const v = this.locationList[0]
            const radiusInMeters = 50
            const circleLayer = L.circle([v.lat, v.lon], { radius: radiusInMeters }).addTo(map)
            this.removeAllLayers = () => {
                circleLayer.removeFrom(map)
                this.removeAllLayers = utils.staticNoopOther
            }
            return
        }

        const areaLayer = L.polygon(this.locationList.map((v) => [v.lat, v.lon])).addTo(map)
        this.removeAllLayers = () => {
            areaLayer.removeFrom(map)
            this.removeAllLayers = utils.staticNoopOther
        }
    }
}
