import _isNil from 'lodash/isNil'
import { ServiceError } from './ServiceError'
import { AppStorage } from './AppStorage'

const appStorage = AppStorage.singleton()

export class ServiceClient {
    protected static INSTANCE = new ServiceClient()

    public static readonly singleton = () => ServiceClient.INSTANCE

    async get<T>(url: URL | string) {
        const response = await fetch(url, { method: 'GET' })

        return (await getBody(response, 200)) as T
    }

    async authGet<T>(url: URL | string) {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `BEARER ${getAccessToken()}`
            }
        })

        return (await getBody(response, 200)) as T
    }

    async post<T>(url: URL | string, requestBody?: unknown) {
        const headers: HeadersInit = {}
        const cfg: RequestInit = { method: 'POST', headers }

        if (!_isNil(requestBody)) {
            headers['Content-Type'] = 'application/json;charset=utf-8'
            cfg.body = JSON.stringify(requestBody)
        }

        if (!_isNil(requestBody)) {
            cfg.body = JSON.stringify(requestBody)
        }

        const response = await fetch(url, cfg)
        return (await getBody(response, 201)) as T
    }

    async authPost<T>(url: URL | string, requestBody?: unknown) {
        const headers: HeadersInit = {
            Authorization: `BEARER ${getAccessToken()}`
        }

        const cfg: RequestInit = { method: 'POST', headers }

        if (!_isNil(requestBody)) {
            headers['Content-Type'] = 'application/json;charset=utf-8'
            cfg.body = JSON.stringify(requestBody)
        }

        const response = await fetch(url, cfg)
        return (await getBody(response, 201)) as T
    }

    async authRawPost(url: URL | string, requestBody?: unknown) {
        const headers: HeadersInit = {
            Authorization: `BEARER ${getAccessToken()}`
        }

        const cfg: RequestInit = { method: 'POST', headers }

        if (!_isNil(requestBody)) {
            headers['Content-Type'] = 'application/json;charset=utf-8'
            cfg.body = JSON.stringify(requestBody)
        }

        return  fetch(url, cfg)
    }
}

function getAccessToken() {
    const accessToken = appStorage.accessToken
    if (!accessToken) {
        throw new ServiceError(401, 'Unauthorized')
    }
    return accessToken
}

async function getBody(response: Response, checkStatusCode: number) {
    type BodyError = {
        statusCode?: number
        message?: string
    }

    const body: unknown = await response.json()

    if (response.status !== checkStatusCode) {
        throw new ServiceError(response.status, response.statusText)
    }

    const potencialError = body as BodyError
    if (potencialError.statusCode !== undefined) {
        throw new ServiceError(potencialError.statusCode, potencialError.message ?? 'Unexpected code')
    }

    return body
}
