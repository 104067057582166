import React from 'react'
import { Logger } from 'wdc-cube'

import { makeStyles } from 'tss-react/mui'

import { bindUpdate, ViewSlot } from 'wdc-cube-react'
import { RestrictedScope } from '../Restricted.scopes'

const LOG = Logger.get('RestrictedView')

const getOrMakeStyles = makeStyles()({
    view: {
        display: 'flex',
        backgroundColor: 'rgb(255, 0, 242)'
    }
})

type RestrictedViewProps = {
    className?: string
    scope: RestrictedScope
}

export function RestrictedView({ scope, className, ...props }: RestrictedViewProps) {
    LOG.debug('update')

    bindUpdate(React, scope)

    const { classes, cx } = getOrMakeStyles()

    return (
        <div className={cx(className, classes.view)} {...props}>
            <ViewSlot scope={scope} />
        </div>
    )
}
