import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFaixasScope } from './tcm_scopes'
import { TheConsumerMarketService, JunctionMode, NumberOperator, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasFaixaIdade')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasFaixaIdade extends Presenter<ChartFaixasScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.faixaDeIdade)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.faixaDeIdade
        this.scope.onItemClicked = this.__onFaixaDeIdade.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.idadeEmpresaEmAnos.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_por_faixa_de_idade: true,
                filter: filter
            })

            const data = {
                qtd_0_to_1: 0,
                qtd_1_to_3: 0,
                qtd_3_to_5: 0,
                qtd_5_to_10: 0,
                qtd_10_to_INF: 0
            }

            for (const bucket of resp.total_de_empresas_por_faixa_de_idade?.buckets ?? []) {
                switch (bucket.key as keyof typeof data) {
                    case 'qtd_0_to_1':
                        data.qtd_0_to_1 += bucket.doc_count
                        break
                    case 'qtd_1_to_3':
                        data.qtd_1_to_3 += bucket.doc_count
                        break
                    case 'qtd_3_to_5':
                        data.qtd_3_to_5 += bucket.doc_count
                        break
                    case 'qtd_5_to_10':
                        data.qtd_5_to_10 += bucket.doc_count
                        break
                    case 'qtd_10_to_INF':
                        data.qtd_10_to_INF += bucket.doc_count
                        break
                }
            }

            this.scope.dataset = [
                [data.qtd_10_to_INF, '10 anos ou mais', false],
                [data.qtd_5_to_10, '5 a 10 anos', false],
                [data.qtd_3_to_5, '3 a 5 anos', false],
                [data.qtd_1_to_3, '1 a 3 anos', false],
                [data.qtd_0_to_1, 'menos de 1 ano', false]
            ]

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.idadeEmpresaEmAnos

        const markRow = (rowIdx: number, value: boolean) => {
            const row = this.scope.dataset[rowIdx]
            row && (row[2] = value)
        }

        markRow(0, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.gte}-10`))
        markRow(1, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-5:10`))
        markRow(2, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-3:5`))
        markRow(3, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-1:3`))
        markRow(4, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.lt}-1`))

        this.scope.update()
    }

    private async __onFaixaDeIdade(rowIdx: number) {
        const idadeEmpresaPresenter = this.owner.filterPane.idadeEmpresa
        switch (rowIdx) {
            case 0:
                idadeEmpresaPresenter.toggleFrom(JunctionMode.OR, 10, true)
                break
            case 1:
                idadeEmpresaPresenter.toggleRange(JunctionMode.OR, 5, 10)
                break
            case 2:
                idadeEmpresaPresenter.toggleRange(JunctionMode.OR, 3, 5)
                break
            case 3:
                idadeEmpresaPresenter.toggleRange(JunctionMode.OR, 1, 3)
                break
            case 4:
                idadeEmpresaPresenter.toggleTo(JunctionMode.OR, 1, false)
                break
        }
        idadeEmpresaPresenter.publish()
        this.__markSelected()

        this.__parent.fetchData(idadeEmpresaPresenter.size ? 'totalPorFaixaDeIdade' : '')
    }
}
