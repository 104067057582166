import { IPresenterOwner, Presenter, Logger, NOOP_VOID, NOOP_PROMISE_VOID, events } from 'wdc-cube'

import objectHash from 'object-hash'
import lodash from 'src/utils/lodash'

import {
    EntityTypeID,
    type FilterOption,
    type SearchOption,
    type SearchPagingDTO,
    type FilterType,
    type SearchMode,
    type TextualSearchRequest,
    type TextualSearchFilters,
    type SBCriteria,
    type EntityType,
    type DateTimeFilter
} from './sb_types'

import { MainPresenter } from 'src/main'
import { SearchBoxScope } from './sb_scopes'
import searchService, { type TextualSearchResponse } from 'src/components/searchbox/sb_service'

const LOG = Logger.get('SearchBoxPresenter')

type FilterHandler = {
    type?: EntityType
    filterId?: FilterType
    getBaseRequest(): TextualSearchRequest
    search: (this: SearchBoxPresenter, prv: PrivateContext, result: SearchPagingDTO, text: string) => Promise<void>
    applyFilter?: (request: TextualSearchRequest, option: SearchOption) => void
}

const TC_FILTER_MENU_OPTIONS_ARRAY = [] as FilterOption[]
const TM_FILTER_MENU_OPTIONS_ARRAY = [] as FilterOption[]
const TA_FILTER_MENU_OPTIONS_ARRAY = [] as FilterOption[]
const HANDLER_BY_FILTER_TYPE_MAP = new Map<string, FilterHandler>()
const HANDLER_BY_FILTER_TYPEID_MAP = new Map<number, FilterHandler>()

let staticInitialization = () => {
    initializeFilterMenuOptionsArray()
    initializeHandlerByFilterTypeRec()
    staticInitialization = NOOP_VOID
}

type Selection = {
    type: number
    items: Map<string, SearchOption>
}

type HandleSearchArgs = {
    result: SearchPagingDTO
    text: string
    tag: string
    baseRequest: TextualSearchRequest
    acceptItem: (tp: number) => boolean
}

// prettier-ignore
type PrivateContext = {
    app: MainPresenter
    handleFreeSearch: (result: SearchPagingDTO, text: string) => Promise<void>
    handleSearch: (args: HandleSearchArgs ) => Promise<void>
}

export class SearchBoxPresenter extends Presenter<SearchBoxScope> {
    public onChanged?: (selecteds: SearchOption[]) => SearchOption[]
    public onSelected?: (selecteds: SearchOption[]) => void
    public onPasteText?: (text: string, response: { accept: boolean }) => void
    public onPrepareSearchRequest?: (request: TextualSearchRequest) => boolean
    public onApply?: (request: TextualSearchRequest) => Promise<void>
    public onSearch?: (request: TextualSearchRequest) => Promise<TextualSearchResponse>

    protected readonly _app: MainPresenter
    private readonly __mapSelectionMap = new Map<number, Selection>()

    private __mode: SearchMode = 'GRAPH'
    private __showInactives = false
    private __applying = false
    private __pageSize = 20
    private __searchText?: string
    private __doSearchCbHandler?: NodeJS.Timeout
    private __acceptSelectByEnterTimeout?: NodeJS.Timeout
    private __acceptSelectByEnterKey = false
    private __allMenuOptionsArray = TC_FILTER_MENU_OPTIONS_ARRAY

    private readonly __doSearchCb = this.__doSearch.bind(this)
    private readonly __doCancelSelectByEnterKey = this.__cancelSelectByEnterKey.bind(this)

    #privateCtx: PrivateContext

    constructor(app: MainPresenter, owner: IPresenterOwner, scope: SearchBoxScope) {
        staticInitialization()
        super(owner, scope)
        this._app = app
        this.#privateCtx = {
            app,
            handleFreeSearch: this.__handleFreeSearch,
            handleSearch: this.__handleSearch
        }
    }

    get mode() {
        return this.__mode
    }

    set mode(value: SearchMode) {
        if (this.__mode !== value) {
            this.__mode = value
            if (value === 'ACTING') {
                this.__allMenuOptionsArray = TA_FILTER_MENU_OPTIONS_ARRAY
            } else if (value === 'MAP') {
                this.__allMenuOptionsArray = TM_FILTER_MENU_OPTIONS_ARRAY
            } else {
                this.__allMenuOptionsArray = TC_FILTER_MENU_OPTIONS_ARRAY
            }
            this.update()
        }
    }

    get pageSize() {
        return this.__pageSize
    }

    set pageSize(value: number) {
        if (this.__pageSize !== value) {
            this.__pageSize = value
            this.update()
        }
    }

    get showInactives() {
        return this.__showInactives
    }

    set showInactives(value: boolean) {
        this.__showInactives = value
    }

    // prettier-ignore
    initialize() {
        this.scope.onInputChange = this.__handleInputChange.bind(this)
        this.scope.onChange = this.__handleChange.bind(this)
        this.scope.onKeyUp = this.__handleKeyUp.bind(this)
        this.scope.onKeyDown = this.__handleKeyDown.bind(this)
        this.scope.onPasteText = this.__handlePasteText.bind(this)
        this.scope.onFilterChanged = this.__handleFilterChanged.bind(this)
        this.scope.onRemoveTag = this.__handleRemoveTag.bind(this)
        this.scope.onClosePopup = this.__handleClosePopup.bind(this)
        this.scope.onApply = this.__handleApply.bind(this)

        this.onComplianceChanged(this._app.compliance)
        this.scope.applyLabel = static_computeLabel(this.__mode, this.scope.filterId, {})
    }

    release() {
        this.scope.onInputChange = NOOP_VOID
        this.scope.onChange = NOOP_VOID
        this.scope.onKeyUp = NOOP_VOID
        this.scope.onPasteText = NOOP_VOID
        this.scope.onFilterChanged = NOOP_PROMISE_VOID
        this.scope.onClosePopup = NOOP_VOID
        this.scope.filterOptions = []
        this.__cancelSearch()
        this.__cancelSelectByEnterKey()
    }

    clearValue() {
        this.scope.value = ''
    }

    clearSelections() {
        this.__mapSelectionMap.clear()
        this.scope.selecteds = []
    }

    search(text: string) {
        this.__cancelSearch()
        this.__searchText = text.trim()
        this.scope.options = []
        this.__doSearchCbHandler = setTimeout(this.__doSearchCb, 1000)
    }

    describeRequest(req: TextualSearchRequest, labels?: string[]) {
        req.mode = this.mode
        return searchService.describe(req, labels)
    }

    useFreeFilter() {
        this.clearValue()
        this.scope.filterId = '_'
        this.scope.options = []
        this.scope.repaintAll()
    }

    async apply() {
        this.__handleApply()
    }

    // :: Events

    public onComplianceChanged(compliance: boolean) {
        const filterOptions = compliance
            ? this.__allMenuOptionsArray
            : this.__allMenuOptionsArray.filter((v) => {
                  if (v.compliance && !this._app.compliance) {
                      return false
                  }

                  return true
              })

        this.scope.filterOptions = filterOptions
    }

    private __handleClosePopup() {
        this.scope.options = []
        this.__cancelSearch()
        this.__cancelSelectByEnterKey()
    }

    private __handleKeyUp(evt: events.KeyPressEvent) {
        this.__handleAcceptSelectByEnterKey(evt)
    }

    private __handleKeyDown(evt: events.KeyPressEvent) {
        if(evt.code === 'ArrowDown') {
            this.__searchText = this.scope.value.trim()
            this.__doSearch()
        }
    }

    private __handleAcceptSelectByEnterKey(evt: events.KeyPressEvent) {
        const trimmedValue = this.scope.value.trim()
        if (evt.key === 'ArrowDown' && this.scope.options.length === 0 && !trimmedValue) {
            this.__searchText = '*'
            this.__doSearch()
            return
        }

        if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
            if (this.__acceptSelectByEnterTimeout) {
                clearTimeout(this.__acceptSelectByEnterTimeout)
            }
            this.__acceptSelectByEnterKey = true
            this.__acceptSelectByEnterTimeout = setTimeout(this.__doCancelSelectByEnterKey, 1000)
        }
    }

    private async __handleInputChange(_evt: events.BaseEvent, newValue: string) {
        this.search(newValue)
    }

    private async __handleChange(evt: events.BaseEvent, newValues: (string | SearchOption)[], reason: string) {
        this.__cancelSearch()

        if (newValues.length === 0 || reason === 'removeOption') {
            return
        }

        if (evt.type !== 'click' && !this.__acceptSelectByEnterKey) {
            const searchText = this.scope.value.trim()
            if (!searchText) {
                return
            }
            newValues = [searchText]
        }

        this.__handleRealChange(newValues)
        this.scope.filterId = '_'
    }

    private __handleRealChange(newValues: (string | SearchOption)[]) {
        const filterHandler = HANDLER_BY_FILTER_TYPE_MAP.get(this.scope.filterId)

        const newOptions: SearchOption[] = []
        for (const value of newValues) {
            if (!value) {
                continue
            }

            let filterId = this.scope.filterId

            if (lodash.isString(value)) {
                let text = value.trim()

                let itemFilterHandler = filterHandler
                if (!itemFilterHandler || !itemFilterHandler.type) {
                    const words = value.split(/\s/g)
                    if (words[0].endsWith(':')) {
                        words[0] = words[0].substring(0, words[0].length - 1)
                    }

                    itemFilterHandler = HANDLER_BY_FILTER_TYPE_MAP.get(words[0])
                    if (itemFilterHandler) {
                        text = words.splice(1).join(' ')
                        filterId = itemFilterHandler.filterId ?? filterId
                    }
                }

                if (text) {
                    let label = '*'
                    let typeCode = -1
                    if (itemFilterHandler && itemFilterHandler.type) {
                        label = itemFilterHandler.type.tag
                        typeCode = itemFilterHandler.type.code
                    }

                    newOptions.push({
                        id: `hash:${objectHash(value)}`,
                        label,
                        text,
                        type: typeCode,
                        filterType: filterId
                    })
                }
            } else {
                let label = '*'
                let typeCode = -1

                let itemFilterHandler = filterHandler
                if (value.type) {
                    itemFilterHandler = HANDLER_BY_FILTER_TYPEID_MAP.get(value.type) ?? filterHandler
                }

                if (itemFilterHandler && itemFilterHandler.type) {
                    label = itemFilterHandler.type.tag
                    typeCode = itemFilterHandler.type.code
                    filterId = itemFilterHandler.filterId ?? filterId
                }

                newOptions.push({ ...value, type: typeCode, filterType: filterId, label })
            }
        }

        this.clearValue()
        this.scope.options = []

        for (const option of newOptions) {
            let selection = this.__mapSelectionMap.get(option.type)
            if (!selection) {
                selection = {
                    type: option.type,
                    items: new Map()
                }
                this.__mapSelectionMap.set(option.type, selection)
            }

            selection.items.set(option.id, option)
        }

        let selecteds: SearchOption[] = []
        for (const selection of this.__mapSelectionMap.values()) {
            selecteds.push(...selection.items.values())
        }

        if (this.onChanged) {
            const oldSelecteds = selecteds
            selecteds = this.onChanged(selecteds) ?? oldSelecteds
            if (oldSelecteds !== selecteds) {
                this.__mapSelectionMap.clear()
                for (const option of selecteds) {
                    let selection = this.__mapSelectionMap.get(option.type)
                    if (!selection) {
                        selection = {
                            type: option.type,
                            items: new Map()
                        }
                        this.__mapSelectionMap.set(selection.type, selection)
                    }
                    selection.items.set(option.id, option)
                }
            }
        }

        this.scope.selecteds = selecteds
        if (this.onSelected) {
            this.onSelected(selecteds)
        }

        // LOG.debug('onChanged:', selecteds)
    }

    private async __handleFilterChanged(newFilterId: FilterType) {
        this.__cancelSearch()
        this.__cancelSelectByEnterKey()

        this.scope.filterId = newFilterId
        this.scope.value = ''
        this.__searchText = '*'
        this.scope.applyLabel = static_computeLabel(this.__mode, newFilterId)

        this.scope.repaintAll()
        this.scope.requestFocusOnInput = true
        await new Promise<void>((resolve) => this.__doSearch(resolve))
    }

    private async __handleRemoveTag(tagIndex: number) {
        const filterOption = this.scope.selecteds[tagIndex]
        if (filterOption) {
            const selection = this.__mapSelectionMap.get(filterOption.type)
            if (selection) {
                selection.items.delete(filterOption.id)
                if (selection.items.size === 0) {
                    this.__mapSelectionMap.delete(filterOption.type)
                }

                const selecteds: SearchOption[] = []
                for (const selection of this.__mapSelectionMap.values()) {
                    selecteds.push(...selection.items.values())
                }
                this.scope.selecteds = selecteds
            }
        }
    }

    private __handlePasteText(text: string, response: { accept: boolean }) {
        response.accept = true
        if (this.onPasteText) {
            this.onPasteText(text, response)
        }
    }

    private async __handleApply() {
        if (this.__applying) {
            return
        }

        this.__applying = true
        try {
            LOG.debug('handleApply')

            const value = this.scope.value.trim()
            if (value && value !== '*') {
                this.__handleRealChange([value])
            }

            this.scope.value = ''
            this.scope.options = []

            if (this.onApply) {
                const request: TextualSearchRequest = {}
                this.__configRequestFilters(request)
                static_computeLabel(this.__mode, this.scope.filterId, request)
                await this.onApply(request)
            }
        } finally {
            this.__applying = false
        }
    }

    // :: Actions

    private __doSearch(cb?: () => void) {
        this.__cancelSearch()

        const requestText = this.__searchText
        if (requestText) {
            this.scope.loading = true

            this.__onSearch(this.scope.filterId, requestText, this.__pageSize)
                .then(this.__handleResponse.bind(this))
                .catch((caught) => {
                    LOG.error('Searching options', caught)
                })
                .finally(() => {
                    if (this.__searchText && this.__searchText !== requestText) {
                        this.scope.options = []
                        this.__doSearch(cb)
                    } else {
                        this.scope.loading = false
                        cb && cb()
                    }
                })
        } else {
            this.scope.loading = false
        }
    }

    private __handleResponse(response: SearchPagingDTO | null | undefined) {
        if (!response) {
            return
        }

        if (response.entries.length > this.__pageSize) {
            response.entries.length = this.__pageSize
        }

        this.scope.options = response.entries
    }

    private __cancelSearch() {
        if (this.__doSearchCbHandler) {
            clearTimeout(this.__doSearchCbHandler)
            this.__doSearchCbHandler = undefined
        }
    }

    private __cancelSelectByEnterKey() {
        this.__acceptSelectByEnterKey = false
        if (this.__acceptSelectByEnterTimeout) {
            clearTimeout(this.__acceptSelectByEnterTimeout)
            this.__acceptSelectByEnterTimeout = undefined
        }
    }

    private async __onSearch(filterId: FilterType, text: string, maxPageSize: number) {
        const result: SearchPagingDTO = {
            entries: [],
            meta: {
                totalItems: 0,
                itemsPerPage: maxPageSize,
                totalPages: 0,
                currentPage: 0
            }
        }

        const h = HANDLER_BY_FILTER_TYPE_MAP.get(filterId)
        if (h) {
            await h.search.call(this, this.#privateCtx, result, text)
        }

        return result
    }

    private __configRequestFilters(request: TextualSearchRequest) {
        if (this._app.compliance) {
            request.compliance = true
        }

        if (this.__showInactives) {
            request.nonActives = true
        }

        const filters: TextualSearchFilters = {}
        const criteria: SBCriteria = {}

        for (const selection of this.__mapSelectionMap.values()) {
            for (const selectionItem of selection.items.values()) {
                if (!selectionItem.filterType) {
                    continue
                }

                const h = HANDLER_BY_FILTER_TYPE_MAP.get(selectionItem.filterType)
                if (h && h.applyFilter) {
                    if (this.onPrepareSearchRequest) {
                        const req: TextualSearchRequest = {}
                        Object.assign(req, h.getBaseRequest())
                        req.text = selectionItem.text
                        req.filters = filters
                        req.criteria = criteria
                        if (this.onPrepareSearchRequest(req)) {
                            continue
                        }
                    }
                    h.applyFilter(request, selectionItem)
                }
            }
        }

        if (!lodash.isEmpty(filters)) {
            request.filters = filters
        }

        if (!lodash.isEmpty(criteria)) {
            request.criteria = criteria
        }

        return request
    }

    private async __handleFreeSearch(result: SearchPagingDTO, text: string) {
        text = text.trim()
        if (text) {
            const words = text.split(/\s/g)
            if (words[0].endsWith(':')) {
                words[0] = words[0].substring(0, words[0].length - 1)
            }

            const filterHandler = HANDLER_BY_FILTER_TYPE_MAP.get(words[0])
            if (filterHandler) {
                text = words.splice(1).join(' ')
                await filterHandler.search.call(this, this.#privateCtx, result, text || '*')
                return
            }
        }

        // LOG.debug('handleFreeSearch')

        const request: TextualSearchRequest = {}
        request.mode = this.mode
        request.empresa = true
        request.socio = true
        request.global = true
        request.text = text || '*'
        this.__configRequestFilters(request)

        const response = await searchService.search(request, this.onSearch)

        Object.assign(result.meta, response.meta)
        for (const item of response.entries) {
            const handler = HANDLER_BY_FILTER_TYPEID_MAP.get(item.type)
            if (!handler || !handler.type) {
                continue
            }

            result.entries.push({
                id: item.id,
                label: handler.type.tag,
                text: item.name,
                type: item.type
            })
        }
    }

    private async __handleSearch({ result, text, tag, baseRequest, acceptItem }: Readonly<HandleSearchArgs>) {
        LOG.debug(`handleSearch for ${tag}`)
        const request = this.__configRequestFilters({ ...baseRequest, text })
        request.mode = this.mode

        const response = await searchService.search(request, this.onSearch)

        Object.assign(result.meta, response.meta)
        for (const item of response.entries) {
            if (!acceptItem(item.type)) {
                continue
            }

            result.entries.push({
                id: item.id,
                label: tag,
                text: item.name,
                type: item.type
            })
        }
    }
}

type FilterKeys = {
    id: keyof TextualSearchFilters
    text: keyof TextualSearchFilters
}

// :: Private Static Methods

function static_computeLabel(mode: SearchMode, filterId: FilterType, request?: TextualSearchRequest) {
    if (filterId === '_') {
        const ff = request?.filters
        if (ff) {
            if ((ff.cnpjs?.length ?? 0) > 0 || (ff.cnpjTexts?.length ?? 0) > 0 || (ff.empresaTexts?.length ?? 0) > 0) {
                filterId = 'empresa'
            } else if ((ff.socios?.length ?? 0) > 0 || (ff.socioTexts?.length ?? 0) > 0) {
                filterId = 'socio'
            } else if ((ff.estabelecimentos?.length ?? 0) > 0 || (ff.estabelecimentoSaudeTexts?.length ?? 0) > 0) {
                filterId = 'estab'
            } else if ((ff.empEnderecos?.length ?? 0) > 0 || (ff.empEnderecoTexts?.length ?? 0) > 0) {
                filterId = 'emp.endereco'
            } else if ((ff.pesEnderecos?.length ?? 0) > 0 || (ff.pesEnderecoTexts?.length ?? 0) > 0) {
                filterId = 'pes.endereco'
            }
        }
    }
    if (mode === 'MAP') {
        switch (filterId) {
            case 'cnpj':
            case 'empresa':
                request && (request.empresa = true)
                return 'Plotar empresas'
            case 'emp.endereco':
                request && (request.empEndereco = true)
                return 'Plotar endereços'

            case 'cnes':
            case 'estab':
                request && (request.estabelecimentoSaude = true)
                return 'Plotar estabelecimentos'
            case 'estab.endereco':
                request && (request.cnesEndereco = true)
                return 'Plotar endereços'

            case 'pes.cpf':
            case 'pessoa':
                request && (request.pessoa = true)
                return 'Plotar pessoas'
            case 'pes.endereco':
                request && (request.pesEndereco = true)
                return 'Plotar endereços'

            case 'socio':
                request && (request.socio = true)
                return 'Plotar sócios'
        }
        return ''
    }

    if (mode === 'ACTING') {
        return 'Filtrar'
    }

    return ''
}

function static_applyFilter(
    request: TextualSearchRequest,
    option: SearchOption,
    filterKeys: FilterKeys,
    acceptItem: (tp: number) => boolean
) {
    if (!acceptItem(option.type)) {
        return
    }

    let filter = request.filters
    if (!filter) {
        filter = request.filters = {}
    }

    if (option.id.startsWith('hash:')) {
        let textFilters = filter[filterKeys.text]
        if (!textFilters) {
            textFilters = filter[filterKeys.text] = []
        }
        textFilters.push(option.text)
    } else {
        let idFilters = filter[filterKeys.id]
        if (!idFilters) {
            idFilters = filter[filterKeys.id] = []
        }
        idFilters.push(option.id)
    }
}

function static_buildFilterHandler(
    filterId: FilterType,
    type: EntityType,
    tag: string,
    baseRequest: TextualSearchRequest,
    filterKeys: FilterKeys
): FilterHandler {
    const checkOk = (typeCode: number) => typeCode === type.code

    let acceptItem = checkOk

    const getEntityTypeById = (typeCode: number) => {
        const h = HANDLER_BY_FILTER_TYPEID_MAP.get(typeCode)
        return h ? h.type : undefined
    }

    if (type === EntityTypeID.person) {
        acceptItem = (typeCode) => {
            return EntityTypeID.person.code === typeCode || EntityTypeID.companyPartner.code === typeCode
        }
    }

    if (type.isAddress) {
        acceptItem = (typeCode) => {
            const otherType = getEntityTypeById(typeCode)
            return otherType ? otherType.isAddress : checkOk(typeCode)
        }
    } //
    else if (type.isAddressCounty) {
        acceptItem = (typeCode) => {
            const otherType = getEntityTypeById(typeCode)
            return otherType ? otherType.isAddressCounty : checkOk(typeCode)
        }
    } //
    else if (type.isAddressNeighborhood) {
        acceptItem = (typeCode) => {
            const otherType = getEntityTypeById(typeCode)
            return otherType ? otherType.isAddressNeighborhood : checkOk(typeCode)
        }
    } //
    else if (type.isAddressStreet) {
        acceptItem = (typeCode) => {
            const otherType = getEntityTypeById(typeCode)
            return otherType ? otherType.isAddressStreet : checkOk(typeCode)
        }
    } //
    else if (type.isAddressZip) {
        acceptItem = (typeCode) => {
            const otherType = getEntityTypeById(typeCode)
            return otherType ? otherType.isAddressZip : checkOk(typeCode)
        }
    }

    // prettier-ignore
    async function handleSearchDelegate(this: SearchBoxPresenter, priv: PrivateContext, result: SearchPagingDTO, text: string) {
        return priv.handleSearch.call(this, { result, text, tag, baseRequest, acceptItem })
    }

    //applyFilter?: (request: TextualSearchRequest, options: SearchOption[]) => void
    function applyFilter(request: TextualSearchRequest, option: SearchOption) {
        static_applyFilter(request, option, filterKeys, acceptItem)
    }

    function getBaseRequest() {
        return lodash.cloneDeep(baseRequest)
    }

    return { filterId, type: type, getBaseRequest, search: handleSearchDelegate, applyFilter }
}

// :: Initializers

function initializeFilterMenuOptionsArray() {
    const tcExclusion = new Set<string>(['ato', 'ato.cbo', 'cid10', 'med.esp', 'ops.emp', 'ops.prf', 'med.crm', 'med.crm.uf'])
    const tmExclusion = new Set<string>(['ops.emp', 'ops.prf', 'med.crm', 'med.crm.uf'])
    const taExclusion = new Set<string>(['socio'])
    const push = (option: FilterOption) => {
        if (!tcExclusion.has(option.key)) {
            TC_FILTER_MENU_OPTIONS_ARRAY.push(option)
        }

        if (!tmExclusion.has(option.key)) {
            TM_FILTER_MENU_OPTIONS_ARRAY.push(option)
        }

        if (!taExclusion.has(option.key)) {
            if (option.label.startsWith('Pessoa ::')) {
                const taOption: FilterOption = { ...option }
                if (taOption.key === 'pessoa' || taOption.key === 'pes.cbo') {
                    taOption.compliance = false
                }
                taOption.label = `Médico ::${taOption.label.substring(9)}`
                TA_FILTER_MENU_OPTIONS_ARRAY.push(taOption)
            } else {
                TA_FILTER_MENU_OPTIONS_ARRAY.push(option)
            }
        }
    }

    push({ key: '_', label: 'Livre' })
    push({ key: 'empresa', label: 'Empresa :: Nome' })
    push({ key: 'cnpj', label: 'Empresa :: CNPJ' })
    push({ key: 'emp.endereco', label: 'Empresa :: Endereço' })
    push({ key: 'emp.cidade', label: 'Empresa :: Cidade' })
    push({ key: 'emp.bairro', label: 'Empresa :: Bairro' })
    push({ key: 'emp.rua', label: 'Empresa :: Rua' })
    push({ key: 'emp.cep', label: 'Empresa :: CEP' })
    push({ key: 'cnae', label: 'Empresa :: CNAE' })
    push({ key: 'socio', label: 'Sócio :: Nome' })
    push({ key: 'pessoa', label: 'Pessoa :: Nome', compliance: true })
    push({ key: 'pes.cpf', label: 'Pessoa :: CPF', compliance: true })
    push({ key: 'pes.endereco', label: 'Pessoa :: Endereço', compliance: true })
    push({ key: 'pes.cidade', label: 'Pessoa :: Cidade', compliance: true })
    push({ key: 'pes.bairro', label: 'Pessoa :: Bairro', compliance: true })
    push({ key: 'pes.rua', label: 'Pessoa :: Rua', compliance: true })
    push({ key: 'pes.cep', label: 'Pessoa :: CEP', compliance: true })
    push({ key: 'pes.cbo', label: 'Pessoa :: CBO', compliance: true })
    push({ key: 'ato.cbo', label: 'Pessoa :: CBO do Ato' })
    push({ key: 'ato', label: 'Pessoa :: ATO' })
    push({ key: 'cid10', label: 'Pessoa :: CID10' })
    push({ key: 'med.esp', label: 'Médico :: Especialidade' })
    push({ key: 'med.crm', label: 'Médico :: CRM' })
    push({ key: 'med.crm.uf', label: 'Médico :: CRM :: UF' })

    TA_FILTER_MENU_OPTIONS_ARRAY.push({
        key: 'crm.dta.inscricao',
        label: 'Médico :: CRM :: Data Inscrição',
        compliance: false
    })

    push({ key: 'estab', label: 'E.Saúde :: Nome' })
    push({ key: 'cnes', label: 'E.Saúde :: CNES' })
    push({ key: 'estab.endereco', label: 'E.Saúde :: Endereço' })
    push({ key: 'estab.cidade', label: 'E.Saúde :: Cidade' })
    push({ key: 'estab.bairro', label: 'E.Saúde :: Bairro' })
    push({ key: 'estab.rua', label: 'E.Saúde :: Rua' })
    push({ key: 'estab.cep', label: 'E.Saúde :: CEP' })

    push({ key: 'ops.emp', label: 'Operadora :: Empresa/E.Saúde' })
    push({ key: 'ops.prf', label: 'Operadora :: Profissional' })
}

// prettier-ignore
function initializeHandlerByFilterTypeRec() {
    const tp = EntityTypeID
    const bb = static_buildFilterHandler

    function tags(tags: string[], subtags: string[]) {
        const a: string[] = []
        for(const tag of tags) {
            for(const subtag of subtags) {
                a.push(`${tag}.${subtag}`)
            }
        }
        return a
    }
    
    // prettier-ignore
    async function handleFreeSearchDelegate(this: SearchBoxPresenter, prv: PrivateContext, result: SearchPagingDTO, text: string) {
        await prv.handleFreeSearch.call(this, result, text)
    }

    // prettier-ignore
    function handleFreeapplyFilter(request: TextualSearchRequest, option: SearchOption) {
        static_applyFilter(request, option, { id: "texts", text: "texts" }, (tp) => tp ==-1)
    }

    function push(alias: string[], handler: FilterHandler) {
        if (handler.type) {
            HANDLER_BY_FILTER_TYPEID_MAP.set(handler.type.code, handler)
        }

        if(alias.length === 1) {
            HANDLER_BY_FILTER_TYPE_MAP.set(alias[0], handler)
            return
        }

        for(const aliasItem of alias) {
            HANDLER_BY_FILTER_TYPE_MAP.set(aliasItem, handler)
        }
    }

    push(['_'], 
        { search: handleFreeSearchDelegate, getBaseRequest: () => ({}), applyFilter: handleFreeapplyFilter })

    push(['ato'], 
        bb('ato', tp.ATO, 'Ato', { ato: true }, {id: 'atos', text: 'atoTexts'}))

    push(['ato.cbo'], 
        bb('ato.cbo', tp.ATO_CBO, 'CBO', { cboAto: true }, {id: 'atoCbos', text: 'atoCboTexts'}))
    
    push(['cid10', 'cid'], 
        bb('cid10', tp.CID, 'CID10', { cid: true }, {id: 'cids', text: 'cidTexts'}))

    push(['cnae'],
        bb('cnae', tp.CNAE, 'CNAE', { cnae: true }, {id: 'cnaes', text: 'cnaeTexts'}))

    push( ['empresa', 'emp', 'pj'],
        bb('empresa',tp.company, 'Empresa', { empresa: true }, {id: 'cnpjs', text: 'empresaTexts'}))

    push(['cnpj'],
        bb('cnpj', tp.company, 'Empresa', { cnpj: true }, {id: 'cnpjs', text: 'cnpjTexts'}))

    push(tags(['empresa', 'emp'], ['endereco', 'endereço', 'end']),
        bb('emp.endereco', tp.companyAddress, 'Endereço', { empEndereco: true }, {id: 'empEnderecos', text: 'empEnderecoTexts'}))

    push([...tags(['empresa', 'emp'], ['cidade', 'municipio', 'município', 'muni', 'mun']), 'municipio', 'município', 'mun'],
        bb('emp.cidade', tp.companyCounty, 'Cidade', { empMunicipio: true }, {id: 'empMunicipios', text: 'empMunicipioTexts'}))

    push(['empresa.bairro', 'emp.bairro', 'bairro'],
        bb('emp.bairro', tp.companyNeighborhood, 'Bairro', { empBairro: true }, {id: 'empBairros', text: 'empBairroTexts'}))

    push([...tags(['empresa', 'emp'], ['rua', 'logradouro', 'logra']), 'rua', 'logradouro', 'logra'],
        bb('emp.rua', tp.companyStreet, 'Rua', { empLogradouro: true }, {id: 'empLogradouros', text: 'empLogradouroTexts'}))

    push(['empresa.cep', 'emp.cep', 'cep'],
        bb('emp.cep', tp.companyZip, 'CEP', { empCep: true }, {id: 'empCeps', text: 'empCepTexts'}))

    push( ['socio', 'sócio', 'associado', 'ass'],
        bb('socio',tp.companyPartner, 'Sócio', { socio: true }, {id: 'socios', text: 'socioTexts'}))

    push(['pessoa', 'pes'],
        bb('pessoa', tp.person, 'Pessoa', { pessoa: true }, {id: 'pessoas', text: 'pessoaTexts'}))

    push(['pessoa.cpf', 'pes.cpf', 'cpf'],
        bb('pes.cpf', tp.person, 'Pessoa', { pessoaCpf: true }, {id: 'cpfs', text: 'cpfTexts'}))

    push(['pessoa.cbo', 'pes.cbo', 'cbo'],
        bb('pes.cbo', tp.CBO, 'CBO', { cboPes: true }, {id: 'pesCbos', text: 'pesCboTexts'}))

    push([...tags(['pessoa', 'pes'], ['endereco','endereço', 'end','res', 'residencia']), 'res', 'residente', 'residencia'],
        bb('pes.endereco', tp.personAddress, 'Endereço', { pesEndereco: true }, {id: 'pesEnderecos', text: 'pesEnderecoTexts'}))

    push(tags(['pessoa', 'pes', 'res'], ['cidade', 'municipio', 'município', 'muni', 'mun']),
        bb('pes.cidade', tp.personCounty, 'Cidade', { pesMunicipio: true }, {id: 'pesMunicipios', text: 'pesMunicipioTexts'}))

    push(['pessoa.bairro', 'pes.bairro'],
        bb('pes.bairro', tp.personNeighborhood, 'Bairro', { pesBairro: true }, {id: 'pesBairros', text: 'pesBairroTexts'}))

    push(tags(['pessoa', 'pes', 'res'], ['rua', 'logradouro', 'logra']),
        bb('pes.rua', tp.personStreet, 'Rua', { pesLogradouro: true }, {id: 'pesLogradouros', text: 'pesLogradouroTexts'}))

    push(['pessoa.cep', 'pes.cep'],
        bb('pes.cep', tp.personZip, 'CEP', { pesCep: true }, {id: 'pesCeps', text: 'pesCepTexts'}))

    push(['estab', 'estabelecimento', 'emp.saude'],
        bb('estab', tp.cnesCompany, 'Estab. Saúde', { estabelecimentoSaude: true }, {id: 'estabelecimentos', text: 'estabelecimentoSaudeTexts'}))

    push(['cnes'],
        bb('cnes', tp.cnesCompany, 'Estab. Saúde', { cnes: true }, {id: 'estabelecimentos', text: 'estabelecimentoSaudeTexts'}))

    push(tags(['estab'], ['endereco', 'endereço', 'end', 'res', 'residencia']),
        bb('estab.endereco', tp.cnesAddress, 'Endereço', { cnesEndereco: true }, {id: 'cnesEnderecos', text: 'cnesEnderecoTexts'}))

    push(tags(['estab'], ['cidade', 'municipio', 'município', 'muni', 'mun']),
        bb('estab.cidade', tp.cnesCounty, 'Cidade', { cnesMunicipio: true }, {id: 'cnesMunicipios', text: 'cnesMunicipioTexts'}))

    push(['estab.bairro'],
        bb('estab.bairro', tp.cnesNeighborhood, 'Bairro', { cnesBairro: true }, {id: 'cnesBairros', text: 'cnesBairroTexts'}))

    push(tags(['estab'], ['rua', 'logradouro', 'logra']),
        bb('estab.rua', tp.cnesStreet, 'Rua', { cnesLogradouro: true }, {id: 'cnesLogradouros', text: 'cnesLogradouroTexts'}))

    push(['estab.cep'],
        bb('estab.cep', tp.cnesZip, 'CEP', { cnesCep: true }, {id: 'cnesCeps', text: 'cnesCepTexts'}))

    push(['med.esp'],
        bb('med.esp', tp.ESPMED, 'Especialidade', { especialidadeMedica: true }, {id: 'espMeds', text: 'espMedTexts'}))

    push(['med.crm'],
        bb('med.crm', tp.MED_CRM, 'CRM', { crm: true }, {id: 'crmCodes', text: 'crmTexts'}))

    push(['med.crm.uf'],
        bb('med.crm.uf', tp.MED_CRM_UF, 'CRM/UF', { crmUf: true }, {id: 'crmUfs', text: 'crmUfTexts'}))
    
    push(['ops.emp'],
        bb('ops.emp', tp.empOps, 'Operadora', { empOps: true }, {id: 'empOpsCodes', text: 'empOpsTexts'}))

    push(['ops.prf'],
        bb('ops.prf', tp.prfOps, 'Operadora', { prfOps: true }, {id: 'prfOpsCodes', text: 'prfOpsTexts'}))

    pushCrmDataInscricaoFilterHandler()
}

function pushCrmDataInscricaoFilterHandler() {
    const type = EntityTypeID.MED_CRM_DATE

    function parseDateRange(text: string): DateTimeFilter | null {
        if (!text) {
            return null
        }
        const entry = JSON.parse(text) as DateTimeFilter
        return entry.operator > 0 && !!entry.value && typeof entry.value === 'string' ? entry : null
    }

    const handle: FilterHandler = {
        type,
        filterId: 'crm.dta.inscricao',

        search: async function () {
            // DO NOTHING
        },

        getBaseRequest: () => {
            return {}
        },

        applyFilter: function (request: TextualSearchRequest, option: SearchOption) {
            if (option.type !== type.code) {
                return
            }

            const entryValue = parseDateRange(option.text)
            if (!entryValue) {
                return
            }

            const criteria = (request.criteria = request.criteria ?? {})
            criteria.pesCrmDataInscricao = criteria.pesCrmDataInscricao ?? []
            criteria.pesCrmDataInscricao.push(entryValue)
        }
    }

    HANDLER_BY_FILTER_TYPEID_MAP.set(type.code, handle)
    HANDLER_BY_FILTER_TYPE_MAP.set(type.tag, handle)
}
