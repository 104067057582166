import { Observable, observe, Scope, events, ObservableArray, NOOP_VOID } from 'wdc-cube'
import { v4 as uuid } from 'uuid'
import { WaitingScope } from 'src/components/waiting/waiting.scopes'
import { IDialogScope } from 'src/main'
import { type GraphSeriesOption } from 'echarts'

import { type EChartsOption } from 'src/components/echarts'
import { type GraphNode } from './tc_types'

import {
    type SearchOption,
    SearchBoxScope
} from 'src/components/searchbox'

type BaseEvent = events.BaseEvent
type KeyPressEvent = events.KeyPressEvent

export { WaitingScope, EChartsOption }
export type { BaseEvent, KeyPressEvent }

@Observable
export class SearchInputScope extends Scope {
    readonly uid = uuid()

    @observe() compliance = false
    @observe() label?: string
    @observe() placeholder?: string = 'Busca'
    @observe() loading = false
    @observe() value = ''
    @observe() selecteds: SearchOption[] = []
    @observe() options: SearchOption[] = []
    @observe() filterId = '_'

    focus: (cb?: () => void) => void = NOOP_VOID

    @observe() onInputChange = Scope.SYNC_ACTION_TWO<BaseEvent, string>()
    @observe() onChange = Scope.SYNC_ACTION_THREE<BaseEvent, SearchOption[], string>()
    @observe() onKeyUp = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
    @observe() onKeyDown = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
    @observe() onFocus = Scope.SYNC_ACTION
    @observe() onBlur = Scope.SYNC_ACTION
    @observe() onRemoveTag = Scope.ASYNC_ACTION_ONE<number>()
    @observe() onTextSearch = Scope.ASYNC_ACTION_ONE<string>()
    @observe() onPasteText = Scope.SYNC_ACTION_TWO<string, { accept: boolean }>()
    @observe() onFilterChanged = Scope.ASYNC_ACTION_ONE<string>()
}

@Observable
export class HeaderBarScope extends Scope {
    @observe() onlyActives = true
    @observe() exclusive = true
    @observe() acceptPhotos = false

    readonly search = new SearchBoxScope()

    onOpenSearchByPicture = Scope.ASYNC_ACTION
    onChangeExclusive = Scope.ASYNC_ACTION_BOOLEAN
    onChangeOnlyActives = Scope.ASYNC_ACTION_BOOLEAN
}

@Observable
export class ChipScope extends Scope {
    @observe() label = ''
    @observe() category = 0
    @observe() hint = ''
    @observe() value = ''

    onDelete = Scope.ASYNC_ACTION
}

@Observable
export class SelectedNodesScope extends Scope {
    readonly companies = new ObservableArray<ChipScope>(this)
    readonly partners = new ObservableArray<ChipScope>(this)
    readonly people = new ObservableArray<ChipScope>(this)
}

@Observable
export class SearchByPictureScope extends Scope implements IDialogScope {
    @observe() userFace = false
    @observe() rearCamera: MediaDeviceInfo | undefined
    @observe() frontCamera: MediaDeviceInfo | undefined

    onCapture = Scope.ASYNC_ACTION_ONE<string>()
    onClose = Scope.ASYNC_ACTION
}

@Observable
export class HoverBarScope extends Scope {
    @observe() labelEnabled = true
    @observe() allNodesEnabled = true

    onZoomIn = Scope.ASYNC_ACTION
    onZoomOut = Scope.ASYNC_ACTION
    onToggleLabels = Scope.ASYNC_ACTION
    onToggleGraphSize = Scope.ASYNC_ACTION
}

@Observable
export class TheConnectionScope extends Scope {
    @observe() waiting?: WaitingScope
    @observe() options?: EChartsOption

    @observe() selectedNodesPanel?: SelectedNodesScope
    readonly hoverBar: HoverBarScope = new HoverBarScope()

    scaleZoom: (value: number) => void = NOOP_VOID
    getZoomValue: () => number = DefaultZoomValue
    mergeSerieOptions: (options: GraphSeriesOption) => void = NOOP_VOID
    refreshChart: () => void = NOOP_VOID

    onReady = Scope.ASYNC_ACTION
    onNodeClicked = Scope.ASYNC_ACTION_TWO<GraphNode, boolean>()
    onCopyInformationalData = Scope.ASYNC_ACTION_ONE<GraphNode>()
    onCopyIdentityData = Scope.ASYNC_ACTION_ONE<GraphNode>()
}

function DefaultZoomValue() {
    return 0.6
}
