import { Places } from '../../Constants'
import { MainKeys } from '../../main/Main.keys'

export class RDStationKeys extends MainKeys {
    get place() {
        return Places.rdStation
    }
}

export default RDStationKeys
