import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFaixasScope } from './tcm_scopes'
import { TheConsumerMarketService, JunctionMode, NumberOperator, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasFaixaFuncionariosGrupo')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasFaixaFuncionariosGrupo extends Presenter<
    ChartFaixasScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.faixaDeFuncionariosNoGrupo)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.faixaDeFuncionariosNoGrupo
        this.scope.onItemClicked = this.__onFaixaDeFuncionariosNoGrupo.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.totalFuncionariosDoGrupo.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_por_faixa_de_funionarios_grupo: true,
                filter: filter
            })

            const data = {
                qtd_0_to_100: 0,
                qtd_101_to_1000: 0,
                qtd_1001_to_5000: 0,
                qtd_5001_to_10000: 0,
                qtd_10001_to_INF: 0
            }

            for (const bucket of resp.total_de_empresas_por_faixa_de_funionarios_grupo?.buckets ?? []) {
                switch (bucket.key as keyof typeof data) {
                    case 'qtd_0_to_100':
                        data.qtd_0_to_100 += bucket.doc_count
                        break
                    case 'qtd_101_to_1000':
                        data.qtd_101_to_1000 += bucket.doc_count
                        break
                    case 'qtd_1001_to_5000':
                        data.qtd_1001_to_5000 += bucket.doc_count
                        break
                    case 'qtd_5001_to_10000':
                        data.qtd_5001_to_10000 += bucket.doc_count
                        break
                    case 'qtd_10001_to_INF':
                        data.qtd_10001_to_INF += bucket.doc_count
                        break
                }
            }

            this.scope.dataset = [
                [data.qtd_10001_to_INF, '10mil ou mais', false],
                [data.qtd_5001_to_10000, '5001 a 10mil', false],
                [data.qtd_1001_to_5000, '1001 a 50mil', false],
                [data.qtd_101_to_1000, '101 a 1mil', false],
                [data.qtd_0_to_100, 'até 100', false]
            ]

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.totalFuncionariosDoGrupo

        const markRow = (rowIdx: number, value: boolean) => {
            const row = this.scope.dataset[rowIdx]
            row && (row[2] = value)
        }

        markRow(0, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.gte}-10000`))
        markRow(1, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-5001:10000`))
        markRow(2, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-1001:5000`))
        markRow(3, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-101:1000`))
        markRow(4, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.lte}-100`))

        this.scope.update()
    }

    private async __onFaixaDeFuncionariosNoGrupo(rowIdx: number) {
        const totalFuncionariosDoGrupo = this.owner.filterPane.totalFuncionariosDoGrupo
        switch (rowIdx) {
            case 0:
                totalFuncionariosDoGrupo.toggleFrom(JunctionMode.OR, 10000, true)
                break
            case 1:
                totalFuncionariosDoGrupo.toggleRange(JunctionMode.OR, 5001, 10000)
                break
            case 2:
                totalFuncionariosDoGrupo.toggleRange(JunctionMode.OR, 1001, 5000)
                break
            case 3:
                totalFuncionariosDoGrupo.toggleRange(JunctionMode.OR, 101, 1000)
                break
            case 4:
                totalFuncionariosDoGrupo.toggleTo(JunctionMode.OR, 100, true)
                break
        }
        totalFuncionariosDoGrupo.publish()
        this.__markSelected()

        this.__parent.fetchData(totalFuncionariosDoGrupo.size ? 'totalPorFaixaDeFuncionariosGrupo' : '')
    }
}
