import * as L from 'leaflet'
import * as utils from './utils'
import { type TcmPresenterForMap } from './tcm_presenter_map'
import { type GeoLocation } from '../tcm_service'
import FilterManager from '../tcm_filter_manager'

export default abstract class BaseSelectionModeBehaviour {
    owner!: TcmPresenterForMap
    
    removeAllLayers = utils.staticNoopOther

    abstract setZoom(value: number): void
    abstract setMode(value: number): void
    abstract preparePoints(): void
    abstract hasFilter(): boolean
    abstract addLocation(location: GeoLocation): void
    abstract onBeforeScopeUpdate(): void
    abstract applyFilter(filterManager: FilterManager): void

    get scope() {
        return this.owner.scope
    }

    get keyboard() {
        return this.owner.keyboard
    }

    getDistanceInKmUnit(): number {
        const scope = this.scope
        if (scope.slider.unit === 'km') {
            return this.scope.slider.value
        } else {
            return this.scope.slider.value / 1000
        }
    }

    getDistanceInMeters(): number {
        const scope = this.scope
        if (scope.slider.unit === 'km') {
            return this.scope.slider.value * 1000
        } else {
            return this.scope.slider.value
        }
    }

    clear() {
        this.removeAllLayers()
        this.scope.showClearButton = false
    }

    draw(map: L.Map): void {
        this.removeAllLayers()

        if (!this.hasFilter()) {
            return
        }

        

        this.doDraw(map)
    }

    abstract doDraw(map: L.Map): void
}
