import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ValueScope } from './tcm_scopes'
import { TheConsumerMarketService, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasTotalNovasUltMes')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasTotalNovasUltMes extends Presenter<ValueScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.totalEmpresasNovasUltimoMes)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.totalEmpresasNovasUltimoMes
        this.scope.onClicked = this.__onTotalEmpresasNovasUltimoMesClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.novasUltimoMes.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_novas_empresas_ultimo_mes: true,
                filter: filter
            })

            this.scope.value = resp.total_novas_empresas_ultimo_mes?.value ?? 0

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.novasUltimoMes
        this.scope.selected = prop.selected
        this.scope.update()
    }

    private async __onTotalEmpresasNovasUltimoMesClicked() {
        const prop = this.__filterManager.novasUltimoMes
        prop.add()
        this.__markSelected()
        this.__parent.fetchData(prop.size ? 'totalNovasEmpresasUltimoMes' : '')
    }
}
