import React from 'react'
import clsx from 'clsx'
//import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'

import { makeStyles } from 'tss-react/mui'

import { TheMapMarkDetailsScope } from '../tm_md_details_scopes'

//const LOG = Logger.get('TM-VIEW-MARK-DETAILS')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10
    }
})

type TheMapMarkDetailsViewProps = {
    className?: string
    scope: TheMapMarkDetailsScope
}

export const TheMapMarkDetailsView = class TheMapMarkDetailsViewCtx {
    static #create = () => new TheMapMarkDetailsViewCtx()

    static view(props: TheMapMarkDetailsViewProps) {
        return React.useMemo(TheMapMarkDetailsViewCtx.#create, []).#render(props)
    }

    scope!: TheMapMarkDetailsScope

    // :: Public

    #render(props: TheMapMarkDetailsViewProps) {
        const scope = (this.scope = props.scope)
        const { classes } = useStyles()

        bindUpdate(React, scope)

        return (
            <div className={clsx(classes.view, props.className)}>
                <span>Detalhamentos de uma marcação do mapa</span>
            </div>
        )
    }
}.view
