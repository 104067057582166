import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'

import ChecklistIcon from '@mui/icons-material/Checklist'
import DeleteIcon from '@mui/icons-material/Delete'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { TheMapSelectionBarScope, TheMapSelectionScope } from '../tm_scopes'

const LOG = Logger.get('TM-SEL-BAR')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        backgroundColor: '#fff',
        border: '2px solid rgba(0,0,0,0.2)',
        backgroundClip: 'padding-box',
        borderRadius: 5
    },
    hoverIcon: {
        cursor: 'pointer'
    },
    mapSelectionView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 5
    },
    labelCheckbox: {
        '& .MuiFormControlLabel-label': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 600
        }
    }
})

export type TheSelectionBarViewProps = {
    className?: string
    scope: TheMapSelectionBarScope
}

export const TheSelectionBarView = class TheSelectionBarCtx {
    static #create = () => new TheSelectionBarCtx()

    static view(props: TheSelectionBarViewProps) {
        return React.useMemo(TheSelectionBarCtx.#create, []).#render(props)
    }

    #scope!: TheMapSelectionBarScope

    #handleMouseEnter = () => {
        this.#scope.hovering = true
    }

    #handleMouseLeave = () => {
        this.#scope.hovering = false
    }

    #render({ className, scope }: TheSelectionBarViewProps) {
        bindUpdate(React, (this.#scope = scope))

        const classes = useStyles().classes

        const hideContent = !scope.hovering || scope.selections.length === 0

        return (
            <div
                className={clsx(classes.view, className)}
                onMouseEnter={this.#handleMouseEnter}
                onMouseLeave={this.#handleMouseLeave}
            >
                {hideContent ? (
                    <ChecklistIcon className={classes.hoverIcon} />
                ) : (
                    <FormGroup>
                        {scope.selections.map((selectionScope) => (
                            <TheMapSelectionView key={selectionScope.uid} scope={selectionScope} />
                        ))}
                    </FormGroup>
                )}
            </div>
        )
    }
}.view

type TheMapSelectionViewProps = {
    className?: string
    scope: TheMapSelectionScope
}

export const TheMapSelectionView = class TheMapSelectionCtx {
    static #create = () => new TheMapSelectionCtx()

    static view(props: TheMapSelectionViewProps) {
        return React.useMemo(TheMapSelectionCtx.#create, []).#render(props)
    }

    #scope!: TheMapSelectionScope

    #handleCheckChange = (_event: unknown, checked: boolean) => {
        this.#scope.onCheckChange(checked).catch(LOG.caught)
    }

    #handleDelete = () => {
        this.#scope.onDelete().catch(LOG.caught)
    }

    #render({ className, scope }: TheMapSelectionViewProps) {
        bindUpdate(React, (this.#scope = scope))

        const classes = useStyles().classes

        return (
            <div className={clsx(classes.mapSelectionView, className)}>
                <div className={scope.mark} />
                <Tooltip title={scope.title} placement="bottom">
                    <FormControlLabel
                        className={classes.labelCheckbox}
                        control={<Checkbox checked={scope.checked} onChange={this.#handleCheckChange} />}
                        label={scope.title}
                    />
                </Tooltip>
                <IconButton aria-label="delete" onClick={this.#handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </div>
        )
    }
}.view

export default TheSelectionBarView
