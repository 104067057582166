import { Presenter, CubePresenter, FlipIntent, Logger, action } from 'wdc-cube'
import * as lang from 'lang-utils'
import * as L from 'leaflet'

import { AppStorage } from 'src/utils/AppStorage'
import { MainPresenter } from 'src/main/Main.presenter'
import { SearchBoxPresenter, type TextualSearchRequest } from 'src/components/searchbox'

import { TheActingKeys } from './ta_keys'
import { TheActingScope, ScoreCardScope, HeaderScope, FilterEntryScope, ScopeDefaults, OverlayScope } from './ta_scopes'
import { TheActingService } from './ta_service'
import {
    type ProfessionalRow,
    type CompanyRow,
    type TablePage,
    type FetchRequest,
    type TextualSearchFilters,
    type MarkData,
    CompanySortModel,
    ProfessionalSortModel,
    CompanySortItem,
    ProfessionalSortItem
} from './ta_types'
import HandleSearchFilterAppliedCtx from './contexts/HandleSearchFilterAppliedCtx'

const LOG = Logger.get('TheActingPresenter')

const storage = window.sessionStorage
const appStorage = AppStorage.singleton()
const service = TheActingService.singleton()
const lodash = lang.Lodash.singleton

const CURRENCY_FORMATTER = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' })
const CK_FILTERS = 'ta-3d7712ff165a'
const CK_CRITERIA = 'ta-3d7712ff165b'
const MARK_CIRCLE_CLASSES = static_buildMarkCircleClasses()

export class TheActingPresenter extends CubePresenter<MainPresenter, TheActingScope> {
    // :: Fields
    private readonly __headerBar: Presenter<HeaderScope, TheActingPresenter>
    private readonly __searchPresenter: SearchBoxPresenter
    private readonly __filterRemovalAction = new FilterRemovalAction()

    private __working = false
    private __map: L.Map | null = null
    private __layerGroup: L.LayerGroup<unknown> | null = null
    private __mapCenter = ScopeDefaults.getDefaultCenter()

    public constructor(app: MainPresenter) {
        super(app, new TheActingScope())
        this.__headerBar = new Presenter(this, new HeaderScope())
        this.__searchPresenter = new SearchBoxPresenter(app, this, this.__headerBar.scope.search)
        this.__searchPresenter.mode = 'ACTING'
        this.__searchPresenter.pageSize = 100
    }

    public override release() {
        if (this.app.scope.toolbar === this.__headerBar.scope) {
            this.app.scope.toolbar = undefined
        }
        this.__searchPresenter.release()
        this.__headerBar.release()
        super.release()
        LOG.debug('Finalized')
    }

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        if (!this.app.scope.theActingEnabled) {
            await this.app.flipToDefault()
            return false
        }

        const keys = new TheActingKeys(this.app, intent)

        this.app.scope.toolbar = this.__headerBar.scope
        keys.parentSlot(this.scope)

        if (initialization) {
            await this.__initialize(keys)
        } else {
            await this.__update(keys)
        }

        if (keys.last) {
            keys.dialogSlot(null)
        }

        return keys.allow
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new TheActingKeys(this.app, intent)
        keys.selectedCard = this.scope.selectedCard
    }

    public onComplianceChanged(compliance: boolean) {
        this.__searchPresenter.onComplianceChanged(compliance)
    }

    private async __initialize(keys: TheActingKeys) {
        this.__setSelectedCard(keys.selectedCard)

        this.__headerBar.scope.onOnlyActivesChange = this.__handleOnlyActivesChange.bind(this)
        this.__searchPresenter.initialize()
        this.__searchPresenter.onComplianceChanged(this.app.compliance)
        this.__searchPresenter.onSelected = () => {
            this.__searchPresenter.apply().catch(LOG.caught)
        }
        this.__searchPresenter.onApply = this.__handleSearchApply.bind(this)
        this.__searchPresenter.onSearch = this.__handleSearchFetch.bind(this)

        {
            const sectionUpdate = this.scope.overlay.updateAsRoot()
            this.scope.overlay.downloadEnabled = appStorage.theActingPrintEnabled
            this.scope.overlay.onFilterToggleClick = this.__handleFilterToggleClick.bind(this)
            this.scope.overlay.onDownloadClick = this.__download.bind(this, 'all')

            this.scope.overlay.livre.update = sectionUpdate
            this.scope.overlay.empresa.update = sectionUpdate
            this.scope.overlay.cnes.update = sectionUpdate
            this.scope.overlay.socios.update = sectionUpdate
            this.scope.overlay.pessoa.update = sectionUpdate
            this.scope.overlay.pessoaAndSocio.update = sectionUpdate
            this.scope.overlay.operadoras.update = sectionUpdate
        }

        this.scope.companyCard.downloadEnabled = appStorage.theActingPrintEnabled
        this.scope.companyCard.onExpandClick = this.__setSelectedCard.bind(this, 'company')
        this.scope.companyCard.onShrunkClick = this.__setSelectedCard.bind(this, 'none')
        this.scope.companyCard.onDownloadClick = this.__download.bind(this, 'company')
        this.scope.companyCard.onPageChange = this.__handleCompanyPageChange.bind(this)
        this.scope.companyCard.onPageSizeChange = this.__handleCompanyPageSizeChange.bind(this)
        this.scope.companyCard.onRowClick = this.__handleCompanyRowClick.bind(this)
        this.scope.companyCard.onSortModelChange = this.__handleCompanySortModelChange.bind(this)
        this.scope.companyCard.updateAsRoot()

        this.scope.professionalCard.downloadEnabled = appStorage.theActingPrintEnabled
        this.scope.professionalCard.onExpandClick = this.__setSelectedCard.bind(this, 'professional')
        this.scope.professionalCard.onShrunkClick = this.__setSelectedCard.bind(this, 'none')
        this.scope.professionalCard.onDownloadClick = this.__download.bind(this, 'professional')
        this.scope.professionalCard.onPageChange = this.__handleProfessionalPageChange.bind(this)
        this.scope.professionalCard.onPageSizeChange = this.__handleProfessionalPageSizeChange.bind(this)
        this.scope.professionalCard.onRowClick = this.__handleProfessionalRowClick.bind(this)
        this.scope.professionalCard.onSortModelChange = this.__handleProfessionalSortModelChange.bind(this)
        this.scope.professionalCard.updateAsRoot()

        this.scope.mapCard.onExpandClick = this.__setSelectedCard.bind(this, 'map')
        this.scope.mapCard.onShrunkClick = this.__setSelectedCard.bind(this, 'none')
        this.scope.mapCard.onMapChanged = this.__handleMapChanged.bind(this)
        this.scope.mapCard.updateAsRoot()

        this.__filterRemovalAction.overlay = this.scope.overlay
        this.__filterRemovalAction.searchFilterAppliedListener = this.__handleSearchApply.bind(this)

        const request: FetchRequest = { filters: {}, criteria: {} }
        const jsonFilters = storage.getItem(CK_FILTERS)
        if (jsonFilters) {
            request.filters = JSON.parse(jsonFilters)
        }

        const jsonCriteria = storage.getItem(CK_CRITERIA)
        if (jsonCriteria) {
            request.criteria = JSON.parse(jsonCriteria)
        }

        this.__startWorking()
        this.__doSearchFilterApplied(request).finally(() => {
            this.__stopWorking()
        })

        LOG.debug('Initalized')
    }

    private async __update(keys: TheActingKeys) {
        if (this.scope.selectedCard !== keys.selectedCard) {
            this.__setSelectedCard(keys.selectedCard)
        }
    }

    private __startWorking() {
        this.scope.professionalCard.working = true
        this.scope.companyCard.working = true
        this.__working = true
    }

    private __stopWorking() {
        this.scope.professionalCard.working = false
        this.scope.companyCard.working = false
        this.__working = false
    }

    private __setScore(scope: ScoreCardScope, label: string, value: number) {
        scope.update = this.update
        scope.label = label
        const absValue = Math.abs(value)
        if (absValue >= 1_000_000 && absValue < 1_000_000_000) {
            scope.unit = 'Mi'
            value = value / 1_000_000
        } else if (absValue >= 1_000_000_000) {
            scope.unit = 'Bi'
            value = value / 1_000_000_000
        } else {
            scope.unit = ''
        }

        scope.value = CURRENCY_FORMATTER.format(value)
        scope.update()
    }

    private async __handleMapChanged(map: L.Map | null) {
        const changed = this.__map !== map
        this.__map = map
        if (map && changed) {
            if (!this.__mapCenter) {
                this.__mapCenter = ScopeDefaults.getDefaultCenter()
            }
            if (this.__layerGroup) {
                this.__layerGroup.addTo(map)
                map.setView(this.__mapCenter, 15)
            } else {
                map.setView(this.__mapCenter, 15)
            }
        }
    }

    private __populateCompanies(tableData: TablePage<CompanyRow>) {
        const pageSize = Math.max(tableData.limit, 1)
        const pageIndex = tableData.offset / pageSize
        const companyCardScope = this.scope.companyCard
        companyCardScope.total = tableData.total
        companyCardScope.page = pageIndex
        companyCardScope.pageSize = pageSize
        companyCardScope.rows = tableData.rows
    }

    private __populateProfessionals(tableData: TablePage<ProfessionalRow>) {
        const pageSize = Math.max(tableData.limit, 1)
        const pageIndex = tableData.offset / pageSize
        const professionalCardScope = this.scope.professionalCard
        professionalCardScope.total = tableData.total
        professionalCardScope.page = pageIndex
        professionalCardScope.pageSize = pageSize
        professionalCardScope.rows = tableData.rows
    }

    private __populateMap(companyData: TablePage<CompanyRow>, professionalData: TablePage<ProfessionalRow>) {
        const leafletMap = this.__map
        if (!leafletMap) {
            return
        }

        if (this.__layerGroup) {
            this.__layerGroup.remove()
            this.__layerGroup = null
        }
        const layerGroup = L.layerGroup()

        let center = ScopeDefaults.getDefaultCenter()
        const southWest: L.LatLngLiteral = { ...center }
        const northEast: L.LatLngLiteral = { ...center }

        const entryMap = new Map<string, MarkData>()

        for (const row of companyData.rows) {
            if (!row.coordenadas) continue
            const key = `${row.coordenadas.lat}:${row.coordenadas.lng}`
            let entry = entryMap.get(key)
            if (!entry) {
                entry = {
                    coordenadas: row.coordenadas,
                    empresas: [],
                    profissionais: []
                }
                entryMap.set(key, entry)
            }
            entry.empresas.push(row)
        }

        for (const row of professionalData.rows) {
            if (!row.coordenadas) continue
            const key = `${row.coordenadas.lat}:${row.coordenadas.lng}`
            let entry = entryMap.get(key)
            if (!entry) {
                entry = {
                    coordenadas: row.coordenadas,
                    empresas: [],
                    profissionais: []
                }
                entryMap.set(key, entry)
            }
            entry.profissionais.push(row)
        }

        if (entryMap.size > 0) {
            const entryValuesIt = entryMap.values()[Symbol.iterator]()
            let entry = entryValuesIt.next()
            if (!entry.done) {
                const coordenadas = entry.value.coordenadas!
                northEast.lng = southWest.lng = coordenadas.lng
                northEast.lat = southWest.lat = coordenadas.lat

                center.lat = coordenadas.lat
                center.lng = coordenadas.lng
            }

            while (!entry.done) {
                const { lng, lat } = entry.value.coordenadas!

                southWest.lng = Math.min(southWest.lng, lng)
                northEast.lng = Math.max(northEast.lng, lng)

                northEast.lat = Math.min(northEast.lat, lat)
                southWest.lat = Math.max(southWest.lat, lat)

                const names = entry.value.empresas.map((e) => `${e.cnpj} - ${e.nome}`)
                entry.value.profissionais.forEach((e) => names.push(`${e.crm} - ${e.nome}`))

                let markClassName = MARK_CIRCLE_CLASSES[0]
                if (entry.value.empresas.length > 0 && entry.value.profissionais.length > 0) {
                    markClassName = MARK_CIRCLE_CLASSES[1]
                } else if (entry.value.empresas.length > 0) {
                    markClassName = MARK_CIRCLE_CLASSES[2]
                } else if (entry.value.profissionais.length > 0) {
                    markClassName = MARK_CIRCLE_CLASSES[3]
                }

                const marker = new L.Marker(
                    { lng, lat },
                    {
                        title: names.join('\n'),
                        icon: L.divIcon({ className: markClassName, iconSize: L.point(12, 12) })
                    }
                )
                marker.on('click', this.__handleMapMarkerClicked.bind(this, entry.value))
                layerGroup.addLayer(marker)

                entry = entryValuesIt.next()
            }
        }

        layerGroup.addTo(leafletMap)

        if (entryMap.size === 1) {
            leafletMap.flyTo(center, 15)
        } else if (entryMap.size > 1) {
            const bounds = new L.LatLngBounds(southWest, northEast)
            center = bounds.getCenter()
            leafletMap.flyToBounds(bounds, { maxZoom: 15 })
        } else {
            leafletMap.flyTo(center, 4)
        }

        this.__layerGroup = layerGroup
        this.__mapCenter = center
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async __handleMapMarkerClicked(data: MarkData, __evt: L.LeafletMouseEvent) {
        const filters: TextualSearchFilters = {}

        data.empresas &&
            data.empresas.forEach((empresa) => {
                const cnpjs = (filters.cnpjs = filters.cnpjs ?? [])
                cnpjs.push(empresa.cnpj)
            })

        data.profissionais &&
            data.profissionais.forEach((profissional) => {
                const pessoas = (filters.pessoas = filters.pessoas ?? [])
                pessoas.push(profissional.id)
            })

        this.__startWorking()
        this.__doSearchFilterApplied({ filters }).finally(() => {
            this.__stopWorking()
        })
    }

    private async __setSelectedCard(cardName: string | undefined) {
        switch (cardName ?? 'none') {
            case 'company':
                this.scope.selectedCard = 'company'
                break
            case 'professional':
                this.scope.selectedCard = 'professional'
                break
            case 'map':
                this.scope.selectedCard = 'map'
                break
            default:
                this.scope.selectedCard = 'none'
        }

        this.scope.companyCard.expanded = this.scope.selectedCard === 'company'
        this.scope.professionalCard.expanded = this.scope.selectedCard === 'professional'
        this.scope.mapCard.expanded = this.scope.selectedCard === 'map'
    }

    @action()
    private async __download(mode: 'company' | 'professional' | 'all') {
        if (this.__working) {
            return
        }
        this.scope.overlay.downloading = true
        this.__startWorking()
        try {
            const ctx = new HandleSearchFilterAppliedCtx()
            const filters = ctx.currentFilters(this.scope.overlay)
            const criteria = ctx.currentCriteria(this.scope.overlay)
            ctx.preserveTextsWithoutId(filters)

            const blob = await service.download({
                compliant: this.app.compliance,
                company: mode === 'all' || mode === 'company' ? {} : undefined,
                professional: mode === 'all' || mode === 'professional' ? {} : undefined,
                filters,
                criteria
            })
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `the-acting-${mode}.xlsx`)

            // Append to html link element page
            document.body.appendChild(link)

            // Start download
            link.click()

            // Clean up and remove the link
            link.parentNode?.removeChild(link)
        } finally {
            this.scope.overlay.downloading = false
            this.__stopWorking()
        }
    }

    private async __fetchData(request: FetchRequest) {
        this.__startWorking()
        try {
            request.compliant = this.app.compliance

            // completa dados de empresa
            request.company = request.company ?? {}

            const companySortBy: CompanySortModel[] =
                this.scope.companyCard.sortBy.length > 0
                    ? this.scope.companyCard.sortBy
                    : [{ field: 'nome', sort: 'asc' }]

            if (lodash().isNil(request.company.offset) && this.scope.companyCard.page > 0) {
                request.company.offset = this.scope.companyCard.page * this.scope.companyCard.pageSize
            }

            if (lodash().isNil(request.company.limit)) {
                request.company.limit = this.scope.companyCard.pageSize
                const row = this.scope.companyCard.rows[this.scope.companyCard.rows.length - 1]
                if (row) {
                    const searchAfter: unknown[] = []
                    companySortBy.forEach((e) => {
                        searchAfter.push(row[e.field])
                    })
                    searchAfter.push(row.id)
                    request.company.search_after = searchAfter
                }
            }

            if (lodash().isNil(request.company.sort)) {
                const sortModel: CompanySortItem[] = []
                companySortBy.forEach((e) => sortModel.push([e.field, e.sort]))
                request.company.sort = sortModel
            }

            // completa dados de empresa

            request.professional = request.professional ?? {}

            const professionalSortBy: ProfessionalSortModel[] =
            this.scope.professionalCard.sortBy.length > 0
                ? this.scope.professionalCard.sortBy
                : [{ field: 'nome', sort: 'asc' }]

            if (lodash().isNil(request.professional.offset) && this.scope.professionalCard.page > 0) {
                request.professional.offset = this.scope.professionalCard.page * this.scope.professionalCard.pageSize
                const row = this.scope.professionalCard.rows[this.scope.companyCard.rows.length - 1]
                if (row) {
                    const searchAfter: unknown[] = []
                    professionalSortBy.forEach((e) => {
                        searchAfter.push(row[e.field])
                    })
                    searchAfter.push(row.id)
                    request.professional.search_after = searchAfter
                }
            }

            if (lodash().isNil(request.professional.limit)) {
                request.professional.limit = this.scope.professionalCard.pageSize
            }

            if (lodash().isNil(request.professional.sort)) {
                const sortModel: ProfessionalSortItem[] = []
                professionalSortBy.forEach((e) => sortModel.push([e.field, e.sort]))
                request.professional.sort = sortModel
            }

            if (!this.__headerBar.scope.onlyActives) {
                request.nonActives = true
            }

            const response = await service.fetch(request)
            this.__setScore(this.scope.profissionalScoreCard, 'Profissionais', response.scores.professionals)
            this.__setScore(this.scope.companyScoreCard, 'Empresas', response.scores.companies)
            this.__setScore(this.scope.revenueScoreCard, 'Faturamento Est.', response.scores.revenue)
            this.__setScore(this.scope.healthCoScoreCard, 'Estabelecimentos', response.scores.healthCo)

            this.__populateProfessionals(response.professionalData)
            this.__populateCompanies(response.companyData)
            this.__populateMap(response.companyData, response.professionalData)

            if (response.companyData && response.companyData.limit > 0) {
                this.scope.companyCard.page = (response.companyData.offset / response.companyData.limit) | 0
                this.scope.companyCard.pageSize = response.companyData.limit
            } else {
                this.scope.companyCard.page = 0
            }

            if (response.professionalData && response.professionalData.limit > 0) {
                this.scope.professionalCard.page =
                    (response.professionalData.offset / response.professionalData.limit) | 0
                this.scope.professionalCard.pageSize = response.professionalData.limit
            } else {
                this.scope.professionalCard.page = 0
            }

            if (request.company.sort) {
                this.scope.companyCard.sortBy.length = 0
                for (const [field, sort] of request.company.sort) {
                    this.scope.companyCard.sortBy.push({ field, sort })
                }
                this.scope.companyCard.update()
            }

            if (request.professional.sort) {
                this.scope.professionalCard.sortBy.length = 0
                for (const [field, sort] of request.professional.sort) {
                    this.scope.professionalCard.sortBy.push({ field, sort })
                }
                this.scope.professionalCard.update()
            }

            storage.setItem(CK_FILTERS, JSON.stringify(request.filters))
            storage.setItem(CK_CRITERIA, JSON.stringify(request.criteria))
        } finally {
            this.__stopWorking()
        }
    }

    private async __handleOnlyActivesChange(checked: boolean) {
        this.__headerBar.scope.onlyActives = checked

        this.__startWorking()
        this.__doSearchFilterApplied({}).finally(() => {
            this.__stopWorking()
        })
    }

    private async __handleFilterToggleClick() {
        this.scope.overlay.expanded = !this.scope.overlay.expanded
    }

    private async __handleProfessionalPageChange(newPage: number) {
        if (this.__working) return
        this.scope.professionalCard.page = newPage
        await this.__doSearchFilterApplied({})
    }

    private async __handleProfessionalPageSizeChange(newPageSize: number) {
        if (this.__working) return
        this.scope.professionalCard.page = 0
        this.scope.professionalCard.pageSize = newPageSize
        await this.__doSearchFilterApplied({})
    }

    private async __handleProfessionalRowClick(row: ProfessionalRow) {
        if (this.__working) return
        this.scope.professionalCard.page = 0
        await this.__doSearchFilterApplied({
            filters: {
                pessoas: [row.id]
            }
        })
    }

    private async __handleCompanyPageChange(newPage: number) {
        if (this.__working) return
        this.scope.companyCard.page = newPage
        await this.__doSearchFilterApplied({})
    }

    private async __handleCompanyPageSizeChange(newPageSize: number) {
        this.scope.companyCard.page = 0
        this.scope.companyCard.pageSize = newPageSize
        await this.__doSearchFilterApplied({})
    }

    private async __handleCompanyRowClick(row: CompanyRow) {
        if (this.__working) return
        this.scope.companyCard.page = 0

        let filterCount = 0
        const filters: TextualSearchRequest['filters'] = {}
        row.cnpj && ((filters.cnpjs = [row.cnpj]), filterCount++)
        row.cnes && ((filters.estabelecimentos = [row.cnes]), filterCount++)

        if (filterCount > 0) {
            await this.__doSearchFilterApplied({ filters })
        }
    }

    private async __handleCompanySortModelChange(sortModel: CompanySortModel[]) {
        const newSortModel: CompanySortItem[] = []

        if (this.scope.keyboard.ctrlKey) {
            this.scope.companyCard.sortBy.forEach(({ field, sort }) => newSortModel.push([field, sort]))
        }
        sortModel.forEach(({ field, sort }) => newSortModel.push([field, sort]))

        await this.__doSearchFilterApplied({
            company: {
                offset: 0,
                sort: newSortModel
            }
        })
    }

    private async __handleProfessionalSortModelChange(sortModel: ProfessionalSortModel[]) {
        const newSortModel: ProfessionalSortItem[] = []
        if (this.scope.keyboard.ctrlKey) {
            this.scope.professionalCard.sortBy.forEach(({ field, sort }) => newSortModel.push([field, sort]))
        }

        sortModel.forEach(({ field, sort }) => newSortModel.push([field, sort]))

        await this.__doSearchFilterApplied({
            professional: {
                offset: 0,
                sort: newSortModel
            }
        })
    }

    private async __handleSearchFetch(req: TextualSearchRequest) {
        req.nonActives = this.__headerBar.scope.onlyActives
        req.compliance = this.app.compliance
        return await service.filterSearch(req)
    }

    private async __handleSearchApply(newRequest: TextualSearchRequest) {
        if (this.__working) {
            this.app.alert('info', 'Aviso', 'Existe uma consulta em andamento.')
            return
        }

        // console.debug('__handleSearchApply', JSON.stringify(newRequest))

        await this.__doSearchFilterApplied({
            company: {
                offset: 0
            },
            professional: {
                offset: 0
            },
            filters: newRequest.filters,
            criteria: newRequest.criteria
        })
    }

    private async __doSearchFilterApplied(newRequest: FetchRequest, runDry = false) {
        this.__searchPresenter.clearSelections()
        this.__searchPresenter.clearValue()

        const ctx = new HandleSearchFilterAppliedCtx()
        const filters = ctx.mergeFilters(newRequest.filters ?? {}, ctx.currentFilters(this.scope.overlay))
        const criteria = ctx.mergeCriteria(newRequest.criteria ?? {}, ctx.currentCriteria(this.scope.overlay))

        const { filters: describedFilters = {} } = await this.__searchPresenter.describeRequest({
            filters: ctx.filterToOnlyIds(filters)
        })

        ctx.appendTextsIfNotPresent(describedFilters, filters)
        //LOG.debug('describedFilters', describedFilters)

        const serverRequest = lodash().cloneDeep(describedFilters)
        ctx.preserveTextsWithoutId(serverRequest)
        //LOG.debug('serverRequest', serverRequest)
        describedFilters.texts = newRequest.filters?.texts
        serverRequest.texts = newRequest.filters?.texts

        const onRemoveListener = this.__filterRemovalAction.run.bind(this.__filterRemovalAction)

        ctx.applyFilterOnOverlay(this.scope.overlay, describedFilters, onRemoveListener)

        ctx.applyCriteriaOnOverlay(this.scope.overlay, criteria, onRemoveListener)

        if (!runDry) {
            newRequest.filters = serverRequest
            newRequest.criteria = criteria
            await this.__fetchData(newRequest)
        }

        this.scope.overlay.update()

        return serverRequest
    }
}

function static_buildMarkCircleClasses() {
    return [
        'gg-ue-red-circle',
        'gg-islamic-green-circle',
        'gg-duke-blue-circle',
        'gg-mughal-green-circle',
        'gg-weldon-blue-circle'
    ]
}

class FilterRemovalAction {
    private __removingFilter = false
    private __pendingFilterToBeRemovedArray: FilterEntryScope[] = []

    overlay!: OverlayScope
    searchFilterAppliedListener!: (newRequest: TextualSearchRequest) => Promise<void>

    async run(scope: FilterEntryScope) {
        try {
            if (this.__removingFilter) {
                this.__pendingFilterToBeRemovedArray.push(scope)
                return
            }

            this.__removingFilter = true
            await this.doRemoveFilters([scope])
            while (this.__pendingFilterToBeRemovedArray.length > 0) {
                const scopeArray = [...this.__pendingFilterToBeRemovedArray]
                this.__pendingFilterToBeRemovedArray.length = 0
                await this.doRemoveFilters(scopeArray)
            }
        } finally {
            this.__removingFilter = false
        }
    }

    async doRemoveFilters(scopeArray: FilterEntryScope[]) {
        const { overlay } = this

        const doUpdate = async () => {
            await this.searchFilterAppliedListener({})
        }

        const filters = [
            ...overlay.livre.getAllFilters(),
            ...overlay.empresa.getAllFilters(),
            ...overlay.cnes.getAllFilters(),
            ...overlay.socios.getAllFilters(),
            ...overlay.pessoa.getAllFilters(),
            ...overlay.pessoaAndSocio.getAllFilters(),
            ...overlay.operadoras.getAllFilters()
        ]

        let numRemoved = 0

        for (const scope of scopeArray) {
            const removeCriteria = (item: FilterEntryScope) => item === scope

            for (const filterArrayEntry of filters) {
                if (filterArrayEntry.removeByCriteria(removeCriteria)) {
                    numRemoved++
                    break
                }
            }
        }

        if (numRemoved > 0) {
            await doUpdate()
        }
    }
}
