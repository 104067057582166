import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFaixasScope } from './tcm_scopes'
import { TheConsumerMarketService, JunctionMode, NumberOperator, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasFaixaCapitalSocial')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasFaixaCapitalSocial extends Presenter<
    ChartFaixasScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.faixaCapitalSocial)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.faixaCapitalSocial
        this.scope.onItemClicked = this.__onFaixaCapitalSocial.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.capitalSocial.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_por_faixa_de_capital_social: true,
                filter: filter
            })

            const data = {
                qtd_0_to_50k: 0,
                qtd_50k_to_200k: 0,
                qtd_200k_to_500k: 0,
                qtd_500k_to_1mi: 0,
                qtd_1mi_to_INF: 0
            }

            for (const bucket of resp.total_de_empresas_por_faixa_de_capital_social?.buckets ?? []) {
                switch (bucket.key as keyof typeof data) {
                    case 'qtd_0_to_50k':
                        data.qtd_0_to_50k += bucket.doc_count
                        break
                    case 'qtd_50k_to_200k':
                        data.qtd_50k_to_200k += bucket.doc_count
                        break
                    case 'qtd_200k_to_500k':
                        data.qtd_200k_to_500k += bucket.doc_count
                        break
                    case 'qtd_500k_to_1mi':
                        data.qtd_500k_to_1mi += bucket.doc_count
                        break
                    case 'qtd_1mi_to_INF':
                        data.qtd_1mi_to_INF += bucket.doc_count
                        break
                }
            }

            this.scope.dataset = [
                [data.qtd_1mi_to_INF, '1,000,000 ou mais', false],
                [data.qtd_500k_to_1mi, '500,001 a 1,000,000', false],
                [data.qtd_200k_to_500k, '200,001 a 500,000', false],
                [data.qtd_50k_to_200k, '50,001 a 200,000', false],
                [data.qtd_0_to_50k, 'até 50,000', false]
            ]

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onFaixaCapitalSocial(rowIdx: number) {
        const totalEmpresaCapSocial = this.owner.filterPane.totalEmpresaCapSocial
        switch (rowIdx) {
            case 0:
                totalEmpresaCapSocial.toggleFrom(JunctionMode.OR, 1000000, true)
                break
            case 1:
                totalEmpresaCapSocial.toggleRange(JunctionMode.OR, 500001, 1000000)
                break
            case 2:
                totalEmpresaCapSocial.toggleRange(JunctionMode.OR, 200001, 500000)
                break
            case 3:
                totalEmpresaCapSocial.toggleRange(JunctionMode.OR, 50001, 200000)
                break
            case 4:
                totalEmpresaCapSocial.toggleTo(JunctionMode.OR, 50000, true)
                break
        }
        totalEmpresaCapSocial.publish()
        this.__markSelected()

        this.__parent.fetchData(totalEmpresaCapSocial.size ? 'totalPorFaixaCapitalSocial' : '')
    }

    private __markSelected() {
        const prop = this.__filterManager.capitalSocial

        const markRow = (rowIdx: number, value: boolean) => {
            const row = this.scope.dataset[rowIdx]
            row && (row[2] = value)
        }

        markRow(0, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.gte}-1000000`))
        markRow(1, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-500001:1000000`))
        markRow(2, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-200001:500000`))
        markRow(3, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-50001:200000`))
        markRow(4, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.lte}-50000`))

        this.scope.update()
    }
}
