import { type LatLngLiteral } from './tm_types'
import * as L from 'leaflet'

import { Observable, observe, Scope, events, ObservableArray } from 'wdc-cube'

import { SearchBoxScope, type TextualSearchRequest } from 'src/components/searchbox'

type BaseEvent = events.BaseEvent
type KeyPressEvent = events.KeyPressEvent
type MouseEvent = events.MouseEvent

export type { BaseEvent, KeyPressEvent }
export { SearchBoxScope }

let UID_GEN = 1
let DEFAULT_CENTER: LatLngLiteral = { lat: -15.7754462, lng: -47.7970891 }

@Observable
export class TheMapHeaderScope extends Scope {
    @observe() onlyActives = true

    readonly search = new SearchBoxScope()

    onOnlyActivesChange = Scope.ASYNC_ACTION_BOOLEAN
}

@Observable
export class TheMapSelectionScope extends Scope {
    readonly uid = UID_GEN++
    @observe() title = ''
    @observe() mark = ''
    @observe() checked = true

    request?: TextualSearchRequest

    onCheckChange = Scope.ASYNC_ACTION_BOOLEAN
    onDelete = Scope.ASYNC_ACTION
}

@Observable
export class TheMapSelectionBarScope extends Scope {
    @observe() hovering = false
    readonly selections = new ObservableArray<TheMapSelectionScope>(this)
}

@Observable
export class TheMapScope extends Scope {
    @observe() selectionBar?: TheMapSelectionBarScope

    showMarkMenu = Scope.SYNC_ACTION_ONE<MouseEvent>()

    // Events
    onReady = Scope.ASYNC_ACTION
    onMapChanged = Scope.ASYNC_ACTION_ONE<L.Map | null>()
    onMarkCopyDataToClipboard = Scope.ASYNC_ACTION
    onMarkShowDetailsClipboard = Scope.ASYNC_ACTION
}

export const ScopeDefaults = new (class {
    getDefaultCenter(): LatLngLiteral {
        return { ...DEFAULT_CENTER }
    }

    setDefaultCenter(value: LatLngLiteral) {
        DEFAULT_CENTER = value
    }
})()
