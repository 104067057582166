import * as L from 'leaflet'
import * as geojson from 'geojson'
import * as turf from '@turf/turf'
import * as utils from './utils'
import { type GeoLocation, type GeoCircleLocation } from '../tcm_service'
import FilterManager from '../tcm_filter_manager'

import BaseSelectionModeBehaviour from './selection_mode_behaviour'

type TurfJS = typeof turf

type TurfFeature = geojson.Feature<geojson.Polygon | geojson.MultiPolygon>
type CircleOption = Parameters<typeof turf.circle>[2]

export default class CircleSelectionModeBehaviour extends BaseSelectionModeBehaviour {
    locationList: GeoCircleLocation[] = []
    areaList: TurfFeature[] = []

    override onBeforeScopeUpdate() {
        this.scope.slider.visible = true
    }

    override applyFilter(mgr: FilterManager): void {
        mgr.localizacaoCircles.clear()
        this.areaList.forEach((feature) => {
            if (!feature) {
                return
            }

            if (!feature.geometry) {
                return
            }

            if (!feature.geometry.coordinates) {
                return
            }

            feature.geometry.coordinates.forEach((coordinate) => {
                if (!coordinate) {
                    return
                }

                const shape: GeoLocation[] = []
                for (const turfPoint of coordinate.values()) {
                    shape.push({
                        lat: turfPoint[1] as number,
                        lon: turfPoint[0] as number
                    })
                }
                mgr.localizacaoCircles.add(shape)
            })
        })
        //this.locationList.forEach((v) => mgr.localizacaoCircles.add(v))
    }

    override clear() {
        super.clear()
        this.locationList.length = 0
        this.areaList.length = 0
        this.owner.filterManager.localizacaoCircles.clear()
    }

    override hasFilter() {
        return this.locationList.length > 0
    }

    addLocation(location: GeoLocation) {
        const newLocationList: GeoCircleLocation[] = []

        let lastCircleLocation: GeoCircleLocation | null = {
            ...location,
            radius: this.getDistanceInKmUnit() / 2
        }

        let found = false
        const point = turf.point([location.lon, location.lat])
        for (const otherLocation of this.locationList) {
            const center = [otherLocation.lon, otherLocation.lat]
            const radius = otherLocation.radius
            const options: CircleOption = { steps: 10, units: 'kilometers' }
            const circle = turf.circle(center, radius, options)

            if (!found && turf.booleanContains(circle as TurfFeature, point)) {
                lastCircleLocation = otherLocation
                lastCircleLocation.lat = location.lat
                lastCircleLocation.lon = location.lon
                found = true
                if (this.keyboard.ctrlKey) {
                    lastCircleLocation = null
                }
                continue
            }
            newLocationList.push(otherLocation)
        }

        if (lastCircleLocation) {
            newLocationList.push(lastCircleLocation)
            if (this.scope.slider.unit === 'm') {
                this.scope.slider.value = lastCircleLocation.radius * 1000 * 2
            } else if (this.scope.slider.unit === 'km') {
                this.scope.slider.value = lastCircleLocation.radius * 2
            }
        }

        this.locationList = newLocationList
    }

    override setZoom(value: number): void {
        // console.debug('setZoom: ' + value)
        const scope = this.scope
        if (value >= 15) {
            scope.slider.unit === 'km' && this.__setSliderValues('m')
        } else {
            scope.slider.unit === 'm' && this.__setSliderValues('km')
        }
    }

    override setMode(value: number): void {
        const scope = this.scope
        scope.mode = 'circle'

        if (value >= 15) {
            this.__setSliderValues('m')
        } else {
            this.__setSliderValues('km')
        }
    }

    private __setSliderValues(unit: string) {
        const scope = this.scope
        if (unit === 'm') {
            let value = 300
            if (scope.slider.unit === 'km') {
                value = scope.slider.value * 1000
            }

            scope.slider.unit = 'm'
            scope.slider.min = 10
            scope.slider.max = 10000
            scope.slider.step = 10
            scope.slider.value = value
            return
        }

        if (unit === 'km') {
            let value = 0.5
            if (scope.slider.unit === 'm') {
                value = scope.slider.value / 1000
            }
            scope.slider.unit = 'km'
            scope.slider.min = 0.1
            scope.slider.max = 100
            scope.slider.step = 0.1
            scope.slider.value = value
            return
        }
    }

    preparePoints() {
        const turfJs = turf as TurfJS

        if (this.locationList.length > 0) {
            const p = this.locationList[this.locationList.length - 1]
            p.radius = this.getDistanceInKmUnit() / 2
        }

        this.areaList.length = 0

        let circlePolygonList: (TurfFeature | null)[] = []
        for (const otherLocation of this.locationList) {
            const center = [otherLocation.lon, otherLocation.lat]
            const radius = otherLocation.radius

            let steps = 10
            if (radius >= 10) {
                steps = 30
            } else if (radius >= 1) {
                steps = 20
            }

            const options: CircleOption = { steps, units: 'kilometers' }
            const circle = turf.circle(center, radius, options)

            circlePolygonList.push(circle as TurfFeature)
        }

        this.areaList = circlePolygonList as TurfFeature[]

        let hasIntersection = true
        while (hasIntersection) {
            hasIntersection = false
            let refPolygon = circlePolygonList[0]!

            const newCirclePolygonList: TurfFeature[] = []
            newCirclePolygonList.push(refPolygon)
            for (let i = 1; i < circlePolygonList.length; i++) {
                const somePolygon = circlePolygonList[i]!

                if (turfJs.booleanIntersects(refPolygon, somePolygon)) {
                    const unitedPolygon = turf.union(turf.featureCollection([refPolygon,somePolygon]))
                    if (unitedPolygon) {
                        refPolygon = unitedPolygon
                        newCirclePolygonList[0] = refPolygon
                    }
                    hasIntersection = true
                    circlePolygonList[i] = null
                } else {
                    newCirclePolygonList.push(somePolygon)
                }
            }

            circlePolygonList = newCirclePolygonList
        }

        this.areaList = circlePolygonList as TurfFeature[]
    }

    override draw(map: L.Map): void {
        this.removeAllLayers()
        if (this.locationList.length === 0) {
            return
        }

        this.doDraw(map)
    }

    doDraw(map: L.Map) {
        try {
            this.doDrawCircles(map)
            //this.doDrawArea(map)
        } catch (e) {
            console.error('doDraw', e)
        }
    }

    doDrawArea(map: L.Map) {
        if (this.areaList.length === 0) {
            //this.removeAllLayers = utils.staticNoopOther
            return
        }

        const multiPolygons: L.LatLngLiteral[][] = []

        for (const feature of this.areaList) {
            for (const coordinate of feature.geometry.coordinates) {
                const mapPolygon: L.LatLngLiteral[] = []

                coordinate.forEach((v) => {
                    mapPolygon.push({
                        lat: v[1] as number,
                        lng: v[0] as number
                    })
                })

                multiPolygons.push(mapPolygon)
            }
        }

        const areaLayer = L.polygon(multiPolygons, {
            fill: false
        }).addTo(map)

        const oldRemoveAllLayers = this.removeAllLayers
        this.removeAllLayers = () => {
            oldRemoveAllLayers()
            areaLayer.removeFrom(map)
            this.removeAllLayers = utils.staticNoopOther
        }
    }

    doDrawCircles(map: L.Map) {
        const shapeArray: L.Layer[] = []
        for (let i = 0, iLast = this.locationList.length - 1; i <= iLast; i++) {
            const p1 = this.locationList[i]

            const color = i < iLast ? utils.NON_ACTIVE_SELECTION_COLOR : utils.ACTIVE_SELECTION_COLOR

            shapeArray.push(
                L.circle([p1.lat, p1.lon], {
                    radius: p1.radius * 1000,
                    color,
                    stroke: false,
                    fillOpacity: 0.4
                }).addTo(map)
            )
        }

        const oldRemoveAllLayers = this.removeAllLayers
        this.removeAllLayers = () => {
            oldRemoveAllLayers()
            for (const shape of shapeArray) {
                shape.removeFrom(map)
            }
            this.removeAllLayers = utils.staticNoopOther
        }
    }
}
