import React from 'react'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef, GridRowParams, GridRowClassNameParams } from '@mui/x-data-grid'

import TcmCard from './helpers/tcm-card'
import StyledDataGrid from './helpers/tcm_datagrid'
import { colors } from './helpers/tcm_view_utils'
import { TableAtividadeEconomicaScope, TableAtividadeEconomicaRow } from '../tcm_scopes'

const LOG = Logger.get('TCM-View-AtivEconomica')

const useStyles = makeStyles()({
    rowSelected: {
        backgroundColor: colors.selected
    },
    dataGrid: {
        borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {
            color: 'primary.main'
        }
    }
})

const View = styled('div')({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zoom: 0.8
})

const ContentBox = styled(Box)({
    height: 400,
    width: '100%',
    paddingBottom: 5
})

const CustomDataGrid = StyledDataGrid as typeof DataGrid

const GRID_COLUMN_DEFS = newGridColumnDefs()

export type AtividadeEconomicaTableViewProps = {
    className?: string
    style?: React.CSSProperties
    scope: TableAtividadeEconomicaScope
}

export class AtividadeEconomicaTableViewClass implements FCClassContext<AtividadeEconomicaTableViewProps> {
    // :: Fields
    scope!: TableAtividadeEconomicaScope
    loaded = false
    loading = true
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: AtividadeEconomicaTableViewProps) {
        this.scope = props.scope
        this.loaded = this.scope.loaded
        this.loading = !this.loaded
        this.classes = useStyles().classes
    }

    render(props: AtividadeEconomicaTableViewProps): JSX.Element {
        const { loading, loaded, scope } = this
        return (
            <TcmCard caption={scope.caption} className={clsx(props.className)} style={props.style}>
                <View>
                    {loading && <CircularProgress />}
                    {loaded && (
                        <ContentBox>
                            <CustomDataGrid
                                className={this.classes.dataGrid}
                                rows={[...scope.dataset]}
                                columns={GRID_COLUMN_DEFS}
                                disableRowSelectionOnClick
                                hideFooterPagination
                                hideFooter
                                getRowId={getRowId}
                                onRowClick={this.emitOnRowClick}
                                getRowClassName={this.computeRowClassName}
                                disableColumnSelector
                            />
                        </ContentBox>
                    )}
                </View>
            </TcmCard>
        )
    }

    emitOnRowClick = (evt: GridRowParams) => {
        this.scope.onItemClicked(evt.id as string).catch(LOG.caught)
    }

    computeRowClassName = (params: GridRowClassNameParams<TableAtividadeEconomicaRow>) => {
        return params.row.selected ? 'super-app-theme-selected' : ''
    }
}

export default classToFComponent(AtividadeEconomicaTableViewClass, React)

function getRowId(row: TableAtividadeEconomicaRow) {
    return row.id
}

function newGridColumnDefs(): GridColDef[] {
    return [
        {
            field: 'descricao',
            headerName: 'Descrição CNAE',
            width: 350
        },
        {
            field: 'qtdEmpresas',
            headerName: 'Empresas',
            width: 100
        },
        {
            field: 'qtdFuncionarios',
            headerName: 'Funcionários',
            width: 100
        }
    ]
}
