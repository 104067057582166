import React from 'react'
import clsx from 'clsx'

import { v4 as uuidv4 } from 'uuid'
import { Logger } from 'wdc-cube'

import { makeStyles } from 'tss-react/mui'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import IconButton from '@mui/material/IconButton'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'

import { useContainer } from 'src/utils/views/useContainer'

import { MainScope } from '../../Main.scopes'

const LOG = Logger.get('MainView/SignoutMenuButton')

const useStyles = makeStyles()(() => ({
    button: {
        color: 'white'
    }
}))

export type SignoutMenuButtonProps = {
    className?: string
    mainScope: MainScope
}

export default function SignoutMenuButton({ className, mainScope }: SignoutMenuButtonProps) {
    const container = useContainer()
    const uuid = React.useMemo(uuidv4, [])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = React.useCallback((evt: React.MouseEvent<HTMLButtonElement>) => {
        if(evt.ctrlKey && evt.shiftKey || evt.shiftKey && evt.altKey) {
            mainScope.onToggleComplianceMode().catch(LOG.caught)
            evt.preventDefault()
            evt.stopPropagation()
            return
        }
        setAnchorEl(evt.currentTarget)
    }, [])

    const handleClose = React.useCallback(() => {
        setAnchorEl(null)
    }, [mainScope])

    const handleProfile = React.useCallback(() => {
        setAnchorEl(null)
        mainScope.onProfile().catch(LOG.caught)
    }, [mainScope])

    const handleSignOut = React.useCallback(() => {
        setAnchorEl(null)
        mainScope.onSignOut().catch(LOG.caught)
    }, [mainScope])

    const { classes } = useStyles()

    return (
        <>
            <IconButton
                className={clsx(classes.button, className)}
                aria-controls={open ? uuid : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <PowerSettingsNewOutlinedIcon />
            </IconButton>
            <Menu
                id={uuid}
                anchorEl={anchorEl}
                container={container}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': uuid
                }}
            >
                <MenuItem onClick={handleProfile}>Pefil</MenuItem>
                <MenuItem onClick={handleSignOut}>Sair</MenuItem>
            </Menu>
        </>
    )
}
