import { ViewFactory } from 'wdc-cube-react'

import { MainView } from './Main.view'
import { MainScope } from '../Main.scopes'

import { BodyView } from './body.view'
import { BodyScope } from '../Main.scopes'

import { SingInView } from './sign-in.view'
import { SignInScope } from '../Main.scopes'

import { ResetPasswordView } from './reset-password.view'
import { ResetPasswordScope } from '../Main.scopes'

export { MainView } from './Main.view'

export function registerViews(rv = ViewFactory.register) {
    rv(MainScope, MainView)
    rv(BodyScope, BodyView)
    rv(SignInScope, SingInView)
    rv(ResetPasswordScope, ResetPasswordView)
}
