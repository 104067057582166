import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'

import { makeStyles } from 'tss-react/mui'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SyncIcon from '@mui/icons-material/Sync'
import DeleteIcon from '@mui/icons-material/Delete'
import StopIcon from '@mui/icons-material/Stop'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'

import RDStationScope from '../rd_scopes'

const LOG = Logger.get('RDStation-VIEW')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        minHeight: 600,
        rowGap: 10
    },
    cnpjInput: {
        flexGrow: 1
    },
    syncButton: {
        alignSelf: 'center'
    }
})

type RDStationViewProps = {
    className?: string
    scope: RDStationScope
}

export const RDStationView = class RDStationViewCtx {
    static #create = () => new RDStationViewCtx()

    static view(props: RDStationViewProps) {
        return React.useMemo(RDStationViewCtx.#create, []).#render(props)
    }

    scope!: RDStationScope
    columns: GridColDef[] = [
        { field: 'id', headerName: 'CNPJ', width: 140 },
        {
            field: 'razaoSocial',
            headerName: 'Razão Social',
            width: 300
        },
        {
            field: 'descricaoCnae',
            headerName: 'Descrição CNAE',
            width: 300
        },
        {
            field: 'website',
            headerName: 'URL',
            width: 300
        }
    ]
    inputEl!: HTMLInputElement

    // :: Public

    #render({ className, scope }: RDStationViewProps) {
        this.scope = bindUpdate(React, scope)

        React.useEffect(this.#initialize, [])

        const { classes } = useStyles()

        return (
            <div className={clsx(classes.view, className)}>
                <Box display="flex" flexDirection="row" alignItems="baseline" gap="10px">
                    <TextField
                        className={classes.cnpjInput}
                        inputRef={this.#collectCnpjInputRef}
                        label="Cole ou escreva CNPJ(s) aqui"
                        variant="standard"
                        disabled={scope.sinchronazing}
                        onKeyUp={(e) => this.#onInputKeyUp(e)}
                    />
                    <Button
                        variant="outlined"
                        endIcon={<AddCircleOutlineIcon />}
                        onClick={this.#onAppendCNPJs}
                        disabled={scope.sinchronazing}
                    >
                        Adiciona
                    </Button>
                </Box>
                <DataGrid
                    rows={scope.companyRows}
                    columns={this.columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10
                            }
                        }
                    }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={scope.cnpjsSelected}
                    onRowSelectionModelChange={this.#onRowSelectionModelChange}
                />
                {scope.sinchronazing && (
                    <LinearProgressWithLabel value={scope.progress} valueBuffer={scope.progressBuffer} />
                )}
                <Box display="flex" flexDirection="row" alignItems="baseline" gap="10px">
                    {scope.sinchronazing ? (
                        <Button
                            className={classes.syncButton}
                            variant="contained"
                            color="error"
                            endIcon={<StopIcon />}
                            onClick={this.#onPararSincronizacao}
                        >
                            Parar Sincronização
                        </Button>
                    ) : (
                        <Button
                            className={classes.syncButton}
                            variant="contained"
                            endIcon={<SyncIcon />}
                            onClick={this.#onSincronizar}
                        >
                            Sincronizar
                        </Button>
                    )}
                    <Box flexGrow={1} />
                    <Button
                        className={classes.syncButton}
                        variant="outlined"
                        endIcon={<DeleteIcon />}
                        disabled={scope.sinchronazing}
                        onClick={this.#onRemoverMarcados}
                    >
                        Remover marcados
                    </Button>
                </Box>
            </div>
        )
    }

    #initialize = () => {
        this.scope.getCnpjsInText = this.#getCnpjsInText
        this.scope.setCnpjsInText = this.#setCnpjsInText
    }

    #onAppendCNPJs = () => this.scope.onAppendCNPJs().catch(LOG.caught)
    #onSincronizar = () => this.scope.onSincronizar().catch(LOG.caught)
    #onPararSincronizacao = () => this.scope.onPararSincronizacao().catch(LOG.caught)
    #onRemoverMarcados = () => this.scope.onRemoverMarcados().catch(LOG.caught)

    #onRowSelectionModelChange = (e: GridRowSelectionModel) => {
        this.scope.cnpjsSelected = e as unknown as string[]
    }

    #onInputKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        this.scope.onKeyPressed(e.key)
    }

    #collectCnpjInputRef = (el: HTMLInputElement) => {
        this.inputEl = el
    }

    #getCnpjsInText = () => {
        return this.inputEl ? this.inputEl.value : ''
    }

    #setCnpjsInText = (v: string) => {
        if (this.inputEl) {
            this.inputEl.value = v
        }
    }
}.view

export default RDStationView

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}
