import React from 'react'

import { classToFComponent } from 'src/utils/views'
import { ValueViewClass, type ValueViewProps } from './helpers/tcm_value'

export type ChartTotalEmpresasAtivasViewProps = ValueViewProps

export class ChartTotalEmpresasAtivasViewClass extends ValueViewClass<ChartTotalEmpresasAtivasViewProps> {
    render(props: ChartTotalEmpresasAtivasViewProps): JSX.Element {
        return super.render(props)
    }
}

export default classToFComponent(ChartTotalEmpresasAtivasViewClass, React)
