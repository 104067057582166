import { ServiceClient } from 'src/utils'
import * as lang from 'src/utils/lang'
import { Lodash } from 'lang-utils'

const lodash = Lodash.singleton
const serviceClient = ServiceClient.singleton

const UF_MAP = newUFMap()

export enum MatrizOrFilial {
    matriz = 1,
    filial = 2
}

export enum SituacaoCadastral {
    baixada = 8,
    ativa = 2,
    inapta = 4,
    supensa = 3,
    nula = 1
}

export type DateRange = Partial<{ from: string; to: string }>
export type ValueRange = Partial<{
    gt: number
    gte: number
    lt: number
    lte: number
    from: number
    to: number
}>

export type GeoLocation = { lat: number; lon: number }
export type GeoLocationMode = 'polygon' | 'route' | 'circle'
export type GeoCircleLocation = { lat: number; lon: number; radius: number }

export type LocalizacaoFilter = Partial<{
    size: number
    polygons: GeoLocation[][]
    routes: GeoLocation[][]
    circles: GeoLocation[][]
}>

enum Operators {
    contains = 1,
    does_not_contain = 2,
    starts_with = 3,
    does_not_start_with = 4,
    is = 5,
    is_not = 6,
    is_blank = 7,
    is_not_blank = 8,
    is_empty = 9,
    is_not_empty = 10,
    is_in = 11,
    is_not_in = 12,
    is_less_then = 13,
    is_less_then_or_equal_to = 14,
    is_greater_then = 15,
    is_greater_then_or_equal_to = 16,
    in_range = 17,
    out_of_range = 18
}

export enum StringOperator {
    contains = Operators.contains,
    does_not_contain = Operators.does_not_contain,
    starts_with = Operators.starts_with,
    does_not_start_with = Operators.does_not_start_with,
    is = Operators.is,
    is_not = Operators.is_not,
    is_blank = Operators.is_blank,
    is_not_blank = Operators.is_not_blank,
    is_empty = Operators.is_empty,
    is_not_empty = Operators.is_not_empty
}

export enum StringKeywordOperator {
    contains = Operators.contains,
    does_not_contain = Operators.does_not_contain,
    starts_with = Operators.starts_with,
    does_not_start_with = Operators.does_not_start_with,
    is = Operators.is,
    is_not = Operators.is_not,
    is_blank = Operators.is_blank,
    is_not_blank = Operators.is_not_blank,
    is_in = Operators.is_in,
    is_not_in = Operators.is_not_in
}

export enum NumberOperator {
    is_less_then = Operators.is_less_then,
    lt = Operators.is_less_then,
    is_less_then_or_equal_to = Operators.is_less_then_or_equal_to,
    lte = Operators.is_less_then_or_equal_to,
    is_greater_then = Operators.is_greater_then,
    gt = Operators.is_greater_then,
    is_greater_then_or_equal_to = Operators.is_greater_then_or_equal_to,
    gte = Operators.is_greater_then_or_equal_to,
    is = Operators.is,
    is_not = Operators.is_not,
    is_blank = Operators.is_blank,
    is_not_blank = Operators.is_not_blank,
    in_range = Operators.in_range,
    out_of_range = Operators.out_of_range
}

export enum JunctionMode {
    AND = 1,
    OR = 2
}

export type StringFilter = {
    mode: JunctionMode
    operator: StringOperator
    value?: string
}

export type StringKeywordFilter = {
    mode: JunctionMode
    operator: StringKeywordOperator
    value?: string
}

export type NumberFilter = {
    mode: JunctionMode
    operator: NumberOperator
    value?: string | number
    to?: string | number
}

export type ColumnSort  = {
    id: string
    desc: boolean
}

export type ColumnFilter = {
    id: string
    value: unknown
}

export type EmpresaFilter = Partial<{
    situacaoCadastral: SituacaoCadastral[]
    matrizOrFilial: MatrizOrFilial[]
    localizacao: LocalizacaoFilter

    empresaRazaoSocial: StringFilter[]
    empresaNomeFantasia: StringFilter[]
    empresaCodigo: StringKeywordFilter[]
    atividadeEconomicaCodes: StringKeywordFilter[]
    atividadeEconomicaTexts: StringFilter[]

    empresaCnes: StringKeywordFilter[]
    empresaGrupoCodes: StringKeywordFilter[]

    enderecoCodes: StringKeywordFilter[]
    enderecoTexts: StringFilter[]

    enderecoRuaCodes: StringKeywordFilter[]
    enderecoRuaTexts: StringFilter[]

    enderecoBairroCodes: StringKeywordFilter[]
    enderecoBairroTexts: StringFilter[]

    enderecoCidadeCodes: StringKeywordFilter[]
    enderecoCidadeTexts: StringFilter[]

    enderecoUfs: StringKeywordFilter[]
    enderecoUfTexts: StringFilter[]

    enderecoCeps: StringKeywordFilter[]
    enderecoCepTexts: StringFilter[]

    enderecoRegiaoMetropolitanaCodes: StringKeywordFilter[]
    enderecoRegiaoMetropolitanaTexts: StringFilter[]

    naturezaJuridicaCodes: StringKeywordFilter[]
    naturezaJuridicaTexts: StringFilter[]

    scoreRiscos: StringKeywordFilter[]

    inicioAtividade: DateRange[]
    idadeEmpresaEmAnos: NumberFilter[]
    totalEmpresasNoGrupo: NumberFilter[]
    capitalSocial: NumberFilter[]
    totalFuncionarios: NumberFilter[]
    totalFuncionarios_0_a_18: NumberFilter[]
    totalFuncionarios_19_a_33: NumberFilter[]
    totalFuncionarios_34_a_48: NumberFilter[]
    totalFuncionarios_49_a_58: NumberFilter[]
    totalFuncionarios_59_ou_mais: NumberFilter[]
    totalFuncionariosFemininos: NumberFilter[]
    totalFuncionariosMasculinos: NumberFilter[]
    totalFuncionariosDoGrupo: NumberFilter[]
    percentualFuncionariasMulheres: NumberFilter[]
    faturamentoEstimado: NumberFilter[]
    faturamentoEstimadoGrupo: NumberFilter[]
    crescimento2021: NumberFilter[]
    crescimento2022: NumberFilter[]
    mediaCrescimento2021_a_2022: NumberFilter[]

    enchenteRs2024: boolean
}>

export type FetchRequest = Partial<{
    sobre_os_dados: boolean | object
    total_de_funcionarios_por_faixa_funcionario_grupo: boolean | object
    total_de_empresas_por_faixa_de_faturamento_anual: boolean | object
    total_de_empresas_por_faixa_de_faturamento_consolidado_grupo: boolean | object
    total_de_empresas: boolean | object
    total_de_empresas_ativas: boolean | object
    total_de_empresas_matrizes: boolean | object
    total_de_empresas_filiais: boolean | object
    total_novas_empresas_ultimo_mes: boolean | object
    total_de_empresas_no_grupo: boolean | object
    total_de_empresas_por_faixa_de_capital_social: boolean | object
    total_de_empresas_por_faixa_de_idade: boolean | object
    total_de_empresas_por_faixa_de_funionarios: boolean | object
    total_de_empresas_por_faixa_de_funionarios_grupo: boolean | object
    quantidade_de_novas_empresas_abertas_por_mes: boolean | object
    quantidade_de_novas_empresas_fechadas_por_mes: boolean | object
    ativ_economica_com_mais_empresas_funcionarios: boolean | object
    total_por_situacao_cadastral: boolean | object
    total_por_natureza_jurica: boolean | object
    listagem_empresas: boolean | object
    listagem_localizacao: boolean | object

    filter?: EmpresaFilter
}>

export type SobreOsDados = {
    dataCorte: string
    atualizadoEm: string
}

export type AggTotal = {
    value: number
}

export type AggValueRange = {
    buckets: {
        key: string
        from?: number
        to?: number
        doc_count: number
    }[]
}

export type AggDateRange = {
    buckets: {
        key_as_string: string
        key: number
        from?: number
        to?: number
        doc_count: number
    }[]
}

export type AggTopCNAE = {
    doc_count_error_upper_bound: number
    sum_other_doc_count: number
    buckets: {
        key: string
        doc_count: number
        total_empregados: AggTotal
        total_empresas: AggTotal
        description: {
            secao: string
            divisao: string
            grupo: string
            classe: string
            subclasse: string
        }
    }[]
}

export type AggTerm<T> = {
    doc_count_error_upper_bound: number
    sum_other_doc_count: number
    buckets: {
        key: T
        doc_count: number
    }[]
}

export type AggTermWithDescripton<T> = {
    doc_count_error_upper_bound: number
    sum_other_doc_count: number
    buckets: {
        key: T
        doc_count: T
        description: string
    }[]
}

export type EmpresaBean = {
    score: number
    cnpj: string
    cnes?: string
    nome: string
    dta_inicio_atividade: string | Date
    cnae: string
    cnae_descricao: string
    natureza_juridica?: string
    natureza_juridica_grp?: number
    situacao_cadastral_nme?: string
    endereco_lat?: number
    endereco_lon?: number
    endereco_cep?: string
    endereco_rua_id?: number
    endereco_rua_nome?: string
    endereco_numero?: string
    endereco_complemento?: string
    endereco_bairro_id?: number
    endereco_bairro_nome?: string
    endereco_cidade_id?: number
    endereco_cidade_nome?: string
    endereco_uf?: string
    endereco_uf_nome?: string
    socios_nomes?: string
    func_qtd_2020?: number
    func_qtd_2021?: number
    func_qtd_2022?: number
    func_qtd?: number
    func_qtd_masc?: number
    func_qtd_fem?: number
    func_qtd_0_a_18?: number
    func_qtd_19_a_33?: number
    func_qtd_34_a_48?: number
    func_qtd_49_a_58?: number
    func_qtd_59_ou_mais?: number
    func_qtd_matriz_e_filiais?: number
    faturamento_estimado?: number
    faturamento_estimado_grupo?: number
    emp_idade_anos?: number
    emp_qtd_grupo?: number
    emp_captial_social?: number

    matriz_cnpj?: string
    matriz_nome?: string
    matriz_uf?: string
    telefone1?: string
    telefone2?: string
    email?: string
    salario_total?: number
    salario_total_grupo?: number
    qtd_filiais?: unknown
    web_nome?: string
    web_categoria?: string
    web_nota?: number
    web_qtd_avaliacoes?: number
    web_site?: string
    web_tel?: string
    web_tempo_medio?: number
    optante_simples?: string
    porte_codigo?: number
}

export type EmpresaAggBean = {
    'pj-count': {
        value: number
    }
    'pj-fatu-est-emp-sum': {
        value: number
    }
    'pj-func-qtd-emp-sum': {
        value: number
    }
    'pj-fatu-est-grp-sum': {
        value: number
    }
}

export type EmpresaLocationBean = {
    cnpj: string
    nome: string
    endereco_location?: {
        lat: number
        lon: number
    }
}

export type FetchResponse = Partial<{
    sobre_os_dados: SobreOsDados
    total_de_funcionarios_por_faixa_funcionario_grupo: AggValueRange
    total_de_empresas_por_faixa_de_faturamento_anual: AggValueRange
    total_de_empresas_por_faixa_de_faturamento_consolidado_grupo: AggValueRange
    total_de_empresas: AggTotal
    total_de_empresas_ativas: AggTotal
    total_de_empresas_matrizes: AggTotal
    total_de_empresas_filiais: AggTotal
    total_novas_empresas_ultimo_mes: AggTotal
    total_de_empresas_no_grupo: AggValueRange
    total_de_empresas_por_faixa_de_capital_social: AggValueRange
    total_de_empresas_por_faixa_de_idade: AggValueRange
    total_de_empresas_por_faixa_de_funionarios: AggValueRange
    total_de_empresas_por_faixa_de_funionarios_grupo: AggValueRange
    quantidade_de_novas_empresas_abertas_por_mes: AggDateRange
    quantidade_de_novas_empresas_fechadas_por_mes: AggDateRange
    ativ_economica_com_mais_empresas_funcionarios: AggTopCNAE
    total_por_situacao_cadastral: AggTerm<number>
    total_por_natureza_jurica: AggTermWithDescripton<number>
    listagem_empresas: EmpresaBean[]
    listagem_empresas_agg: EmpresaAggBean
    listagem_localizacao: EmpresaLocationBean[]
}>

export type AtividadeEconomicaSubclasse = {
    key: string
    source: {
        'pj-ae-subclasse-cod': string
        'pj-ae-secao-cod': string
        'pj-ae-secao-dsc': string
        'pj-ae-divisao-cod': string
        'pj-ae-divisao-dsc': string
        'pj-ae-classe-cod': string
        'pj-ae-classe-dsc': string
        'pj-ae-subclasse-dsc': string
    }
}

export type Empresa = {
    key: string
    source: Partial<{
        'pj-addr-id': number
        'pj-fl-matriz': number
        'pj-addr-regiao-metropolitana-cod': number
        'pj-media-crescimento-2021_2022': number
        'pj-cnpj-grupo': string
        'pj-emp-grp-qtd': number
        'pj-emp-idade-anos': number
        'pj-ae-secao-dsc': string
        'pj-addr-rua-nme': string
        'pj-addr-bairro-nme': string
        'pj-ae-divisao-cod': string
        'pj-rs-enchente-2024-05-02': number
        'pj-razao-social': string
        'pj-score-risco-code': string
        'pj-addr-cep': string
        'pj-rs-enchente-2024-05-06': number
        'pj-addr-cidade-id': number
        'pj-addr-rua-id': number
        'pj-cadastrao-sit-dta': string
        'pj-cnpj-matriz': string
        'pj-crescimento-2022': number
        'pj-addr-uf-dsc': string
        'pj-crescimento-2021': number
        'pj-ae-classe-cod': string
        'pj-addr-rua-compl': string
        'pj-ae-grupo-cod': string
        'pj-nat-jur-cod': number
        'pj-addr-uf': string
        'pj-fatu-est-grp': number
        'pj-nome-fantasia': string
        'pj-ae-subclasse-dsc': string
        'pj-addr-bairro-id': number
        'pj-ae-subclasse-cod': string
        'pj-addr-txt': string
        'pj-inicio-atividade-dta': string
        'pj-nat-jur-nme': string
        'pj-func-qtd-grp': number
        'pj-ae-secao-cod': 'S'
        'pj-addr-regiao-metropolitana-dsc': string
        'pj-fatu-est-emp': number
        'pj-cnpj': string
        'pj-addr-cidade-nme': string
        'pj-ae-divisao-dsc': string
        'pj-competencia-inicio-ativ-dta': string
        'pj-func-qtd-emp': number
        'pj-addr-location': {
            lat: number
            lon: number
        }
        'pj-cadastrao-sit': number
        'pj-ae-classe-dsc': string
        'pj-emp-cap-social': number
        'pj-ae-grupo-dsc': string
    }>
}

export type UnidadeFederacao = {
    key: string
    source: {
        'pj-addr-uf': string
        'pj-addr-uf-dsc': string
        'pj-addr-location'?: {
            lat: number
            lon: number
        }
    }
}

export type Cidade = {
    key: number
    source: {
        'pj-addr-cidade-id': number
        'pj-addr-cidade-nme': string
        'pj-addr-uf': string
        'pj-addr-uf-dsc': string
        'pj-addr-location'?: {
            lat: number
            lon: number
        }
    }
}

export type RegiaoMetropolitana = {
    key: number
    source: {
        'pj-addr-regiao-metropolitana-cod': number
        'pj-addr-regiao-metropolitana-dsc': string
    }
}

export type NaturezaJuridica = {
    key: number
    source: {
        'pj-nat-jur-cod': number
        'pj-nat-jur-nme': string
    }
}

export type EmpresaFieldProjection = (keyof Empresa['source'])[]

export class TheConsumerMarketService {
    private static INSTANCE = new TheConsumerMarketService()

    static singleton() {
        return TheConsumerMarketService.INSTANCE
    }

    async fetch(request: FetchRequest) {
        return serviceClient().authPost<FetchResponse>('/api/mercado-consumidor/fetch', request)
    }

    async fetchSobreOsDados() {
        const resp = await this.fetch({
            sobre_os_dados: true
        })
        return resp.sobre_os_dados!
    }

    async downloadEmpresa(filter: EmpresaFilter) {
        const response = await serviceClient().authRawPost('/api/mercado-consumidor/xlsx-empresa', filter)
        if (response.status !== 201 && response.status !== 200) {
            const body = await response.text()
            if (body.charAt(0) === '{') {
                const errorInfo = JSON.parse(body) as { message: string }
                throw new Error(`${response.status}: ${errorInfo.message ?? response.statusText}`)
            }
            throw new Error(`${response.status}: ${response.statusText}`)
        }

        return response.blob()
    }

    async searchEmpresaByRazaoSocial(
        text: string,
        filterContext?: EmpresaFilter,
        fields?: (keyof Empresa['source'])[]
    ): Promise<Empresa[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        const cnpjArray: string[] = []
        const textArray: string[] = []

        const searchParts = text.split(/[,;]|\s/)
        for (const searchPart of searchParts) {
            const cnpj = lang.onlyNumbers(searchPart)
            if (!cnpj) {
                searchPart.length > 0 && textArray.push(searchPart)
                continue
            }

            if (cnpj.length === 14) {
                cnpjArray.push(cnpj)
                continue
            }

            searchPart.length > 0 && textArray.push(searchPart)
        }

        request.empresaCodigo = []
        request.empresaRazaoSocial = []

        let hasFilter = false
        if (textArray.length > 0) {
            request.empresaRazaoSocial.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: textArray.join(' ')
            })
            hasFilter = true
        }

        if (cnpjArray.length > 0) {
            request.empresaCodigo.push({
                mode: JunctionMode.OR,
                operator: StringKeywordOperator.is_in,
                value: cnpjArray.join(',')
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type EmpresaResponse = {
            buckets: Empresa[]
        }

        if (!fields || fields.length === 0) {
            fields = ['pj-razao-social']
        }

        const response = await serviceClient().authPost<EmpresaResponse>('/api/mercado-consumidor/search-empresa', {
            ...request,
            fields
        })

        return response.buckets
    }

    async searchEmpresaByNomeFantasia(
        text: string,
        filterContext?: EmpresaFilter,
        fields?: (keyof Empresa['source'])[]
    ): Promise<Empresa[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        const cnpjArray: string[] = []
        const textArray: string[] = []

        const searchParts = text.split(/[,;]|\s/)
        for (const searchPart of searchParts) {
            const cnpj = lang.onlyNumbers(searchPart)
            if (!cnpj) {
                searchPart.length > 0 && textArray.push(searchPart)
                continue
            }

            if (cnpj.length === 14) {
                cnpjArray.push(cnpj)
                continue
            }

            searchPart.length > 0 && textArray.push(searchPart)
        }

        request.empresaCodigo = []
        request.empresaNomeFantasia = []

        let hasFilter = false
        if (textArray.length > 0) {
            request.empresaNomeFantasia.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: textArray.join(' ')
            })
            hasFilter = true
        }

        if (cnpjArray.length > 0) {
            request.empresaCodigo.push({
                mode: JunctionMode.OR,
                operator: StringKeywordOperator.is_in,
                value: cnpjArray.join(',')
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type EmpresaResponse = {
            buckets: Empresa[]
        }

        if (!fields || fields.length === 0) {
            fields = ['pj-nome-fantasia']
        }

        const response = await serviceClient().authPost<EmpresaResponse>('/api/mercado-consumidor/search-empresa', {
            ...request,
            fields
        })

        return response.buckets
    }

    async searchCnaeByDescricao(text: string, filterContext?: EmpresaFilter): Promise<AtividadeEconomicaSubclasse[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        const cnaeArray: string[] = []
        const textArray: string[] = []

        const searchParts = text.split(/[,;]|\s/)
        for (const searchPart of searchParts) {
            const cnae = lang.onlyNumbers(searchPart)
            if (!cnae) {
                searchPart.length > 0 && textArray.push(searchPart)
                continue
            }

            if (cnae.length === searchPart.trim().length) {
                cnaeArray.push(cnae)
                continue
            }

            searchPart.length > 0 && textArray.push(searchPart)
        }

        request.atividadeEconomicaCodes = []
        request.atividadeEconomicaTexts = []

        let hasFilter = false
        if (textArray.length > 0) {
            request.atividadeEconomicaTexts.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: textArray.join(' ')
            })
            hasFilter = true
        }

        if (cnaeArray.length > 0) {
            request.atividadeEconomicaCodes.push({
                mode: JunctionMode.OR,
                operator: StringKeywordOperator.is_in,
                value: cnaeArray.join(',')
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type SearchAESubClasseResponse = {
            buckets: AtividadeEconomicaSubclasse[]
        }

        const response = await serviceClient().authPost<SearchAESubClasseResponse>(
            '/api/mercado-consumidor/search-ae-subclasse',
            request
        )

        return response.buckets
    }

    async searchUFByDescricao(
        text: string,
        filterContext?: EmpresaFilter,
        fields?: (keyof UnidadeFederacao['source'])[]
    ): Promise<UnidadeFederacao[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        const ufArray: string[] = []
        const textArray: string[] = []

        const searchParts = text.split(/[,;]|\s/)
        for (const searchPart of searchParts) {
            if (UF_MAP.has(searchPart)) {
                ufArray.push(searchPart)
                continue
            }

            searchPart.length > 0 && textArray.push(searchPart)
        }

        request.enderecoUfs = []
        request.enderecoUfTexts = []

        let hasFilter = false
        if (textArray.length > 0) {
            request.enderecoUfTexts.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: textArray.join(' ')
            })
            hasFilter = true
        }

        if (ufArray.length > 0) {
            request.enderecoUfs.push({
                mode: JunctionMode.OR,
                operator: StringKeywordOperator.is_in,
                value: ufArray.join(',')
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type UnidadeFederacaoResponse = {
            buckets: UnidadeFederacao[]
        }

        const response = await serviceClient().authPost<UnidadeFederacaoResponse>(
            '/api/mercado-consumidor/search-endereco-uf',
            {
                ...request,
                fields
            }
        )

        return response.buckets
    }

    async searchCidadeByDescricao(
        text: string,
        filterContext?: EmpresaFilter,
        fields?: (keyof Cidade['source'])[]
    ): Promise<Cidade[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        request.enderecoCidadeTexts = []

        let hasFilter = false
        if (text.length > 0) {
            request.enderecoCidadeTexts.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: text
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type CidadeResponse = {
            buckets: Cidade[]
        }

        const response = await serviceClient().authPost<CidadeResponse>(
            '/api/mercado-consumidor/search-endereco-cidade',
            {
                ...request,
                fields
            }
        )

        return response.buckets
    }

    async searchRegiaoMetroByDescricao(
        text: string,
        filterContext?: EmpresaFilter,
        fields?: (keyof RegiaoMetropolitana['source'])[]
    ): Promise<RegiaoMetropolitana[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        request.enderecoRegiaoMetropolitanaTexts = []

        let hasFilter = false
        if (text.length > 0) {
            request.enderecoRegiaoMetropolitanaTexts.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: text
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type RegiaoMetropolitanaResponse = {
            buckets: RegiaoMetropolitana[]
        }

        const response = await serviceClient().authPost<RegiaoMetropolitanaResponse>(
            '/api/mercado-consumidor/search-endereco-regiao-metropolitana',
            {
                ...request,
                fields
            }
        )

        return response.buckets
    }

    async searchNaturezaJuridicaByDescricao(
        text: string,
        filterContext?: EmpresaFilter,
        fields?: (keyof NaturezaJuridica['source'])[]
    ): Promise<NaturezaJuridica[]> {
        const request: EmpresaFilter = filterContext ? lodash().cloneDeep(filterContext) : {}

        request.naturezaJuridicaTexts = []

        let hasFilter = false
        if (text.length > 0) {
            request.naturezaJuridicaTexts.push({
                mode: JunctionMode.OR,
                operator: StringOperator.contains,
                value: text
            })
            hasFilter = true
        }

        if (!hasFilter) {
            return []
        }

        type NaturezaJuridicaResponse = {
            buckets: NaturezaJuridica[]
        }

        const response = await serviceClient().authPost<NaturezaJuridicaResponse>(
            '/api/mercado-consumidor/search-natureza-jurica',
            {
                ...request,
                fields
            }
        )

        return response.buckets
    }
}

function newUFMap() {
    const map = new Map<string, number>()
    map.set('RO', 1)
    map.set('AC', 1)
    map.set('AM', 1)
    map.set('RR', 1)
    map.set('PA', 1)
    map.set('AP', 1)
    map.set('TO', 1)
    map.set('MA', 1)
    map.set('PI', 1)
    map.set('CE', 1)
    map.set('RN', 1)
    map.set('PB', 1)
    map.set('PE', 1)
    map.set('AL', 1)
    map.set('SE', 1)
    map.set('BA', 1)
    map.set('MG', 1)
    map.set('ES', 1)
    map.set('RJ', 1)
    map.set('SP', 1)
    map.set('PR', 1)
    map.set('SC', 1)
    map.set('RS', 1)
    map.set('MS', 1)
    map.set('MT', 1)
    map.set('GO', 1)
    map.set('DF', 1)
    return map
}
