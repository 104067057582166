import lodash from 'lodash'
import { Logger } from 'wdc-cube'
import { JunctionMode, NumberFilterScope, NumberParcelFilterScope, NumberOperator } from '../tcm_scopes'
import { TcmPresenterForFilterPane } from './tcm_presenter_filter_pane'
import { ObjectProperty, NumberFilter } from '../tcm_filter_manager'
import { FilterPresenter } from './tcm_filter_types'

const LOG = Logger.get('TCM-NumberFilter')

let UID_GEN = 0

export class TcmPresenterForNumberFilter extends FilterPresenter<NumberFilterScope, TcmPresenterForFilterPane> {
    // Constructor

    constructor(
        owner: TcmPresenterForFilterPane,
        scope: NumberFilterScope,
        valueProperty: ObjectProperty<NumberFilter>
    ) {
        super(owner, scope)
        this.valueProperty = valueProperty
        this.fetch = lodash.debounce(() => {
            this.owner.owner.fetchData('').catch(LOG.caught)
        }, 150)
    }

    // :: Fields

    private valueProperty: ObjectProperty<NumberFilter>

    private fetch: lodash.DebouncedFuncLeading<() => void>

    // :: Getters and Setters

    get owner() {
        return super.owner as TcmPresenterForFilterPane
    }

    get size() {
        return this.scope.conjunction.length + this.scope.disjunction.length
    }

    protected override async bindEvents() {
        this.scope.onAddFilter = this.handleAddFilter.bind(this)
        LOG.debug('initialized')
    }

    override release(): void {
        this.fetch.cancel()
    }

    override clear() {
        this.scope.conjunction.length = 0
        this.scope.disjunction.length = 0
        this.valueProperty.clear()
    }

    override publish() {
        this.valueProperty.clear()

        const emptyPredicate = (scope: NumberParcelFilterScope) => lodash.trim(scope.value)===''
        this.scope.conjunction.removeByCriteria(emptyPredicate)
        this.scope.disjunction.removeByCriteria(emptyPredicate)

        for (const parcelScope of [...this.scope.conjunction, ...this.scope.disjunction]) {
            const value = parcelScope.value.trim()
            if (!value) {
                continue
            }

            const filter: NumberFilter = {
                mode: parcelScope.junctionMode,
                operator: parcelScope.operator,
                value
            }

            if (
                parcelScope.operator === NumberOperator.in_range ||
                parcelScope.operator === NumberOperator.out_of_range
            ) {
                filter.to = parcelScope.toValue
            }

            this.valueProperty.add(filter)
        }
    }

    add(filter: NumberFilter) {
        const juntionScope = filter.mode == JunctionMode.AND ? this.addConjuntion() : this.addDisjuntion()
        juntionScope.operator = filter.operator
        juntionScope.value = filter.value ? `${filter.value}` : ''
        juntionScope.toValue = filter.to ? `${filter.to}` : ''
    }

    toggleFrom(mode: JunctionMode, value: number, inclusive: boolean) {
        const junction = mode === JunctionMode.AND ? this.scope.conjunction : this.scope.disjunction
        const strValue = `${value}`
        const opearator = inclusive ? NumberOperator.gte : NumberOperator.gt
        const idx = junction.findIndex((itemScope) => {
            return itemScope.junctionMode === mode && itemScope.operator === opearator && itemScope.value === strValue
        })
        if (idx !== -1) {
            junction.removeByIndex(idx)
            return
        }

        this.add({
            mode: mode,
            operator: opearator,
            value: value
        })
    }

    toggleTo(mode: JunctionMode, value: number, inclusive: boolean) {
        const junction = mode === JunctionMode.AND ? this.scope.conjunction : this.scope.disjunction
        const strValue = `${value}`
        const opearator = inclusive ? NumberOperator.lte : NumberOperator.lt
        const idx = junction.findIndex((itemScope) => {
            return itemScope.junctionMode === mode && itemScope.operator === opearator && itemScope.value === strValue
        })
        if (idx !== -1) {
            junction.removeByIndex(idx)
            return
        }

        this.add({
            mode: mode,
            operator: opearator,
            value: value
        })
    }

    toggleRange(mode: JunctionMode, from: number, to: number) {
        const junction = mode === JunctionMode.AND ? this.scope.conjunction : this.scope.disjunction
        const strFromValue = `${from}`
        const strToValue = `${to}`
        const idx = junction.findIndex((itemScope) => {
            return (
                itemScope.junctionMode === mode &&
                itemScope.operator === NumberOperator.in_range &&
                itemScope.value === strFromValue &&
                itemScope.toValue === strToValue
            )
        })

        if (idx !== -1) {
            junction.removeByIndex(idx)
            return
        }

        this.add({
            mode: mode,
            operator: NumberOperator.in_range,
            value: from,
            to: to
        })
    }

    addConjuntion() {
        const andScope = new NumberParcelFilterScope()
        andScope.uid = `and-${UID_GEN++}`
        andScope.junctionMode = JunctionMode.AND
        andScope.operator = NumberOperator.is
        andScope.onOperatorChange = this.handleOperatorChange.bind(this, andScope)
        andScope.onValueChange = this.handleValueChange.bind(this, andScope)
        andScope.onClear = this.handleClear.bind(this, andScope)
        andScope.onEnterKeyPressed = this.handleEnterKeyPressed.bind(this)
        andScope.update = this.update

        this.scope.conjunction.push(andScope)
        return andScope
    }

    addDisjuntion() {
        const orScope = new NumberParcelFilterScope()
        orScope.uid = `or-${UID_GEN++}`
        orScope.junctionMode = JunctionMode.OR
        orScope.operator = NumberOperator.is
        orScope.onOperatorChange = this.handleOperatorChange.bind(this, orScope)
        orScope.onValueChange = this.handleValueChange.bind(this, orScope)
        orScope.onClear = this.handleClear.bind(this, orScope)
        orScope.onEnterKeyPressed = this.handleEnterKeyPressed.bind(this)
        orScope.update = this.update
        this.scope.disjunction.push(orScope)
        return orScope
    }

    private async handleAddFilter(mode: JunctionMode) {
        if (mode === JunctionMode.AND) {
            this.addConjuntion()
        } else {
            this.addDisjuntion()
        }
    }

    private async handleClear(parcelScope: NumberParcelFilterScope) {
        let found = true

        let idx = this.scope.conjunction.findIndex((item) => item.uid === parcelScope.uid)
        if (idx !== -1) {
            this.scope.conjunction.removeByIndex(idx)
            found = true
        }

        idx = this.scope.disjunction.findIndex((item) => item.uid === parcelScope.uid)
        if (idx !== -1) {
            this.scope.disjunction.removeByIndex(idx)
            found = true
        }

        if (found && parcelScope.value) {
            this.fetch.cancel()
            this.fetch()
        }
    }

    private async handleOperatorChange(parcelScope: NumberParcelFilterScope, value: NumberOperator) {
        parcelScope.operator = value
        parcelScope.valueVisible = !(value === NumberOperator.is_blank || value === NumberOperator.is_not_blank)
    }

    private async handleValueChange(parcelScope: NumberParcelFilterScope, value: string, source: 'from' | 'to') {
        if (source === 'from') {
            parcelScope.value = value
        } else {
            parcelScope.toValue = value
        }
    }

    private async handleEnterKeyPressed() {
        this.fetch.cancel()
        this.fetch()
    }
}
