import Hash from 'hash.js'
import utils from 'minimalistic-crypto-utils'
import assert from 'minimalistic-assert'

export { Hash }

export type HashConstructor = (
    | Ripemd160Constructor
    | Sha1Constructor
    | Sha224Constructor
    | Sha256Constructor
    | Sha384Constructor
    | Sha512Constructor
) & {
    outSize?: number
    hmacStrength?: number
}

export type HmacDRBGOptions = {
    hash: HashConstructor
    minEntropy?: number
    entropy: number[] | string
    entropyEnc?: string
    nonce: number[] | string
    nonceEnc?: string
    pers?: number[] | string | null
    persEnc?: string
    predResist?: boolean
}

export class HmacDRBG {
    public readonly hash: HashConstructor
    public readonly outLen: number
    public readonly predResist: boolean
    public readonly minEntropy: number

    private K: number[]
    private V: number[]
    private reseedInterval: number
    private _reseed: number

    constructor(options: HmacDRBGOptions) {
        this.hash = options.hash
        this.predResist = !!options.predResist

        this.outLen = this.hash.outSize ?? 0
        this.minEntropy = (options.minEntropy || this.hash.hmacStrength) ?? 0

        this._reseed = 0
        this.reseedInterval = 0
        this.K = []
        this.V = []

        const entropy = utils.toArray(options.entropy, options.entropyEnc || 'hex')
        const nonce = utils.toArray(options.nonce, options.nonceEnc || 'hex')
        const pers = utils.toArray(options.pers, options.persEnc || 'hex')
        assert(entropy.length >= this.minEntropy / 8, 'Not enough entropy. Minimum is: ' + this.minEntropy + ' bits')
        this._init(entropy, nonce, pers)
    }

    private _init(entropy: number[], nonce: number[], pers: number[]) {
        const seed = entropy.concat(nonce).concat(pers)

        this.K = new Array(this.outLen / 8)
        this.V = new Array(this.outLen / 8)
        for (let i = 0; i < this.V.length; i++) {
            this.K[i] = 0x00
            this.V[i] = 0x01
        }

        this._update(seed)
        this._reseed = 1
        this.reseedInterval = 0x1000000000000 // 2^48
    }

    private _hmac() {
        return Hash.hmac(this.hash as unknown as BlockHash<unknown>, this.K)
    }

    private _update(seed?: number[]) {
        let kmac = this._hmac().update(this.V).update([0x00])
        if (seed) kmac = kmac.update(seed)
        this.K = kmac.digest()
        this.V = this._hmac().update(this.V).digest()
        if (!seed) return

        this.K = this._hmac().update(this.V).update([0x01]).update(seed).digest()
        this.V = this._hmac().update(this.V).digest()
    }

    reseed(entropy: number[] | string, add?: number[] | string, addEnc?: string): void
    reseed(entropy: number[] | string, entropyEnc: string, add?: number[] | string, addEnc?: string): void
    reseed(...args: unknown[]): void {
        // Arguments
        const entropy = args[0] as number[] | string
        let entropyEnc: string | undefined = undefined
        let add: number[] | string | undefined = undefined
        let addEnc: string | undefined = undefined

        // Optional entropy enc
        if (typeof args[1] !== 'string') {
            add = args[1] as number[] | string
            addEnc = args[2] as string | undefined
        } else {
            entropyEnc = args[1] as string
            add = args[2] as number[] | string
            addEnc = args[3] as string | undefined
        }

        const actualEntropy = utils.toArray(entropy, entropyEnc)
        const actualAdd = utils.toArray(add, addEnc)

        assert(
            actualEntropy.length >= this.minEntropy / 8,
            'Not enough entropy. Minimum is: ' + this.minEntropy + ' bits'
        )

        this._update(actualEntropy.concat(actualAdd || []))
        this._reseed = 1
    }

    generate(len: number, enc?: string): number[] | string
    generate(len: number, add: number[] | string, addEnc?: string): number[] | string
    generate(len: number, enc: string, add: number[] | string, addEnc?: string): number[] | string
    generate(...args: unknown[]): number[] | string {
        if (this._reseed > this.reseedInterval) throw new Error('Reseed is required')

        // Arguments
        const len = args[0] as number
        let enc: string | undefined = undefined
        let add: number[] | undefined = undefined
        let addEnc: string | undefined = undefined

        // Optional encoding
        if (typeof args[1] !== 'string') {
            add = args[1] as number[]
            addEnc = args[2] as string | undefined
        } else {
            enc = args[1] as string
            add = args[2] as number[]
            addEnc = args[3] as string | undefined
        }

        // Optional additional data
        if (add) {
            add = utils.toArray(add, addEnc || 'hex')
            this._update(add)
        }

        let temp: number[] = []
        while (temp.length < len) {
            this.V = this._hmac().update(this.V).digest()
            temp = temp.concat(this.V)
        }

        const res = temp.slice(0, len)
        this._update(add)
        this._reseed++
        return utils.encode(res, enc)
    }
}
