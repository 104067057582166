import { type GraphSeriesOption } from 'echarts'

import lodash from 'src/utils/lodash'
import * as vec2 from 'zrender/lib/core/vector.js'
import { CubePresenter, Presenter, FlipIntent, Logger, ObservableArray } from 'wdc-cube'
import htmlToFormattedText from 'src/utils/html-to-formatted-text'

import { MainPresenter } from '../../main'
import { TheConnectionKeys } from './tc_keys'

import {
    type GraphNode,
    type GraphLink,
    type GraphType,
    type SearchOption,
    Category,
    EntityTypeID,
    CATEGORY_ARRAY
} from './tc_types'

import {
    type EChartsOption,
    SelectedNodesScope,
    ChipScope,
    TheConnectionScope,
    HeaderBarScope,
    WaitingScope,
    SearchByPictureScope
} from './tc_scopes'

import service, { type FetchRequest } from './tc_service'
import * as graphLayout from './tc_layout'

import { SearchBoxPresenter } from 'src/components/searchbox'

const LOG = Logger.get('TheConnectionPresenter')

type ChipInfoCallback = (index: number) =>
    | {
          node: GraphNode
          onDelete: () => Promise<void>
      }
    | undefined

export class TheConnectionPresenter extends CubePresenter<MainPresenter, TheConnectionScope> {
    // :: Fields

    #headerBar = new Presenter(this, new HeaderBarScope())
    #hoverBar = new Presenter(this, this.scope.hoverBar)
    #selectedNodes = new Presenter(this, new SelectedNodesScope())

    #companyIds?: string[]
    #partnerIds?: string[]
    #personIds?: string[]

    #searchPresenter: SearchBoxPresenter
    #localToGlobalCategoryIndex: number[] = []

    #searchByPictureScope?: SearchByPictureScope

    #rearCamera?: MediaDeviceInfo
    #frontCamera?: MediaDeviceInfo

    readonly #currentGraph = new (class {
        nodes: GraphNode[] = []
        links: GraphLink[] = []
        nodeByIdMap = new Map<GraphLink['source'], GraphNode>()
        legend: { name: string }[] = []
        categories: Category[] = []
        colorPalette: string[] = []
        edgeMatrix: number[][] = []
    })()

    readonly #filteredGraph = new (class {
        nodes: GraphNode[] = []
        links: GraphLink[] = []
    })()

    // :: Constructor

    public constructor(app: MainPresenter) {
        super(app, new TheConnectionScope())
        this.#searchPresenter = new SearchBoxPresenter(app, this, this.#headerBar.scope.search)
    }

    public override release() {
        if (this.app.scope.toolbar === this.#headerBar.scope) {
            this.app.scope.toolbar = undefined
        }

        if (this.app.scope.dialog === this.#searchByPictureScope) {
            this.app.scope.dialog = undefined
        }

        this.#selectedNodes.release()
        this.#hoverBar.release()
        this.#headerBar.release()

        super.release()
        LOG.debug('Finalized')
    }

    /**
     * This event is emited by Main.presenter whenever user change to compliance mode
     *
     * @param compliance
     */
    public onComplianceChanged(compliance: boolean) {
        this.#searchPresenter.onComplianceChanged(compliance)
    }

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        if(!this.app.scope.theConnectionEnabled) {
            await this.app.flipToDefault()
            return false
        }

        const keys = new TheConnectionKeys(this.app, intent)

        this.app.scope.toolbar = this.#headerBar.scope
        keys.parentSlot(this.scope)

        if (initialization) {
            await this.#detectCameraDevices()

            this.scope.onNodeClicked = this.#handleNodeClicked.bind(this)
            this.scope.onCopyInformationalData = this.#handleCopyInformationalData.bind(this)
            this.scope.onCopyIdentityData = this.#handleCopyIdentityData.bind(this)

            this.#hoverBar.scope.onZoomIn = this.#handleZoomIn.bind(this)
            this.#hoverBar.scope.onZoomOut = this.#handleZoomOut.bind(this)
            this.#hoverBar.scope.onToggleLabels = this.#handleToggleLabels.bind(this)
            this.#hoverBar.scope.onToggleGraphSize = this.#handleToggleGraphSize.bind(this)

            this.#headerBar.scope.acceptPhotos = !!(this.#frontCamera || this.#rearCamera)
            this.#headerBar.scope.onOpenSearchByPicture = this.#handleOpenSearchByPicture.bind(this)
            this.#headerBar.scope.onChangeExclusive = this.#handleChangeExclusive.bind(this)
            this.#headerBar.scope.onChangeOnlyActives = this.#handleChangeOnlyActives.bind(this)

            this.#searchPresenter.pageSize = 100
            this.#searchPresenter.onChanged = this.#handleSearchBoxChanged.bind(this)
            this.#searchPresenter.initialize()
        }

        // Se o diálogo estava aberto
        if (this.#searchByPictureScope) {
            // ... reabre
            this.app.scope.dialog = this.#searchByPictureScope
        }

        if (
            initialization ||
            lodash.ArrayStringCompare(this.#companyIds, keys.companyIds) !== 0 ||
            lodash.ArrayStringCompare(this.#partnerIds, keys.partnerIds) !== 0 ||
            lodash.ArrayStringCompare(this.#personIds, keys.personIds) !== 0
        ) {
            await this.#load({
                companyIds: keys.companyIds,
                partnerIds: keys.partnerIds,
                personIds: keys.personIds
            })
        }

        return true
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new TheConnectionKeys(this.app, intent)
        keys.companyIds = this.#companyIds
        keys.partnerIds = this.#partnerIds
        keys.personIds = this.#personIds
    }

    async #load(request: FetchRequest) {
        this.scope.waiting = new WaitingScope()
        try {
            request.onlyActives = this.#searchPresenter.showInactives != true
            request.complience = this.app.compliance
            request.companyIds = removeDuplicatedFromArray(request.companyIds)
            request.partnerIds = removeDuplicatedFromArray(request.partnerIds)
            request.personIds = removeDuplicatedFromArray(request.personIds)
            request.cnesIds = removeDuplicatedFromArray(request.cnesIds)

            const { graph, selectedCompanyNodes, selectedPartnerNodes, selectedPersonNodes } = await service.fetchGraph(
                request
            )

            this.#currentGraph.nodes = graph.nodes
            this.#currentGraph.links = graph.links
            this.#currentGraphInitialize()

            this.#filteredGraph.nodes = graph.nodes
            this.#filteredGraph.links = graph.links

            this.#updateOptions(this.#hoverBar.scope.allNodesEnabled)

            this.#companyIds = request.companyIds
            this.#partnerIds = request.partnerIds
            this.#personIds = request.personIds

            const hasCompanyIds = this.#companyIds && this.#companyIds.length > 0
            const hasParterIds = this.#partnerIds && this.#partnerIds.length > 0
            const hasPersonIds = this.#personIds && this.#personIds.length > 0
            if (hasCompanyIds || hasParterIds || hasPersonIds) {
                this.#syncCompanyChips(selectedCompanyNodes ?? [])
                this.#syncPartnerChips(selectedPartnerNodes ?? [])
                this.#syncPersonChips(selectedPersonNodes ?? [])
                this.scope.selectedNodesPanel = this.#selectedNodes.scope
            } else {
                this.#syncCompanyChips([])
                this.#syncPartnerChips([])
                this.#syncPersonChips([])
                this.scope.selectedNodesPanel = undefined
            }
            this.updateHistory()
        } catch (err) {
            const exn = err as Error
            LOG.error(exn.message, exn)
            this.app.alert('error', 'Erro de acesso aos dados', exn.message)
        } finally {
            this.scope.waiting = undefined
        }
    }

    #currentGraphInitialize() {
        const INF = Number.MAX_SAFE_INTEGER
        const { nodes, links, categories, legend, colorPalette, nodeByIdMap, edgeMatrix } = this.#currentGraph

        graphLayout.computeNodesXY(nodes, links)

        edgeMatrix.length = nodes.length

        const existingCategoryMap = new Map<number, Category>()

        for (let u = 0; u < nodes.length; u++) {
            const node = nodes[u]

            node.dataIndex = u
            nodeByIdMap.set(node.id, node)

            const edgeVector = (edgeMatrix[u] = new Array<number>(nodes.length))
            for (let v = 0; v < nodes.length; v++) {
                edgeVector[v] = u === v ? 0 : INF
            }

            const category = CATEGORY_ARRAY[node.category]
            if (category) {
                existingCategoryMap.set(node.category, category)
            }
        }

        const globalToLocalCategoryIndex: number[] = []
        for (const category of CATEGORY_ARRAY) {
            if (existingCategoryMap.has(category.index)) {
                globalToLocalCategoryIndex[category.index] = categories.length
                categories.push(category)
                legend.push({ name: category.name })
                colorPalette.push(category.color)
            }
        }

        this.#localToGlobalCategoryIndex.length = 0
        for (const node of nodes) {
            const localCategoryIndex = globalToLocalCategoryIndex[node.category]
            this.#localToGlobalCategoryIndex[localCategoryIndex] = node.category
            node.category = localCategoryIndex
        }

        for (let i = 0; i < links.length; i++) {
            const edge = links[i]
            const sourceNode = nodeByIdMap.get(edge.source)
            const targetNode = nodeByIdMap.get(edge.target)
            if (sourceNode && targetNode) {
                let edgeWeight = 1

                if (
                    sourceNode.x != undefined &&
                    sourceNode.y != undefined &&
                    targetNode.x != undefined &&
                    targetNode.y != undefined
                ) {
                    edgeWeight = vec2.distance([sourceNode.x, sourceNode.y], [targetNode.x, targetNode.y])
                    edgeWeight = Math.abs(edgeWeight)
                }

                edgeMatrix[sourceNode.dataIndex][targetNode.dataIndex] = edgeWeight
                edgeMatrix[targetNode.dataIndex][sourceNode.dataIndex] = edgeWeight
            }
        }
    }

    #syncCompanyChips(companyNodes: GraphNode[]) {
        this.#syncChips(this.#selectedNodes.scope.companies, (i) => {
            const node = companyNodes[i]
            if (node) {
                const item: ReturnType<ChipInfoCallback> = {
                    node,
                    onDelete: this.#handleDeleteCompanyIdFilter.bind(this, node.id)
                }
                return item
            }
        })
    }

    #syncPartnerChips(partnerNodes: GraphNode[]) {
        this.#syncChips(this.#selectedNodes.scope.partners, (i) => {
            const node = partnerNodes[i]
            if (node) {
                const item: ReturnType<ChipInfoCallback> = {
                    node,
                    onDelete: this.#handleDeletePartnerIdFilter.bind(this, node.id)
                }
                return item
            }
        })
    }

    #syncPersonChips(personNodes: GraphNode[]) {
        this.#syncChips(this.#selectedNodes.scope.people, (i) => {
            const node = personNodes[i]
            if (node) {
                const item: ReturnType<ChipInfoCallback> = {
                    node,
                    onDelete: this.#handleDeletePersonIdFilter.bind(this, node.id)
                }
                return item
            }
        })
    }

    #syncChips(array: ObservableArray<ChipScope>, fnNext: ChipInfoCallback) {
        let i = 0

        let current = fnNext(i)
        while (current) {
            let item = array.get(i)
            if (!item) {
                item = new ChipScope()
                item.update = this.update
                array.set(i, item)
            }

            item.label = current.node.hid
            item.hint = current.node.value
            item.category = this.#localToGlobalCategoryIndex[current.node.category] ?? current.node.category

            if (item.value !== current.node.id) {
                item.value = current.node.id
                item.onDelete = current.onDelete
            }

            current = fnNext(++i)
        }
        array.length = i
    }

    async #handleZoomIn() {
        this.scope.scaleZoom(1.2)
    }

    async #handleZoomOut() {
        this.scope.scaleZoom(0.8334)
    }

    async #handleToggleLabels() {
        this.#hoverBar.scope.labelEnabled = !this.#hoverBar.scope.labelEnabled
        this.#refreshChart()
    }

    async #handleToggleGraphSize() {
        LOG.debug('handleToggleGraphSize')
        this.#updateOptions(!this.#hoverBar.scope.allNodesEnabled)
    }

    #updateOptions(allNodesEnabled: boolean) {
        if (allNodesEnabled) {
            this.#filteredGraph.nodes = this.#currentGraph.nodes
            this.#filteredGraph.links = this.#currentGraph.links
        } else {
            type IdType = GraphLink['source']

            const nodeMap = new Map<IdType, GraphNode>()
            const selectedNodes = new Map<IdType, GraphNode>()

            const newNodeMap = new Map<IdType, GraphNode>()

            for (const node of this.#currentGraph.nodes) {
                const nodeClone = lodash.cloneDeep(node)
                nodeMap.set(node.id, nodeClone)

                if (node.extra.selected) {
                    selectedNodes.set(node.id, nodeClone)
                    newNodeMap.set(node.id, nodeClone)
                }
            }

            const newLinks: GraphLink[] = []
            for (const link of this.#currentGraph.links) {
                // Only for selected nodes
                if (selectedNodes.has(link.source) || selectedNodes.has(link.target)) {
                    const sourceNode = nodeMap.get(link.source)
                    const targetNode = nodeMap.get(link.target)
                    if (sourceNode && targetNode) {
                        newLinks.push(link)
                        newNodeMap.set(sourceNode.id, sourceNode)
                        newNodeMap.set(targetNode.id, targetNode)
                    }
                }
            }

            this.#filteredGraph.links = newLinks
            this.#filteredGraph.nodes = [...newNodeMap.values()]
            graphLayout.computeNodesXY(this.#filteredGraph.nodes, this.#filteredGraph.links)
        }

        this.scope.options = this.#prepareChartOptions(this.#filteredGraph)
        this.#hoverBar.scope.allNodesEnabled = allNodesEnabled
    }

    #refreshChart() {
        const options = this.#prepareChartOptions(this.#filteredGraph)

        if (lodash.isArray(options.series) && options.series.length > 0) {
            const graphSerie = options.series[0] as GraphSeriesOption
            graphSerie.zoom = this.scope.getZoomValue()
            this.scope.mergeSerieOptions(graphSerie)
        }

        this.scope.refreshChart()
    }

    #prepareChartOptions(newGraghData: GraphType) {
        const { nodes: nodes, links: link } = newGraghData
        const { categories, legend, colorPalette } = this.#currentGraph
        this.scope.waiting = undefined

        for (const node of nodes) {
            node.label = {
                show: this.scope.hoverBar.labelEnabled
            }
        }

        const options: EChartsOption = {
            renderer: 'canvas',
            tooltip: {},
            legend: legend,
            animationDurationUpdate: 2500,
            animationEasingUpdate: 'quinticInOut',
            dataZoom: [
                {
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter'
                }
            ],
            series: [
                {
                    type: 'graph',
                    layout: 'none',
                    color: colorPalette,
                    symbolSize: 20,
                    draggable: true,
                    roam: true,
                    label: {
                        show: this.scope.hoverBar.labelEnabled,
                        position: 'right',
                        formatter: '{b}',
                        fontSize: 8
                    },
                    tooltip: {
                        //fontSize: 10,
                        formatter: (params) => {
                            return String(params.value)
                            //return ''
                        }
                    },
                    emphasis: {
                        focus: 'adjacency',
                        lineStyle: {
                            //width: 5
                        }
                    },
                    force: {
                        initLayout: 'circular',
                        layoutAnimation: false,
                        repulsion: 90,
                        edgeLength: 50,
                        friction: 0.6,
                        gravity: 0.1
                    },
                    edgeSymbol: ['none', 'arrow'],
                    edgeSymbolSize: [1, 5],
                    edgeLabel: {
                        fontSize: 15
                    },
                    labelLayout: {
                        hideOverlap: true
                    },
                    zoom: 0.4,

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: nodes as unknown as any,

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    links: link as unknown as any,

                    categories: categories,
                    lineStyle: {
                        //opacity: 0.9,
                        //width: 2,
                        curveness: 0.3,
                        color: 'source'
                    }
                }
            ]
        }

        return options
    }

    async #handleNodeClicked(node: GraphNode, exclusiveFilter: boolean) {
        const companyIds = this.#companyIds ? this.#companyIds.slice() : []
        const partnerIds = this.#partnerIds ? this.#partnerIds.slice() : []
        const personIds = this.#personIds ? this.#personIds.slice() : []

        if (exclusiveFilter) {
            companyIds.length = 0
            partnerIds.length = 0
        }

        if (node.type === EntityTypeID.company.code) {
            companyIds.push(node.id)
        } else if (node.type === EntityTypeID.companyPartner.code) {
            partnerIds.push(node.id)
        } else if (node.type === EntityTypeID.person.code) {
            personIds.push(node.id)
        }

        await this.#load({ companyIds, partnerIds, personIds })
    }

    async #handleCopyInformationalData(node: GraphNode) {
        if (node.value) {
            await navigator.clipboard.writeText(htmlToFormattedText(node.value))
        }
    }

    async #handleCopyIdentityData(node: GraphNode) {
        await navigator.clipboard.writeText(node.hid)
    }

    async #handleDeleteCompanyIdFilter(companyId: string) {
        if (this.#companyIds && this.#companyIds.length > 0) {
            const companyIds = this.#companyIds.filter((v) => v !== companyId)

            if (companyIds.length !== this.#companyIds.length) {
                const partnerIds = this.#partnerIds ? this.#partnerIds.slice() : []
                const personIds = this.#personIds ? this.#personIds.slice() : []
                await this.#load({ companyIds, partnerIds, personIds })
            }
        }
    }

    async #handleDeletePartnerIdFilter(partnerId: string) {
        if (this.#partnerIds && this.#partnerIds.length > 0) {
            const partnerIds = this.#partnerIds.filter((v) => v !== partnerId)
            if (partnerIds.length !== this.#partnerIds.length) {
                const companyIds = this.#companyIds ? this.#companyIds.slice() : []
                const personIds = this.#personIds ? this.#personIds.slice() : []
                await this.#load({ companyIds, partnerIds, personIds })
            }
        }
    }

    async #handleDeletePersonIdFilter(personId: string) {
        if (this.#personIds && this.#personIds.length > 0) {
            const personIds = this.#personIds.filter((v) => v !== personId)

            if (personIds.length !== this.#personIds.length) {
                const companyIds = this.#companyIds ? this.#companyIds.slice() : []
                const partnerIds = this.#partnerIds ? this.#partnerIds.slice() : []
                await this.#load({ companyIds, partnerIds, personIds })
            }
        }
    }

    #handleSearchBoxChanged(selecteds: SearchOption[]): SearchOption[] {
        LOG.debug('handleSearchBoxChanged:', { selecteds })

        const companyIds = this.#companyIds && !this.#headerBar.scope.exclusive ? this.#companyIds.slice() : []
        const partnerIds = this.#partnerIds && !this.#headerBar.scope.exclusive ? this.#partnerIds.slice() : []
        const personIds = this.#personIds && !this.#headerBar.scope.exclusive ? this.#personIds.slice() : []
        const cnesIds = []

        const result: SearchOption[] = []
        for (const option of selecteds) {
            if (option.id && option.id.startsWith('hash:')) {
                result.push(option)
                continue
            }

            if (option.type === EntityTypeID.company.code) {
                companyIds.push(option.id)
            } else if (option.type === EntityTypeID.companyPartner.code) {
                partnerIds.push(option.id)
            } else if (option.type === EntityTypeID.person.code) {
                personIds.push(option.id)
            } else if (option.type === EntityTypeID.cnesCompany.code) {
                cnesIds.push(option.id)
            } else {
                result.push(option)
            }
        }

        this.#load({ companyIds, partnerIds, personIds, cnesIds }).catch((e) => {
            // TODO: informar o usuário do problema
            LOG.error('Loging graph:', e)
        })

        return result
    }

    async #handleChangeExclusive(checked: boolean) {
        LOG.debug('handleToggleExclusive')
        this.#headerBar.scope.exclusive = checked
    }

    async #handleChangeOnlyActives(checked: boolean) {
        LOG.debug('handleChangeOnlyActives')
        this.#headerBar.scope.onlyActives = checked
        this.#searchPresenter.showInactives = !checked
    }

    async #handleOpenSearchByPicture() {
        LOG.debug('handleOpenSearchByPicture')
        if (!this.app.scope.dialog) {
            this.#searchByPictureScope = new SearchByPictureScope()
            this.#searchByPictureScope.frontCamera = this.#frontCamera
            this.#searchByPictureScope.rearCamera = this.#rearCamera
            this.#searchByPictureScope.update = this.update

            this.#searchByPictureScope.onClose = async () => {
                if (this.app.scope.dialog === this.#searchByPictureScope) {
                    this.#searchByPictureScope = undefined
                    this.app.scope.dialog = undefined
                }
            }

            this.#searchByPictureScope.onCapture = this.#handleDocumentCaptured.bind(this)

            this.app.scope.dialog = this.#searchByPictureScope
        } else {
            this.app.alert('error', '', 'Já temos um diálogo aberto')
        }
    }

    async #handleDocumentCaptured(image: string) {
        LOG.debug('Capture image:', image)
    }

    async #detectCameraDevices() {
        this.#rearCamera = undefined
        this.#frontCamera = undefined

        if (!navigator.mediaDevices) {
            return
        }
        const mediaDevices = navigator.mediaDevices?.enumerateDevices
            ? await navigator.mediaDevices.enumerateDevices()
            : []

        let someVideoDevice: MediaDeviceInfo | undefined = undefined
        for (const device of mediaDevices.filter(({ kind }) => kind === 'videoinput')) {
            someVideoDevice = device

            const label = device.label.toLowerCase()

            if (label.includes('rear') || label.includes('back')) {
                this.#rearCamera = device
                continue
            }

            if (label.includes('front')) {
                this.#frontCamera = device
                continue
            }
        }

        if (!this.#rearCamera && !this.#frontCamera) {
            this.#frontCamera = someVideoDevice
        }
    }
}

// :: Helpers

function removeDuplicatedFromArray(array: Array<string> | null | undefined) {
    if (!array || array.length == 0) {
        return undefined
    }

    const map = new Map<string, boolean>()
    return array.filter((value) => {
        if (map.has(value)) {
            return false
        }
        map.set(value, true)
        return true
    })
}
