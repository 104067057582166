import { CubePresenter, FlipIntent, Logger } from 'wdc-cube'

import type { TextualSearchRequest } from 'src/components/searchbox'
import { MainPresenter } from 'src/main/Main.presenter'
import { TheMapKeys } from '../tm_keys'
import { TheMapMarkDetailKeys } from './tm_md_details_keys'
import { TheMapMarkDetailsScope } from './tm_md_details_scopes'

const LOG = Logger.get('TheMapMarkDetailsPresenter')

export class TheMapMarkDetailsPresenter extends CubePresenter<MainPresenter, TheMapMarkDetailsScope> {
    public constructor(app: MainPresenter) {
        super(app, new TheMapMarkDetailsScope())
    }

    // :: Instance
    #requestId?: string
    #request!: TextualSearchRequest

    public override release() {
        super.release()
        LOG.debug('Finalized')
    }

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new TheMapMarkDetailKeys(this.app, intent)

        keys.dialogSlot(this.scope)

        if (initialization) {
            await this.#initialize(keys)
        } else if (this.#requestId !== keys.requestId) {
            LOG.debug('TODO: update')
        }

        return keys.allow
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new TheMapMarkDetailKeys(this.app, intent)
        keys.requestId = this.#requestId
    }

    async #initialize(keys: TheMapMarkDetailKeys) {
        this.scope.onClose = this.#handleClose.bind(this)

        const cacheValue = keys.requestId ? localStorage.getItem(keys.requestId) : null
        if (!cacheValue) {
            keys.allow = false
            await this.#handleClose()
            return
        }

        this.#requestId = keys.requestId
        this.#request = JSON.parse(cacheValue) as TextualSearchRequest
        LOG.debug('initialized:', this.#request)
    }

    async #handleClose() {
        await new TheMapKeys(this.app).flip()
    }
}
