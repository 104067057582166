import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'
import { makeStyles } from 'tss-react/mui'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import PhotoCamera from '@mui/icons-material/PhotoCamera'

import { SearchBoxView } from 'src/components/searchbox/views'
import { HeaderBarScope } from '../tc_scopes'

export { HeaderBarScope as ToolbarScope }

const LOG = Logger.get('ToolbarView')

const useStyles = makeStyles()({
    view: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        flexBasis: 0,
        gap: 8,
        marginRight: 10,
        marginLeft: 10
    },
    search: {
        alignSelf: 'center',
        flexGrow: 1,
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white'
        }
    },
    switch: {
        zoom: 0.6
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column'
    }
})

type ToolbarViewProps = {
    className?: string
    scope: HeaderBarScope
}

export const ToolbarView = function ({ className, ...props }: ToolbarViewProps) {
    const ctx = ToolbarContext.use(props)
    const { classes, scope } = ctx

    return (
        <FormGroup className={clsx(classes.view, className)}>
            <SearchBoxView
                className={classes.search}
                scope={scope.search}
                endAdornment={
                    <div className={classes.hbox}>
                        <FormGroup className={classes.vbox}>
                            <FormControlLabel
                                className={classes.switch}
                                label="Filtro exclusivo"
                                control={<Switch checked={scope.exclusive} onChange={ctx.handleChangeExclusiveCheck} />}
                            />
                            <FormControlLabel
                                className={classes.switch}
                                label="Mostrar ativos"
                                control={<Switch checked={scope.onlyActives} onChange={ctx.handleChangeAtivoCheck} />}
                            />
                        </FormGroup>
                        {scope.acceptPhotos && (
                            <Tooltip title="Buscar por foto">
                                <IconButton onClick={ctx.handleonOpenSearchByPicture}>
                                    <PhotoCamera />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                }
            />
        </FormGroup>
    )
}

class ToolbarContext {
    static #create = () => new ToolbarContext()

    static use({ scope }: ToolbarViewProps) {
        bindUpdate(React, scope)
        const context = React.useMemo(ToolbarContext.#create, [])
        context.#update(scope)
        return context
    }

    scope!: HeaderBarScope
    classes!: ReturnType<typeof useStyles>['classes']

    #update(scope: HeaderBarScope) {
        this.scope = scope
        this.classes = useStyles().classes
    }

    handleChangeExclusiveCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.scope.onChangeExclusive(e.target.checked).catch(LOG.caught)
    }

    handleChangeAtivoCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.scope.onChangeOnlyActives(e.target.checked).catch(LOG.caught)
    }

    handleonOpenSearchByPicture = () => {
        this.scope.onOpenSearchByPicture()
    }
}
