import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { LicenseInfo } from '@mui/x-license'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PageHistoryManager } from 'wdc-cube-react'
import { MainPresenter } from './main'
import { registerViews as registerWaitingViews } from './components/waiting/views'
import { registerViews as registerMultiselectViews } from './components/multiselect/views'
import { registerViews as registerSearchBoxViews } from './components/searchbox/views'
import { registerViews as registerMainViews, MainView } from './main/views'
import { registerViews as registerTheConnectionViews } from './modules/the-connection/views'
import { registerViews as registerTheActingViews } from './modules/the-acting/views'
import { registerViews as registerTheMapViews } from './modules/the-map/views'
import { registerViews as registerTheMapMarkDetailViews } from './modules/the-map/mark_details/views'
import { registerViews as registerTheConsumerMarket } from './modules/the-consumer-market/views'
import { registerViews as registerRDStationViews } from './modules/rd-station/views'
import { registerViews as registerRestrictedViews } from './modules/restricted/views'
import { buildCube } from './Cube'

import { MuiTheme } from './theme'
import { LocaleProvider } from 'src/utils/views'

LicenseInfo.setLicenseKey(
    'e6e2a927329dbfd9e981659d8bcd9994Tz05NDUzNSxFPTE3NTI5NDkwMDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

registerWaitingViews()
registerMultiselectViews()
registerSearchBoxViews()
registerMainViews()
registerTheConnectionViews()
registerTheActingViews()
registerTheMapViews()
registerTheMapMarkDetailViews()
registerTheConsumerMarket()
registerRDStationViews()
registerRestrictedViews()
buildCube()

const historyManager = new PageHistoryManager(true)

function App() {
    const presenter = React.useMemo(() => new MainPresenter(historyManager), [])

    React.useEffect(() => {
        const handleOnComponentWillUnmount = presenter.initialize()
        return () => {
            handleOnComponentWillUnmount()
        }
    }, [presenter])

    const locale = LocaleProvider.get()
    //adapterLocale={locale.fns}

    return (
        <MuiTheme>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
                localeText={locale.mui.components.MuiLocalizationProvider.defaultProps.localeText}
            >
                <MainView scope={presenter.scope} />
            </LocalizationProvider>
        </MuiTheme>
    )
}

const container = document.getElementById('root')
if (container) {
    const root = createRoot(container)
    root.render(<App />)
}

