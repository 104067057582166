import { ViewFactory } from 'wdc-cube-react'

import TheConsumerMarketScope from '../tcm_scopes'
import TheConsumerMarketView from './tcm_view'

import { HeaderScope } from '../tcm_scopes'
import { HeaderView } from './tcm_header_view'

export function registerViews(rv = ViewFactory.register) {
    rv(TheConsumerMarketScope, TheConsumerMarketView)
    rv(HeaderScope, HeaderView)
}
