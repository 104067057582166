import isNil from 'lodash/isNil'

export function getCpfFromCpfOrCnpjText(value: string | null | undefined) {
    if (!value) {
        return ''
    }

    if (value.length === 11) {
        return value
    }

    if (value.length === 14) {
        if (value.charAt(0) === '0' && value.charAt(1) === '0' && value.charAt(2) === '0' && value.charAt(3) === '*') {
            return value.substring(3)
        }
    }

    return ''
}

export function formatCNPJ(value: string | null | undefined) {
    if (!value) {
        return ''
    }

    if (value.length === 14) {
        // 000***527087**
        if (value.charAt(0) === '0' && value.charAt(1) === '0' && value.charAt(2) === '0' && value.charAt(3) === '*') {
            // Masked CPF
            return formatCPF(value.substring(3))
        }

        return [
            value.charAt(0),
            value.charAt(1),
            '.',
            value.charAt(2),
            value.charAt(3),
            value.charAt(4),
            '.',
            value.charAt(5),
            value.charAt(6),
            value.charAt(7),
            '/',
            value.charAt(8),
            value.charAt(9),
            value.charAt(10),
            value.charAt(11),
            '-',
            value.charAt(12),
            value.charAt(13)
        ].join('')
    }
    return value
}

export function formatCPF(value: string | null | undefined) {
    if (!value) {
        return ''
    }

    if (value.length === 11) {
        return [
            value.charAt(0),
            value.charAt(1),
            value.charAt(2),
            '.',
            value.charAt(3),
            value.charAt(4),
            value.charAt(5),
            '.',
            value.charAt(6),
            value.charAt(7),
            value.charAt(8),
            '-',
            value.charAt(9),
            value.charAt(10)
        ].join('')
    }

    return value
}

export function formatCPFOrCNPJ(value: string | null | undefined) {
    if (!value) {
        return ''
    }

    if (value.length === 11) {
        return formatCPF(value)
    }

    if (value.length === 14) {
        return formatCNPJ(value)
    }

    return value
}

export const formatTelefone = (() => {
    const regexOnlyNumber = /[^\d]/g

    return function formatTelefone(str: string) {
        if (isNil(str)) {
            return str
        }
        str = `${str}`

        const numStr = str.replace(regexOnlyNumber, '')

        if (numStr.length === 10) {
            return `(${numStr.substring(0, 2)}) ${numStr.substring(2, 6)}-${numStr.substring(6)}`
        }

        if (numStr.length === 12) {
            return `+${numStr.substring(0, 2)} (${numStr.substring(2, 4)}) ${numStr.substring(4, 8)}-${numStr.substring(
                8
            )}`
        }

        if (numStr.length === 11) {
            return `(${numStr.substring(0, 2)}) ${numStr.substring(2, 7)}-${numStr.substring(7)}`
        }

        if (numStr.length === 13) {
            return `+${numStr.substring(0, 2)} (${numStr.substring(2, 4)}) ${numStr.substring(4, 9)}-${numStr.substring(
                9
            )}`
        }

        return str
    }
})()

export function formatNumber(value: number) {
    return value.toLocaleString('pt-BR', { style: 'decimal' })
}

export function formatCurrency(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}
