import lodash from 'lodash'
import { Logger, Presenter } from 'wdc-cube'
import * as lang from 'src/utils/lang'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartNaturezaJuridicaScope, type NaturezaJuridicaBean } from './tcm_scopes'
import { TheConsumerMarketService, StringKeywordOperator, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasTotalNaturezaJuridica')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasTotalNaturezaJuridica extends Presenter<
    ChartNaturezaJuridicaScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.naturezaJuridica)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.naturezaJuridica
        this.scope.onItemClicked = this.__onNaturezaJuridicaClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.naturezaJuridicaCodes.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_por_natureza_jurica: true,
                filter: filter
            })

            const dataset: NaturezaJuridicaBean[] = []
            for (const bucket of resp.total_por_natureza_jurica?.buckets ?? []) {
                dataset.push({
                    codigo: bucket.key,
                    descricao: bucket.description,
                    quantidade: bucket.doc_count
                })
            }

            dataset.sort((a, b) => a.quantidade - b.quantidade)

            this.scope.dataset = dataset

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const codigoValMap = this.collectExplicitValues()

        for (const row of this.scope.dataset) {
            row.selected = codigoValMap.has(`${row.codigo}`)
        }
        this.scope.update()
    }

    private async __onNaturezaJuridicaClicked(cdNaturezaJuridica: number) {
        const sCode = `${cdNaturezaJuridica}`
        const filterPresenter = this.owner.filterPane.naturezaJuridicaCodes

        const inConditionScope = filterPresenter.getOrCreateIsIn()

        const map = this.collectExplicitValues()
        if (map.has(sCode)) {
            map.delete(sCode)
        } else {
            map.set(sCode, true)
        }

        inConditionScope.value = [...map.keys()].join(', ')
        filterPresenter.publish()

        this.__markSelected()
        this.__parent.fetchData('totalPorNaturezaJuridica')
    }

    private collectExplicitValues() {
        const prop = this.__filterManager.naturezaJuridicaCodes

        const codigoValMap = new Map<string, boolean>()
        for (const filterVal of prop.values()) {
            if (filterVal.value) {
                if (filterVal.operator == StringKeywordOperator.is_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigo && codigoValMap.set(codigo, true))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigoValMap.delete(codigo))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is) {
                    const codigo = lodash.trim(filterVal.value)
                    codigo && codigoValMap.set(codigo, true)
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not) {
                    codigoValMap.delete(filterVal.value)
                    continue
                }
            }
        }
        return codigoValMap
    }
}
