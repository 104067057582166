import React from 'react'
import clsx from 'clsx'

import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'

import { makeStyles } from 'tss-react/mui'

import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import LogoDarkBackground from 'src/components/icons/LogoDarkBackground'

import { SignInScope } from '../Main.scopes'

const LOG = Logger.get('MainView/SingInView')

export const usseStyles = makeStyles()({
    view: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'stretch',
        flexGrow: 1,
        justifyContent: 'center'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
    },
    logo: {
        width: 'unset',
        height: '64px',
        marginBottom: '32px'
    },
    card: {
        minWidth: '472px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px'
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: 1.25,
        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: '16px'
    }
})

export type SingInViewProps = {
    className?: string
    scope: SignInScope
}

export function SingInView({ className, scope }: SingInViewProps) {
    bindUpdate(React, scope)

    const handleUserNameChanged = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (scope.userName = e.target.value),
        [scope]
    )

    const handlePasswordChanged = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (scope.password = e.target.value),
        [scope]
    )

    const handleOnPasswordKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                scope.onSignIn().catch(LOG.caught)
            }
        },
        [scope]
    )

    const handleRememeberMeChanged = React.useCallback(
        (e: React.SyntheticEvent<Element, Event>, checked: boolean) => (scope.rememberMe = checked),
        [scope]
    )

    const { classes } = usseStyles()

    return (
        <div className={clsx(classes.view, className)}>
            <div className={classes.content}>
                <LogoDarkBackground className={classes.logo} textColor="#1976d2" />
                <Card className={classes.card}>
                    <Typography variant="h1" className={classes.title}>
                        Entre com sua conta para continuar
                    </Typography>

                    {scope.errorMessage && <Alert severity="error">{scope.errorMessage}</Alert>}

                    <TextField
                        label="Email"
                        helperText={scope.userNameError}
                        error={!!scope.userNameError}
                        onChange={handleUserNameChanged}
                    />

                    <TextField
                        label="Senha"
                        type="password"
                        helperText={scope.passwordError}
                        error={!!scope.passwordError}
                        onChange={handlePasswordChanged}
                        onKeyDown={handleOnPasswordKeyDown}
                    />

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Manter conectado por uma semana"
                            onChange={handleRememeberMeChanged}
                        />
                        <Button variant="contained" onClick={scope.onSignIn}>
                            Entrar
                        </Button>
                        <Button variant="text" onClick={scope.onForgotPassword}>
                            Esqueci minha senha
                        </Button>
                    </FormGroup>
                </Card>
            </div>
        </div>
    )
}
