import React from 'react'

import { classToFComponent } from 'src/utils/views'
import { ValueViewClass, type ValueViewProps } from './helpers/tcm_value'

export type ChartTotalEmpresasViewProps = ValueViewProps

export class ChartTotalEmpresasViewClass extends ValueViewClass<ChartTotalEmpresasViewProps> {
    render(props: ChartTotalEmpresasViewProps): JSX.Element {
        return super.render(props)
    }
}

export default classToFComponent(ChartTotalEmpresasViewClass, React)
