import React from 'react'
import lodash from 'lodash'
import * as echarts from 'echarts'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { makeStyles } from 'tss-react/mui'

import { classToFComponent } from 'src/utils/views'
import TcmCard from './helpers/tcm-card'
import { EChartViewClass } from './helpers/base-echart'

import { formatNumberToCompactShortForm, colors } from './helpers/tcm_view_utils'
import { ChartSituacaoCadastralScope, SituacaoCadastral } from '../tcm_scopes'

const LOG = Logger.get('TCM-SIT-CAD')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0
    },
    chart: {}
})

type ChartSituacaoCadastralProps = {
    className?: string
    style?: React.CSSProperties
    scope: ChartSituacaoCadastralScope
}

class ChartSituacaoCadastralViewClass extends EChartViewClass<ChartSituacaoCadastralProps> {
    // :: Fields
    scope!: ChartSituacaoCadastralScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: ChartSituacaoCadastralProps) {
        this.scope = props.scope
        this.classes = useStyles().classes
        this.chartData = buildChartData(this.scope)
        this.loaded = this.scope.loaded
        super.onSyncState(props)
    }

    override render({ className, style, scope }: ChartSituacaoCadastralProps): JSX.Element {
        return (
            <TcmCard caption={scope.caption} className={clsx(className)} style={style}>
                {super.render({ className: this.classes.view, scope })}
            </TcmCard>
        )
    }

    protected override onBindChartEvents(chart: echarts.ECharts) {
        chart.off('click', this.emitClickOnSeries)
        chart.on('click', this.emitClickOnSeries)
    }

    emitClickOnSeries = (evt: echarts.ECElementEvent) => {
        if (!lodash.isNumber(evt.dataIndex)) {
            return
        }

        switch (evt.dataIndex) {
            case 0:
                this.scope.onItemClicked(SituacaoCadastral.baixada).catch(LOG.caught)
                break
            case 1:
                this.scope.onItemClicked(SituacaoCadastral.ativa).catch(LOG.caught)
                break
            case 2:
                this.scope.onItemClicked(SituacaoCadastral.inapta).catch(LOG.caught)
                break
            case 3:
                this.scope.onItemClicked(SituacaoCadastral.supensa).catch(LOG.caught)
                break
            case 4:
                this.scope.onItemClicked(SituacaoCadastral.nula).catch(LOG.caught)
                break
        }
    }
}

export default classToFComponent(ChartSituacaoCadastralViewClass, React)

function buildChartData(scope: ChartSituacaoCadastralScope): echarts.EChartsOption {
    return {
        title: {
            show: false
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            confine: true
        },
        xAxis: {
            type: 'category',
            data: [
                {
                    value: 'Baixada',
                    textStyle: {
                        color: scope.selectedMap.has(SituacaoCadastral.baixada) ? colors.selected : undefined
                    }
                },
                {
                    value: 'Ativa',
                    textStyle: {
                        color: scope.selectedMap.has(SituacaoCadastral.ativa) ? colors.selected : undefined
                    }
                },
                {
                    value: 'Inapta',
                    textStyle: {
                        color: scope.selectedMap.has(SituacaoCadastral.inapta) ? colors.selected : undefined
                    }
                },
                {
                    value: 'Suspensa',
                    textStyle: {
                        color: scope.selectedMap.has(SituacaoCadastral.supensa) ? colors.selected : undefined
                    }
                },
                {
                    value: 'Nula',
                    textStyle: {
                        color: scope.selectedMap.has(SituacaoCadastral.nula) ? colors.selected : undefined
                    }
                }
            ],
            axisLabel: {
                fontSize: 10
            },
            triggerEvent: true,
            splitLine: { show: false }
        },
        grid: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 40
        },
        yAxis: {
            type: 'value',
            show: false
        },
        series: [
            {
                data: [
                    {
                        value: scope.baixada,
                        itemStyle: {
                            color: scope.selectedMap.has(SituacaoCadastral.baixada) ? colors.selected : undefined
                        }
                    },
                    {
                        value: scope.ativa,
                        itemStyle: {
                            color: scope.selectedMap.has(SituacaoCadastral.ativa) ? colors.selected : undefined
                        }
                    },
                    {
                        value: scope.inapta,
                        itemStyle: {
                            color: scope.selectedMap.has(SituacaoCadastral.inapta) ? colors.selected : undefined
                        }
                    },
                    {
                        value: scope.supensa,
                        itemStyle: {
                            color: scope.selectedMap.has(SituacaoCadastral.supensa) ? colors.selected : undefined
                        }
                    },
                    {
                        value: scope.nula,
                        itemStyle: {
                            color: scope.selectedMap.has(SituacaoCadastral.nula) ? colors.selected : undefined
                        }
                    }
                ],
                type: 'bar',
                label: {
                    show: true,
                    align: 'center',
                    position: 'top',
                    formatter: (params) => {
                        return formatNumberToCompactShortForm(params.value as number)
                    }
                }
            }
        ]
    }
}
