import type { TextualSearchRequest } from 'src/components/searchbox'

import * as L from 'leaflet'

import md5 from 'md5'
import { CubePresenter, Presenter, FlipIntent, Logger, NOOP_PROMISE_VOID } from 'wdc-cube'

import { MainPresenter } from 'src/main/Main.presenter'
import { SearchBoxPresenter } from 'src/components/searchbox'

import { TheMapKeys } from './tm_keys'
import { TheMapMarkDetailKeys } from './mark_details'
import {
    TheMapScope,
    TheMapHeaderScope,
    TheMapSelectionBarScope,
    TheMapSelectionScope,
    ScopeDefaults
} from './tm_scopes'

import TheMapService, { type PlotItem } from './tm_service'

const LOG = Logger.get('TheMapPresenter')

const service = TheMapService.singleton()
const MARK_CIRCLE_CLASSES = buildMarkCircleClasses()

type SelectionCtx = {
    selection: TheMapSelectionScope
    layerGroup: L.LayerGroup
}

export class TheMapPresenter extends CubePresenter<MainPresenter, TheMapScope> {
    #headerBar: Presenter<TheMapHeaderScope, TheMapPresenter>
    #selectionBar: Presenter<TheMapSelectionBarScope, TheMapPresenter>
    #searchPresenter: SearchBoxPresenter
    #map: L.Map | null = null
    #requestGroupId?: string
    #pendingRequesGrouptUpdate = NOOP_PROMISE_VOID

    public constructor(app: MainPresenter) {
        super(app, new TheMapScope())
        this.#headerBar = new Presenter(this, new TheMapHeaderScope())
        this.#selectionBar = new Presenter(this, new TheMapSelectionBarScope())
        this.#searchPresenter = new SearchBoxPresenter(app, this, this.#headerBar.scope.search)
        this.#searchPresenter.mode = 'MAP'
    }

    public override release() {
        if (this.app.scope.toolbar === this.#headerBar.scope) {
            this.app.scope.toolbar = undefined
        }

        this.#searchPresenter.release()
        this.#selectionBar.release()
        this.#headerBar.release()

        super.release()
        LOG.debug('Finalized')
    }

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        if(!this.app.scope.theMapEnabled) {
            await this.app.flipToDefault()
            return false
        }

        const keys = new TheMapKeys(this.app, intent)

        this.app.scope.toolbar = this.#headerBar.scope
        keys.parentSlot(this.scope)

        if (initialization) {
            await this.#initialize(keys)
        } else if (keys.requestGroupId !== this.#requestGroupId) {
            await this.#loadRequests(keys.requestGroupId)
        }

        if (keys.last) {
            keys.dialogSlot(null)
        }

        return keys.allow
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new TheMapKeys(this.app, intent)
        keys.requestGroupId = this.#requestGroupId
    }

    async #initialize(keys: TheMapKeys) {
        this.app.scope.toolbar = this.#headerBar.scope
        this.scope.selectionBar = this.#selectionBar.scope

        this.scope.onMapChanged = this.#handleMapChanged.bind(this)

        this.#headerBar.scope.onOnlyActivesChange = this.#handleOnlyActivesChange.bind(this)

        this.#searchPresenter.initialize()
        this.#searchPresenter.onComplianceChanged(keys.compliance)
        this.#searchPresenter.onApply = this.#handleApplyFilter.bind(this)
        this.#pendingRequesGrouptUpdate = async () => {
            this.#pendingRequesGrouptUpdate = NOOP_PROMISE_VOID
            await this.#loadRequests(keys.requestGroupId)
        }
    }

    async #loadRequests(requestGroupId: string | undefined | null) {
        this.#selectionBar.scope.selections.length = 0
        this.#requestGroupId = undefined

        const requestGroupJson = requestGroupId ? localStorage.getItem(requestGroupId) : null
        if (requestGroupId && requestGroupJson) {
            this.#requestGroupId = requestGroupId
            const requests = JSON.parse(requestGroupJson) as TextualSearchRequest[]
            for (const request of requests) {
                await this.#handleApplyFilter(request)
            }
        }
    }

    // :: Events

    public onComplianceChanged(compliance: boolean) {
        this.#searchPresenter.onComplianceChanged(compliance)
    }

    async #handleMapChanged(map: L.Map | null) {
        this.#map = map
        if (map) {
            map.setView(ScopeDefaults.getDefaultCenter(), 4)
            this.#pendingRequesGrouptUpdate()
        }
    }

    async #handleOnlyActivesChange(checked: boolean) {
        this.#headerBar.scope.onlyActives = checked
        this.#searchPresenter.showInactives = !checked
    }

    async #handleApplyFilter(request: TextualSearchRequest) {
        LOG.debug('#handleApplyFilter:', request)
        const leafletMap = this.#map
        if (!leafletMap) {
            return
        }

        const data = await service.fetch(request)

        const layerGroup = L.layerGroup()

        const center = ScopeDefaults.getDefaultCenter()
        const southWest: L.LatLngLiteral = { ...center }
        const northEast: L.LatLngLiteral = { ...center }

        const markClassName =
            MARK_CIRCLE_CLASSES[this.#selectionBar.scope.selections.length % MARK_CIRCLE_CLASSES.length]

        // For Each Scatter
        if (data.scatter.length > 0) {
            const scatterGroupMap = new Map<string, PlotItem[]>()
            for (const scatter of data.scatter) {
                const key = `${scatter.value[0]}:${scatter.value[1]}`
                let scatterGroup = scatterGroupMap.get(key)
                if (!scatterGroup) {
                    scatterGroup = []
                    scatterGroupMap.set(key, scatterGroup)
                }
                scatterGroup.push(scatter)
            }

            const scatterIt = scatterGroupMap.values()[Symbol.iterator]()
            let entry = scatterIt.next()
            if (!entry.done) {
                const someEntry = entry.value[0]
                northEast.lng = southWest.lng = someEntry.value[0]
                northEast.lat = southWest.lat = someEntry.value[1]
            }

            while (!entry.done) {
                const someEntry = entry.value[0]
                const lng = someEntry.value[0]
                const lat = someEntry.value[1]

                southWest.lng = Math.min(southWest.lng, lng)
                northEast.lng = Math.max(northEast.lng, lng)

                northEast.lat = Math.min(northEast.lat, lat)
                southWest.lat = Math.max(southWest.lat, lat)

                const names = entry.value.map((e) => e.name)
                const marker = new L.Marker(
                    { lng, lat },
                    {
                        title: names.join('\n'),
                        icon: request.empresa
                            ? L.divIcon({ className: markClassName, iconSize: L.point(12, 12) })
                            : L.divIcon({ className: 'gg-pin', iconSize: L.point(24, 24) })
                    }
                )
                marker.on('click', this.#handleMarkerClicked.bind(this, request, entry.value))
                layerGroup.addLayer(marker)

                entry = scatterIt.next()
            }
        }

        layerGroup.addTo(leafletMap)

        const selection = new TheMapSelectionScope()
        selection.request = request
        selection.title = await this.#buildSelectionTitle(request)
        selection.mark = markClassName
        //LOG.debug('DESCRICAO_FILTRO:', selection.title)
        //selection.title = JSON.stringify(request)
        selection.checked = true

        selection.update = this.#selectionBar.update
        {
            const ctx: SelectionCtx = {
                selection,
                layerGroup
            }
            selection.onCheckChange = this.#handleSelectionCheckChange.bind(this, ctx)
            selection.onDelete = this.#handleSelectionDelete.bind(this, ctx)
        }

        this.#selectionBar.scope.selections.push(selection)

        this.scope.selectionBar = this.#selectionBar.scope

        if (data.scatter.length === 1) {
            const item = data.scatter[0]
            center.lat = item.value[1]
            center.lng = item.value[0]
            leafletMap.flyTo(center, 13)
        } else if (data.scatter.length > 1) {
            leafletMap.flyToBounds(new L.LatLngBounds(southWest, northEast), { maxZoom: 13 })
        } else {
            leafletMap.flyTo(center, 4)
        }

        this.#updateRequestCache()
    }

    async #buildSelectionTitle(req: TextualSearchRequest) {
        const labels = [] as string[]
        await this.#searchPresenter.describeRequest(req, labels)
        return labels.join('; ')
    }

    #updateRequestCache() {
        const requestArray = this.#selectionBar.scope.selections.filter((e) => !!e.request).map((e) => e.request)

        if (requestArray.length > 0) {
            const requestGroupJson = JSON.stringify(requestArray)
            this.#requestGroupId = md5(requestGroupJson)
            localStorage.setItem(this.#requestGroupId, requestGroupJson)
        } else {
            this.#requestGroupId = undefined
        }

        this.updateHistory()
    }

    async #handleSelectionCheckChange(ctx: SelectionCtx, checked: boolean) {
        LOG.debug(`handleSelectionCheckChange(checked=${checked})`)
        ctx.selection.checked = checked
        if (checked) {
            this.#map && ctx.layerGroup.addTo(this.#map)
        } else {
            ctx.layerGroup.remove()
        }
    }

    async #handleSelectionDelete(ctx: SelectionCtx) {
        ctx.selection.checked = false
        ctx.layerGroup.remove()
        this.#selectionBar.scope.selections.removeByCriteria((v) => ctx.selection.uid === v.uid)
        this.#selectionBar.scope.update()

        this.#updateRequestCache()
    }

    async #handleMarkerClicked(request: TextualSearchRequest, plotItens: PlotItem[], evt: L.LeafletMouseEvent) {
        this.scope.onMarkCopyDataToClipboard = async () => {
            const names = plotItens.map((e) => e.name)
            await navigator.clipboard.writeText(names.join('\n'))
        }

        this.scope.onMarkShowDetailsClipboard = async () => {
            const requestJson = JSON.stringify(request)
            const requestId = md5(requestJson)
            localStorage.setItem(requestId, requestJson)

            const target = new TheMapMarkDetailKeys(this.app)
            target.requestId = requestId
            await target.flip()
        }

        this.scope.showMarkMenu(evt.originalEvent)
    }
}

function buildMarkCircleClasses() {
    return [
        'gg-ue-red-circle',
        'gg-islamic-green-circle',
        'gg-duke-blue-circle',
        'gg-mughal-green-circle',
        'gg-weldon-blue-circle'
    ]
}
