import * as DateFnsNs from 'date-fns'
import locale from 'date-fns/locale/pt-BR'
import { AppStorage, lodash, ServiceError } from 'src/utils'
import { AlertSeverity } from 'wdc-cube'
import { action, ApplicationPresenter, FlipIntent, HistoryManager, Logger, NOOP_VOID, Presenter, Scope } from 'wdc-cube'
import { Places } from '../Constants'
import { MainKeys } from './Main.keys'
import { AlertScope, BodyScope, IDialogScope, MainScope, ResetPasswordScope, SignInScope } from './Main.scopes'
import { MainService } from './Main.service'

const LOG = Logger.get('MainPresenter')

const appStorage = AppStorage.singleton()
const mainService = MainService.singleton()

export class MainPresenter extends ApplicationPresenter<MainScope> {
    // :: Instance

    private __removeAccessTokenAboutToExpireListener = NOOP_VOID

    private readonly __signInScope = new SignInScope()
    private readonly __resetPasswordScope = new ResetPasswordScope()
    private readonly __defaultScope = new BodyScope()

    private readonly __bodySlot = this.__setBodySlot.bind(this)
    private readonly __dialogSlot = this.__setDialogSlot.bind(this)

    private __resetPassword = false
    private __compliance = false
    private __nonAuthenticatedIntent?: FlipIntent
    private __defaultPlace = Places.theConnection

    constructor(historyManager: HistoryManager) {
        super(historyManager, new MainScope())

        // Important to allow newUriFromString to work properly
        this.setPlaces(Places)
    }

    // :: Properties

    get compliance() {
        return this.__compliance
    }

    get rdStationEnabled() {
        return this.scope.rdStationEnabled
    }

    get language() {
        return 'pt-BR'
    }

    // :: API

    initialize() {
        let initialized = false

        const bootstrap = async () => {
            try {
                await this.kickStart(Places.main)
                initialized = true
            } catch (error) {
                LOG.error('Initializing', error)
                this.release()
            }

            if (initialized && this.lastPlace === Places.main && this.scope.authenticated) {
                try {
                    this.flip(this.__defaultPlace)
                } catch (error) {
                    LOG.error('Flipping to theConnection on page initialization', error)
                }
            }
        }

        bootstrap().catch(LOG.caught)

        return () => {
            if (initialized) {
                this.release()
            }
        }
    }

    override release() {
        this.__removeAccessTokenAboutToExpireListener()
        this.__removeAccessTokenAboutToExpireListener = NOOP_VOID
        super.release()
    }

    override async applyParameters(intent: FlipIntent, initialization: boolean, last?: boolean): Promise<boolean> {
        const keys = new MainKeys(this, intent)

        if (initialization) {
            await this.__intializeState(keys)
        } else {
            this.__compliance = keys.compliance
        }

        if (this.lastPlace !== keys.targetPlace) {
            this.scope.toolbar = undefined
        }

        if (last || !this.scope.authenticated || this.__resetPassword) {
            this.scope.toolbar = undefined

            if (intent.place !== Places.main) {
                this.__nonAuthenticatedIntent = intent
            }

            this.__bodySlot(undefined)
            keys.allow = false
        } else {
            keys.parentSlot = this.__bodySlot
            keys.dialogSlot = this.__dialogSlot
        }

        if (keys.last) {
            keys.dialogSlot(null)
        }

        return keys.allow
    }

    override publishParameters(intent: FlipIntent) {
        const keys = new MainKeys(this, intent)
        keys.resetPassword = this.__resetPassword
        keys.compliance = this.__compliance
    }

    private async __intializeState(keys: MainKeys) {
        this.__resetPassword = keys.resetPassword
        this.__compliance = keys.compliance

        this.__removeAccessTokenAboutToExpireListener = appStorage.onAccessTokenAboutToExpire(
            this.__handleAccessTokenAboutToExpire.bind(this)
        )

        this.scope.onSignOut = this._handleSignOut.bind(this)
        this.scope.onProfile = this._handleProfile.bind(this)
        this.scope.onToggleComplianceMode = this._handleToggleComplianceMode.bind(this)
        this.scope.onDrawerToggle = this._handleDrawerToggle.bind(this)
        this.scope.onHome = this._handleHome.bind(this)
        this.scope.onTheConnection = this._handleTheConnection.bind(this)
        this.scope.onTheMap = this._handleTheMap.bind(this)
        this.scope.onTheActing = this._handleTheActing.bind(this)
        this.scope.onTheConsumerMarket = this._handleTheConsumerMarket.bind(this)
        this.scope.onRdStation = this._handleRdStation.bind(this)

        this.__signInScope.rememberMe = appStorage.rememberMe
        this.__signInScope.onSignIn = this._handleSignIn.bind(this)
        this.__signInScope.onForgotPassword = this._handleForgotPassword.bind(this)
        this.__signInScope.update = this.update

        this.__resetPasswordScope.onResetPassword = this._handleResetPassword.bind(this)
        this.__resetPasswordScope.onBackToLogin = this._handleSignOut.bind(this)
        this.__resetPasswordScope.update = this.update

        this.__defaultScope.update = this.update

        this.scope.authenticated = appStorage.accessTokenExpiration >= Date.now()
        if (this.scope.authenticated) {
            //this.scope.authenticated = await mainService.reloadAuthorizations()
        }

        this.scope.rdStationEnabled = this.scope.authenticated && appStorage.rdStationEnbled
        this.scope.theMapEnabled = this.scope.authenticated && appStorage.theMapEnabled
        this.scope.theActingEnabled = this.scope.authenticated && appStorage.theActingEnabled
        this.scope.theConsumerMarketEnabled = this.scope.authenticated && appStorage.theConsumerMarketEnabled
        this.scope.theConnectionEnabled = this.scope.authenticated && appStorage.theConnectionEnabled

        this.__computeDefaultPlace()

        LOG.info(`Initialized(${keys.toString()})`)
    }

    // :: Helper API

    override unexpected(message: string, error: unknown) {
        super.unexpected(message, error)
        if (error instanceof ServiceError) {
            this.alert('error', 'Erro proveniente do servidor', `${error.statusCode}: ${error.message}`)
        } else {
            this.alert('error', 'Erro não esperado', message)
        }
    }

    override alert(severity: AlertSeverity, title: string, message: string, onClose?: () => Promise<void>) {
        const alertScope = new AlertScope()
        alertScope.severity = severity
        alertScope.title = title
        alertScope.message = message
        alertScope.onClose = this._handleCloseAlert.bind(this, onClose)
        alertScope.update = this.update
        this.scope.alert = alertScope
    }

    notify(severity: AlertSeverity, message: string, duration = 3000) {
        this.scope.notify(severity, message, duration)
    }

    releaseToolbar(toolbar: Presenter<Scope>) {
        if (this.scope.toolbar === toolbar.scope) {
            this.scope.toolbar = undefined
        }
        toolbar.release()
    }

    formatDate(date: Date) {
        return DateFnsNs.format(date, 'dd/MM/yyyy', { locale: locale.ptBR })
    }

    // :: View Actions

    private __setBodySlot(scope: Scope | undefined | null) {
        let newScope = scope
        if (this.__resetPassword) {
            newScope = this.__resetPasswordScope
        } else if (this.scope.authenticated) {
            newScope = scope ?? this.__defaultScope
        } else {
            newScope = this.__signInScope
        }

        this.scope.body = newScope
    }

    private __setDialogSlot(scope: Scope | undefined | null) {
        if (this.scope.dialog !== scope) {
            this.scope.dialog = scope as IDialogScope

            if (this.scope.dialog && !this.scope.dialog.onClose) {
                LOG.error(`Missing onClose action on scope ${this.scope.dialog.constructor.name}`)
            }
        }
    }

    private __handleAccessTokenAboutToExpire() {
        LOG.debug('handleAccessTokenAboutToExpire')
        const action = async () => {
            try {
                await mainService.refreshAccessToken(this.__signInScope.rememberMe ?? false)
            } catch (err) {
                this._handleSignOut()
            }
        }
        action().catch(LOG.caught)
    }

    @action()
    protected async _handleCloseAlert(onClose?: () => Promise<void>) {
        this.scope.alert = undefined
        if (onClose) {
            await onClose()
        }
    }

    @action()
    protected async _handleSignIn() {
        LOG.debug('handleSignIn')

        let hasError = false
        this.__signInScope.userNameError = undefined
        this.__signInScope.passwordError = undefined
        this.__signInScope.errorMessage = undefined

        const userName = this.__signInScope.userName
        if (!userName) {
            this.__signInScope.userNameError = 'Campo requirido'
            hasError = true
        }

        const password = this.__signInScope.password
        if (!password) {
            this.__signInScope.passwordError = 'Campo requirido'
            hasError = true
        }

        if (hasError) {
            return
        }

        try {
            await mainService.signIn(userName!, password!, this.__signInScope.rememberMe ?? false)

            this.scope.authenticated = true
            this.scope.rdStationEnabled = appStorage.rdStationEnbled
            this.scope.theMapEnabled = appStorage.theMapEnabled
            this.scope.theActingEnabled = appStorage.theActingEnabled
            this.scope.theConsumerMarketEnabled = appStorage.theConsumerMarketEnabled
            this.scope.theConnectionEnabled = appStorage.theConnectionEnabled

            this.__computeDefaultPlace()

            if (this.__nonAuthenticatedIntent != null) {
                const targetIntent = this.__nonAuthenticatedIntent
                try {
                    targetIntent.attributes.clear()

                    this.__nonAuthenticatedIntent = undefined
                    await this.flipToIntent(targetIntent)
                } catch (err) {
                    LOG.error('fliping to intent ' + targetIntent, err)
                    await this.flipToDefault()
                }
            } else {
                await this.flipToDefault()
            }
        } catch (err) {
            if (err instanceof ServiceError) {
                if (err.statusCode === 401) {
                    this.__signInScope.errorMessage = 'Usuário desconhecido ou senha incorreta'
                    return
                }
            }
            throw err
        }
    }

    private __computeDefaultPlace() {
        if (this.scope.theConnectionEnabled) {
            this.__defaultPlace = Places.theConnection
        } else if (this.scope.theConsumerMarketEnabled) {
            this.__defaultPlace = Places.theConsumerMarket
        } else if (this.scope.theActingEnabled) {
            this.__defaultPlace = Places.theActing
        } else if (this.scope.theMapEnabled) {
            this.__defaultPlace = Places.theMap
        } else {
            this.__defaultPlace = Places.main
        }
    }

    @action()
    protected async _handleSignOut() {
        LOG.debug('handleSignOut')
        this.scope.authenticated = false
        this.__resetPassword = false
        this.__signInScope.rememberMe = false
        this.__signInScope.userName = undefined
        this.__signInScope.userNameError = undefined
        this.__signInScope.password = undefined
        this.__signInScope.passwordError = undefined
        this.__signInScope.errorMessage = undefined
        appStorage.clearAccessToken()
        await this.flip(this.rootPlace)
    }

    @action()
    protected async _handleProfile() {
        LOG.debug('handleProfile')
    }

    @action()
    protected async _handleToggleComplianceMode() {
        this.__compliance = !this.__compliance
        this.updateHistory()

        this.forEachPresenter((child) => {
            const childAsRecord = child as unknown as Record<string, unknown>
            const onComplianceChangedListener = childAsRecord['onComplianceChanged']
            if (lodash.isFunction(onComplianceChangedListener)) {
                onComplianceChangedListener.call(child, this.__compliance)
            }
        })
    }

    public async flipToDefault() {
        await this.flip(this.__defaultPlace)
    }

    @action()
    protected async _handleForgotPassword() {
        LOG.info('handleForgotPassword')
        this.__resetPassword = true
        this.__resetPasswordScope.email = undefined
        await this.flip(this.rootPlace)
    }

    @action()
    protected async _handleResetPassword() {
        LOG.info('handleResetPassword')
        this.__resetPasswordScope.emailError = undefined
        if (this.__resetPasswordScope.email) {
            await mainService.requestPasswordReset(this.__resetPasswordScope.email)
            this.scope.notify('success', 'Solicitação enviada', 3000)
        } else {
            this.__resetPasswordScope.emailError = 'Campo requerido'
            return
        }

        this.__resetPassword = false
        await this._handleSignOut()
    }

    @action()
    protected async _handleDrawerToggle() {
        this.scope.drawerOpen = !this.scope.drawerOpen
    }

    @action()
    protected async _handleHome() {
        await this.flip(this.rootPlace)
    }

    @action()
    protected async _handleTheConnection() {
        await this.flip(Places.theConnection)
    }

    @action()
    protected async _handleTheMap() {
        await this.flip(Places.theMap)
    }

    @action()
    protected async _handleTheActing() {
        await this.flip(Places.theActing)
    }

    @action()
    protected async _handleTheConsumerMarket() {
        await this.flip(Places.theConsumerMarket)
    }

    @action()
    protected async _handleRdStation() {
        await this.flip(Places.rdStation)
    }
}
