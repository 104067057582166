import { DateFns } from 'lang-utils'
import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartEmpresasAbertasFechadasScope, type DataZoom } from './tcm_scopes'
import { TheConsumerMarketService, type EmpresaFilter, type AggDateRange } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasAbertasFechadas')

const fns = DateFns.singleton
const service = TheConsumerMarketService.singleton()

type AbertaFechadaBean = {
    competencia: number
    qtdAbertas: number
    qtdFechadas: number
}

type Bucket = AggDateRange['buckets'][0]

export default class TcmPresenterForEmpresasAbertasFechadas extends Presenter<
    ChartEmpresasAbertasFechadasScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.abertasFechadas)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.abertasFechadas
        this.scope.onRestore = this.__onEmpresasAbertasFechadasRestore.bind(this)
        this.scope.onDataZoom = this.__onEmpresasAbertasFechadasDataZoom.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.inicioAtividade.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                quantidade_de_novas_empresas_abertas_por_mes: true,
                quantidade_de_novas_empresas_fechadas_por_mes: true,
                filter: filter
            })

            const abertaFechadaBeanMap = new Map<number, AbertaFechadaBean>()

            const emptyBuckets = [] as Bucket[]
            const abertasBuckets = resp.quantidade_de_novas_empresas_abertas_por_mes?.buckets ?? emptyBuckets
            const fechadasBuckets = resp.quantidade_de_novas_empresas_fechadas_por_mes?.buckets ?? emptyBuckets

            abertasBuckets.forEach((bucket) => {
                abertaFechadaBeanMap.set(bucket.key, {
                    competencia: bucket.key,
                    qtdAbertas: bucket.doc_count,
                    qtdFechadas: 0
                })
            })

            fechadasBuckets.forEach((bucket) => {
                const bean = abertaFechadaBeanMap.get(bucket.key)
                if (bean) {
                    bean.qtdFechadas = bucket.doc_count
                    return
                }

                abertaFechadaBeanMap.set(bucket.key, {
                    competencia: bucket.key,
                    qtdAbertas: 0,
                    qtdFechadas: bucket.doc_count
                })
            })

            const beanSet = [...abertaFechadaBeanMap.values()]
            beanSet.sort((a, b) => a.competencia - b.competencia)

            const dataset = [] as [string, number, number][]
            beanSet.forEach((b) => {
                const dt = new Date(b.competencia)
                const label = `${dt.getMonth() + 1}/${dt.getFullYear()}`
                dataset.push([label, b.qtdAbertas, b.qtdFechadas])
            })

            this.scope.dataset = dataset
        } finally {
            this.scope.loaded = true
        }
    }

    private __zoomInfoToPeriod(zoomInfo: DataZoom) {
        const startRow = this.scope.dataset[zoomInfo.startValue]
        const endRow = this.scope.dataset[zoomInfo.endValue]
        if (startRow && endRow) {
            return {
                startIsoDate: fns().formatISO(convertStringDateToDate(startRow[0])),
                endIsoDate: fns().formatISO(fns().lastDayOfMonth(convertStringDateToDate(endRow[0])))
            }
        }
    }

    private async __onEmpresasAbertasFechadasRestore() {
        this.clear()
        this.__parent.fetchData('')
    }

    private async __onEmpresasAbertasFechadasDataZoom(zoomInfo: DataZoom) {
        const prp = this.__filterManager.inicioAtividade
        prp.clear()
        const period = this.__zoomInfoToPeriod(zoomInfo)
        if (period) {
            prp.add({ from: period.startIsoDate, to: period.endIsoDate })
        }
        this.scope.update()
        this.__parent.fetchData(prp.size ? 'relacaoEmpresasAbertasFechadas' : '')
    }
}

// :: Internal

function convertStringDateToDate(monthAndYearDate: string) {
    const parts = monthAndYearDate.split('/')
    const month = Number.parseInt(parts[0])
    const year = Number.parseInt(parts[1])
    const refDate = new Date(year, month - 1, 1)
    return refDate
}
