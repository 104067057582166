import { Observable, ObservableArray, Scope, observe } from 'wdc-cube'
import {
    // Concrete
    SituacaoCadastral,
    JunctionMode,
    StringOperator,
    StringKeywordOperator,
    NumberOperator,
    // Abstract
    type EmpresaBean,
    type GeoLocationMode,
    type ColumnSort,
    type ColumnFilter
} from './tcm_service'

export { type GeoLocationMode }
export { SituacaoCadastral, JunctionMode, StringOperator, StringKeywordOperator, NumberOperator }

@Observable
export class HeaderScope extends Scope {
    @observe() dataAtualizacao = ''
    @observe() dataDeCorte = ''
}

@Observable
export class ValueScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() value = 0
    @observe() selected = false

    onClicked?: typeof Scope.ASYNC_ACTION
}

@Observable
export class ChartFilialMatrizScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() numMatrizes = 0
    @observe() numFiliais = 0

    @observe() matrizesSelected = false
    @observe() filiaisSelected = false

    onItemClicked = Scope.ASYNC_ACTION_ONE<boolean>()
}

@Observable
export class ChartSituacaoCadastralScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() baixada = 0
    @observe() ativa = 0
    @observe() inapta = 0
    @observe() supensa = 0
    @observe() nula = 0

    selectedMap = new Map<SituacaoCadastral, boolean>()

    onItemClicked = Scope.ASYNC_ACTION_ONE<SituacaoCadastral>()
}

export type NaturezaJuridicaBean = {
    codigo: number
    descricao: string
    quantidade: number
    selected?: boolean
}

@Observable
export class ChartNaturezaJuridicaScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() dataset = [] as NaturezaJuridicaBean[]

    onItemClicked = Scope.ASYNC_ACTION_ONE<number>()
}

export type TableAtividadeEconomicaRow = {
    id: string
    descricao: string
    qtdEmpresas: number
    qtdFuncionarios: number
    selected?: boolean
}

@Observable
export class TableAtividadeEconomicaScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() dataset = [] as TableAtividadeEconomicaRow[]

    onItemClicked = Scope.ASYNC_ACTION_ONE<string>()
}

export type DataZoom = {
    startValue: number
    endValue: number
}

@Observable
export class ChartEmpresasAbertasFechadasScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() dataset = [] as [string, number, number][]

    onRestore = Scope.ASYNC_ACTION
    onDataZoom = Scope.ASYNC_ACTION_ONE<DataZoom>()
}

@Observable
export class ChartFaixasScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() dataset = [] as [number, string, boolean][]

    onItemClicked = Scope.ASYNC_ACTION_ONE<number>()
}

export type EmpresaRow = EmpresaBean & { selected?: boolean }

@Observable
export class TableEmpresaScope extends Scope {
    @observe() caption = ''
    @observe() loaded = false
    @observe() dataset = [] as EmpresaRow[]
    @observe() datasetVersion = 0
    @observe() downloadEnabled = true
    @observe() working = false
    @observe() pageIndex = 0
    @observe() pageSize = 100
    @observe() rowCount = 1
    @observe() sorting: ColumnSort[] = []
    @observe() filters: ColumnFilter[] = []

    readonly total = {
        func_qtd: 0,
        faturamento_estimado: 0,
        faturamento_estimado_grupo: 0
    }

    onSelectionChange = Scope.ASYNC_ACTION_TWO<string, boolean>()
    onFiltrationChange = Scope.ASYNC_ACTION_ONE<ColumnFilter[]>()
    onPaginationChange = Scope.ASYNC_ACTION_TWO<number, number>()
    onSortingChange = Scope.ASYNC_ACTION_ONE<ColumnSort[]>()
    onDownloadClick = Scope.ASYNC_ACTION
}

@Observable
export class SliderScope extends Scope {
    @observe() min = 10
    @observe() max = 1000
    @observe() step = 10
    @observe() value = 50
    @observe() unit: 'km' | 'm' = 'm'
    @observe() visible = false

    onValueChanged = Scope.ASYNC_ACTION_NUMBER
}

@Observable
export class MapCardScope extends Scope {
    @observe() caption = ''
    @observe() expanded = false
    @observe() showClearButton = false
    @observe() mode: GeoLocationMode = 'route'

    readonly slider = new SliderScope()

    onExpandClick = Scope.ASYNC_ACTION
    onShrunkClick = Scope.ASYNC_ACTION
    onMapChanged = Scope.ASYNC_ACTION_ONE<L.Map | null>()
    onClearFilters = Scope.ASYNC_ACTION
    onSetAreaMode = Scope.ASYNC_ACTION
    onSetRouteMode = Scope.ASYNC_ACTION
    onSetCircleMode = Scope.ASYNC_ACTION
}

@Observable
export class StringParcelFilterScope extends Scope {
    uid = ''
    junctionMode: JunctionMode = JunctionMode.AND

    @observe() operator = StringOperator.contains
    @observe() value = ''
    @observe() valueVisible = true
    @observe() options: string[] = []

    onClear = Scope.ASYNC_ACTION
    onEnterKeyPressed = Scope.ASYNC_ACTION
    onOperatorChange = Scope.ASYNC_ACTION_ONE<StringOperator>()
    onValueChange = Scope.ASYNC_ACTION_STRING
    onOptionSelected = Scope.ASYNC_ACTION_STRING
}

export interface IFilterScope extends Scope {
    order: number
    visible: boolean
    expanded: boolean
    caption: string
}

@Observable
export class StringFilterScope extends Scope implements IFilterScope {
    @observe() caption = ''
    @observe() expanded = true
    @observe() visible = false
    order = 0
    readonly conjunction = new ObservableArray<StringParcelFilterScope>(this)
    readonly disjunction = new ObservableArray<StringParcelFilterScope>(this)

    onAddFilter = Scope.ASYNC_ACTION_ONE<JunctionMode>()
}

export type KeywordOption = {
    key: string
    label: string
}

@Observable
export class StringKeywordParcelFilterScope extends Scope {
    uid = ''
    junctionMode: JunctionMode = JunctionMode.AND

    @observe() operator = StringKeywordOperator.is_in
    @observe() value = ''
    @observe() valueVisible = true

    @observe() options: KeywordOption[] = []

    onClear = Scope.ASYNC_ACTION
    onEnterKeyPressed = Scope.ASYNC_ACTION
    onOperatorChange = Scope.ASYNC_ACTION_ONE<StringKeywordOperator>()
    onValueChange = Scope.ASYNC_ACTION_STRING
    onOptionSelected = Scope.ASYNC_ACTION_ONE<KeywordOption>()
}

@Observable
export class StringKeywordFilterScope extends Scope implements IFilterScope {
    @observe() caption = ''
    @observe() expanded = true
    @observe() visible = false
    @observe() acceptBlankValues = false
    order = 0
    readonly conjunction = new ObservableArray<StringKeywordParcelFilterScope>(this)
    readonly disjunction = new ObservableArray<StringKeywordParcelFilterScope>(this)

    onAddFilter = Scope.ASYNC_ACTION_ONE<JunctionMode>()
}

@Observable
export class NumberParcelFilterScope extends Scope {
    uid = ''
    junctionMode: JunctionMode = JunctionMode.AND

    @observe() operator = NumberOperator.is
    @observe() value = ''
    @observe() toValue = ''
    @observe() valueVisible = true

    onClear = Scope.ASYNC_ACTION
    onEnterKeyPressed = Scope.ASYNC_ACTION
    onOperatorChange = Scope.ASYNC_ACTION_ONE<NumberOperator>()
    onValueChange = Scope.ASYNC_ACTION_TWO<string, 'from' | 'to'>()
}

@Observable
export class NumberFilterScope extends Scope implements IFilterScope {
    @observe() caption = ''
    @observe() expanded = true
    @observe() visible = false
    @observe() acceptBlankValues = false
    order = 0
    readonly conjunction = new ObservableArray<NumberParcelFilterScope>(this)
    readonly disjunction = new ObservableArray<NumberParcelFilterScope>(this)

    onAddFilter = Scope.ASYNC_ACTION_ONE<JunctionMode>()
}

@Observable
export class EnchenteRs2024Scope extends Scope {
    @observe() active = false
    onChange = Scope.ASYNC_ACTION_BOOLEAN
    onNotifyMap = Scope.ASYNC_ACTION_BOOLEAN
}

@Observable
export class FilterPaneScope extends Scope {
    @observe() expanded = false

    readonly enchenteRs2024 = new EnchenteRs2024Scope()
    readonly nomeEmpresa = new StringFilterScope()
    readonly nomeFantasia = new StringFilterScope()
    readonly cnpj = new StringKeywordFilterScope()
    readonly naturezaJuridicaCodes = new StringKeywordFilterScope()
    readonly naturezaJuridicaTexts = new StringFilterScope()
    readonly cnae = new StringKeywordFilterScope()
    readonly cnaeTexts = new StringFilterScope()
    readonly uf = new StringKeywordFilterScope()
    readonly ufTexts = new StringFilterScope()
    readonly cidadeTexts = new StringFilterScope()
    readonly regiaoMetropolitanaTexts = new StringFilterScope()
    readonly scoreRiscos = new StringKeywordFilterScope()
    readonly totalEmpresaGrupo = new NumberFilterScope()
    readonly totalEmpresaCapSocial = new NumberFilterScope()
    readonly totalFuncionarios = new NumberFilterScope()
    readonly totalFuncionarios_0_a_18 = new NumberFilterScope()
    readonly totalFuncionarios_19_a_33 = new NumberFilterScope()
    readonly totalFuncionarios_34_a_48 = new NumberFilterScope()
    readonly totalFuncionarios_49_a_58 = new NumberFilterScope()
    readonly totalFuncionarios_59_ou_mais = new NumberFilterScope()
    readonly totalFuncionariosFemininos = new NumberFilterScope()
    readonly totalFuncionariosMasculinos = new NumberFilterScope()
    readonly totalFuncionariosDoGrupo = new NumberFilterScope()
    readonly faixaFaturamentoAnual = new NumberFilterScope()
    readonly faixaFaturamentoConsolidadoGrupo = new NumberFilterScope()
    readonly idadeEmpresa = new NumberFilterScope()
    readonly crescimento2021 = new NumberFilterScope()
    readonly crescimento2022 = new NumberFilterScope()
    readonly mediaCrescimento2021_2022 = new NumberFilterScope()

    onToggleExpanded = Scope.ASYNC_ACTION
    onApply = Scope.ASYNC_ACTION
}

@Observable
export class TheConsumerMarketScope extends Scope {
    header = new HeaderScope()
    filterPane = new FilterPaneScope()

    totalEmpresas = new ValueScope()
    totalEmpresasAtivas = new ValueScope()
    totalEmpresasNovasUltimoMes = new ValueScope()
    filialMatriz = new ChartFilialMatrizScope()
    situacaoCadastral = new ChartSituacaoCadastralScope()
    naturezaJuridica = new ChartNaturezaJuridicaScope()
    atividadeEconomica = new TableAtividadeEconomicaScope()
    abertasFechadas = new ChartEmpresasAbertasFechadasScope()
    empresasNoGrupo = new ChartFaixasScope()
    faixaCapitalSocial = new ChartFaixasScope()
    faixaDeIdade = new ChartFaixasScope()
    faixaDeFuncionarios = new ChartFaixasScope()
    faixaDeFuncionariosNoGrupo = new ChartFaixasScope()
    faixaDeFaturamentoAnual = new ChartFaixasScope()
    faixaDeFaturamentoConsolidadoGrupo = new ChartFaixasScope()
    empresasListagem = new TableEmpresaScope()
    mapa = new MapCardScope()

    readonly keyboard = {
        ctrlKey: false
    }

    onCtrlKeyToggled = Scope.ASYNC_ACTION_BOOLEAN
}

let DEFAULT_CENTER: L.LatLngLiteral = { lat: -15.7754462, lng: -47.7970891 }

export const ScopeDefaults = new (class {
    getDefaultCenter(): L.LatLngLiteral {
        return { ...DEFAULT_CENTER }
    }

    setDefaultCenter(value: L.LatLngLiteral) {
        DEFAULT_CENTER = value
    }
})()

export default TheConsumerMarketScope
