import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { ViewSlot, bindUpdate } from 'wdc-cube-react'
import { makeStyles } from 'tss-react/mui'
import ReactECharts from 'src/components/echarts'
import { Menu, Item, useContextMenu, ItemParams } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'

import { TheConnectionScope } from '../tc_scopes'
import SelectedNodesView from './tc_selected-nodes_view'
import HoverBarView from './tc_hover-bar_view'

import type { ECharts } from 'echarts'
import type { GraphNode } from '../tc_types'

const LOG = Logger.get('TC-VIEW')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        minHeight: 600
    },
    appliedFilters: {},
    chart: {
        display: 'flex',
        flexGrow: 1
    },
    hoverBar: {
        position: 'absolute',
        right: 10,
        bottom: 10,

        display: 'flex',
        flexDirection: 'column'
    }
})

type GraphCompaniesViewProps = {
    className?: string
    scope: TheConnectionScope
}

const MENU_ID = '380a0d88-9dca-4b1d-867a-e2319d1775b7'

// https://github.com/fkhadra/react-contexify

export const TheConnectionView = function ({ className, scope }: GraphCompaniesViewProps) {
    bindUpdate(React, scope)

    const { show } = useContextMenu({
        id: MENU_ID
    })

    const instanceHolder = React.useMemo(() => ({ instance: undefined as ECharts | undefined }), [])

    const handleExclusiveFilterClick = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ event, props }: ItemParams<Record<string, unknown>, Record<string, unknown>>) => {
            // console.log(event, props)
            if (props) {
                const node = props.node as GraphNode
                scope.onNodeClicked(node, true).catch(LOG.caught)
            }
        },
        [scope]
    )

    const handleAdditionalFilterClick = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ event, props }: ItemParams<Record<string, unknown>, Record<string, unknown>>) => {
            // console.log(event, props)
            if (props) {
                const node = props.node as GraphNode
                scope.onNodeClicked(node, false).catch(LOG.caught)
            }
        },
        [scope]
    )

    const handleCopyInformationalDataClick = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ event, props }: ItemParams<Record<string, unknown>, Record<string, unknown>>) => {
            // console.log(event, props)
            if (props) {
                const node = props.node as GraphNode
                scope.onCopyInformationalData(node).catch(LOG.caught)
            }
        },
        [scope]
    )

    const handleCopyIdentityDataClick = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ event, props }: ItemParams<Record<string, unknown>, Record<string, unknown>>) => {
            // console.log(event, props)
            if (props) {
                const node = props.node as GraphNode
                scope.onCopyIdentityData(node).catch(LOG.caught)
            }
        },
        [scope]
    )

    const handleChartReady = React.useCallback(
        (instance: ECharts) => {
            instanceHolder.instance = instance

            const getSeriesByIndex = (idx: number) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const anyEChart = instance as unknown as any

                const model = anyEChart.getModel()
                if (model) {
                    return model.getSeriesByIndex(idx)
                }
            }

            scope.refreshChart = () => {
                instance.resize({ height: 'auto', width: 'auto' })
            }

            scope.getZoomValue = () => {
                const serie = getSeriesByIndex(0)
                if (serie) {
                    return serie.coordinateSystem.getZoom()
                }
                return 0.2
            }

            scope.scaleZoom = (scale) => {
                echartZoom(instance, scale)
            }

            scope.mergeSerieOptions = (options) => {
                const serie = getSeriesByIndex(0)
                if (!serie) {
                    return {}
                }
                serie.mergeOption(options)
            }

            instance.on('contextmenu', { dataType: 'node' }, function (this, item) {
                // call this method while the node of graph was clicked.
                //console.log('contextmenu on node:', item)
                //console.log('echart', instance)

                const rawEvent = item.event?.event

                if (rawEvent) {
                    show({
                        event: rawEvent,
                        props: {
                            node: item.data
                        }
                    })
                }
            })

            instance.on('click', { dataType: 'edge' }, function (this, item) {
                // call this method while the edge of graph was clicked.
                console.log('click on edge: ', item)
            })

            instance.on('click', { dataType: 'node' }, function (this, item) {
                // call this method while the edge of graph was clicked.
                console.log('click on node: ', item.data)
            })

            instance.on('dblclick', { dataType: 'node' }, function (this, item) {
                const node = item.data as unknown as GraphNode
                scope.onNodeClicked(node, true).catch(LOG.caught)
            })

            instance.on('finished', function () {
                // NOOP
            })

            scope.onReady().catch(LOG.caught)
        },
        [scope]
    )

    const { classes } = useStyles()

    if (scope.waiting) {
        return <ViewSlot scope={scope.waiting} />
    }

    if (!scope.options) {
        return <div className={clsx(classes.view, className)} />
    }

    return (
        <div className={clsx(classes.view, className)}>
            {scope.selectedNodesPanel && (
                <SelectedNodesView className={classes.appliedFilters} scope={scope.selectedNodesPanel} />
            )}
            <ReactECharts className={classes.chart} option={scope.options} onChartReady={handleChartReady} />
            <HoverBarView className={classes.hoverBar} scope={scope.hoverBar} />
            <Menu id={MENU_ID}>
                <Item onClick={handleExclusiveFilterClick}>Filtro exclusivo</Item>
                <Item onClick={handleAdditionalFilterClick}>Filtro adicional</Item>
                <Item onClick={handleCopyInformationalDataClick}>Copiar informações</Item>
                <Item onClick={handleCopyIdentityDataClick}>Copiar CPF/CNPJ</Item>
            </Menu>
        </div>
    )
}

// :: internals

function echartZoom(echart: ECharts, scale: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyEChart: any = echart
    const echartDiv: HTMLDivElement = anyEChart._dom
    const roamControler = anyEChart._chartsViews[0]._controller
    roamControler.trigger('zoom', {
        scale: scale,
        originX: (echartDiv.offsetWidth / 2) | 0,
        originY: (echartDiv.offsetHeight / 2) | 0
    })
}
