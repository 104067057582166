import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFaixasScope } from './tcm_scopes'
import { TheConsumerMarketService, JunctionMode, NumberOperator, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasFaixaFuncionarios')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasFaixaFuncionarios extends Presenter<
    ChartFaixasScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.faixaDeFuncionarios)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.faixaDeFuncionarios
        this.scope.onItemClicked = this.__onFaixaDeFuncionarios.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.totalFuncionarios.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_por_faixa_de_funionarios: true,
                filter: filter
            })

            const data = {
                qtd_1_to_5: 0,
                qtd_6_to_10: 0,
                qtd_11_to_50: 0,
                qtd_51_to_100: 0,
                qtd_101_to_500: 0,
                qtd_501_to_1000: 0,
                qtd_1001_to_2500: 0,
                qtd_2501_to_5000: 0,
                qtd_5000_to_INF: 0
            }

            for (const bucket of resp.total_de_empresas_por_faixa_de_funionarios?.buckets ?? []) {
                switch (bucket.key as keyof typeof data) {
                    case 'qtd_1_to_5':
                        data.qtd_1_to_5 += bucket.doc_count
                        break
                    case 'qtd_6_to_10':
                        data.qtd_6_to_10 += bucket.doc_count
                        break
                    case 'qtd_11_to_50':
                        data.qtd_11_to_50 += bucket.doc_count
                        break
                    case 'qtd_51_to_100':
                        data.qtd_51_to_100 += bucket.doc_count
                        break
                    case 'qtd_101_to_500':
                        data.qtd_101_to_500 += bucket.doc_count
                        break
                    case 'qtd_501_to_1000':
                        data.qtd_501_to_1000 += bucket.doc_count
                        break
                    case 'qtd_1001_to_2500':
                        data.qtd_1001_to_2500 += bucket.doc_count
                        break
                    case 'qtd_2501_to_5000':
                        data.qtd_2501_to_5000 += bucket.doc_count
                        break
                    case 'qtd_5000_to_INF':
                        data.qtd_5000_to_INF += bucket.doc_count
                        break
                }
            }

            this.scope.dataset = [
                [data.qtd_5000_to_INF, '5000 ou mais', false],
                [data.qtd_2501_to_5000, '2501 a 5000', false],
                [data.qtd_1001_to_2500, '1001 a 2500', false],
                [data.qtd_501_to_1000, '501 a 1000', false],
                [data.qtd_101_to_500, '101 a 500', false],
                [data.qtd_51_to_100, '51 a 100', false],
                [data.qtd_11_to_50, '11 a 50', false],
                [data.qtd_6_to_10, '6 a 10', false],
                [data.qtd_1_to_5, '1 a 5', false]
            ]

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.totalFuncionarios

        const markRow = (rowIdx: number, value: boolean) => {
            const row = this.scope.dataset[rowIdx]
            row && (row[2] = value)
        }

        markRow(0, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.gte}-5000`))
        markRow(1, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-2501:5000`))
        markRow(2, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-1001:2501`))
        markRow(3, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-501:1001`))
        markRow(4, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-101:501`))
        markRow(5, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-51:101`))
        markRow(6, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-11:51`))
        markRow(7, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-6:11`))
        markRow(8, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-1:6`))

        this.scope.update()
    }

    private async __onFaixaDeFuncionarios(rowIdx: number) {
        const totalFuncionarios = this.owner.filterPane.totalFuncionarios
        switch (rowIdx) {
            case 0:
                totalFuncionarios.toggleFrom(JunctionMode.OR, 5000, true)
                break
            case 1:
                totalFuncionarios.toggleRange(JunctionMode.OR, 2501, 5000)
                break
            case 2:
                totalFuncionarios.toggleRange(JunctionMode.OR, 1001, 2501)
                break
            case 3:
                totalFuncionarios.toggleRange(JunctionMode.OR, 501, 1001)
                break
            case 4:
                totalFuncionarios.toggleRange(JunctionMode.OR, 101, 501)
                break
            case 5:
                totalFuncionarios.toggleRange(JunctionMode.OR, 51, 101)
                break
            case 6:
                totalFuncionarios.toggleRange(JunctionMode.OR, 11, 51)
                break
            case 7:
                totalFuncionarios.toggleRange(JunctionMode.OR, 6, 11)
                break
            case 8:
                totalFuncionarios.toggleRange(JunctionMode.OR, 1, 6)
                break
        }
        totalFuncionarios.publish()
        this.__markSelected()

        this.__parent.fetchData(totalFuncionarios.size ? 'totalPorFaixaDeFuncionarios' : '')
    }
}
