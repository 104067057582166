import { ServiceClient } from 'src/utils'
import { CompanyRow } from './rd_scopes'

const serviceClient = ServiceClient.singleton()

type PjInfoResponse = {
    duration: number
    rows: CompanyRow[]
}

type SyncResponse = {
    duration: number
    updated: string[]
    inserted: string[]
    notfound: string[]
}

export class RdStationService {
    static #INSTANCE = new RdStationService()
    static readonly singleton = () => RdStationService.#INSTANCE

    async fetchCompanyInfos(cnpjs: string[]) {
        return serviceClient.authPost<PjInfoResponse>('/api/rd-station/pj/info', { cnpjs })
    }

    async synchronize(cnpjs: string[]) {
        return serviceClient.authPost<SyncResponse>('/api/rd-station/sync', { cnpjs })
    }
}

export default RdStationService.singleton()
