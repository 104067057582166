import * as L from 'leaflet'
import * as geojson from 'geojson'
import * as turf from '@turf/turf'
import * as utils from './utils'
import { type GeoLocation } from '../tcm_service'
import FilterManager from '../tcm_filter_manager'

import BaseSelectionModeBehaviour from './selection_mode_behaviour'

type TurfFeature = geojson.Feature<geojson.Polygon | geojson.MultiPolygon>

export default class RouteSelectionModeBehaviour extends BaseSelectionModeBehaviour {
    private locationList: GeoLocation[] = []
    private areaPolygon: TurfFeature | null = null

    override onBeforeScopeUpdate(): void {
        this.scope.slider.visible = true
    }

    override applyFilter(mgr: FilterManager): void {
        mgr.localizacaoRoutes.clear()

        if (this.areaPolygon && this.areaPolygon.geometry.coordinates.length > 0) {
            this.areaPolygon.geometry.coordinates.forEach((coordinate) => {
                const entry: GeoLocation[] = coordinate.map((v) => ({
                    lat: v[1] as number,
                    lon: v[0] as number
                }))

                mgr.localizacaoRoutes.add(entry)
            })
        }
    }

    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    override setZoom(_value: number): void {
        // TODO
    }

    override setMode(value: number): void {
        const scope = this.scope
        scope.mode = 'route'
        scope.slider.unit = 'm'
        scope.slider.min = 10
        scope.slider.max = 1000
        scope.slider.step = 10
        scope.slider.value = 50
        this.setZoom(value)
    }

    override clear() {
        super.clear()
        this.locationList.length = 0
        this.areaPolygon = null
        this.owner.filterManager.localizacaoRoutes.clear()
    }

    override hasFilter() {
        return this.locationList.length > 0
    }

    addLocation(location: GeoLocation) {
        this.locationList.push(location)
        this.scope.update()
    }

    preparePoints() {
        this.areaPolygon = null
        if (this.locationList.length <= 1) {
            return
        }

        const multiPolygons: L.LatLngLiteral[][] = []

        const offset = turf.lengthToDegrees(this.getDistanceInMeters() / 2, 'meters')

        let pA: GeoLocation | null = null
        let pB: GeoLocation | null = null
        let p1 = this.locationList[0]
        for (let i = 1; i < this.locationList.length; i++) {
            const p2 = this.locationList[i]
            const [p3, p4] = utils.computeLine(p1, p2, offset)
            const [p5, p6] = utils.computeLine(p1, p2, -offset)

            if (!pA) {
                pA = p3
            }

            if (!pB) {
                pB = p5
            }

            const points = turf.featureCollection([
                turf.point([pA.lon, pA.lat]),
                turf.point([p4.lon, p4.lat]),
                turf.point([pB.lon, pB.lat]),
                turf.point([p6.lon, p6.lat]),
                turf.point([pA.lon, pA.lat])
            ])

            const hull = turf.convex(points)
            if (!hull) {
                continue
            }

            hull.geometry.coordinates.forEach((coordinate) => {
                const mapPolygon: L.LatLngLiteral[] = []

                coordinate.forEach((v) => {
                    mapPolygon.push({
                        lat: v[1] as number,
                        lng: v[0] as number
                    })
                })

                multiPolygons.push(mapPolygon)
            })

            p1 = p2
            pA = p4
            pB = p6
        }

        this.areaPolygon = null
        const area = multiPolygons[0]
        if (area) {
            let areaPolygon = turf.polygon([area.map((v) => [v.lng, v.lat])]) as TurfFeature

            for (let i = 1; i < multiPolygons.length; i++) {
                const curAreaPolygon = turf.polygon([multiPolygons[i].map((v) => [v.lng, v.lat])])
                const unitedPolygon = turf.union(turf.featureCollection([areaPolygon, curAreaPolygon]))
                if (unitedPolygon) {
                    areaPolygon = unitedPolygon
                }
            }

            this.areaPolygon = areaPolygon
        }
    }

    doDraw(map: L.Map) {
        if (this.locationList.length === 1) {
            const v = this.locationList[0]
            const radiusInMeters = this.getDistanceInMeters() / 2
            const circleLayer = L.circle([v.lat, v.lon], { radius: radiusInMeters }).addTo(map)
            this.removeAllLayers = () => {
                circleLayer.removeFrom(map)
                this.removeAllLayers = utils.staticNoopOther
            }
            return
        }

        const multiPolygons: L.LatLngLiteral[][] = []
        if (this.areaPolygon) {
            this.areaPolygon.geometry.coordinates.forEach((coordinate) => {
                const entry: L.LatLngLiteral[] = []
                coordinate.forEach((point) => {
                    entry.push({
                        lat: point[1] as number,
                        lng: point[0] as number
                    })
                })
                multiPolygons.push(entry)
            })
        }

        if (multiPolygons.length > 0) {
            const areaLayer = L.polygon(multiPolygons).addTo(map)
            this.removeAllLayers = () => {
                areaLayer.removeFrom(map)
                this.removeAllLayers = utils.staticNoopOther
            }
        }
    }
}
