import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import Tooltip from '@mui/material/Tooltip'

import EnchenteRs2024View from './tcm_enchente_rs_2024'
import TcmStringFilterView from './tcm_filter_for_string'
import TcmStringKeywordFilterView from './tcm_filter_for_string_keyword'
import NumberFilterView from './tcm_filter_for_number'

import { FilterPaneScope } from '../../tcm_scopes'

const LOG = Logger.get('TCM-FILTER-VIEW')

const ViewContainer = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: 40
})

const HeaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingLeft: 5,
    //backgroundColor: 'blue',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box'
})

const HorizontalSpacer = styled('div')({
    alignSelf: 'stretch',
    flexGrow: 1,
    flexShrink: 1
})

const ToggleFilterButton = styled(ToggleButton)({
    border: 'none'
})

const VHeaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column'
})

const CaptionTypography = styled(Typography)({
    marginLeft: 5
})

const VTypography = styled(Typography)({
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    width: 30,
    marginTop: 5
})

const VFilterListIcon = styled(FilterListIcon)({
    transform: 'rotate(90deg)',
    width: 30
})

const FilterBody = styled('div')({
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    overflowY: 'scroll'
})

const ApplyBand = styled('div')({
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column'
})

const useStyles = makeStyles()({
    expanded: {
        minWidth: 175
    },
    shrinked: {
        minWidth: 36
    }
})

type FilterPaneViewProps = {
    className?: string
    scope: FilterPaneScope
}

class FilterPaneViewClass implements FCClassContext<FilterPaneViewProps> {
    // :: Fields

    scope!: FilterPaneScope
    classes!: ReturnType<typeof useStyles>['classes']

    // :: Emissors

    readonly emitToggleExpanded = () => this.scope.onToggleExpanded().catch(LOG.caught)
    readonly emitApply = () => this.scope.onApply().catch(LOG.caught)

    // :: Methods

    render({ className, scope }: FilterPaneViewProps) {
        this.scope = scope
        this.classes = useStyles().classes

        return (
            <ViewContainer className={clsx(scope.expanded ? this.classes.expanded : this.classes.shrinked, className)}>
                {scope.expanded ? this.renderOpenFilter() : this.renderCloseFilter()}
            </ViewContainer>
        )
    }

    renderOpenFilter() {
        const { scope } = this

        const filterList: { order: number; element: JSX.Element }[] = []

        if (scope.nomeEmpresa.visible) {
            filterList.push({
                order: scope.nomeEmpresa.order,
                element: <TcmStringFilterView key="nomeEmpresa" scope={scope.nomeEmpresa} />
            })
        }

        if (scope.nomeFantasia.visible) {
            filterList.push({
                order: scope.nomeFantasia.order,
                element: <TcmStringFilterView key="nomeFantasia" scope={scope.nomeFantasia} />
            })
        }

        if (scope.cnpj.visible) {
            filterList.push({
                order: scope.cnpj.order,
                element: <TcmStringKeywordFilterView key="cnpj" scope={scope.cnpj} />
            })
        }

        if (scope.naturezaJuridicaTexts.visible) {
            filterList.push({
                order: scope.naturezaJuridicaTexts.order,
                element: <TcmStringFilterView key="natureza-juridica-texts" scope={scope.naturezaJuridicaTexts} />
            })
        }

        if (scope.cnae.visible) {
            filterList.push({
                order: scope.cnae.order,
                element: <TcmStringKeywordFilterView key="cnae" scope={scope.cnae} />
            })
        }

        if (scope.cnaeTexts.visible) {
            filterList.push({
                order: scope.cnaeTexts.order,
                element: <TcmStringFilterView key="cnae-texts" scope={scope.cnaeTexts} />
            })
        }

        if (scope.scoreRiscos.visible) {
            filterList.push({
                order: scope.scoreRiscos.order,
                element: <TcmStringKeywordFilterView key="score-riscos" scope={scope.scoreRiscos} />
            })
        }

        if (scope.uf.visible) {
            filterList.push({
                order: scope.uf.order,
                element: <TcmStringKeywordFilterView key="uf-codigo" scope={scope.uf} />
            })
        }

        if (scope.ufTexts.visible) {
            filterList.push({
                order: scope.ufTexts.order,
                element: <TcmStringFilterView key="uf-texts" scope={scope.ufTexts} />
            })
        }

        if (scope.cidadeTexts.visible) {
            filterList.push({
                order: scope.cidadeTexts.order,
                element: <TcmStringFilterView key="cidade-texts" scope={scope.cidadeTexts} />
            })
        }

        if (scope.regiaoMetropolitanaTexts.visible) {
            filterList.push({
                order: scope.regiaoMetropolitanaTexts.order,
                element: <TcmStringFilterView key="regiao-metro-texts" scope={scope.regiaoMetropolitanaTexts} />
            })
        }

        if (scope.totalEmpresaGrupo.visible) {
            filterList.push({
                order: scope.totalEmpresaGrupo.order,
                element: <NumberFilterView key="qtde-empresa-grupo" scope={scope.totalEmpresaGrupo} />
            })
        }

        if (scope.totalEmpresaCapSocial.visible) {
            filterList.push({
                order: scope.totalEmpresaCapSocial.order,
                element: <NumberFilterView key="qtde-empresa-cap-social" scope={scope.totalEmpresaCapSocial} />
            })
        }

        if (scope.idadeEmpresa.visible) {
            filterList.push({
                order: scope.idadeEmpresa.order,
                element: <NumberFilterView key="idade-empresa" scope={scope.idadeEmpresa} />
            })
        }

        if (scope.totalFuncionarios.visible) {
            filterList.push({
                order: scope.totalEmpresaCapSocial.order,
                element: <NumberFilterView key="qtde-funcionarios" scope={scope.totalFuncionarios} />
            })
        }

        if (scope.totalFuncionarios_0_a_18.visible) {
            filterList.push({
                order: scope.totalFuncionarios_0_a_18.order,
                element: <NumberFilterView key="qtde-funcionarios-0-18" scope={scope.totalFuncionarios_0_a_18} />
            })
        }

        if (scope.totalFuncionarios_19_a_33.visible) {
            filterList.push({
                order: scope.totalFuncionarios_19_a_33.order,
                element: <NumberFilterView key="qtde-funcionarios-19-33" scope={scope.totalFuncionarios_19_a_33} />
            })
        }

        if (scope.totalFuncionarios_34_a_48.visible) {
            filterList.push({
                order: scope.totalFuncionarios_34_a_48.order,
                element: <NumberFilterView key="qtde-funcionarios-33-48" scope={scope.totalFuncionarios_34_a_48} />
            })
        }

        if (scope.totalFuncionarios_49_a_58.visible) {
            filterList.push({
                order: scope.totalFuncionarios_49_a_58.order,
                element: <NumberFilterView key="qtde-funcionarios-49-58" scope={scope.totalFuncionarios_49_a_58} />
            })
        }

        if (scope.totalFuncionarios_59_ou_mais.visible) {
            filterList.push({
                order: scope.totalFuncionarios_59_ou_mais.order,
                element: <NumberFilterView key="qtde-funcionarios-59-ou-mais" scope={scope.totalFuncionarios_59_ou_mais} />
            })
        }

        if (scope.totalFuncionariosFemininos.visible) {
            filterList.push({
                order: scope.totalFuncionariosFemininos.order,
                element: <NumberFilterView key="qtde-funcionarios-fem" scope={scope.totalFuncionariosFemininos} />
            })
        }

        if (scope.totalFuncionariosMasculinos.visible) {
            filterList.push({
                order: scope.totalFuncionariosMasculinos.order,
                element: <NumberFilterView key="qtde-funcionarios-masc" scope={scope.totalFuncionariosMasculinos} />
            })
        }

        if (scope.totalFuncionariosDoGrupo.visible) {
            filterList.push({
                order: scope.totalFuncionariosDoGrupo.order,
                element: <NumberFilterView key="qtde-funcionarios-grupo" scope={scope.totalFuncionariosDoGrupo} />
            })
        }

        if (scope.faixaFaturamentoAnual.visible) {
            filterList.push({
                order: scope.totalFuncionariosDoGrupo.order,
                element: <NumberFilterView key="faixa-fat-anual" scope={scope.faixaFaturamentoAnual} />
            })
        }

        if (scope.faixaFaturamentoConsolidadoGrupo.visible) {
            filterList.push({
                order: scope.totalFuncionariosDoGrupo.order,
                element: <NumberFilterView key="faixa-fat-cons-grp" scope={scope.faixaFaturamentoConsolidadoGrupo} />
            })
        }

        if (scope.crescimento2021.visible) {
            filterList.push({
                order: scope.crescimento2021.order,
                element: <NumberFilterView key="crescimento-media-2021" scope={scope.crescimento2021} />
            })
        }

        if (scope.crescimento2022.visible) {
            filterList.push({
                order: scope.mediaCrescimento2021_2022.order,
                element: <NumberFilterView key="crescimento-media-2022" scope={scope.crescimento2022} />
            })
        }

        if (scope.mediaCrescimento2021_2022.visible) {
            filterList.push({
                order: scope.mediaCrescimento2021_2022.order,
                element: <NumberFilterView key="crescimento-media-2021-2022" scope={scope.mediaCrescimento2021_2022} />
            })
        }

        filterList.sort((a, b) => a.order - b.order)

        return (
            <>
                <HeaderContainer>
                    <FilterListIcon />
                    <CaptionTypography>Filtros</CaptionTypography>
                    <HorizontalSpacer />
                    <ToggleFilterButton value="checked" size="small" onClick={this.emitToggleExpanded}>
                        <Tooltip title="Oculta filtros">
                            <KeyboardDoubleArrowRightIcon />
                        </Tooltip>
                    </ToggleFilterButton>
                </HeaderContainer>
                <FilterBody>
                    <EnchenteRs2024View scope={scope.enchenteRs2024} />
                    {filterList.map((e) => e.element)}
                </FilterBody>
                <ApplyBand>
                    <Button onClick={this.emitApply}>Aplicar</Button>
                </ApplyBand>
            </>
        )
    }

    renderCloseFilter() {
        return (
            <VHeaderContainer>
                <ToggleFilterButton value="checked" size="small" onClick={this.emitToggleExpanded}>
                    <Tooltip title="Mostra filtros">
                        <KeyboardDoubleArrowLeftIcon />
                    </Tooltip>
                </ToggleFilterButton>
                <VFilterListIcon />
                <VTypography>Filtros</VTypography>
            </VHeaderContainer>
        )
    }
}
export const FilterPaneView = classToFComponent(FilterPaneViewClass, React)
export default FilterPaneView

// :: Helpers
