import React from 'react'

import { AlertSeverity } from 'wdc-cube'

import Alert from '@mui/material/Alert'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import Fade from '@mui/material/Fade'

import { MainScope } from '../../Main.scopes'

export type SnackAlertProps = {
    className?: string
    scope: MainScope
}

export default function SnackAlert({ className, scope }: SnackAlertProps) {
    const snack = React.useMemo(() => {
        const _state = {
            open: false,
            severity: 'info',
            message: '',
            duration: 6000,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' } as SnackbarOrigin,
            transactionClass: Fade
        }

        return {
            get open() {
                return _state.open
            },

            get severity() {
                return _state.severity
            },

            get message() {
                return _state.message
            },

            get duration() {
                return _state.duration
            },

            get anchorOrigin() {
                return _state.anchorOrigin
            },

            get transactionComponent() {
                return _state.transactionClass
            },

            get transactionName() {
                return _state.transactionClass.name
            },

            notify(severity: AlertSeverity, message: string, duration: number) {
                _state.open = true
                _state.message = message
                _state.duration = duration
                _state.severity = severity
                scope.update()
            },

            handleClose() {
                _state.open = false
                _state.message = ''
                _state.duration = 6000
                _state.severity = 'info'
                scope.update()
            }
        }
    }, [scope])

    scope.notify = snack.notify

    return (
        <Snackbar
            className={className}
            open={snack.open}
            onClose={snack.handleClose}
            TransitionComponent={snack.transactionComponent}
            message={snack.message}
            autoHideDuration={snack.duration}
            anchorOrigin={snack.anchorOrigin}
        >
            <Alert onClose={snack.handleClose} security={snack.severity}>
                {snack.message}
            </Alert>
        </Snackbar>
    )
}
