import { type GraphSeriesOption } from 'echarts'
import { type SearchOption, EntityTypeID } from 'src/components/searchbox'

export { type SearchOption, EntityTypeID }

type Unpacked<T> = T extends (infer U)[] ? U : T
type GraphNodeItemOption = NonNullable<Unpacked<GraphSeriesOption['edges']>>
type GraphEdgeItemOption = NonNullable<Unpacked<GraphSeriesOption['links']>>

export type GraphNode = {
    type: number
    id: string
    hid: string
    name: string
    symbol?: string
    symbolSize: number
    value: string
    category: number
    x?: number
    y?: number
    fixed?: boolean
    emphasis?: GraphNodeItemOption['emphasis']
    // config
    label?: Partial<{ show: boolean }>
    //
    dataIndex: number
    extra: {
        selected: boolean
        active: boolean
    }
}

export type GraphLink = Omit<GraphEdgeItemOption, 'value'> & {
    value: string
}

export type GraphType = {
    nodes: GraphNode[]
    links: GraphLink[]
}

export type Category = {
    index: number
    name: string
    color: string
}

export const CATEGORY_ARRAY: Category[] = []
export const CATEGORY_COLOR_ARRAY: string[] = []

const pushCategory = (name: string, color: string): Category => {
    const index = CATEGORY_ARRAY.length
    const category = { index, name, color }
    CATEGORY_COLOR_ARRAY[index] = color
    CATEGORY_ARRAY.push(category)
    return category
}

export const CATEGORY_RECORD = {
    empresas: pushCategory('Empresas', '#2E8336'),
    empresasInativas: pushCategory('Empresas Inativas', '#8BD69E'),

    // Tipos de empresa
    clinica: pushCategory('Clínicas', '#c23531'),
    consultorio: pushCategory('Consultório', '#759aa0'),
    hospital: pushCategory('Hospitais', '#e69d87'),
    unidadeMista: pushCategory('Unidade Mista', '#8dc1a9'),
    unidade: pushCategory('Unidade', '#7289ab'),
    centro: pushCategory('Centro', '#91ca8c'),
    planos: pushCategory('Planos', '#f49f42'),

    // Tipos de sócios
    medico: pushCategory('Médicos', '#73b9bc'),
    socioPJ: pushCategory('Sócios PJ', '#070782'),
    socioPF: pushCategory('Sócios PF', '#6495ED'),

    pessoa: pushCategory('Pessoa', '#D48C70')
}
