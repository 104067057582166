import { Logger, Presenter } from 'wdc-cube'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFilialMatrizScope } from './tcm_scopes'
import { TheConsumerMarketService, MatrizOrFilial, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresaMatrizFiliais')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresaMatrizFiliais extends Presenter<
    ChartFilialMatrizScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.filialMatriz)
        this.__parent = owner
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.onItemClicked = this.__onFilialMatrizClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.serMatrizOrFilial.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_matrizes: true,
                total_de_empresas_filiais: true,
                filter: filter
            })

            this.scope.numMatrizes = resp.total_de_empresas_matrizes?.value ?? 0
            this.scope.numFiliais = resp.total_de_empresas_filiais?.value ?? 0

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.serMatrizOrFilial
        this.scope.matrizesSelected = prop.has(MatrizOrFilial.matriz)
        this.scope.filiaisSelected = prop.has(MatrizOrFilial.filial)
        this.scope.update()
    }

    private async __onFilialMatrizClicked(ehMatriz: boolean) {
        const prp = this.__filterManager.serMatrizOrFilial
        prp.add(ehMatriz ? MatrizOrFilial.matriz : MatrizOrFilial.filial)
        this.__markSelected()
        this.__parent.fetchData(prp.size ? 'totalDeMatrizesEFiliais' : '')
    }
}
