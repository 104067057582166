//eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import * as echarts from 'echarts'
import { FCClassContext } from 'src/utils/views'
import TcmProgress from './tcm_progress'

export type EChartViewProps = {
    className?: string
}

export abstract class EChartViewClass<P extends EChartViewProps> implements FCClassContext<P> {
    // :: Fields
    protected chart?: echarts.ECharts
    protected chartData: echarts.EChartsOption = {}
    protected thisEl?: HTMLDivElement | null
    protected readonly contentEl: HTMLDivElement
    protected loaded = false

    private resizeObserver: ResizeObserver
    private requestAnimationFrameId = 0

    constructor() {
        this.resizeObserver = new ResizeObserver(this.emitResize)
        this.contentEl = document.createElement('div')
        this.contentEl.style.width = '100%'
    }

    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSyncState(_props: P) {
        if (this.chart && this.loaded) {
            this.chart.setOption(this.chartData)
        }
        this.emitResize()
    }

    onAttach() {
        if (this.thisEl) {
            this.resizeObserver.observe(this.thisEl)
            this.thisEl.appendChild(this.contentEl)
        }

        this.initChart()
        this.emitResize()
    }

    onDetach() {
        if (this.thisEl) {
            this.resizeObserver.unobserve(this.thisEl)
        }

        if (this.requestAnimationFrameId) {
            window.cancelAnimationFrame(this.requestAnimationFrameId)
        }

        this.disposeChart()
    }

    private readonly emitResize = () => {
        if (this.requestAnimationFrameId) {
            window.cancelAnimationFrame(this.requestAnimationFrameId)
        }
        this.requestAnimationFrameId = window.requestAnimationFrame(this.emitUpdateSize)
    }

    private readonly emitThisElRef = (elm: HTMLDivElement | null) => {
        if (this.thisEl) {
            this.resizeObserver.unobserve(this.thisEl)
        }

        this.thisEl = elm

        if (this.thisEl) {
            this.resizeObserver.observe(this.thisEl)
            this.thisEl.appendChild(this.contentEl)
        }

        if (!this.chart) {
            this.initChart()
            this.emitResize()
        }

        this.onThisElChanged()
    }

    private initChart() {
        this.disposeChart()

        if (this.thisEl) {
            this.chart = echarts.init(this.contentEl, null, {
                renderer: 'svg'
            })
            this.onBindChartEvents(this.chart)

            if (this.loaded) {
                this.chart.setOption(this.chartData)
            }
        }
    }

    private disposeChart() {
        if (this.chart) {
            this.chart.dispose()
            this.chart = undefined
        }
    }

    protected onThisElChanged() {
        // NOOP
    }

    private emitUpdateSize = () => {
        if (this.chart && this.contentEl && this.thisEl) {
            this.contentEl.style.display = 'none'
            const opts: echarts.ResizeOpts = {
                width: Math.max(this.thisEl.clientWidth - 5, 0),
                height: this.thisEl.clientHeight
            }
            this.onComputeSize(opts)
            this.chart.resize(opts)
            this.contentEl.style.display = 'block'
        }
    }

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    protected onComputeSize(_opts: echarts.ResizeOpts) {
        // NOOP
    }

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    protected onBindChartEvents(_chart: echarts.ECharts) {
        // NOOP
    }

    render(props: P) {
        const loading = !this.loaded
        return (
            <div className={props.className} ref={this.emitThisElRef}>
                {loading && <TcmProgress />}
            </div>
        )
    }
}
