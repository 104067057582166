import type { LoDashStatic } from 'lodash'

let impl: LoDashStatic = {} as unknown as LoDashStatic

import('lodash').then((lodash) => {
    impl = lodash.default
})

export const Lodash = {
    singleton() {
        return impl
    }
}

export default Lodash
