import type { ECharts } from 'echarts'
import * as echarts from 'echarts'

import React, { PureComponent } from 'react'
import { bind, clear } from 'size-sensor'
import { pick } from './helper/pick'
import { isFunction } from './helper/is-function'
import { isString } from './helper/is-string'
import { isEqual } from './helper/is-equal'
import { EChartsReactProps, EChartsInstance, EChartsInitOpts } from './types'
import clsx from 'clsx'

/**
 * core component for echarts binding
 */
export default class EChartsReactCore extends PureComponent<EChartsReactProps> {
    /**
     * echarts render container
     */
    public ele: HTMLElement | null

    /**
     * if this is the first time we are resizing
     */
    private isInitialResize: boolean

    constructor(props: EChartsReactProps) {
        super(props)
        this.ele = null
        this.isInitialResize = true
    }

    componentDidMount() {
        if (this.ele) {
            this.renderNewEcharts(this.ele)
        }
    }

    // update
    componentDidUpdate(prevProps: EChartsReactProps) {
        /**
         * if shouldSetOption return false, then return, not update echarts options
         * default is true
         */
        const { shouldSetOption } = this.props
        if (isFunction(shouldSetOption) && !shouldSetOption(prevProps, this.props)) {
            return
        }

        // When the following attributes are modified, they need to be disposed before creating a new one
        // 1. When switching theme
        // 2. When modifying opts
        // 3. When modifying onEvents, this can cancel all previously bound events issue #151
        if (
            !isEqual(prevProps.theme, this.props.theme) ||
            !isEqual(prevProps.opts, this.props.opts) ||
            !isEqual(prevProps.onEvents, this.props.onEvents)
        ) {
            this.dispose()

            if (this.ele) {
                this.renderNewEcharts(this.ele) // reconstruction
            }
            return
        }

        // when these props are not isEqual, update echarts
        const pickKeys = ['option', 'notMerge', 'lazyUpdate', 'showLoading', 'loadingOption']
        if (!isEqual(pick(this.props, pickKeys), pick(prevProps, pickKeys))) {
            this.updateEChartsOption()
        }

        /**
         * when style or class name updated, change size.
         */
        if (!isEqual(prevProps.style, this.props.style) || !isEqual(prevProps.className, this.props.className)) {
            this.resize()
        }
    }

    componentWillUnmount() {
        this.dispose()
    }

    /*
     * initialise an echarts instance
     */
    public async initEchartsInstance(): Promise<ECharts> {
        return new Promise((resolve, reject) => {
            if (!this.ele) {
                reject(new Error('Missing DOM element'))
                return
            }

            // create temporary echart instance
            echarts.init(this.ele, this.props.theme, this.props.opts)

            const echartsInstance = this.getEchartsInstance()
            if (!echartsInstance) {
                reject(new Error('Missing eChart instance'))
                return
            }

            echartsInstance.on('finished', () => {
                if (!this.ele) {
                    reject(new Error('Missing DOM element'))
                    return
                }

                // get final width and height
                const width = this.ele ? this.ele.clientWidth : undefined
                const height = this.ele ? this.ele.clientHeight : undefined

                // dispose temporary echart instance
                if (this.ele) {
                    echarts.dispose(this.ele)
                }

                // recreate echart instance
                // we use final width and height only if not originally provided as opts
                const opts: EChartsInitOpts = {
                    width,
                    height,
                    ...this.props.opts
                }
                resolve(echarts.init(this.ele, this.props.theme, opts))
            })
        })
    }

    /**
     * return the existing echart object
     */
    public getEchartsInstance(): ECharts | undefined {
        return this.ele ? echarts.getInstanceByDom(this.ele) : undefined
    }

    /**
     * dispose echarts and clear size-sensor
     */
    private dispose() {
        if (this.ele) {
            try {
                clear(this.ele)
            } catch (e) {
                console.warn(e)
            }
            // dispose echarts instance
            echarts.dispose(this.ele)
        }
    }

    /**
     * render a new echarts instance
     */
    private async renderNewEcharts(ele: HTMLElement) {
        const { onEvents, onChartReady, autoResize = true } = this.props

        // 1. init echarts instance
        await this.initEchartsInstance()

        // 2. update echarts instance
        const echartsInstance = this.updateEChartsOption()

        // 3. bind events
        this.bindEvents(echartsInstance, onEvents || {})

        // 4. on chart ready
        if (isFunction(onChartReady)) onChartReady(echartsInstance)

        // 5. on resize
        if (ele && autoResize) {
            bind(ele, () => {
                this.resize()
            })
        }
    }

    // bind the events
    private bindEvents(instance: EChartsInstance, events: EChartsReactProps['onEvents']) {
        // eslint-disable-next-line @typescript-eslint/ban-types
        function _bindEvent(eventName: string, func: Function) {
            // ignore the event config which not satisfy
            if (isString(eventName) && isFunction(func)) {
                // binding event
                instance.on(eventName, (param: unknown) => {
                    func(param, instance)
                })
            }
        }

        // loop and bind
        for (const eventName in events) {
            if (Object.prototype.hasOwnProperty.call(events, eventName)) {
                _bindEvent(eventName, events[eventName])
            }
        }
    }

    /**
     * render the echarts
     */
    private updateEChartsOption(): EChartsInstance {
        const { option, notMerge = false, lazyUpdate = false, showLoading, loadingOption = null } = this.props
        // 1. get or initial the echarts object
        const echartInstance = this.getEchartsInstance()
        if (!echartInstance) {
            throw new Error('Missing eChart instance')
        }

        // 2. set the echarts option
        echartInstance.setOption(option, notMerge, lazyUpdate)
        // 3. set loading mask
        if (showLoading) echartInstance.showLoading(loadingOption)
        else echartInstance.hideLoading()

        return echartInstance
    }

    /**
     * resize wrapper
     */
    private resize() {
        // 1. get the echarts object
        const echartsInstance = this.getEchartsInstance()
        if (!echartsInstance) {
            return
        }

        // 2. call echarts instance resize if not the initial resize
        // resize should not happen on first render as it will cancel initial echarts animations
        if (!this.isInitialResize) {
            try {
                echartsInstance.resize({
                    width: 'auto',
                    height: 'auto'
                })
            } catch (e) {
                console.warn(e)
            }
        }

        // 3. update variable for future calls
        this.isInitialResize = false
    }

    #divRefCollector = (e: HTMLDivElement | null) => {
        this.ele = e
    }

    render(): JSX.Element {
        const { style, className = '' } = this.props
        return <div ref={this.#divRefCollector} style={style} className={clsx('echarts-for-react', className)} />
    }
}
