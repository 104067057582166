import React from 'react'
import * as echarts from 'echarts'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { makeStyles } from 'tss-react/mui'
import { classToFComponent } from 'src/utils/views'
import { EChartViewClass } from './helpers/base-echart'

import TcmCard from './helpers/tcm-card'
import { formatNumberToCompactShortForm } from './helpers/tcm_view_utils'
import { ChartEmpresasAbertasFechadasScope, type DataZoom } from '../tcm_scopes'
import { JSX } from 'react/jsx-runtime'

const LOG = Logger.get('TCM-EVOLUCAO-ABERTA-FECHADAS')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0
    },
    chart: {}
})

type ChartAbertasFechadasProps = {
    className?: string
    style?: React.CSSProperties
    scope: ChartEmpresasAbertasFechadasScope
}

class ChartAbertasFechadasViewClass extends EChartViewClass<ChartAbertasFechadasProps> {
    // :: Fields
    scope!: ChartEmpresasAbertasFechadasScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: ChartAbertasFechadasProps) {
        this.scope = props.scope
        this.classes = useStyles().classes
        this.chartData = buildChartData(this.scope)
        this.loaded = this.scope.loaded
        super.onSyncState(props)
    }

    override render({ className, style, scope }: ChartAbertasFechadasProps): JSX.Element {
        return (
            <TcmCard caption={scope.caption} className={clsx(className)} style={style}>
                {super.render({ className: this.classes.view, scope })}
            </TcmCard>
        )
    }

    override onBindChartEvents(chart: echarts.ECharts): void {
        //highlight
        chart.off('dataZoom', this.emitOnDataZoom)
        chart.on('dataZoom', this.emitOnDataZoom)

        chart.off('restore', this.emitRestore)
        chart.on('restore', this.emitRestore)
    }

    emitOnDataZoom = (event: unknown) => {
        const evt = event as {
            batch: DataZoom[]
        }

        if (evt.batch && evt.batch.length > 0) {
            const src = evt.batch[0]
            this.scope
                .onDataZoom({
                    startValue: src.startValue!,
                    endValue: src.endValue!
                })
                .catch(LOG.caught)
        }
    }

    emitRestore = () => {
        this.scope.onRestore().catch(LOG.caught)
    }
}

export default classToFComponent(ChartAbertasFechadasViewClass, React)

type CallbackDataParams = Parameters<echarts.LabelFormatterCallback>[0]

const tooltipTemplate = (params: CallbackDataParams[]) => {
    const name = params[0].name
    const qtdAbertas = params[0].value as number
    const qtdFechadas = params[1].value as number
    const saldo = params[2].value as number
    return `
  <div style="margin: 0px 0 0;line-height:1;"><div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${name}</div>
  <div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">
      <div style="margin: 0px 0 0;line-height:1;">
          ${params[0].marker}
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[0].seriesName}</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${formatNumberToCompactShortForm(
              qtdAbertas
          )}</span>
          <div style="clear:both"></div></div><div style="clear:both"></div>
      </div>
      <div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">
          ${params[1].marker}
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[1].seriesName}</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${formatNumberToCompactShortForm(
              qtdFechadas
          )}</span>
          <div style="clear:both"></div></div><div style="clear:both"></div></div><div style="clear:both"></div>
      </div>
      <div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">
          ${params[2].marker}
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Saldo de empresas por mês</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${formatNumberToCompactShortForm(
              saldo
          )}</span>
          <div style="clear:both"></div></div><div style="clear:both"></div></div><div style="clear:both"></div>
      </div>
      <div style="clear:both"></div>
  </div>`
}

const computePoints = (x1: number, y1: number, x2: number, y2: number, steps: number) => {
    const a = y2 - y1
    const b = x2 - x1

    const yStep = a / steps
    const xStep = b / steps

    const result: [number, number][] = []
    for (let i = 0; i < steps; i++) {
        const xCur = Math.round(x1 + xStep * i)
        const yCur = Math.round(y1 + yStep * i)
        result.push([xCur, yCur])
    }
    return result
}

function buildChartData(scope: ChartEmpresasAbertasFechadasScope): echarts.EChartsOption {
    const xAxisLabelData = scope.dataset.map((r) => r[0])
    const abertaValueData = scope.dataset.map((r) => r[1])
    const fechadaValueData = scope.dataset.map((r) => r[2])
    const diferencaValueData = scope.dataset.map((r) => r[1] - r[2])

    // prettier-ignore
    const trendValueData = (function () {
        const valueData = diferencaValueData
        const x1 = 0
        const x2 = Math.max(valueData.length - 2, x1)
        const y1 = (valueData[x1] - valueData[x1])/2 + Math.min(valueData[x1], valueData[x1])
        const y2 = (valueData[x2] - valueData[x2])/2 + Math.min(valueData[x2], valueData[x2])
        return computePoints(x1, y1, x2, y2, valueData.length).map(v => v[1])
    })()

    return {
        tooltip: {
            trigger: 'axis',
            confine: false,
            axisPointer: {
                type: 'shadow'
            },
            formatter: (params) => {
                return tooltipTemplate(params as CallbackDataParams[])
            }
        },
        toolbox: {
            left: 'center',
            itemSize: 25,
            top: 55,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {}
            }
        },
        color: ['#C6EBC5', '#C4E4FF', '#FBF3D5', 'red'],
        legend: {
            data: [
                {
                    name: 'Empresas abertas por mês'
                },
                {
                    name: 'Empresas fechadas por mês'
                }
            ]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisLabelData
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: formatNumberToCompactShortForm
            }
        },
        series: [
            {
                name: 'Empresas abertas por mês',
                type: 'line',
                data: abertaValueData
            },
            {
                name: 'Empresas fechadas por mês',
                type: 'line',
                data: fechadaValueData
            },
            {
                type: 'line',
                data: diferencaValueData
            },
            {
                type: 'line',
                symbol: 'none',
                data: trendValueData,
                lineStyle: {
                    type: 'dashed'
                }
            }
        ]
    }
}
