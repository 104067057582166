import lodash from 'lodash'
import { Logger } from 'wdc-cube'
import {
    JunctionMode,
    StringKeywordFilterScope,
    StringKeywordParcelFilterScope,
    StringKeywordOperator,
    type KeywordOption
} from '../tcm_scopes'
import { TcmPresenterForFilterPane } from './tcm_presenter_filter_pane'
import { ValueProperty, StringKeywordFilter } from '../tcm_filter_manager'
import { FilterPresenter } from './tcm_filter_types'

const LOG = Logger.get('TCM-KeywordFilter')

let UID_GEN = 0

export class TcmPresenterForStringKeywordFilter extends FilterPresenter<
    StringKeywordFilterScope,
    TcmPresenterForFilterPane
> {
    // Constructor

    constructor(
        owner: TcmPresenterForFilterPane,
        scope: StringKeywordFilterScope,
        valueProperty: ValueProperty<StringKeywordFilter>,
        uppercase?: boolean,
        onSearch?: (mode: JunctionMode, text: string) => Promise<KeywordOption[]>
    ) {
        super(owner, scope)
        this.uppercase = uppercase ?? false
        this.valueProperty = valueProperty
        this.onSearch = onSearch
        this.fetch = lodash.debounce(() => {
            this.owner.owner.fetchData('').catch(LOG.caught)
        }, 150)
        this.onSearchDebounce = lodash.debounce(this.doSearch.bind(this), 150)
    }

    // :: Fields

    private readonly uppercase: boolean
    private valueProperty: ValueProperty<StringKeywordFilter>
    private fetch: lodash.DebouncedFuncLeading<() => void>
    private onSearchDebounce: lodash.DebouncedFuncLeading<
        (parcelScope: StringKeywordParcelFilterScope, searchText: string) => void
    >
    private onSearch?: (mode: JunctionMode, text: string) => Promise<KeywordOption[]>

    // :: Getters and Setters

    get owner() {
        return super.owner as TcmPresenterForFilterPane
    }

    protected override async bindEvents() {
        this.scope.onAddFilter = this.handleAddFilter.bind(this)
        LOG.debug('initialized')
    }

    override release(): void {
        this.fetch.cancel()
    }

    override clear() {
        this.scope.conjunction.length = 0
        this.scope.disjunction.length = 0
        this.valueProperty.clear()
    }

    populateUsingValueProporty() {
        this.scope.conjunction.length = 0
        this.scope.disjunction.length = 0

        for (const entry of this.valueProperty.values()) {
            const entryScope = entry.mode === JunctionMode.AND ? this.addConjuntion() : this.addDisjuntion()
            entryScope.operator = entry.operator
            entryScope.value = entry.value ?? ''
        }
    }

    override publish() {
        this.valueProperty.clear()

        const emptyPredicate = (scope: StringKeywordParcelFilterScope) => lodash.trim(scope.value)===''
        this.scope.conjunction.removeByCriteria(emptyPredicate)
        this.scope.disjunction.removeByCriteria(emptyPredicate)

        for (const parcelScope of [...this.scope.conjunction, ...this.scope.disjunction]) {
            const value = parcelScope.value.trim()
            if (!value) {
                continue
            }
            this.valueProperty.add({
                mode: parcelScope.junctionMode,
                operator: parcelScope.operator,
                value
            })
        }
    }

    addConjuntion() {
        const andScope = new StringKeywordParcelFilterScope()
        andScope.uid = `and-${UID_GEN++}`
        andScope.junctionMode = JunctionMode.AND
        andScope.operator = StringKeywordOperator.is_in
        andScope.onOperatorChange = this.handleOperatorChange.bind(this, andScope)
        andScope.onValueChange = this.handleValueChange.bind(this, andScope)
        andScope.onClear = this.handleClear.bind(this, andScope)
        andScope.onEnterKeyPressed = this.handleEnterKeyPressed.bind(this)
        andScope.onOptionSelected = this.handleOptionSelected.bind(this, andScope)
        andScope.update = this.update
        this.scope.conjunction.push(andScope)
        return andScope
    }

    addDisjuntion() {
        const orScope = new StringKeywordParcelFilterScope()
        orScope.uid = `or-${UID_GEN++}`
        orScope.junctionMode = JunctionMode.OR
        orScope.operator = StringKeywordOperator.is_in
        orScope.onOperatorChange = this.handleOperatorChange.bind(this, orScope)
        orScope.onValueChange = this.handleValueChange.bind(this, orScope)
        orScope.onClear = this.handleClear.bind(this, orScope)
        orScope.onEnterKeyPressed = this.handleEnterKeyPressed.bind(this)
        orScope.onOptionSelected = this.handleOptionSelected.bind(this, orScope)
        orScope.update = this.update
        this.scope.disjunction.push(orScope)
        return orScope
    }

    getsIn() {
        for (const conditionScope of this.scope.conjunction) {
            if (conditionScope.operator === StringKeywordOperator.is_in) {
                return conditionScope
            }
        }

        for (const conditionScope of this.scope.disjunction) {
            if (conditionScope.operator === StringKeywordOperator.is_in) {
                return conditionScope
            }
        }

        return null
    }

    getOrCreateIsIn() {
        let conditioScope = this.getsIn()
        if (conditioScope) {
            return conditioScope
        }

        conditioScope = this.addConjuntion()
        conditioScope.operator = StringKeywordOperator.is_in
        return conditioScope
    }

    private async handleAddFilter(mode: JunctionMode) {
        if (mode === JunctionMode.AND) {
            this.addConjuntion()
        } else {
            this.addDisjuntion()
        }
    }

    private async handleClear(parcelScope: StringKeywordParcelFilterScope) {
        let found = true

        let idx = this.scope.conjunction.findIndex((item) => item.uid === parcelScope.uid)
        if (idx !== -1) {
            this.scope.conjunction.removeByIndex(idx)
            found = true
        }

        idx = this.scope.disjunction.findIndex((item) => item.uid === parcelScope.uid)
        if (idx !== -1) {
            this.scope.disjunction.removeByIndex(idx)
            found = true
        }

        if (found && parcelScope.value) {
            this.fetch.cancel()
            this.fetch()
        }
    }

    private async handleOperatorChange(parcelScope: StringKeywordParcelFilterScope, value: StringKeywordOperator) {
        parcelScope.operator = value
        parcelScope.valueVisible = !(
            value === StringKeywordOperator.is_blank || value === StringKeywordOperator.is_not_blank
        )
    }

    private async handleValueChange(parcelScope: StringKeywordParcelFilterScope, value: string) {
        if (this.uppercase) {
            parcelScope.value = value.toUpperCase()
        } else {
            parcelScope.value = value
        }

        if (this.onSearch) {
            this.onSearchDebounce.cancel()
            this.onSearchDebounce(parcelScope, parcelScope.value.trim())
        }
    }

    private async handleOptionSelected(parcelScope: StringKeywordParcelFilterScope, value: KeywordOption) {
        parcelScope.value = value.key
        parcelScope.options = []
    }

    private async handleEnterKeyPressed() {
        this.fetch.cancel()
        this.fetch()
    }

    private doSearch(parcelScope: StringKeywordParcelFilterScope, searchText: string) {
        if (!this.onSearch) {
            parcelScope.options.length > 0 && (parcelScope.options = [])
            return
        }

        if (searchText) {
            this.onSearch(parcelScope.junctionMode, searchText)
                .then((options) => {
                    parcelScope.options = options
                })
                .catch((e) => {
                    parcelScope.options = []
                    LOG.error(e.message)
                })
            return
        }

        parcelScope.options.length > 0 && (parcelScope.options = [])
    }
}
