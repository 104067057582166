import React from 'react'
import { styled } from '@mui/material/styles'

import { Logger } from 'wdc-cube'
import { ViewSlot } from 'wdc-cube-react'

import { makeStyles } from 'tss-react/mui'

import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'

import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LogoDarkBackground from 'src/components/icons/LogoDarkBackground'
import MenuIcon from '@mui/icons-material/Menu'

import { useContainer } from 'src/utils/views/useContainer'

import { MainScope } from '../Main.scopes'
import { AlertView } from './alert.view'
import Link from '@mui/material/Link'

import { FCClassContext, classToFComponent } from 'src/utils/views'
import SignoutMenuButton from './components/SignoutMenuButton'
import DrawerContent from './components/DrawerContent'
import SnackAlert from './components/SnackAlert'

const LOG = Logger.get('MainView')

const drawerWidth = 240

export const useStyles = makeStyles()((theme) => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        flexGrow: 1,
        backgroundColor: 'rgb(247, 249, 252)'
    },
    appBarMenuButton: {
        marginRight: 10
    },
    logoButton: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center'
    },
    logoIcon: {
        width: '5em',
        height: '2em'
    },
    grabSpace: {
        flexGrow: 1,
        display: 'flex',
        flexBasis: 0,
        alignSelf: 'stretch'
    },
    toolbar: {},
    main: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        marginTop: '64px',
        overflow: 'auto',
        display: 'flex'
    },
    body: {
        flexGrow: 1
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 900
        }
    }
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

/////////////////////

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9)
            }
        })
    }
}))

const DrawerToolbar = styled(Toolbar)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    px: [1]
})

const AppToolbar = styled(Toolbar)({
    display: 'flex',
    gap: 10
})

///////////////////

export type MainViewProps = {
    className?: string
    scope: MainScope
}

class MainViewClass implements FCClassContext<MainViewProps> {
    scope!: MainScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState({ scope }: MainViewProps) {
        this.scope = scope
        this.classes = useStyles().classes
    }

    render() {
        const { scope, classes } = this

        LOG.debug('scope.drawerOpen=' + scope.drawerOpen)

        const container = useContainer()

        let content: React.ReactNode = <></>

        if (scope.authenticated) {
            content = (
                <>
                    <CssBaseline />
                    <AppBar position="absolute" open={scope.drawerOpen}>
                        <AppToolbar>
                            <IconButton
                                edge="start"
                                className={classes.appBarMenuButton}
                                color="inherit"
                                aria-label="menu"
                                onClick={this.emitDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.grabSpace}>
                                <ViewSlot className={classes.toolbar} scope={scope.toolbar} optional={true} />
                            </div>
                            <Link className={classes.logoButton} component="button" variant="body2">
                                <LogoDarkBackground className={classes.logoIcon} textColor="white" />
                            </Link>
                            <SignoutMenuButton mainScope={scope} />
                        </AppToolbar>
                    </AppBar>
                    <Drawer variant="permanent" container={container} open={scope.drawerOpen}>
                        <DrawerToolbar>
                            <IconButton onClick={this.emitDrawerToggle}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </DrawerToolbar>
                        <Divider />
                        <DrawerContent mainScope={scope} />
                    </Drawer>
                    <ViewSlot className={classes.main} scope={scope.body} optional={false} />
                </>
            )
        } else {
            content = <ViewSlot className={classes.view} scope={scope.body} optional={false} />
        }

        return (
            
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    {content}

                    <Dialog
                        className={classes.dialog}
                        open={!!scope.dialog}
                        onClose={this.emitCloseDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <ViewSlot scope={scope.dialog} />
                    </Dialog>

                    <Dialog open={!!scope.alert} onClose={this.emitCloseAlert} aria-labelledby="form-dialog-title">
                        <ViewSlot scope={scope.alert} view={AlertView} />
                    </Dialog>

                    <SnackAlert scope={scope} />
                </Box>
        )
    }

    // Emissors

    emitDrawerToggle = () => {
        this.scope.onDrawerToggle().catch(LOG.caught)
    }

    emitCloseDialog = () => {
        this.scope.dialog?.onClose().catch(LOG.caught)
    }

    emitCloseAlert = () => {
        this.scope.alert?.onClose().catch(LOG.caught)
    }
}

export const MainView = classToFComponent(MainViewClass)
