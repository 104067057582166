import { ServiceClient, lodash } from 'src/utils'
import { SYMBOLS } from 'src/components/symbols'
import { Stats } from 'fast-stats'
import {
    formatCPF,
    formatCNPJ,
    formatCPFOrCNPJ,
    formatTelefone,
    formatCurrency,
    formatNumber
} from 'src/utils/formatter-utils'
import * as DateFnsNs from 'date-fns'
import * as lang from 'src/utils/lang'

import { type GraphType, type GraphNode, type GraphLink, CATEGORY_RECORD, EntityTypeID } from './tc_types'

const serviceClient = ServiceClient.singleton()

const BREAK_TAG = '<br />'
const LN_SEPARATOR = `${BREAK_TAG}&nbsp;&nbsp;&nbsp;&nbsp;`

// Private Types

type EnderecoFields = {
    tp_logradouro?: string
    logradouro?: string
    numero?: string
    complemento?: string
    bairro?: string
    municipio?: string
    uf?: string
    cep?: string
}

type PersonFields = {
    nome: string
    dt_nascimento?: string
    sexo?: string
    cpf?: string
    telefone1?: string
    telefone2?: string
    celular1?: string
    celular2?: string
    celular3?: string
    celular4?: string
    email1?: string
    email2?: string
    email3?: string
    email4?: string
    cbo?: string
    cbo_descricao?: string
    crm?: string
    crm_dt_inscricao?: string
    especialidades?: string
}

type CompanyRecord = {
    cnpj: string
    cnpj_matriz: string
    cnes?: string
    cd_operadora?: string
    razao_social: string
    nome_fantasia?: string
    cnae: number
    descricao_cnae: string
    codigo_natureza_juridica: number
    descricao_da_natureza_juridica: string
    situacao_cadastral: number
    total_funcionarios: number
    faturamento: number
    email: string
    telefone: string
    optante_simples: string
    optante_mei: string
    num_socios: number
    fl_selected: number
    cnpj_cluster?: string
    razao_social_cluster?: string
    faturamento_estimado_cluster?: number
} & EnderecoFields

type PartnerRecord = {
    partner_id: string
    nome: string
    cpfcnpj: string
    socio_cbos: string
    num_cnpjs: number
    faturamento: number
    fl_selected: number
} & EnderecoFields &
    PersonFields

type PersonRecord = {
    pessoa_id: string
    cnpj_empregador?: string
} & PersonFields &
    EnderecoFields

// Public Types

export type FetchRequest = {
    onlyActives?: boolean
    complience?: boolean
    companyIds?: string[]
    partnerIds?: string[]
    personIds?: string[]
    cnesIds?: string[]
}

export type FetchResponse = {
    graph: GraphType
    selectedCompanyNodes?: GraphNode[]
    selectedPartnerNodes?: GraphNode[]
    selectedPersonNodes?: GraphNode[]
}

const SYMBOL_SIZE_0 = 7
const SYMBOL_SIZE_1 = 9
//const SYMBOL_SIZE_2 = 10
const SYMBOL_SIZE_3 = 15
const SYMBOL_SIZE_4 = 20
const SYMBOL_SIZE_5 = 25
const MAX_SYMBOL_SIZE = SYMBOL_SIZE_5

export class TheConnectionService {
    private static INSTANCE = new TheConnectionService()

    static singleton() {
        return TheConnectionService.INSTANCE
    }

    async fetchGraph(request: FetchRequest): Promise<FetchResponse> {
        const companyRequestedIdMap = new Map<string, boolean>()
        if (request.companyIds) {
            request.companyIds.forEach((id) => companyRequestedIdMap.set(id, true))
        }

        const partnerRequestedIdMap = new Map<string, boolean>()
        if (request.partnerIds) {
            request.partnerIds.forEach((id) => partnerRequestedIdMap.set(id, true))
        }

        const personRequestedIdMap = new Map<string, boolean>()
        if (request.personIds) {
            request.personIds.forEach((id) => personRequestedIdMap.set(id, true))
        }

        const cnesRequestedIdMap = new Map<string, boolean>()
        if (request.cnesIds) {
            request.cnesIds.forEach((id) => cnesRequestedIdMap.set(id, true))
        }

        const graph: GraphType = {
            nodes: [],
            links: []
        }

        const selectedCompanyNodes: GraphNode[] = []
        const selectedPartnerNodes: GraphNode[] = []
        const selectedPersonNodes: GraphNode[] = []

        if (
            companyRequestedIdMap.size === 0 &&
            partnerRequestedIdMap.size === 0 &&
            personRequestedIdMap.size === 0 &&
            cnesRequestedIdMap.size === 0
        ) {
            return { graph, selectedCompanyNodes, selectedPartnerNodes, selectedPersonNodes }
        }

        type LinkCompanyToPartner = {
            partner_id: string
            cnpj: string
        }

        type LinkPartnerToCompany = {
            cnpj: string
            partner_id: string
            qualificacao?: string
        }

        type LinkPersonToCompany = {
            cnpj: string
            person_id: string
        }

        type DataType = {
            companies: CompanyRecord[]
            partners: PartnerRecord[]
            people: PersonRecord[]
            link_company_to_partner: LinkCompanyToPartner[]
            link_partner_to_company: LinkPartnerToCompany[]
            link_person_to_company: LinkPersonToCompany[]
        }

        const planoSaudeCnaeMap = new Map<number, boolean>()
        planoSaudeCnaeMap.set(6550200, true)
        planoSaudeCnaeMap.set(6622300, true)

        const data = await serviceClient.authPost<DataType>('/api/graph/company-to-companies', request)

        const socioCnpjLink = new Map<string, GraphLink>()
        const colaboradorCnpjLink = new Map<string, GraphLink>()

        let companySimbolSize: (value: number) => number
        {
            const faturamentoStats = new Stats()
            for (const company of data.companies) {
                if (company.faturamento > 0) {
                    faturamentoStats.push(company.faturamento)
                }
            }
            const avg_faturamento = faturamentoStats.amean()
            const std_faturamento = faturamentoStats.stddev()
            companySimbolSize = (value) => {
                return computeSimbolSizeByProfit(avg_faturamento, std_faturamento, value)
            }
        }

        let partnerSimbolSize: (value: number) => number
        {
            const faturamentoStats = new Stats()
            for (const partner of data.partners) {
                if (partner.faturamento > 0) {
                    faturamentoStats.push(partner.faturamento)
                }
            }
            const avg_faturamento = faturamentoStats.amean()
            const std_faturamento = faturamentoStats.stddev()
            partnerSimbolSize = (value) => {
                return computeSimbolSizeByProfit(avg_faturamento, std_faturamento, value)
            }
        }

        for (const company of data.companies) {
            let companyName = company.razao_social
            if (company.nome_fantasia && company.nome_fantasia !== company.razao_social) {
                companyName = `${company.nome_fantasia} / ${company.razao_social}`
            }

            const node: GraphNode = {
                dataIndex: 0,
                type: EntityTypeID.company.code,
                id: company.cnpj,
                hid: formatCNPJ(company.cnpj),
                name: companyName ? companyName : 'desconhecido',
                symbolSize: companySimbolSize(company.faturamento),
                value: '',
                category: CATEGORY_RECORD.empresas.index,
                extra: {
                    selected: false,
                    active: company.situacao_cadastral === 2
                }
            }

            if (companyRequestedIdMap.has(node.id) || cnesRequestedIdMap.has(company.cnes ?? '')) {
                node.symbol = SYMBOLS.selected
                selectedCompanyNodes.push(node)
                node.extra.selected = true
                node.symbolSize = MAX_SYMBOL_SIZE

                companyRequestedIdMap.set(company.cnpj, true)
            }

            if (node.extra.active) {
                if (node.name.substring(0, 8) === 'HOSPITAL') {
                    node.category = CATEGORY_RECORD.hospital.index
                } else if (node.name.substring(0, 11) === 'CONSULTORIO') {
                    node.category = CATEGORY_RECORD.consultorio.index
                } else if (node.name.substring(0, 7) === 'CLINICA') {
                    node.category = CATEGORY_RECORD.clinica.index
                } else if (node.name.substring(0, 13) === 'UNIDADE MISTA') {
                    node.category = CATEGORY_RECORD.unidadeMista.index
                } else if (node.name.substring(0, 7) === 'UNIDADE') {
                    node.category = CATEGORY_RECORD.unidade.index
                } else if (node.name.substring(0, 6) === 'CENTRO') {
                    node.category = CATEGORY_RECORD.centro.index
                } else if (planoSaudeCnaeMap.has(company.cnae)) {
                    node.category = CATEGORY_RECORD.planos.index
                }
            } else {
                node.category = CATEGORY_RECORD.empresasInativas.index
            }

            node.value = `<b>CNPJ</b>: ${formatCNPJ(company.cnpj)}`

            if (!node.extra.active) {
                node.value += ' (Inativa)'
            }

            node.value += ` (<b>${company.cnpj === company.cnpj_matriz ? 'Matriz' : 'Filial'}</b>)`

            if (company.cnpj === company.cnpj_cluster) {
                node.value += `${BREAK_TAG}<b>Matriz do cluster</b>`
            }

            if (company.cnes) {
                node.value += `${BREAK_TAG}<b>CNES</b>: ${lodash.padStart(company.cnes, 7, '0')}`
            }

            if (company.cd_operadora) {
                node.value += `${BREAK_TAG}<b>Operadora de Saúde</b>: ${company.cd_operadora}`
            }

            if (company.nome_fantasia && company.razao_social !== company.nome_fantasia) {
                const txt = splitByWords(company.nome_fantasia, 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>Nome Fantasia</b>: ${txt}`
            }

            if (company.razao_social) {
                const txt = splitByWords(company.razao_social, 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>Razão social</b>: ${txt}`
            }

            if (company.cnae) {
                const txt = splitByWords(company.descricao_cnae, 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>CNAE</b>: ${txt}`
            }

            if (company.codigo_natureza_juridica) {
                const txt = splitByWords(company.descricao_da_natureza_juridica, 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>Natureza Jurídica</b>: ${txt}`
            }

            if (company.total_funcionarios) {
                node.value += `${BREAK_TAG}<b>Total Funcionários</b>: ${formatNumber(company.total_funcionarios)}`
            }

            if (company.faturamento) {
                node.value += `${BREAK_TAG}<b>Faturamento Estimado</b>: ${formatCurrency(company.faturamento)}`
            }

            const faturamento_estimado_cluster = company.faturamento_estimado_cluster ?? 0
            if (faturamento_estimado_cluster > 0) {
                if (company.cnpj !== company.cnpj_cluster) {
                    node.value += `${BREAK_TAG}<b>Razão Social do Cluster</b>: ${company.razao_social_cluster}`
                }
                node.value += `${BREAK_TAG}<b>Faturamento Estimado do Cluster</b>: ${formatCurrency(
                    faturamento_estimado_cluster
                )}`
            }

            if (company.email) {
                const txt = splitByWords(company.email.toLocaleLowerCase(), 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>E-Mail</b>: ${txt}`
            }

            if (company.telefone) {
                const txt = splitByWords(formatTelefone(company.telefone), 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>Telefone</b>: ${txt}`
            }

            const endereco = buildEndereco(company)
            if (endereco) {
                const txt = splitByWords(endereco, 10, LN_SEPARATOR)
                node.value += `${BREAK_TAG}<b>Endereço</b>: ${txt}`
            }

            if (company.optante_simples === 'S') {
                node.value += `${BREAK_TAG}Optante do SIMPLES`
            }

            if (company.optante_mei === 'S') {
                node.value += `${BREAK_TAG}Micro Empresa`
            }

            node.dataIndex = graph.nodes.length
            graph.nodes.push(node)

            if (company.cnpj !== company.cnpj_matriz) {
                const link: GraphLink = {
                    source: company.cnpj,
                    target: company.cnpj_matriz,
                    value: 'É filial'
                }

                const linkId = `${link.source}->${link.target}`
                if (!socioCnpjLink.has(linkId)) {
                    graph.links.push(link)
                    socioCnpjLink.set(linkId, link)
                }
            }
        }

        const personRecordMap = new Map<string, PersonRecord>()
        for (const person of data.people) {
            personRecordMap.set(person.pessoa_id, person)
        }

        const fnLinkEmpregador = (person: PersonRecord) => {
            if (person.cnpj_empregador) {
                const link: GraphLink = {
                    source: person.pessoa_id!,
                    target: person.cnpj_empregador!,
                    value: 'É colaborador de'
                }

                const linkId = `${link.source}->${link.target}`
                if (!colaboradorCnpjLink.has(linkId)) {
                    graph.links.push(link)
                    colaboradorCnpjLink.set(linkId, link)
                }
            }
        }

        for (const partner of data.partners) {
            let person = personRecordMap.get(partner.partner_id)

            const partnerNode: GraphNode = {
                dataIndex: 0,
                type: EntityTypeID.companyPartner.code,
                id: partner.partner_id,
                hid: partner.cpf ? formatCPF(partner.cpf) : formatCPFOrCNPJ(partner.cpfcnpj),
                name: partner.nome,
                symbolSize: partnerSimbolSize(partner.faturamento),
                value: partner.nome,
                category: 0,
                extra: {
                    selected: false,
                    active: true
                }
            }

            if (!person && (partner.cpf || (partner.cpfcnpj && partner.cpfcnpj.indexOf('*') !== -1))) {
                person = {
                    pessoa_id: partner.partner_id,
                    nome: partner.nome,
                    dt_nascimento: partner.dt_nascimento,
                    cpf: partner.cpf ?? partner.cpfcnpj,
                    telefone1: partner.telefone1,
                    telefone2: partner.telefone2,
                    celular1: partner.celular1,
                    celular2: partner.celular2,
                    celular3: partner.celular3,
                    celular4: partner.celular4,
                    email1: partner.email1,
                    email2: partner.email2,
                    email3: partner.email3,
                    email4: partner.email4,
                    cbo: partner.cbo,
                    cbo_descricao: partner.cbo_descricao,
                    crm: partner.crm,
                    crm_dt_inscricao: partner.crm_dt_inscricao,
                    especialidades: partner.especialidades
                }
            }

            if (person) {
                partnerNode.category = CATEGORY_RECORD.socioPF.index
                partnerNode.symbol = SYMBOLS.pessoa

                if (person.crm) {
                    partnerNode.category = CATEGORY_RECORD.medico.index
                    partnerNode.symbol = SYMBOLS.medico
                }
                partnerNode.value = buildPersonHint(partnerNode.value, person)
                personRecordMap.delete(person.pessoa_id)

                if (personRequestedIdMap.has(partnerNode.id)) {
                    selectedPersonNodes.push(partnerNode)
                }

                fnLinkEmpregador(person)
            } else {
                partnerNode.category = CATEGORY_RECORD.socioPJ.index
                partnerNode.symbol = SYMBOLS.empresa

                if (partner.socio_cbos && (partner.socio_cbos.match(/225\d*/gm) ?? []).length > 0) {
                    partnerNode.category = CATEGORY_RECORD.medico.index
                    partnerNode.symbol = SYMBOLS.medico
                }

                if (partner.cpfcnpj) {
                    partnerNode.value += `${BREAK_TAG}<b>CNPJ</b>: ${formatCNPJ(partner.cpfcnpj)}`
                }
            }

            if (partnerRequestedIdMap.has(partnerNode.id)) {
                partnerNode.symbol = SYMBOLS.selected
                selectedPartnerNodes.push(partnerNode)
                partnerNode.extra.selected = true
                partnerNode.symbolSize = MAX_SYMBOL_SIZE
            }

            partnerNode.dataIndex = graph.nodes.length
            graph.nodes.push(partnerNode)
        }

        for (const person of personRecordMap.values()) {
            const personNode: GraphNode = {
                dataIndex: 0,
                type: EntityTypeID.person.code,
                id: person.pessoa_id!,
                hid: formatCPF(person.cpf),
                name: person.nome,
                symbolSize: 20,
                value: person.nome!,
                category: 0,
                extra: {
                    selected: false,
                    active: true
                }
            }

            if (person.crm) {
                personNode.category = CATEGORY_RECORD.medico.index
                personNode.symbol = SYMBOLS.medico
            } else {
                personNode.category = CATEGORY_RECORD.pessoa.index
                personNode.symbol = SYMBOLS.pessoa
            }

            if (personRequestedIdMap.has(personNode.id)) {
                personNode.symbol = SYMBOLS.selected
                selectedPersonNodes.push(personNode)
                personNode.extra.selected = true
                personNode.symbolSize = MAX_SYMBOL_SIZE
            }

            personNode.value = buildPersonHint(personNode.value, person)

            fnLinkEmpregador(person)

            personNode.dataIndex = graph.nodes.length
            graph.nodes.push(personNode)
        }

        for (const row of data.link_company_to_partner) {
            const link: GraphLink = {
                source: row.partner_id,
                target: row.cnpj,
                value: 'É um dos sócios'
            }

            const linkId = `${link.source}->${link.target}`
            if (!socioCnpjLink.has(linkId)) {
                graph.links.push(link)
                socioCnpjLink.set(linkId, link)
                socioCnpjLink.set(`${link.target}->${link.source}`, link)
            }
        }

        for (const row of data.link_partner_to_company) {
            const link: GraphLink = {
                source: row.partner_id,
                target: row.cnpj,
                value: row.qualificacao ? `${lodash.capitalize(row.qualificacao.toLowerCase())} de` : 'Sócio de'
            }

            const linkId = `${link.source}->${link.target}`
            if (!socioCnpjLink.has(linkId)) {
                graph.links.push(link)
                socioCnpjLink.set(linkId, link)
                socioCnpjLink.set(`${link.target}->${link.source}`, link)
            }
        }

        request.companyIds = [...companyRequestedIdMap.keys()]

        return { graph, selectedCompanyNodes, selectedPartnerNodes, selectedPersonNodes }
    }
}

function computeSimbolSizeByProfit(avg_faturamento: number, std_faturamento: number, faturamento: number) {
    let simbolSize = SYMBOL_SIZE_0
    if (faturamento < avg_faturamento) {
        simbolSize = SYMBOL_SIZE_0
    } else if (faturamento >= std_faturamento) {
        simbolSize = SYMBOL_SIZE_5
    } else {
        const one_third_std = std_faturamento / 3
        const two_third_std = one_third_std * 2

        if (faturamento < one_third_std) {
            simbolSize = SYMBOL_SIZE_1
        } else if (faturamento < two_third_std) {
            simbolSize = SYMBOL_SIZE_3
        } else {
            simbolSize = SYMBOL_SIZE_4
        }
    }
    return simbolSize
}

function splitByWords(text: string, numWords: number, separator: string) {
    if (numWords <= 0) {
        return text
    }

    const words = text.split(/\s/)

    const result: string[] = []

    let i = 0
    const ilast = words.length - 1
    while (i < words.length) {
        const endPos = Math.min(i + numWords, words.length)
        while (i < endPos) {
            result.push(words[i++])
            if (i <= ilast) {
                result.push(' ')
            }
        }

        if (i < ilast) {
            result.push(separator)
        }
    }

    return result.join('')
}

function buildEndereco(local: EnderecoFields) {
    const endereco: string[] = []
    local.logradouro && endereco.push(local.logradouro)
    local.numero && endereco.push(`, ${local.numero}`)
    local.complemento && endereco.push(`, ${local.complemento}`)
    local.bairro && endereco.push(`, ${local.bairro}`)

    if (local.municipio && local.uf) {
        endereco.push(`, ${local.municipio}/${local.uf}`)
    } else {
        local.municipio && endereco.push(`, ${local.municipio}`)
        local.uf && endereco.push(`, ${local.uf}`)
    }

    const cep = lang.onlyNumbers(local.cep)
    if (cep) {
        const cep0 = cep.substring(0, 5)
        const cep1 = cep.substring(5)
        endereco.push(` - CEP ${cep0}-${cep1}`)
    }

    return endereco.length > 0 ? endereco.join('') : undefined
}

function buildTelefone(person: PersonFields) {
    const telefones: string[] = []
    person.celular1 && telefones.push(person.celular1)
    person.celular2 && telefones.push(person.celular2)
    person.celular3 && telefones.push(person.celular3)
    person.celular4 && telefones.push(person.celular4)
    person.telefone1 && telefones.push(person.telefone1)
    person.telefone2 && telefones.push(person.telefone2)
    return telefones
}

function buildEmail(person: PersonFields) {
    const emails: string[] = []
    person.email1 && emails.push(person.email1)
    person.email2 && emails.push(person.email2)
    person.email3 && emails.push(person.email3)
    person.email4 && emails.push(person.email4)
    return emails
}

function iso8601ToSimpleDate(v: string | undefined) {
    if (!v) {
        return undefined
    }

    if (v.length < 10) {
        return undefined
    }

    const parts = v.substring(0, 10).split('-')
    if (parts.length != 3) {
        return undefined
    }

    return new Date(Number.parseInt(parts[0]), Number.parseInt(parts[1]), Number.parseInt(parts[2]))
}

function buildPersonHint(value: string, person: PersonRecord): string {
    let hint = value

    if (person.cpf) {
        if (person.dt_nascimento) {
            const dtNascimento = iso8601ToSimpleDate(person.dt_nascimento)
            if (dtNascimento) {
                const years = DateFnsNs.differenceInYears(Date.now(), dtNascimento.getTime())
                if (years > 0) {
                    hint += `${BREAK_TAG}<b>Idade</b>: ${years} ano${years > 1 ? 's' : ''}`
                }
            }
        }

        hint += `${BREAK_TAG}<b>CPF</b>: ${formatCPFOrCNPJ(person.cpf)}`

        if (person.crm) {
            hint += `${BREAK_TAG}<b>CRM</b>: ${person.crm}`

            const dtInscricao = iso8601ToSimpleDate(person.crm_dt_inscricao)
            if (dtInscricao) {
                const years = DateFnsNs.differenceInYears(Date.now(), dtInscricao.getTime())
                if (years > 0) {
                    hint += ` (Inscrito há ${years} ano${years > 1 ? 's' : ''})`
                }
            }
        }
    }

    const telefones: string[] = buildTelefone(person)
    if (telefones.length > 0) {
        const txt = splitByWords(telefones.join(' / '), 10, LN_SEPARATOR)
        hint += `${BREAK_TAG}<b>Telefone${telefones.length > 1 ? 's' : ''}</b>: ${txt}`
    }

    const emails: string[] = buildEmail(person)
    if (emails.length > 0) {
        const txt = splitByWords(emails.join(' / '), 10, LN_SEPARATOR)
        hint += `${BREAK_TAG}<b>EMail${emails.length > 1 ? 's' : ''}</b>: ${txt}`
    }

    const endereco = buildEndereco(person)
    if (endereco) {
        const txt = splitByWords(endereco, 10, LN_SEPARATOR)
        hint += `${BREAK_TAG}<b>Endereço</b>: ${txt}`
    }

    return hint
}

export default TheConnectionService.singleton()
