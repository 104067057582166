import { darken, lighten, styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import { colors } from './tcm_view_utils'

const getBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7))

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4)

export default styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme-selected': {
        backgroundColor: getBackgroundColor(colors.selected, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(colors.selected, theme.palette.mode)
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(colors.selected, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(colors.selected, theme.palette.mode)
            }
        }
    }
}))