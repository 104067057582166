export type ILogger = {
    debug(message: any, context?: string): void
    debug(message: any, ...optionalParams: [...any, string?]): void
}

// 10 Minutes
const TTL_IN_MS = 10 * 60 * 1000

export type TaskResponder<T> = {
    resolve: (value: T | PromiseLike<T>) => void
    reject: (reason?: unknown) => void
}

type TaskResponderEntry<T> = {
    responders: TaskResponder<T>[]
    expires: number
}

export class ExecutionSupervisor {
    private readonly taskMap = new Map<string, TaskResponderEntry<unknown>>()
    private readonly logger: ILogger
    private readonly fake: boolean

    constructor(logger: ILogger, fake = false) {
        this.logger = logger
        this.fake = fake
    }

    async runTask<T>(requestId: string, task: () => Promise<T>, ttl?: number) {
        if (this.fake) {
            return task()
        }

        return new Promise<T>((resolve, reject) => {
            this.doRunTask(requestId, task, ttl ?? TTL_IN_MS, { resolve, reject }).catch(reject)
        })
    }

    private async doRunTask<T>(taskId: string, task: () => Promise<T>, ttl: number, responder: TaskResponder<T>) {
        const taskEntry = this.taskMap.get(taskId)
        if (taskEntry) {
            this.logger.debug(`Task(${taskId}) is underway. Waiting for response...`)
            taskEntry.responders.push(responder as TaskResponder<unknown>)
            return
        }

        const newTaskEntry = {
            responders: [responder as TaskResponder<unknown>],
            expires: Date.now() + ttl
        }

        let timeoutId: NodeJS.Timeout | undefined

        let position = 0
        try {
            this.taskMap.set(taskId, newTaskEntry)

            const timeoutTask = new Promise<void>((___, reject) => {
                timeoutId = setTimeout(() => {
                    reject(new Error('Timeout'))
                }, TTL_IN_MS)
            })

            const realTask = task().finally(() => {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
            })

            const response = await Promise.race([realTask, timeoutTask])

            this.logger.debug(`Responding task(${taskId})...`)
            for (; position < newTaskEntry.responders.length; position++) {
                const someResponder = newTaskEntry.responders[position]
                try {
                    someResponder.resolve(response)
                } catch (caught) {
                    someResponder.reject(caught)
                }
            }
        } catch (caught) {
            for (; position < newTaskEntry.responders.length; position++) {
                const someResponder = newTaskEntry.responders[position]
                someResponder.reject(caught)
            }
        } finally {
            this.taskMap.delete(taskId)
            this.logger.debug(`Task(${taskId}) finalized`)
        }
    }
}
