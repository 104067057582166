import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { SearchBoxView } from 'src/components/searchbox/views'
import { HeaderScope } from '../ta_scopes'

const LOG = Logger.get('TheActingHeaderView')

const useStyles = makeStyles()(() => ({
    view: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        flexBasis: 0,
        gap: 8,
        marginRight: 10,
        marginLeft: 10
    },
    search: {
        flex: 1
    },
    switch: {
        zoom: 0.6
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column'
    }
}))

type HeaderProps = {
    className?: string
    scope: HeaderScope
}

class HeaderViewClass implements FCClassContext<HeaderProps> {
    // :: Fields

    scope!: HeaderScope

    // :: Emissors

    readonly emitOnlyActivesChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.scope.onOnlyActivesChange(e.target.checked).catch(LOG.caught)

    // :: Methods

    onSyncState({ scope }: HeaderProps) {
        this.scope = scope
    }

    render({ className, scope }: HeaderProps): JSX.Element {
        const classes = useStyles().classes

        return (
            <div className={clsx(classes.view, className)}>
                <SearchBoxView
                    className={classes.search}
                    scope={scope.search}
                    endAdornment={
                        <div className={classes.hbox}>
                            <FormGroup className={classes.vbox}>
                                <FormControlLabel
                                    className={classes.switch}
                                    label="Mostrar ativos"
                                    control={
                                        <Switch checked={scope.onlyActives} onChange={this.emitOnlyActivesChange} />
                                    }
                                />
                            </FormGroup>
                        </div>
                    }
                />
            </div>
        )
    }
}
export const HeaderView = classToFComponent(HeaderViewClass, React)
export default HeaderView
