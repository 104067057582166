import lodash from 'lodash'
import { type SearchOption, type FilterType } from '../sb_types'

import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'

import { useAutocomplete, UseAutocompleteReturnValue, AutocompleteChangeReason, ClickAwayListener } from '@mui/base'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import Select, { SelectProps } from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

import { DateRangeInput, DateRangeInputScope } from './sb_date_range_input'

import { FCClassContext, classToFComponent } from 'src/utils/views'
import { SearchBoxScope } from '../sb_scopes'
import TagChip from './sb_tag'
import ItemOption from './sb_item'

type AutoCompleteType = UseAutocompleteReturnValue<SearchOption, true, false, true>
type InputProps = ReturnType<AutoCompleteType['getInputProps']>

const LOG = Logger.get('tm_searchbox.view')

const useStyles = makeStyles()((theme) => ({
    view: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)',
        fontSize: 14
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        border: theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9',
        backgroundColor: theme.palette.mode === 'dark' ? '#141414' : '#fff',
        borderRadius: 4,
        padding: '1px 5px 1px 1px'
    },
    inputWrapper: {
        minWidth: 300,
        minHeight: 44,
        flexGrow: 1,
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        '&:hover': {
            borderColor: theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'
        },
        '&.focused': {
            borderColor: theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff',
            boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)'
        },
        '& input': {
            backgroundColor: theme.palette.mode === 'dark' ? '#141414' : '#fff',
            color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)',
            height: 30,
            boxSizing: 'border-box',
            padding: '4px 6px',
            width: 0,
            minWidth: 30,
            flexGrow: 1,
            border: 0,
            margin: 0,
            outline: 0
        }
    },
    popup: {
        top: 55,
        width: 'calc(100% - 368px)',
        minWidth: 300,
        margin: '2px 0 0',
        position: 'absolute',
        backgroundColor: theme.palette.mode === 'dark' ? '#141414' : '#fff',
        borderRadius: 4,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    listboxBand: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row'
    },
    dividerMargin: {
        marginTop: 5
    },
    listbox: {
        padding: 0,
        margin: 0,
        alignSelf: 'stretch',
        listStyle: 'none',
        maxHeight: 250,
        overflow: 'auto',
        '& li': {
            padding: '5px 12px',
            display: 'flex',
            '& svg': {
                color: 'transparent'
            }
        },
        "& li[aria-selected='true']": {
            backgroundColor: theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa',
            fontWeight: 600,
            '& svg': {
                color: '#1890ff'
            }
        },
        [`& li.${autocompleteClasses.focused}`]: {
            backgroundColor: theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff',
            cursor: 'pointer',
            '& svg': {
                color: 'currentColor'
            }
        }
    },
    filterSelect: {
        marginLeft: '5pt',
        '::before': {
            borderBottom: 'none'
        }
    },
    dateInputBox: {
        flexGrow: 1,
        alignSelf: 'stretch',
        marginLeft: 5
    }
}))

export type SearchBoxViewProps = {
    className?: string
    scope: SearchBoxScope
    endAdornment?: React.ReactNode
}

enum FilterWidgetType {
    text,
    date
}

const FILTER_TYPE_MAP = new Map<FilterType, FilterWidgetType>([
    ['crm.dta.inscricao', FilterWidgetType.date]
    // Fallback "text"
])

class SearchBoxTextViewClass implements FCClassContext<SearchBoxViewProps> {
    // :: Fields
    scope!: SearchBoxScope
    classes!: ReturnType<typeof useStyles>['classes']
    filterType = FilterWidgetType.text
    autoComplete!: AutoCompleteType
    originalInputProps!: InputProps
    inputProps!: InputProps
    inputElm?: HTMLInputElement | null

    // :: Methods

    // @Override
    onSyncState({ scope }: SearchBoxViewProps) {
        this.scope = scope
        this.classes = useStyles().classes
        this.filterType = FILTER_TYPE_MAP.get(scope.filterId) ?? FilterWidgetType.text

        this.autoComplete = useAutocomplete({
            id: scope.uid,
            defaultValue: [],
            value: [],
            multiple: true,
            options: [...scope.options],
            open: scope.loading || scope.options.length > 0,
            freeSolo: true,
            handleHomeEndKeys: true,
            clearOnBlur: true,
            clearOnEscape: true,
            onChange: this.onChange,
            isOptionEqualToValue,
            filterOptions
        })

        this.originalInputProps = this.autoComplete.getInputProps()
        this.originalInputProps.ref = this.collectInputRef
        this.inputProps = { ...this.originalInputProps }
        Object.assign(this.inputProps, this.overrideInputProps)
        this.inputProps.value = scope.value

        if (scope.requestFocusOnInput) {
            scope.requestFocusOnInput = false
            this.focusOnInputElm.cancel()
            this.focusOnInputElm()
        }
    }

    render(props: SearchBoxViewProps) {
        const { classes, scope, autoComplete: acx } = this

        const popupElement: JSX.Element = acx.popupOpen ? (
            <ClickAwayListener onClickAway={this.onClickedAwayFromListbox}>
                <div className={classes.popup}>
                    <ul className={classes.listbox} {...acx.getListboxProps()}>
                        {scope.loading && <li>Carregando...</li>}
                        {!scope.loading && (
                            <>
                                {(acx.groupedOptions as SearchOption[]).map((option, index) => (
                                    <ItemOption
                                        {...acx.getOptionProps({ option, index })}
                                        key={option.id}
                                        option={option}
                                    />
                                ))}
                            </>
                        )}
                    </ul>
                </div>
            </ClickAwayListener>
        ) : (
            <></>
        )

        const boxElement: JSX.Element = (
            <div className={classes.inputGroup}>
                <div ref={acx.setAnchorEl} className={clsx(classes.inputWrapper, acx.focused ? 'focused' : '')}>
                    {scope.selecteds.map((option: SearchOption, index: number) => (
                        <TagChip
                            onRemoveTag={scope.onRemoveTag}
                            option={option}
                            {...acx.getTagProps({ index })}
                            key={index}
                        />
                    ))}
                    <Select
                        className={classes.filterSelect}
                        margin="dense"
                        variant="standard"
                        value={scope.filterId}
                        {...this.selectFilterProps}
                    >
                        {scope.filterOptions.map((item) => (
                            <MenuItem key={item.key} value={item.key}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <input {...this.inputProps} />
                    {props.endAdornment}
                    {!!scope.applyLabel && (
                        <Button variant="outlined" onClick={this.onApply}>
                            {scope.applyLabel}
                        </Button>
                    )}
                </div>
            </div>
        )

        return (
            <div className={clsx(classes.view, props.className)} {...acx.getRootProps()}>
                {boxElement}
                {popupElement}
            </div>
        )
    }

    readonly focusOnInputElm = lodash.debounce(() => {
        this.scope.requestFocusOnInput = false
        if (this.inputElm) {
            try {
                this.inputElm.focus()
            } catch (e) {
                LOG.error('Focusing on inputElm', e)
            }
        }
    }, 100)

    readonly collectInputRef = (inputElm: HTMLInputElement | null) => {
        this.inputElm = inputElm
        if (inputElm && this.scope.requestFocusOnInput) {
            this.scope.requestFocusOnInput = false
            this.focusOnInputElm.cancel()
            this.focusOnInputElm()
        }

        const inputRef = (
            this.autoComplete.getInputProps() as {
                ref: {
                    current: HTMLInputElement | null
                }
            }
        ).ref
        if (inputRef) {
            inputRef.current = inputElm
        }
    }

    readonly selectFilterProps: Partial<SelectProps> = {
        onChange: (evt) => {
            this.scope.onFilterChanged(evt.target.value as FilterType)
        }
    }

    readonly overrideInputProps: Partial<InputProps> = {
        onKeyUp: (evt) => {
            this.originalInputProps.onKeyUp && this.originalInputProps.onKeyUp(evt)
            this.scope.onKeyUp(evt)
            if (evt.key === 'Enter' && this.inputProps.value) {
                this.scope.onChange(evt, [String(this.inputProps.value ?? '')], 'key.Enter')
            }
        },

        onKeyDown: (evt) => {
            this.originalInputProps.onKeyDown && this.originalInputProps.onKeyDown(evt)
            this.scope.onKeyDown(evt)
        },

        onInput: (evt) => {
            const newValue = (evt.target as unknown as { value: string }).value
            if (this.scope.value !== newValue) {
                this.scope.value = newValue
                this.scope.onInputChange(evt, newValue)
            }
        },

        onPaste: (evt) => {
            const text = evt.clipboardData.getData('Text')
            if (text) {
                const response = { accept: true }
                this.scope.onPasteText(text, response)
                if (!response.accept) {
                    evt.preventDefault()
                    evt.stopPropagation()
                }
            }
        }
    }

    onChange = (
        evt: React.SyntheticEvent<Element, Event>,
        value: (string | SearchOption)[],
        reason: AutocompleteChangeReason
    ) => {
        this.scope.onChange(evt, value, reason)
    }

    onClickedAwayFromListbox = () => {
        this.scope.onClosePopup()
    }

    onApply = () => {
        this.scope.onApply().catch(LOG.caught)
    }
}
const SearchBoxTextView = classToFComponent(SearchBoxTextViewClass, React)

// :: Private static functions

function isOptionEqualToValue(option: SearchOption, value: SearchOption): boolean {
    if (option && value) {
        return option.id === value.id
    }
    return false
}

function filterOptions(options: SearchOption[]) {
    //((options: SearchOption[], state: FilterOptionsState<SearchOption>) => SearchOption[]) | undefined
    return options
}

class SearchBoxDateViewClass implements FCClassContext<SearchBoxViewProps> {
    scope!: SearchBoxScope
    classes!: ReturnType<typeof useStyles>['classes']
    dateRangeScope = new DateRangeInputScope()

    // @Override
    onSyncState({ scope }: SearchBoxViewProps, initialized: boolean) {
        this.scope = scope
        this.classes = useStyles().classes

        if (initialized) {
            this.dateRangeScope.update = lodash.debounce(() => this.dateRangeScope.forceUpdate(), 60)
            this.dateRangeScope.onEnter = async () => this.onApply()
        }

        if (scope.requestFocusOnInput) {
            this.dateRangeScope.requestFocusOnInput = true
            scope.requestFocusOnInput = false
        }
    }

    render(props: SearchBoxViewProps) {
        const { classes, scope } = this

        return (
            <div className={clsx(classes.view, props.className)}>
                <div className={classes.inputGroup}>
                    <Select
                        className={classes.filterSelect}
                        margin="dense"
                        variant="standard"
                        value={scope.filterId}
                        {...this.selectFilterProps}
                    >
                        {scope.filterOptions.map((item) => (
                            <MenuItem key={item.key} value={item.key}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <DateRangeInput className={classes.dateInputBox} scope={this.dateRangeScope} />
                    {props.endAdornment}
                    {!!scope.applyLabel && (
                        <Button variant="outlined" onClick={this.onApply}>
                            {scope.applyLabel}
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    readonly onApply = () => {
        this.scope.value = JSON.stringify({
            operator: this.dateRangeScope.operator,
            value: this.dateRangeScope.value?.toISOString(),
            to: this.dateRangeScope.toValue?.toISOString()
        })
        this.scope.onApply().catch(LOG.caught)
    }

    readonly selectFilterProps: Partial<SelectProps> = {
        onChange: (evt) => {
            this.scope.onFilterChanged(evt.target.value as FilterType)
        }
    }
}
const SearchBoxDateView = classToFComponent(SearchBoxDateViewClass, React)

// Export

export function SearchBoxView(props: SearchBoxViewProps) {
    const { scope } = props

    const [paintCount, setPaintCount] = React.useState(0)
    scope.repaintAll = () => {
        setPaintCount(paintCount + 1)
    }

    const filterType = FILTER_TYPE_MAP.get(scope.filterId) ?? FilterWidgetType.text
    if (filterType == FilterWidgetType.date) {
        return <SearchBoxDateView {...props} />
    } else {
        return <SearchBoxTextView {...props} />
    }
}

export default SearchBoxView
