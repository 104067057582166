import React from 'react'
import clsx from 'clsx'
import { bindUpdate } from 'wdc-cube-react'
import { makeStyles } from 'tss-react/mui'

import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'

import { CATEGORY_ARRAY } from '../tc_types'
import { SelectedNodesScope, ChipScope } from '../tc_scopes'

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        padding: 10
    }
})

// ::

export type GraphAppliedFiltersViewProps = {
    className?: string
    scope: SelectedNodesScope
}

export const SelectedNodesView = function ({ className, scope }: GraphAppliedFiltersViewProps) {
    bindUpdate(React, scope)

    const { classes } = useStyles()

    return (
        <Stack className={clsx(classes.view, className)} direction="row" gap={1} flexWrap="wrap">
            {scope.companies.length > 0 && scope.companies.map((item) => <ChipView key={item.value} scope={item} />)}
            {scope.partners.length > 0 && scope.partners.map((item) => <ChipView key={item.value} scope={item} />)}
            {scope.people.length > 0 && scope.people.map((item) => <ChipView key={item.value} scope={item} />)}
        </Stack>
    )
}

export default SelectedNodesView

// ::

const ChipStyles = (function () {
    const styles: React.CSSProperties[] = []
    for (const category of CATEGORY_ARRAY) {
        styles[category.index] = {
            color: 'white',
            backgroundColor: category.color
        }
    }
    return styles
})()

export type ChipViewProps = {
    key?: string
    scope: ChipScope
}

function ChipView({ scope }: ChipViewProps) {
    bindUpdate(React, scope)

    if (scope.hint) {
        const hint = <div dangerouslySetInnerHTML={{ __html: scope.hint }} />
        return (
            <Tooltip title={hint}>
                <Chip key={scope.value} label={scope.label} sx={ChipStyles[scope.category]} onDelete={scope.onDelete} />
            </Tooltip>
        )
    }

    return <Chip key={scope.value} label={scope.label} onDelete={scope.onDelete} />
}
