import React from 'react'

import { classToFComponent } from 'src/utils/views'
import { ValueViewClass, type ValueViewProps } from './helpers/tcm_value'

export type ChartTotalEmpresasNovasUltMesViewProps = ValueViewProps

export class ChartTotalEmpresasNovasUltMesViewClass extends ValueViewClass<ChartTotalEmpresasNovasUltMesViewProps> {
    render(props: ChartTotalEmpresasNovasUltMesViewProps): JSX.Element {
        return super.render(props)
    }
}

export default classToFComponent(ChartTotalEmpresasNovasUltMesViewClass, React)
