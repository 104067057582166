import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'
import { makeStyles } from 'tss-react/mui'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import WebCam from 'react-webcam'
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty'
import FileUploadIcon from '@mui/icons-material/FileUpload'

import { SearchByPictureScope } from '../tc_scopes'

const LOG = Logger.get('SearchByPictureView')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        padding: 10
    }
})

const { userVideoConstraints, environmentVideoConstraints } = (() => {
    const width = 640
    const height = 480

    const userVideoConstraints: MediaTrackConstraints = {
        width,
        height,
        facingMode: 'user'
    }

    const environmentVideoConstraints: MediaTrackConstraints = {
        width,
        height,
        facingMode: 'environment'
    }

    return { userVideoConstraints, environmentVideoConstraints }
})()

// ::

export type SearchByPictureViewProps = {
    className?: string
    scope: SearchByPictureScope
}

export const SearchByPictureView = function ({ className, scope }: SearchByPictureViewProps) {
    bindUpdate(React, scope)

    const webcamRef = React.useRef<WebCam>(null)

    const haddleOnCaptureClick = React.useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot()
            if (imageSrc) {
                scope.onCapture(imageSrc).catch(LOG.caught)
            }
        }
    }, [webcamRef, scope])

    const handleOnFileUpload = React.useCallback(() => {
        LOG.debug("handleOnFileUpload")
    }, [scope])

    const handleOnFlipCamera = React.useCallback(() => {
        scope.userFace = !scope.userFace
    }, [scope])

    const handleOnClose = React.useCallback(() => {
        scope.onClose().catch(LOG.caught)
    }, [scope])

    const hasVideoCamera = !!(scope.frontCamera || scope.rearCamera)
    const hasFrontAndReaderCamera = !!(scope.frontCamera && scope.rearCamera)

    let videoConstrainst = userVideoConstraints
    if (hasFrontAndReaderCamera) {
        videoConstrainst = scope.userFace ? userVideoConstraints : environmentVideoConstraints
    }

    const { classes } = useStyles()

    return (
        <Stack
            className={clsx(classes.view, className)}
            direction="column"
            gap={1}
            flexWrap="wrap"
            alignItems="stretch"
        >
            <DialogTitle>Busca por foto</DialogTitle>
            <DialogContent>
                {hasVideoCamera && (
                    <WebCam
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstrainst}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handleOnFileUpload}>
                    <FileUploadIcon />
                </IconButton>

                {hasFrontAndReaderCamera && (
                    <IconButton onClick={handleOnFlipCamera}>
                        <ThreeSixtyIcon />
                    </IconButton>
                )}
                <Button onClick={haddleOnCaptureClick}>Tirar foto</Button>
                <Button onClick={handleOnClose}>Fechar</Button>
            </DialogActions>
        </Stack>
    )
}
