import striptags from 'striptags'

const TAGS_TO_BREAK_ON = [
    'p',
    'div',
    'br',
    'hr',
    'title',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ol',
    'ul',
    'li',
    'pre',
    'table',
    'th',
    'td',
    'blockquote',
    'header',
    'footer',
    'nav',
    'section',
    'summary',
    'aside',
    'article',
    'address'
]

const removeAllNonTagsToBreakOn = (html: string) => striptags(html, TAGS_TO_BREAK_ON)
const convertTagsToBreak = (html: string) => striptags(html, [], '\n')
const replaceSpaces = (html: string) => html.replace(/&nbsp;/g, ' ')
const replaceMultiNewLines = (html: string) => html.replace(/\n\n/g, '\n')
const removeLeadingNewLines = (html: string) => html.replace(/\n+$/, '')
const removeTrailingNewLines = (html: string) => html.replace(/^\n+/, '')

type OneArgFunc = (p0: string) => string
const compose = (...fns: OneArgFunc[]) =>
    fns.reduce(
        (f, g) =>
            (...args) =>
                f(g(...args))
    )

export const htmlToFormattedText = compose(
    removeTrailingNewLines,
    removeLeadingNewLines,
    replaceMultiNewLines,
    replaceSpaces,
    convertTagsToBreak,
    removeAllNonTagsToBreakOn
)

export default htmlToFormattedText
