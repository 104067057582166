import type { TextualSearchRequest } from 'src/components/searchbox'

import { ServiceClient } from 'src/utils'
import ExecutionSupervisor from 'src/utils/execution-supervisor'
import objectHash from 'object-hash'
//import { Logger } from 'wdc-cube'

//const LOG = Logger.get('TheMapService')

const executorSupervisor = new ExecutionSupervisor()
const serviceClient = ServiceClient.singleton()

export type PlotItem = {
    id: string
    name: string
    value: number[]
}

export type PlotResult = {
    scatter: PlotItem[]
    effectScatter?: PlotItem[]
}

type FetchResponse = {
    result: PlotItem[]
    duration: number
}

export default class TheMapService {
    private static INSTANCE = new TheMapService()

    static singleton() {
        return TheMapService.INSTANCE
    }

    async fetch(request: TextualSearchRequest): Promise<PlotResult> {
        //LOG.debug('fetch:', request)
        const requestHash = `fetch:${objectHash(request)}`
        return executorSupervisor.runTask(requestHash, async () => {
            const data = await serviceClient.authPost<FetchResponse>('/api/map/fetch', request)
            return { scatter: data?.result ?? [] }
        })
    }
}
