import React from 'react'
import lodash from 'lodash'
import * as echarts from 'echarts'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { classToFComponent } from 'src/utils/views'

import TcmCard from './helpers/tcm-card'
import { colors } from './helpers/tcm_view_utils'
import { EChartViewClass } from './helpers/base-echart'

import { ChartFilialMatrizScope } from '../tcm_scopes'

const LOG = Logger.get('TCM-FILIAL-MATRIX')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        zoom: 0.8
    },
    chart: {}
})

type ChartFilialMatrizProps = {
    className?: string
    style?: React.CSSProperties
    scope: ChartFilialMatrizScope
}

class ChartFilialMatrizViewClass extends EChartViewClass<ChartFilialMatrizProps> {
    // :: Fields
    scope!: ChartFilialMatrizScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: ChartFilialMatrizProps) {
        this.scope = props.scope
        this.classes = useStyles().classes
        this.chartData = buildChartData(this.scope)
        this.loaded = this.scope.loaded
        super.onSyncState(props)
    }

    override render({ className, style, scope }: ChartFilialMatrizProps): JSX.Element {
        return (
            <TcmCard className={clsx(className)} style={style} caption={scope.caption}>
                {super.render({ className: this.classes.view, scope })}
            </TcmCard>
        )
    }

    protected override onBindChartEvents(chart: echarts.ECharts) {
        chart.off('click', this.emitClickOnSeries)
        chart.on('click', this.emitClickOnSeries)
    }

    emitClickOnSeries = (evt: echarts.ECElementEvent) => {
        if (!lodash.isNumber(evt.seriesIndex)) {
            return
        }

        switch (evt.seriesIndex) {
            case 0:
                this.scope.onItemClicked(false).catch(LOG.caught)
                break
            case 1:
                this.scope.onItemClicked(true).catch(LOG.caught)
                break
        }
    }
}

export default classToFComponent(ChartFilialMatrizViewClass, React)

function buildChartData(scope: ChartFilialMatrizScope): echarts.EChartsOption {
    const total = scope.numMatrizes + scope.numFiliais
    const labelPerctFormat: echarts.LabelFormatterCallback<echarts.DefaultLabelFormatterCallbackParams> = (params) => {
        return Math.round(((params.value as number) / total) * 100.0) + '%'
    }
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            position: {
                top: '50%',
                left: '40%'
            },
            confine: false
        },
        legend: {
            padding: 0
        },
        grid: {
            left: '3%',
            right: '6%',
            bottom: '3%',
            containLabel: true,
            top: 15
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: ['PJ']
        },
        series: [
            {
                name: 'Filial',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: labelPerctFormat
                },
                emphasis: {
                    focus: 'series'
                },
                data: [
                    {
                        value: scope.numFiliais,
                        itemStyle: {
                            color: scope.filiaisSelected ? colors.selected : undefined
                        }
                    }
                ]
            },
            {
                name: 'Matriz',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: labelPerctFormat
                },
                emphasis: {
                    focus: 'series'
                },
                data: [
                    {
                        value: scope.numMatrizes,
                        itemStyle: {
                            color: scope.matrizesSelected ? colors.selected : undefined
                        }
                    }
                ]
            }
        ]
    }
}
