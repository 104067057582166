import { Places, ParamIds } from '../../Constants'
import { MainKeys } from '../../main/Main.keys'

export class TheActingKeys extends MainKeys {
    get place() {
        return Places.theActing
    }

    get selectedCard() {
        return this._intent.getParameterAsString(ParamIds.taSelectedCard)
    }

    set selectedCard(value: string | undefined) {
        if (value && value !== 'none') {
            this._intent.setParameter(ParamIds.taSelectedCard, value)
        } else {
            this._intent.setParameter(ParamIds.taSelectedCard, undefined)
        }
    }
}
