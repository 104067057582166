import lodash from 'lodash'
import { Logger, Presenter } from 'wdc-cube'
import * as lang from 'src/utils/lang'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { TableAtividadeEconomicaScope, type TableAtividadeEconomicaRow } from './tcm_scopes'
import { TheConsumerMarketService, type EmpresaFilter, StringKeywordOperator, JunctionMode } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasTableAtivEconomica')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasTableAtivEconomica extends Presenter<
    TableAtividadeEconomicaScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.atividadeEconomica)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.atividadeEconomica
        this.scope.onItemClicked = this.__onAtivEconomicaClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.atividadeEconomicaCodes.clear()
        this.__filterManager.atividadeEconomicaTexts.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                ativ_economica_com_mais_empresas_funcionarios: true,
                filter: filter
            })

            const dataset: TableAtividadeEconomicaRow[] = []
            for (const bucket of resp.ativ_economica_com_mais_empresas_funcionarios?.buckets ?? []) {
                dataset.push({
                    id: bucket.key,
                    descricao: bucket.description.subclasse,
                    qtdEmpresas: bucket.total_empresas.value,
                    qtdFuncionarios: bucket.total_empregados.value
                })
            }

            dataset.sort((a, b) => {
                let v = b.qtdEmpresas - a.qtdEmpresas
                if (v === 0) {
                    v = b.qtdFuncionarios - a.qtdFuncionarios
                }
                return v
            })

            this.scope.dataset = dataset

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        const codigoValMap = this.collectExplicitValues()

        for (const row of this.scope.dataset) {
            row.selected = codigoValMap.has(row.id)
        }
        this.scope.update()
    }

    private async __onAtivEconomicaClicked(cnae: string) {
        const codigoValMap = this.collectExplicitValues()

        if (codigoValMap.has(cnae)) {
            codigoValMap.delete(cnae)
        } else {
            codigoValMap.set(cnae, true)
        }

        const prop = this.__filterManager.atividadeEconomicaCodes
        prop.clear()
        if (codigoValMap.size > 0) {
            prop.add({
                mode: JunctionMode.AND,
                operator: StringKeywordOperator.is_in,
                value: [...codigoValMap.keys()].join(',')
            })
        }

        this.owner.filterPane.cnae.populateUsingValueProporty()

        this.__markSelected()
        this.__parent.fetchData(prop.size ? 'totalPorAtividadeEconomica' : '')
    }

    private collectExplicitValues() {
        const prop = this.__filterManager.atividadeEconomicaCodes

        const codigoValMap = new Map<string, boolean>()
        for (const filterVal of prop.values()) {
            if (filterVal.value) {
                if (filterVal.operator == StringKeywordOperator.is_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigo && codigoValMap.set(codigo, true))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigoValMap.delete(codigo))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is) {
                    const codigo = lodash.trim(filterVal.value)
                    codigo && codigoValMap.set(codigo, true)
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not) {
                    codigoValMap.delete(filterVal.value)
                    continue
                }
            }
        }
        return codigoValMap
    }
}
