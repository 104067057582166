import lodash from 'lodash'
import * as DateFnsNs from 'date-fns'
import { Logger, Presenter, action } from 'wdc-cube'
import * as lang from 'src/utils/lang'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { TableEmpresaScope } from './tcm_scopes'
import { 
    TheConsumerMarketService,
    StringKeywordOperator,
    type EmpresaFilter,
    type ColumnSort,
    type ColumnFilter
} from './tcm_service'

type PaginationState = {
    pageIndex: number
    pageSize: number
}

const LOG = Logger.get('TcmPresenterForEmpresasFaixaCapitalSocial')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasTableListagem extends Presenter<
    TableEmpresaScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.empresasListagem)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.empresasListagem
        this.scope.onSelectionChange = this.__onEmpresaRowClicked.bind(this)
        this.scope.onFiltrationChange = this.__onFiltrationChange.bind(this)
        this.scope.onPaginationChange = this.__onPaginationChange.bind(this)
        this.scope.onSortingChange = this.__onSortingChange.bind(this)
        this.scope.onDownloadClick = this.__onDownloadClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.scope.pageIndex = 0
        this.scope.pageSize = 100
        this.owner.filterPane.codigo.clear()
    }

    async fetch(
        filter: EmpresaFilter,
        cfg?: {
            paginationState?: PaginationState
            sorting?: ColumnSort[]
            columnFilters?: ColumnFilter[]
        }
    ) {
        let { paginationState, sorting, columnFilters } = cfg ?? {}
        this.scope.loaded = false
        try {
            if (!columnFilters) {
                columnFilters = this.scope.filters
            }

            if (!paginationState) {
                paginationState = {
                    pageIndex: 0,
                    pageSize: this.scope.pageSize
                }
            }

            if(!sorting) {
                sorting = this.scope.sorting
            }

            let search_after: unknown
            if (paginationState.pageIndex > 0) {
                const lastRow = this.scope.dataset[this.scope.dataset.length - 1]
                if (lastRow) {
                    search_after = [
                        lastRow.faturamento_estimado,
                        lastRow.func_qtd,
                        lastRow.cnpj,
                        lastRow.score
                    ]
                }
            }

            const resp = await service.fetch({
                listagem_empresas: {
                    sorting,
                    columnFilters,
                    search_after,
                    pageSize: paginationState.pageSize
                },
                filter: filter
            })

            this.scope.pageIndex = paginationState.pageIndex
            this.scope.pageSize = paginationState.pageSize
            this.scope.sorting = sorting

            const dataset = resp.listagem_empresas ?? []
            for(let i = 0; i < dataset.length; i++) {
                const row = dataset[i]
                if (lodash.isString(row.dta_inicio_atividade)) {
                    row.dta_inicio_atividade = DateFnsNs.parseISO(row.dta_inicio_atividade)
                }
            }
            LOG.debug(`dataset.length=${dataset.length}`)

            if (resp.listagem_empresas_agg) {
                this.scope.rowCount = resp.listagem_empresas_agg['pj-count'].value | 0
                this.scope.total.func_qtd = resp.listagem_empresas_agg['pj-func-qtd-emp-sum'].value
                this.scope.total.faturamento_estimado = resp.listagem_empresas_agg['pj-fatu-est-emp-sum'].value
                this.scope.total.faturamento_estimado_grupo = resp.listagem_empresas_agg['pj-fatu-est-grp-sum'].value
            }
            this.scope.dataset = dataset
            this.scope.datasetVersion++

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onEmpresaRowClicked(cnpj: string, checked: boolean) {
        const cnpjPresenter = this.owner.filterPane.codigo
        const inConditionScope = cnpjPresenter.getOrCreateIsIn()

        const map = this.collectExplicitValues()
        if (checked) {
            map.set(cnpj, true)
        } else if (map.has(cnpj)) {
            map.delete(cnpj)
        }

        inConditionScope.value = [...map.keys()].join(', ')
        cnpjPresenter.publish()

        this.__markSelected()
        this.__parent.fetchData('listagemDeEmpresas')
    }

    private __markSelected() {
        const map = this.collectExplicitValues()

        for (const row of this.scope.dataset) {
            row.selected = map.has(row.cnpj)
        }

        this.scope.update()
    }

    @action()
    async __onFiltrationChange(columnFilters: ColumnFilter[]) {
        this.scope.filters = columnFilters
        await this.fetch(this.__filterManager.build(), {
            columnFilters
        })
    }

    @action()
    async __onPaginationChange(pageIndex: number, pageSize: number) {
        if (pageSize !== this.scope.pageSize) {
            pageIndex = 0
        }
        await this.fetch(this.__filterManager.build(), {
            paginationState: { pageIndex, pageSize }
        })
    }

    @action()
    async __onSortingChange(sorting: ColumnSort[]) {
        await this.fetch(this.__filterManager.build(), { sorting })
    }

    @action()
    async __onDownloadClicked() {
        try {
            this.scope.working = true
            const blob = await service.downloadEmpresa(this.__filterManager.build())

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `tcm-empresa.xlsx`)

            // Append to html link element page
            document.body.appendChild(link)
            try {
                // Start download
                link.click()
            } finally {
                // Clean up and remove the link
                link.parentNode?.removeChild(link)
            }
        } finally {
            this.scope.working = false
        }
    }

    private collectExplicitValues() {
        const prop = this.__filterManager.empresaCodigo

        const codigoValMap = new Map<string, boolean>()
        for (const filterVal of prop.values()) {
            if (filterVal.value) {
                if (filterVal.operator == StringKeywordOperator.is_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigo && codigoValMap.set(codigo, true))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigoValMap.delete(codigo))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is) {
                    const codigo = lodash.trim(filterVal.value.trim())
                    codigo && codigoValMap.set(codigo, true)
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not) {
                    codigoValMap.delete(filterVal.value)
                    continue
                }
            }
        }
        return codigoValMap
    }
}
