import { ViewFactory } from 'wdc-cube-react'

import { TheActingScope } from '../ta_scopes'
import { TheActingView } from './ta_view'

import { HeaderScope } from '../ta_scopes'
import { HeaderView } from './ta_header-bar_view'

import { OverlayScope } from '../ta_scopes'
import { OverlayView } from './ta_overlay_view'

export function registerViews(rv = ViewFactory.register) {
    rv(TheActingScope, TheActingView)
    rv(HeaderScope, HeaderView)
    rv(OverlayScope, OverlayView)
}
