import { Places, ParamIds } from '../../Constants'
import { MainKeys } from '../../main/Main.keys'

export class TheConnectionKeys extends MainKeys {
    get place() {
        return Places.theConnection
    }

    #companyIds?: string[]

    get companyIds() {
        if(this.#companyIds) {
            return this.#companyIds
        }

        const s = this._intent.getParameterRawValue(ParamIds.companyIds) as string | undefined
        if (s) {
            this.#companyIds = s.split(/,/)
        }

        return this.#companyIds
    }

    set companyIds(value: string[] | undefined) {
        if (value && value.length > 0) {
            this.#companyIds = value
            this._intent.setParameter(ParamIds.companyIds, value.join(','))
        } else {
            this.#companyIds = undefined
            this._intent.setParameter(ParamIds.companyIds, null)
        }
    }

    #partnerIds?: string[]

    get partnerIds() {
        if(this.#partnerIds) {
            return this.#partnerIds
        }

        const s = this._intent.getParameterRawValue(ParamIds.partnerIds) as string | undefined
        if (s) {
            this.#partnerIds = s.split(/,/)
        }

        return this.#partnerIds
    }

    set partnerIds(value: string[] | undefined) {
        if (value && value.length > 0) {
            this.#partnerIds = value
            this._intent.setParameter(ParamIds.partnerIds, value.join(','))
        } else {
            this.#partnerIds = undefined
            this._intent.setParameter(ParamIds.partnerIds, null)
        }
    }

    #personIds?: string[]

    get personIds() {
        if(this.#personIds) {
            return this.#personIds
        }

        const s = this._intent.getParameterRawValue(ParamIds.personIds) as string | undefined
        if (s) {
            this.#personIds = s.split(/,/)
        }

        return this.#personIds
    }

    set personIds(value: string[] | undefined) {
        if (value && value.length > 0) {
            this.#personIds = value
            this._intent.setParameter(ParamIds.personIds, value.join(','))
        } else {
            this.#personIds = undefined
            this._intent.setParameter(ParamIds.personIds, null)
        }
    }

}
