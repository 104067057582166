import React from 'react'
import { Logger } from 'wdc-cube'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

import FilterPaneView from './filters/tcm_filter_pane_view'

import ChartTotalEmpresasView from './tcm_chart_total_empresas'
import ChartTotalEmpresasAtivasView from './tcm_chart_total_empresas_ativas'
import ChartTotalEmpresasNovasUltMesView from './tcm_chart_total_novas_ult_mes'
import ChartFilialMatrizView from './tcm_chart_filial_matriz'
import ChartSituacaoCadastralView from './tcm_chart_situacao_cadastral'
import ChartNaturezaJuridicaView from './tcm_chart_natureza_juridica'
import AtividadeEconomicaTableView from './tcm_ativ_economica'
import ChartAbertasFechadasView from './tcm_chart_abertas_fechadas'
import ChartFaixasView from './helpers/tcm_chart_faixas'
import ListagemEmpresaView from './tcm_listagem_empresa'
import MapCardView from './tcm_map_card'

import { TheConsumerMarketScope } from '../tcm_scopes'

const LOG = Logger.get('TCM-VIEW')

const ModuleView = styled('div')({
    display: 'flex',
    paddingTop: 10,
    paddingLeft: 10
})

const DashboardView = styled('div')({
    display: 'flex',
    marginRight: 5,
    overflowX: 'hidden',
    overflowY: 'scroll',
    flexGrow: 1,
    flexShrink: 1
})

const useStyles = makeStyles()({
    overlay: {
        marginRight: 5,
        marginBottom: 5
    },
    dataView: {
        flexGrow: 1,
        paddingRight: 5
    },
    grade: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content min-content 1fr',
        gap: '5px 5px',
        gridAutoFlow: 'row',
        gridTemplateAreas: '"l0" "l1" "l2"'
    },
    firstRow: {
        display: 'grid',
        gridTemplateColumns: '1fr minmax(475px, 0.5fr)',
        gridTemplateRows: '1fr 1fr',
        gap: '5px 5px',
        gridTemplateAreas: '"p0 p1" "p0 p2"'
    },
    firstRowLopLeftCorner: {
        display: 'grid',
        gridTemplateColumns: '120px 120px 120px 1fr',
        gridTemplateRows: 'min-content 1fr 1fr',
        gap: '5px 5px',
        gridAutoFlow: 'row',
        gridTemplateAreas: '"p0 p1 p2 p3" "p5 p5 p5 p4" "p6 p6 p6 p4"'
    },
    secondRow: {
        display: 'flex'
    },
    totalEmpresas: {
        gridArea: 'p0'
    },
    totalEmpresasAtivas: {
        gridArea: 'p1'
    },
    totalUltimoMes: {
        gridArea: 'p2'
    },
    situacaoCadastral: {
        gridArea: 'p5'
    },
    naturezaJuridica: {
        gridArea: 'p6',
        minHeight: 300
    },
    filialMatriz: {
        gridArea: 'p3',
        height: 85
    },
    mapa: {
        gridArea: 'p4',
        height: '100%'
    },
    novasEmpresas: {
        gridArea: 'p1'
    },
    detalhamentoAtivPrincipal: {
        gridArea: 'p2'
    },
    detalhamentoDasEmpresas: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    thirdRow: {
        display: 'flex'
    },
    min300Height: {
        minHeight: 300
    }
})

const FlexGrid = styled(Grid)({
    display: 'flex',
    flexGrow: 1
})

type TheConsumerMarketProps = {
    className?: string
    scope: TheConsumerMarketScope
}

class TheConsumerMarketViewClass implements FCClassContext<TheConsumerMarketProps> {
    // :: State

    scope!: TheConsumerMarketScope
    classes!: ReturnType<typeof useStyles>['classes']

    // :: Emissors

    readonly emitOnWndKeyDown = (evt: KeyboardEvent) => {
        this.emitCtrlKeyToggled(evt)
    }

    readonly emitOnWndKeyUp = (evt: KeyboardEvent) => {
        this.emitCtrlKeyToggled(evt)
    }

    readonly emitOnMouseMouve = (evt: MouseEvent) => {
        this.emitCtrlKeyToggled(evt)
    }

    private emitCtrlKeyToggled(evt: { ctrlKey: boolean; metaKey: boolean }) {
        const previousCtrlKey = this.scope.keyboard.ctrlKey
        const newCtrlKey = evt.ctrlKey || evt.metaKey
        this.scope.keyboard.ctrlKey = newCtrlKey
        if (previousCtrlKey !== newCtrlKey) {
            this.scope.onCtrlKeyToggled(newCtrlKey).catch(LOG.caught)
        }
    }

    // :: Lifecicle listeners

    onSyncState({ scope }: TheConsumerMarketProps) {
        this.scope = scope
        this.classes = useStyles().classes
    }

    onAttach() {
        document.addEventListener('keydown', this.emitOnWndKeyDown)
        document.addEventListener('keyup', this.emitOnWndKeyUp)
        document.addEventListener('mousemove', this.emitOnMouseMouve)
    }

    onDetach() {
        document.removeEventListener('keydown', this.emitOnWndKeyDown)
        document.removeEventListener('keyup', this.emitOnWndKeyUp)
        document.addEventListener('mousemove', this.emitOnMouseMouve)
    }

    render(props: TheConsumerMarketProps) {
        const { classes, scope } = this
        return (
            <ModuleView className={props.className}>
                <DashboardView>
                    <Stack direction="column" gap={1} className={classes.dataView}>
                        <div className={classes.firstRow}>
                            <div className={classes.firstRowLopLeftCorner} style={{ gridArea: 'p0' }}>
                                <ChartTotalEmpresasView className={classes.totalEmpresas} scope={scope.totalEmpresas} />
                                <ChartTotalEmpresasAtivasView
                                    className={classes.totalEmpresasAtivas}
                                    scope={scope.totalEmpresasAtivas}
                                />
                                <ChartTotalEmpresasNovasUltMesView
                                    className={classes.totalUltimoMes}
                                    scope={scope.totalEmpresasNovasUltimoMes}
                                />
                                <ChartSituacaoCadastralView
                                    className={classes.situacaoCadastral}
                                    scope={scope.situacaoCadastral}
                                />
                                <ChartNaturezaJuridicaView
                                    className={classes.naturezaJuridica}
                                    scope={scope.naturezaJuridica}
                                />
                                <ChartFilialMatrizView
                                    className={classes.filialMatriz}
                                    scope={this.scope.filialMatriz}
                                />
                                <MapCardView className={classes.mapa} scope={scope.mapa} />
                            </div>

                            <ChartAbertasFechadasView className={classes.novasEmpresas} scope={scope.abertasFechadas} />
                            <AtividadeEconomicaTableView
                                className={classes.detalhamentoAtivPrincipal}
                                scope={scope.atividadeEconomica}
                            />
                        </div>

                        <Grid container spacing={1} className={classes.secondRow}>
                            <FlexGrid item xs={3}>
                                <ChartFaixasView scope={scope.empresasNoGrupo} className={classes.min300Height} />
                            </FlexGrid>
                            <FlexGrid item xs={3}>
                                <ChartFaixasView scope={scope.faixaCapitalSocial} className={classes.min300Height} />
                            </FlexGrid>
                            <FlexGrid item xs={3}>
                                <ChartFaixasView scope={scope.faixaDeIdade} className={classes.min300Height} />
                            </FlexGrid>
                            <FlexGrid item xs={3}>
                                <ChartFaixasView scope={scope.faixaDeFuncionarios} className={classes.min300Height} />
                            </FlexGrid>
                        </Grid>

                        <Grid container spacing={1} className={classes.thirdRow}>
                            <FlexGrid item xs={4}>
                                <ChartFaixasView
                                    scope={scope.faixaDeFuncionariosNoGrupo}
                                    className={classes.min300Height}
                                />
                            </FlexGrid>
                            <FlexGrid item xs={4}>
                                <ChartFaixasView
                                    scope={scope.faixaDeFaturamentoAnual}
                                    className={classes.min300Height}
                                />
                            </FlexGrid>
                            <FlexGrid item xs={4}>
                                <ChartFaixasView
                                    scope={scope.faixaDeFaturamentoConsolidadoGrupo}
                                    className={classes.min300Height}
                                />
                            </FlexGrid>
                        </Grid>

                        <FlexGrid item flexGrow={1} paddingBottom={"5px"}>
                            <ListagemEmpresaView
                                className={classes.detalhamentoDasEmpresas}
                                scope={scope.empresasListagem}
                            />
                        </FlexGrid>
                    </Stack>
                </DashboardView>
                <FilterPaneView className={classes.overlay} scope={scope.filterPane} />
            </ModuleView>
        )
    }
}

export default classToFComponent(TheConsumerMarketViewClass, React)
