import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { makeStyles } from 'tss-react/mui'
import { MapCardScope } from '../ta_scopes'

import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import * as L from 'leaflet'
import { MapContainer, TileLayer } from 'react-leaflet'

const LOG = Logger.get('TA-VIEW-MAP-CARD')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10
    },
    viewShrunk: {
        minWidth: 560,
        minHeight: 420
    },
    viewExpanded: {
        height: '100%'
    },
    expandButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 1001
    },
    map: {
        display: 'flex',
        flexGrow: 1
    }
})

export type MapCardViewProps = {
    className?: string
    scope: MapCardScope
}

class MapCardViewClass implements FCClassContext<MapCardViewProps> {
    // :: Fields
    scope!: MapCardScope
    mapRef: L.Map | null = null
    mapResizeObserver = new ResizeObserver(() => this.onMapResize())
    paperEl: HTMLDivElement | null = null

    // :: Emissors

    readonly emitOnPaperAttached = (paperEl: HTMLDivElement | null) => {
        this.paperEl && this.mapResizeObserver.unobserve(this.paperEl)
        this.paperEl = paperEl
        this.paperEl && this.mapResizeObserver.observe(this.paperEl)
    }

    readonly emitExpandClick = () => this.scope.onExpandClick().catch(LOG.caught)
    readonly emitShrunkClick = () => this.scope.onShrunkClick().catch(LOG.caught)

    readonly emitMapChanged = (value: L.Map | null) => {
        if (this.mapRef !== value) {
            this.mapRef = value
            this.scope.onMapChanged(value).catch(LOG.caught)
            //value && value.setView({ lat: -27.591984322510214, lng: -48.55231447232847 }, 15)
        }
    }

    // :: Methods

    onDetach() {
        this.paperEl && this.mapResizeObserver.unobserve(this.paperEl)
        this.mapResizeObserver.disconnect()
    }

    onSyncState({ scope }: MapCardViewProps) {
        this.scope = scope
    }

    onMapResize() {
        this.mapRef && this.mapRef.invalidateSize()
    }

    render({ className, scope }: MapCardViewProps) {
        const classes = useStyles().classes

        const expandedCn = scope.expanded ? classes.viewExpanded : classes.viewShrunk

        return (
            <Paper ref={this.emitOnPaperAttached} className={clsx(classes.view, expandedCn, className)}>
                <MapContainer className={classes.map} scrollWheelZoom={true} ref={this.emitMapChanged}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </MapContainer>
                <IconButton
                    className={classes.expandButton}
                    edge="start"
                    color="inherit"
                    aria-label="expand card"
                    onClick={scope.expanded ? this.emitShrunkClick : this.emitExpandClick}
                >
                    {scope.expanded && <CloseFullscreenRoundedIcon />}
                    {!scope.expanded && <LaunchRoundedIcon />}
                </IconButton>
            </Paper>
        )
    }
}
export const MapCardView = classToFComponent(MapCardViewClass, React)
export default MapCardView
