import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { WaitingScope } from '../waiting.scopes'
import { getOrMakeWaitingStyles } from '../waiting.styles'

export { WaitingScope }

export type WaitingViewProps = IViewProps & {
    scope: WaitingScope
    text?: string
}

export function WaitingView({ className, scope, text }: WaitingViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeWaitingStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.appLoading} />
            <div className={styles.appLoadingText}>{scope.text ?? (text || 'Carregando')}</div>
        </div>
    )
}
