import { NOOP_VOID, Observable, observe, Scope } from 'wdc-cube'

import type { AlertSeverity } from 'wdc-cube'

export type IDialogScope = Scope & { onClose: () => Promise<void> }

@Observable
export class SignInScope extends Scope {
    @observe() userName?: string
    @observe() userNameError?: string
    @observe() password?: string
    @observe() passwordError?: string
    @observe() rememberMe?: boolean
    @observe() errorMessage?: string

    onSignIn = Scope.ASYNC_ACTION
    onForgotPassword = Scope.ASYNC_ACTION
}

@Observable
export class ResetPasswordScope extends Scope {
    @observe() email?: string
    @observe() emailError?: string

    onResetPassword = Scope.ASYNC_ACTION
    onBackToLogin = Scope.ASYNC_ACTION
}

@Observable
export class AlertScope extends Scope {
    @observe() severity: AlertSeverity = 'info'
    @observe() title?: string
    @observe() message?: string

    onClose = Scope.ASYNC_ACTION
}

@Observable
export class BodyScope extends Scope {
    // NOOP
}

@Observable
export class MainScope extends Scope {
    @observe() authenticated = false
    @observe() toolbar?: Scope | null
    @observe() body?: Scope | null
    @observe() dialog?: IDialogScope
    @observe() alert?: AlertScope
    @observe() drawerOpen = false
    @observe() rdStationEnabled = false
    @observe() theMapEnabled = false
    @observe() theActingEnabled = false
    @observe() theConsumerMarketEnabled = false
    @observe() theConnectionEnabled = false

    notify: (severity: AlertSeverity, message: string, duration: number) => void = NOOP_VOID

    onSignOut = Scope.ASYNC_ACTION
    onToggleComplianceMode = Scope.ASYNC_ACTION
    onProfile = Scope.ASYNC_ACTION
    onHome = Scope.ASYNC_ACTION
    onDrawerToggle = Scope.ASYNC_ACTION
    onTheConnection = Scope.ASYNC_ACTION
    onTheConsumerMarket = Scope.ASYNC_ACTION
    onTheActing = Scope.ASYNC_ACTION
    onTheMap = Scope.ASYNC_ACTION
    onRdStation = Scope.ASYNC_ACTION
}
