import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ValueScope } from './tcm_scopes'
import { TheConsumerMarketService, type EmpresaFilter } from './tcm_service'
import TcmPresenterForMap from './tcm_presenter_map'

const LOG = Logger.get('TcmPresenterForEmpresasTotal')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasTotal extends Presenter<ValueScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter, mapPresenter: TcmPresenterForMap) {
        super(owner, owner.scope.totalEmpresas)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
        this.__mapPresenter = mapPresenter
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __mapPresenter: TcmPresenterForMap
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.totalEmpresas
        this.scope.onClicked = this.__onEmpresasClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.owner.filterPane.clear()
        this.__filterManager.clear()
        this.__mapPresenter.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas: true,
                filter: filter
            })

            this.scope.value = resp.total_de_empresas?.value ?? 0
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onEmpresasClicked() {
        this.clear()
        this.__parent.fetchData('')
    }
}
