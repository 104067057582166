import React from 'react'
import clsx from 'clsx'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { makeStyles } from 'tss-react/mui'
import { TheActingScope } from '../ta_scopes'

import OverlayView from './ta_overlay_view'
import ScoreView from './ta_score_view'
import ProfessionalCardView from './ta_professional_card_view'
import MapCardView from './ta_map_card_view'
import CompanyCardView from './ta_company_card_view'

const MAP_KEY = 'e4cffd84'

const useStyles = makeStyles()({
    view: {
        // https://grid.layoutit.com/
        position: 'relative',
        padding: '25px 40px 10px 10px',
        minHeight: 600,
        zoom: 0.8
    },
    g_layout_all: {
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr', '4fr'].join(' '),
        gridTemplateRows: ['min-content', '1fr', '2fr'].join(' '), 
        gap: '15px 15px',
        gridTemplateAreas: [
            '"sc-prof sc-co sc-revn sc-hco map"',
            '"companies companies companies companies map"',
            '"profissionals profissionals profissionals profissionals profissionals"'
        ].join(' ')
    },
    g_layout_one: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 0,
        flexShrink: 1,
        flex: 1,
        alignSelf: 'stretch'
    },
    g_map: { gridArea: 'map' },
    g_profissionals: { gridArea: 'profissionals' },
    g_companies: { gridArea: 'companies' },
    g_sc_prof: { gridArea: 'sc-prof' },
    g_sc_co: { gridArea: 'sc-co' },
    g_sc_revn: { gridArea: 'sc-revn' },
    g_sc_hco: { gridArea: 'sc-hco' },
    excluded: { display: 'none' },
    overlay: {
        position: 'absolute',
        zIndex: 1002,
        top: 48,
        right: 0,
        bottom: 48
    }
})

type TheActingViewProps = {
    className?: string
    scope: TheActingScope
}

class TheActingViewClass implements FCClassContext<TheActingViewProps> {
    // :: Fields
    scope!: TheActingScope
    sytles!: ReturnType<typeof useStyles>

    // :: emissors

    readonly emitOnWndKeyDown = (e: KeyboardEvent) => {
        this.scope.keyboard.ctrlKey = e.ctrlKey
    }

    readonly emitOnWndKeyUp = (e: KeyboardEvent) => {
        this.scope.keyboard.ctrlKey = e.ctrlKey
    }

    // :: Methods

    onSyncState({ scope }: TheActingViewProps) {
        this.scope = scope
        this.sytles = useStyles()
    }

    onAttach() {
        document.addEventListener('keydown', this.emitOnWndKeyDown)
        document.addEventListener('keyup', this.emitOnWndKeyUp)
    }

    onDetach() {
        document.removeEventListener('keydown', this.emitOnWndKeyDown)
        document.removeEventListener('keyup', this.emitOnWndKeyUp)
    }

    render(props: TheActingViewProps) {
        switch (this.scope.selectedCard) {
            case 'company':
                return this.renderCompany(props)
            case 'professional':
                return this.renderProfessional(props)
            case 'map':
                return this.renderMap(props)
            default:
                return this.renderDefault(props)
        }
    }

    renderCompany({ className, scope }: TheActingViewProps) {
        const classes = this.sytles.classes
        return (
            <div className={clsx(classes.view, classes.g_layout_one, className)}>
                <CompanyCardView scope={scope.companyCard} />
                <MapCardView key={MAP_KEY} className={classes.excluded} scope={scope.mapCard} />
                <OverlayView className={classes.overlay} scope={scope.overlay} />
            </div>
        )
    }

    renderProfessional({ className, scope }: TheActingViewProps) {
        const classes = this.sytles.classes
        return (
            <div className={clsx(classes.view, classes.g_layout_one, className)}>
                <ProfessionalCardView scope={scope.professionalCard} />
                <MapCardView key={MAP_KEY} className={classes.excluded} scope={scope.mapCard} />
                <OverlayView className={classes.overlay} scope={scope.overlay} />
            </div>
        )
    }

    renderMap({ className, scope }: TheActingViewProps) {
        const classes = this.sytles.classes
        return (
            <div className={clsx(classes.view, classes.g_layout_one, className)}>
                <MapCardView key={MAP_KEY} className={classes.g_map} scope={scope.mapCard} />
                <OverlayView className={classes.overlay} scope={scope.overlay} />
            </div>
        )
    }

    renderDefault({ className, scope }: TheActingViewProps) {
        const classes = this.sytles.classes
        return (
            <div className={clsx(classes.view, classes.g_layout_all, className)}>
                <ScoreView className={classes.g_sc_co} scope={scope.companyScoreCard} />
                <ScoreView className={classes.g_sc_prof} scope={scope.profissionalScoreCard} />
                <ScoreView className={classes.g_sc_revn} scope={scope.revenueScoreCard} />
                <ScoreView className={classes.g_sc_hco} scope={scope.healthCoScoreCard} />
                <MapCardView key={MAP_KEY} className={classes.g_map} scope={scope.mapCard} />
                <div className={clsx(classes.g_companies, classes.g_layout_one)}>
                    <CompanyCardView scope={scope.companyCard} />
                </div>
                <div className={clsx(classes.g_profissionals, classes.g_layout_one)}>
                    <ProfessionalCardView scope={scope.professionalCard} />
                </div>
                <OverlayView className={classes.overlay} scope={scope.overlay} />
            </div>
        )
    }
}

export const TheActingView = classToFComponent(TheActingViewClass, React)
export default TheActingView
