import * as React from 'react'

import { Logger } from 'wdc-cube'

import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'

import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import LayersIcon from '@mui/icons-material/Layers'
import HubIcon from '@mui/icons-material/Hub'
import MyLocationIcon from '@mui/icons-material/MyLocation'

import { FCClassContext, classToFComponent } from 'src/utils/views'
import { MainScope } from '../../Main.scopes'

const LOG = Logger.get('DrawerContent')

export type DrawerContentProps = {
    className?: string
    mainScope: MainScope
}

class DrawerContentClass implements FCClassContext<DrawerContentProps> {
    // :: Fields

    scope!: MainScope

    // :: emissors

    readonly emitTheConnection = () => this.scope.onTheConnection().catch(LOG.caught)
    readonly emitTheMap = () => this.scope.onTheMap().catch(LOG.caught)
    readonly emitTheActing = () => this.scope.onTheActing().catch(LOG.caught)
    readonly emitTheConsumerMarket = () => this.scope.onTheConsumerMarket().catch(LOG.caught)
    readonly emitRDStation = () => this.scope.onRdStation().catch(LOG.caught)

    // :: Methods

    onSyncState({ mainScope }: DrawerContentProps) {
        this.scope = mainScope
    }

    render() {
        const { scope } = this

        return (
            <List component="nav">
                {scope.theConnectionEnabled && (
                    <ListItemButton onClick={this.emitTheConnection}>
                        <Tooltip title="The Connection">
                            <ListItemIcon>
                                <HubIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="The Connection" />
                    </ListItemButton>
                )}
                {scope.theMapEnabled && (
                    <ListItemButton onClick={this.emitTheMap}>
                        <Tooltip title="The Map">
                            <ListItemIcon>
                                <MyLocationIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="The Map" />
                    </ListItemButton>
                )}
                {scope.theActingEnabled && (
                    <ListItemButton onClick={this.emitTheActing}>
                        <Tooltip title="The Acting">
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="The Acting" />
                    </ListItemButton>
                )}
                {scope.theConsumerMarketEnabled && (
                    <ListItemButton onClick={this.emitTheConsumerMarket}>
                        <Tooltip title="The Market">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="The Market" />
                    </ListItemButton>
                )}
                {scope.rdStationEnabled && (
                    <>
                        <Divider sx={{ my: 1 }} />
                        <ListItemButton onClick={this.emitRDStation}>
                            <Tooltip title="RD Station">
                                <ListItemIcon>
                                    <LayersIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="RD Station" />
                        </ListItemButton>
                    </>
                )}
            </List>
        )
    }
}

export default classToFComponent(DrawerContentClass)
