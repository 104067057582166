import { Places, ParamIds } from '../../Constants'
import { MainKeys } from '../../main/Main.keys'

export class TheMapKeys extends MainKeys {
    get place() {
        return Places.theMap
    }

    get requestGroupId() {
        return this._intent.getParameterAsString(ParamIds.tmRequestGroupId)
    }

    set requestGroupId(value: string | undefined) {
        this._intent.setParameter(ParamIds.tmRequestGroupId, value)
    }
}
