import type { GraphNode as ChartNode } from './tc_types'
import type { GraphLink as ChartEdge } from './tc_types'

import * as vec2 from 'zrender/lib/core/vector.js'

type InNode = {
    fixed: boolean
    p: vec2.VectorArray | null
    pp: vec2.VectorArray
    edges?: InEdge[] | null
    w: number
    rep: number
    target: ChartNode
}

type InEdge = {
    n1: InNode
    n2: InNode
    d: number
    curveness: number
    ignoreForceLayout?: boolean
}

type OnBeforeCallback = (nodes: InNode[], edges: InEdge[]) => void
type OnAfterCallback = (nodes: InNode[], edges: InEdge[], finished: boolean) => void
type OnStep = (finished: boolean) => void

type ForceLayoutOpts = {
    rect: {
        x: number
        y: number
        width: number
        height: number
    }
    gravity: number
    friction: number
}

function forceLayout(inNodes: InNode[], inEdges: InEdge[], opts: ForceLayoutOpts) {
    const nodes = inNodes
    const edges = inEdges
    const rect = opts.rect
    const width = rect.width
    const height = rect.height
    const center = [rect.x + width / 2, rect.y + height / 2]
    const gravity = opts.gravity == null ? 0.1 : opts.gravity

    for (let i = 0; i < nodes.length; i++) {
        const n = nodes[i]

        if (!n.p) {
            n.p = vec2.create(width * (Math.random() - 0.5) + center[0], height * (Math.random() - 0.5) + center[1])
        }

        n.pp = vec2.clone(n.p)
        n.edges = null
    } // Formula in 'Graph Drawing by Force-directed Placement'
    // let k = scale * Math.sqrt(width * height / nodes.length);
    // let k2 = k * k;

    const initialFriction = opts.friction == null ? 0.6 : opts.friction
    let friction = initialFriction
    let beforeStepCallback: OnBeforeCallback | null = null
    let afterStepCallback: OnAfterCallback | null = null

    return {
        warmUp: function () {
            friction = initialFriction * 0.8
        },
        setFixed: function (idx: number) {
            nodes[idx].fixed = true
        },
        setUnfixed: function (idx: number) {
            nodes[idx].fixed = false
        },

        /**
         * Before step hook
         */
        beforeStep: function (cb: OnBeforeCallback | null) {
            beforeStepCallback = cb
        },

        /**
         * After step hook
         */
        afterStep: function (cb: OnAfterCallback | null) {
            afterStepCallback = cb
        },

        /**
         * Some formulas were originally copied from "d3.js"
         * https://github.com/d3/d3/blob/b516d77fb8566b576088e73410437494717ada26/src/layout/force.js
         * with some modifications made for this project.
         * See the license statement at the head of this file.
         */
        step: function (cb: OnStep) {
            beforeStepCallback && beforeStepCallback(nodes, edges)
            const v12: vec2.VectorArray = []
            const nLen = nodes.length

            for (let i = 0; i < edges.length; i++) {
                const e = edges[i]

                if (e.ignoreForceLayout) {
                    continue
                }

                const n1 = e.n1
                const n2 = e.n2
                vec2.sub(v12, n2.p!, n1.p!)
                const d = vec2.len(v12) - e.d
                let w = n2.w / (n1.w + n2.w)

                if (isNaN(w)) {
                    w = 0
                }

                vec2.normalize(v12, v12)
                !n1.fixed && vec2.scaleAndAdd(n1.p!, n1.p!, v12, w * d * friction)
                !n2.fixed && vec2.scaleAndAdd(n2.p!, n2.p!, v12, -(1 - w) * d * friction)
            } // Gravity

            for (let i = 0; i < nLen; i++) {
                const n = nodes[i]

                if (!n.fixed) {
                    vec2.sub(v12, center, n.p!) // let d = vec2.len(v12);
                    // vec2.scale(v12, v12, 1 / d);
                    // let gravityFactor = gravity;

                    vec2.scaleAndAdd(n.p!, n.p!, v12, gravity * friction)
                }
            } // Repulsive
            // PENDING

            for (let i = 0; i < nLen; i++) {
                const n1 = nodes[i]

                for (let j = i + 1; j < nLen; j++) {
                    const n2 = nodes[j]
                    vec2.sub(v12, n2.p!, n1.p!)
                    let d = vec2.len(v12)

                    if (d === 0) {
                        // Random repulse
                        vec2.set(v12, Math.random() - 0.5, Math.random() - 0.5)
                        d = 1
                    }

                    const repFact = (n1.rep + n2.rep) / d / d
                    !n1.fixed && vec2.scaleAndAdd(n1.pp, n1.pp, v12, repFact)
                    !n2.fixed && vec2.scaleAndAdd(n2.pp, n2.pp, v12, -repFact)
                }
            }

            const v: vec2.VectorArray = []

            for (let i = 0; i < nLen; i++) {
                const n = nodes[i]

                if (!n.fixed) {
                    vec2.sub(v, n.p!, n.pp)
                    vec2.scaleAndAdd(n.p!, n.p!, v, friction)
                    vec2.copy(n.pp, n.p!)
                }
            }

            friction = friction * 0.992
            const finished = friction < 0.01
            afterStepCallback && afterStepCallback(nodes, edges, finished)
            cb && cb(finished)
        }
    }
}

function circularLayout(nodes: InNode[], center: number[], d: number[]) {
    const increase = (Math.PI * 2) / nodes.length
    let x = 0,
        y = 0,
        angle = 0,
        node

    for (let i = 0; i < nodes.length; i++) {
        node = nodes[i]
        x = center[0] * Math.cos(angle) + d[0]
        y = center[1] * Math.sin(angle) + d[1]
        node.p = vec2.create(x, y)
        angle += increase
    }
}

export async function computeNodesXY(
    chartNodes: ChartNode[],
    chartLinks: ChartEdge[],
    initLayout?: string
): Promise<void> {
    const opts: ForceLayoutOpts = {
        rect: {
            width: 6000, // Math.max(1024, window.innerWidth - 275),
            height: 4000, // Math.max(800, window.innerHeight - 300),
            x: 0,
            y: 100
        },

        // The gravity factor enforcing nodes approach to the center. The nodes will be closer to the center
        // as the value becomes larger. (defalult 0.1)
        gravity: 0.1,

        // It will slow down the nodes' movement. The value range is from 0 to 1.
        // (default 0.6)
        friction: 0.6
    }

    // It will slow down the nodes' movement. The value range is from 0 to 1.
    // It can be an array to represent the range of edge length. In this case edge with larger
    // value will be shorter, which means two nodes are closer. And edge with smaller value will be longer.
    // (default 30)
    const edgeLength = 30

    // The repulsion factor between nodes. The repulsion will be stronger and the distance between 2 nodes becomes further as this value becomes larger.
    // It can be an array to represent the range of repulsion. In this case larger value have larger repulsion and smaller value will have smaller repulsion.
    // (default 50)
    const repulsion = 50

    const nodeMap = new Map<string, number>()
    const nodes = chartNodes.map((node, idx) => {
        nodeMap.set(node.id, idx)

        const rep = repulsion * (node.symbolSize / 2)

        return {
            w: rep,
            rep: rep,
            fixed: node.fixed,
            p: null,
            target: node
        } as InNode
    })

    const edges: InEdge[] = []
    chartLinks.forEach((edge) => {
        const node1 = nodes[nodeMap.get(edge.source as string) ?? -1]
        const node2 = nodes[nodeMap.get(edge.target as string) ?? -1]

        if (node1 && node2) {
            edges.push({
                n1: node1,
                n2: node2,
                d: edgeLength,
                curveness: 0.3,
                ignoreForceLayout: false
            })
        }
    })

    if (initLayout === 'circular') {
        // Run circular layout
        const rect = opts.rect
        const width = opts.rect.width
        const height = opts.rect.height
        const center = [rect.x + width / 2, rect.y + height / 2]
        const distance = [width / 2, height / 2]
        circularLayout(nodes, center, distance)
    }

    const forceInstance = forceLayout(nodes, edges, opts)
    forceInstance.warmUp()

    let running = true

    const stepCb: OnStep = (finished) => (running = !finished)

    const startMoment = Date.now()
    while (running) {
        forceInstance.step(stepCb)
        const delta = Date.now() - startMoment
        if (delta > 2000) {
            break
        }
    }

    for (const node of nodes) {
        node.target.x = node.p![0]
        node.target.y = node.p![1]
    }
}
