// sessionStorage = data is cleared when the page session ends.
// localStorage = data is bounded to the site, it does not expires
import { Logger, NOOP_VOID } from 'wdc-cube'
import { jwtDecode } from 'jwt-decode'
import * as DateFnsNs from 'date-fns'

export type AccessTokenAboutToExpire = (sender: AppStorage, token: string) => void

type VoidListenerType = () => void

type Authorization = {
    token: string
    rdStation?: object
    theMap?: object
    theActing?: { print: boolean }
    theConsumerMarket?: object
    theConnection?: object
}

const LOG = Logger.get('AppStorage')

type StorageProperty<T> = {
    get: () => T
    set: (value: unknown, rememberMe?: boolean) => void
    clear: () => void
}

const EXPIRATION_DATE_IN_MILLIS = Date.now()

export class AppStorage {
    private static readonly INSTANCE = new AppStorage()

    public static singleton = () => AppStorage.INSTANCE

    private __eventIdGen = 0

    private __tokenAbouteToExpireMap = new Map<number, AccessTokenAboutToExpire>()
    private __tokenAboutToExpireTimeHandler: VoidListenerType = NOOP_VOID

    private __rememberMeProp = newLocalStorageBoolProperty('3ae9b5f1')
    private __accesssTokenProp = newStorageStringProperty('a9c07324')
    private __rdStationEnabledProp = newStorageBoolProperty('64b990d0')
    private __theMapEnabledProp = newStorageBoolProperty('c9983f7f')
    private __theActingEnabledProp = newStorageBoolProperty('a719206b')
    private __theActingPrintEnabledProp = newStorageBoolProperty('02ea71c8')
    private __theConsumerMarkedEnabledProp = newStorageBoolProperty('42506e97')
    private __theConnectionEnabledProp = newStorageBoolProperty('74ea9baa')
    private __userNameProp = newStorageStringProperty('J9VCpx')

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    get rememberMe() {
        return this.__rememberMeProp.get()
    }

    get rdStationEnbled() {
        return this.__rdStationEnabledProp.get()
    }

    get theMapEnabled() {
        return this.__theMapEnabledProp.get()
    }

    get theActingEnabled() {
        return this.__theActingEnabledProp.get()
    }

    get theConsumerMarketEnabled() {
        return this.__theConsumerMarkedEnabledProp.get()
    }

    get theActingPrintEnabled() {
        return this.__theActingPrintEnabledProp.get()
    }

    get theConnectionEnabled() {
        return this.__theConnectionEnabledProp.get()
    }

    get accessTokenExpiration() {
        try {
            const accessToken = this.accessToken
            if (!accessToken) {
                return EXPIRATION_DATE_IN_MILLIS
            }

            const info = decodeAccessToken(accessToken)

            return info.exp * 1000
        } catch (err) {
            LOG.error('[accessTokenExpirationDate]', err)
            return EXPIRATION_DATE_IN_MILLIS
        }
    }

    get accessToken() {
        return this.__accesssTokenProp.get()
    }

    clearAccessToken() {
        this.__rememberMeProp.clear()
        this.__accesssTokenProp.clear()
        this.__rdStationEnabledProp.clear()
        this.__theMapEnabledProp.clear()
        this.__theActingEnabledProp.clear()
        this.__theActingPrintEnabledProp.clear()
        this.__theConsumerMarkedEnabledProp.clear()
        this.__theConnectionEnabledProp.clear()

        this.__tokenAboutToExpireTimeHandler()
        this.__tokenAboutToExpireTimeHandler = NOOP_VOID
    }

    get userName(): string {
        return this.__userNameProp.get()
    }

    setUserName(username: string, rememberMe: boolean) {
        this.__userNameProp.set(username, rememberMe)
    }

    setAuthorization(value: Omit<Authorization, 'token'>, rememberMe: boolean) {
        this.__rememberMeProp.set(rememberMe, false)
        this.__rdStationEnabledProp.set(value.rdStation, rememberMe)
        this.__theMapEnabledProp.set(value.theMap, rememberMe)
        this.__theActingEnabledProp.set(value.theActing, rememberMe)
        this.__theActingPrintEnabledProp.set(value.theActing?.print, rememberMe)
        this.__theConsumerMarkedEnabledProp.set(value.theConsumerMarket, rememberMe)
        this.__theConnectionEnabledProp.set(value.theConnection, rememberMe)

        const token = this.__accesssTokenProp.get()
        this.launchTokenExpirationTimeout(token)
    }

    setAccessToken(value: Authorization, rememberMe: boolean) {
        this.clearAccessToken()
        if (!value || !value.token) {
            return
        }
        this.__accesssTokenProp.set(value.token, rememberMe)
        this.setAuthorization(value, rememberMe)
    }

    onAccessTokenAboutToExpire(listener: AccessTokenAboutToExpire) {
        const eventId = this.__eventIdGen++
        this.__tokenAbouteToExpireMap.set(eventId, listener)

        const accessToken = this.accessToken
        if (accessToken) {
            this.launchTokenExpirationTimeout(accessToken)
        } else {
            this.__tokenAboutToExpireTimeHandler()
            this.__tokenAboutToExpireTimeHandler = NOOP_VOID
        }

        return () => {
            this.__tokenAbouteToExpireMap.delete(eventId)
        }
    }

    private launchTokenExpirationTimeout(accessToken: string) {
        this.__tokenAboutToExpireTimeHandler()
        this.__tokenAboutToExpireTimeHandler = NOOP_VOID

        const info = decodeAccessToken(accessToken)
        const millisToExpire = DateFnsNs.differenceInMilliseconds(info.exp * 1000, Date.now())
        if (millisToExpire > 0) {
            const refreshToken = () => {
                for (const listener of this.__tokenAbouteToExpireMap.values()) {
                    try {
                        listener(this, accessToken)
                    } catch (err) {
                        LOG.error(`Processing listner ${listener}`, err)
                    }
                }
            }

            const oneHourInMillis = DateFnsNs.minutesToMilliseconds(60)

            if (millisToExpire < oneHourInMillis) {
                // Do it now
                refreshToken()
            }

            // And, refresh at fix rate of one hour
            this.__tokenAboutToExpireTimeHandler = scheduleAtFixRate(refreshToken, oneHourInMillis)
        }
    }
}

function decodeAccessToken(accessToken: string) {
    return jwtDecode(accessToken) as {
        username: string
        sub: number
        iat: number
        exp: number
    }
}

function scheduleAtFixRate(listner: VoidListenerType, interval: number) {
    const ctx = {
        handler: undefined as NodeJS.Timeout | undefined,
        stoped: false
    }

    const relauchListener: VoidListenerType = () => {
        if (ctx.stoped) {
            return
        }

        try {
            ctx.handler = undefined
            listner()
        } catch (err) {
            LOG.error('[scheduleAtFixRate]', err)
        } finally {
            ctx.handler = setTimeout(relauchListener, interval)
        }
    }

    ctx.handler = setTimeout(relauchListener, interval)

    return () => {
        ctx.stoped = true
        if (ctx.handler) {
            clearTimeout(ctx.handler)
            ctx.handler = undefined
        }
    }
}

function newLocalStorageBoolProperty(uid: string): StorageProperty<boolean> {
    return {
        set: (value: unknown) => {
            localStorage.setItem(uid, value ? 'true' : 'false')
        },
        get: () => {
            return localStorage.getItem(uid) === 'true'
        },
        clear: () => {
            localStorage.removeItem(uid)
        }
    }
}

function newStorageBoolProperty(uid: string): StorageProperty<boolean> {
    return {
        set: (value: unknown, rememberMe?: boolean) => {
            if (rememberMe) {
                localStorage.setItem(uid, value ? 'true' : 'false')
                sessionStorage.removeItem(uid)
            } else {
                sessionStorage.setItem(uid, value ? 'true' : 'false')
                localStorage.removeItem(uid)
            }
        },
        get: () => {
            return localStorage.getItem(uid) === 'true' || sessionStorage.getItem(uid) === 'true'
        },
        clear: () => {
            localStorage.removeItem(uid)
            sessionStorage.removeItem(uid)
        }
    }
}

function newStorageStringProperty(uid: string): StorageProperty<string> {
    return {
        set: (value: unknown, rememberMe?: boolean) => {
            if (rememberMe) {
                if (value) {
                    localStorage.setItem(uid, `${value}`)
                } else {
                    localStorage.removeItem(uid)
                }

                sessionStorage.removeItem(uid)
            } else {
                if (value) {
                    sessionStorage.setItem(uid, `${value}`)
                } else {
                    sessionStorage.removeItem(uid)
                }
                localStorage.removeItem(uid)
            }
        },
        get: () => {
            return localStorage.getItem(uid) || sessionStorage.getItem(uid) || ''
        },
        clear: () => {
            localStorage.removeItem(uid)
            sessionStorage.removeItem(uid)
        }
    }
}

export default AppStorage
