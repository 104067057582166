import DateFns from './date-fns'
import Lodash from './lodash'

const fns = DateFns.singleton
const lodash = Lodash.singleton

export const joinArrays = <T>(a: T[], b: T[], c?: T[]) => {
    if (a && b && c) {
        return [...a, ...b, ...c]
    }
    if (a && b) {
        return [...a, ...b]
    }

    if (a) return a
    if (b) return b
    if (c) return c

    return undefined
}

export const removeExtraSpacesBetweenWords = (() => {
    const splitBySpaces = /\s+/gm
    return function removeExtraSpacesBetweenWords(str: string) {
        return str.replace(splitBySpaces, ' ')
    }
})()

export function onlyNumbers(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (ch >= '0' && ch <= '9') {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export function onlyLetters(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (
            (ch >= 'A' && ch <= 'Z') ||
            (ch >= 'a' && ch <= 'z') ||
            (ch > 'z' && ch.toLowerCase() !== ch.toUpperCase())
        ) {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export function onlyLettersAndSpace(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (ch === ' ') {
            buffer.push(ch)
            continue
        }

        if (
            (ch >= 'A' && ch <= 'Z') ||
            (ch >= 'a' && ch <= 'z') ||
            (ch > 'z' && ch.toLowerCase() !== ch.toUpperCase())
        ) {
            buffer.push(ch)
            continue
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export function onlyNumbersAndLetters(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (
            (ch >= '0' && ch <= '9') ||
            (ch >= 'A' && ch <= 'Z') ||
            (ch >= 'a' && ch <= 'z') ||
            (ch > 'z' && ch.toLowerCase() !== ch.toUpperCase())
        ) {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export function onlyElasticSearchSafeChars(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (
            ch === '*' ||
            ch === ' ' ||
            (ch >= '0' && ch <= '9') ||
            (ch >= 'A' && ch <= 'Z') ||
            (ch >= 'a' && ch <= 'z') ||
            (ch > 'z' && ch.toLowerCase() !== ch.toUpperCase())
        ) {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export const onlyNumbersAndStar = function (s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if ((ch >= '0' && ch <= '9') || ch === '*') {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export function onlyHex(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if ((ch >= '0' && ch <= '9') || (ch >= 'A' && ch <= 'Z') || (ch >= 'a' && ch <= 'z')) {
            buffer.push(ch.toUpperCase())
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}

export function removeDiatrict(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function sortObjectByAttr<T>(pickStr: (o: T) => string) {
    return (a: T, b: T) => {
        if (a === b) return 0
        if (!a) return -1
        if (!b) return 1
        const sa = pickStr(a) ?? ''
        const sb = pickStr(b) ?? ''
        return String.prototype.localeCompare.call(sa, sb)
    }
}

function onlyWords(texts: string[] | undefined) {
    if (!texts) {
        return undefined
    }
    const words: string[] = []
    for (const text of texts) {
        if (!text) {
            continue
        }
        const parts: string[] = text.split(/\s/g)
        for (const word of parts) {
            if (word) {
                words.push(word)
            }
        }
    }
    return words
}

export function mergeTextsStar(...arrays: (string[] | undefined)[]) {
    const r = mergeTexts(...arrays)
    if (r) {
        r.map((v) => `${v}*`)
    }
    return r
}

export function mergeTexts(...arrays: (string[] | undefined)[]) {
    if (arrays.length === 0) {
        return undefined
    }

    const r: string[] = new Array<string>()
    for (const entry of arrays) {
        if (!entry || entry.length === 0) {
            continue
        }

        for (let subEntry of entry) {
            if (!subEntry || subEntry.length === 0) {
                continue
            }

            subEntry = onlyElasticSearchSafeChars(subEntry) ?? ''
            if (!subEntry || subEntry.length === 0) {
                continue
            }

            r.push(subEntry.toLocaleLowerCase())
        }
    }
    return r.length > 0 ? r : undefined
}

export function mergeCodes(...arrays: (string[] | undefined)[]) {
    if (arrays.length === 0) {
        return undefined
    }
    const words: string[] = []
    for (const entry of arrays) {
        if (!entry || entry.length === 0) {
            continue
        }

        const wordArray = onlyWords(entry)
        wordArray && words.push(...wordArray)
    }
    // Remove duplicados
    const codes = [...new Set<string>(words)]
    return codes.length > 0 ? codes : undefined
}

export function mergeNumCodes(...arrays: (string[] | undefined)[]) {
    const codes = mergeCodes(...arrays)
    if (!codes) {
        return undefined
    }
    const numCodes: string[] = []
    for (const codeVal of codes) {
        const numCode = onlyNumbers(codeVal)
        if (numCode) {
            numCodes.push(numCode)
        }
    }
    return numCodes.length > 0 ? numCodes : undefined
}

export function parseDate(value: unknown) {
    let dateValue: Date | undefined
    if (lodash().isNumber(value)) {
        dateValue = new Date(value)
    } else if (lodash().isString(value)) {
        dateValue = fns().parseISO(value)
    }
    return dateValue
}

export function formatIsoDateOnly(value: Date) {
    const isoDate = fns().formatISO(value)
    return isoDate.length >= 10 ? isoDate.substring(0, 10) : undefined
}

export function textWithStar(text: string | undefined | null) {
    if (!text) {
        return undefined
    }

    if ('*' === text) {
        return undefined
    }

    if (text[text.length - 1] === '*') {
        return text
    }

    return `${text}*`
}