const ptBr = {
    totalEmpresas: 'Empresas',
    totalEmpresasAtivas: 'Empresas Ativas',
    totalEmpresasNovasUltimoMes: 'Novas último mês',
    situacaoCadastral: 'Situacao cadastral',
    naturezaJuridica: 'Natureza juridica',
    atividadeEconomica: 'Detalhamento por atividade principal',
    abertasFechadas: 'Novas empresas',
    empresasNoGrupo: 'Total de empresas no grupo',
    faixaCapitalSocial: 'Total de empresas por faixa de capital social',
    faixaDeIdade: 'Total de empresas por faixa de idade',
    faixaDeFuncionarios: 'Total de empresas for faixa de funcionario',
    faixaDeFuncionariosNoGrupo: 'Total de empresas por faixa de func. no grupo',
    faixaDeFaturamentoAnual: 'Total de empresas por faixa de faturamento anual',
    faixaDeFaturamentoConsolidadoGrupo: 'Total de empresas por faixa de fat. consolidado (grupo)',
    empresasListagem: 'Detalhes das empresas'
}

export type TCMTexts = typeof ptBr

export function getTexts(lang: string) {
    if (lang === 'pt' || lang === 'pt-BR') {
        return ptBr
    }
    return ptBr
}
