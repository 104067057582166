import { Logger } from 'wdc-cube'

const LOG = Logger.get('ExecutionSupervisor')

// 2 Minutes
const TTL_IN_MS = 2 * 60 * 1000

export type TaskResponder<T> = {
    resolve: (value: T | PromiseLike<T>) => void
    reject: (reason?: unknown) => void
}

type TaskResponderEntry<T> = {
    responders: TaskResponder<T>[]
    expires: number
}

export class ExecutionSupervisor {
    private taskMap = new Map<string, TaskResponderEntry<unknown>>()

    runTask<T>(requestId: string, task: () => Promise<T>) {
        return new Promise<T>((resolve, reject) => {
            this.doRunTask(requestId, task, { resolve, reject }).catch(reject)
        })
    }

    private async doRunTask<T>(taskId: string, task: () => Promise<T>, responder: TaskResponder<T>) {
        const taskEntry = this.taskMap.get(taskId)
        if (taskEntry) {
            LOG.debug(`Task(${taskId}) is underway. Waiting for response...`)
            taskEntry.responders.push(responder as TaskResponder<unknown>)
            return
        }

        const newTaskEntry = {
            responders: [responder as TaskResponder<unknown>],
            expires: Date.now() + TTL_IN_MS
        }

        let timeoutId: NodeJS.Timeout | undefined

        let position = 0
        try {
            this.taskMap.set(taskId, newTaskEntry)

            const timeoutTask = new Promise<void>((___, reject) => {
                timeoutId = setTimeout(() => {
                    reject(new Error('Timeout'))
                }, TTL_IN_MS)
            })

            const realTask = task().finally(() => {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
            })

            const response = await Promise.race([realTask, timeoutTask])

            LOG.debug(`Responding task(${taskId})...`)
            for (; position < newTaskEntry.responders.length; position++) {
                const someResponder = newTaskEntry.responders[position]
                try {
                    someResponder.resolve(response)
                } catch (caught) {
                    someResponder.reject(caught)
                }
            }
        } catch (caught) {
            for (; position < newTaskEntry.responders.length; position++) {
                const someResponder = newTaskEntry.responders[position]
                someResponder.reject(caught)
            }
        } finally {
            this.taskMap.delete(taskId)
            LOG.debug(`Task(${taskId}) finalized`)
        }
    }
}

export default ExecutionSupervisor