import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartSituacaoCadastralScope } from './tcm_scopes'
import { TheConsumerMarketService, SituacaoCadastral, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasPorSituacaoCadastral')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasPorSituacaoCadastral extends Presenter<
    ChartSituacaoCadastralScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.situacaoCadastral)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.situacaoCadastral
        this.scope.onItemClicked = this.__onSituacaoCadastralClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.situacaoCadastral.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_por_situacao_cadastral: true,
                filter: filter
            })

            let baixadaCount = 0
            let ativaCount = 0
            let inaptaCount = 0
            let supensaCount = 0
            let nulaCount = 0
            for (const bucket of resp.total_por_situacao_cadastral?.buckets ?? []) {
                switch (bucket.key) {
                    case SituacaoCadastral.baixada:
                        baixadaCount += bucket.doc_count
                        break
                    case SituacaoCadastral.inapta:
                        inaptaCount += bucket.doc_count
                        break
                    case SituacaoCadastral.supensa:
                        supensaCount += bucket.doc_count
                        break
                    case SituacaoCadastral.ativa:
                        ativaCount += bucket.doc_count
                        break
                    case SituacaoCadastral.nula:
                        nulaCount += bucket.doc_count
                        break
                }
            }

            this.scope.baixada = baixadaCount
            this.scope.ativa = ativaCount
            this.scope.inapta = inaptaCount
            this.scope.supensa = supensaCount
            this.scope.nula = nulaCount

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private __markSelected() {
        this.scope.selectedMap.clear()
        const prop = this.__filterManager.situacaoCadastral
        for (const value of prop.values()) {
            this.scope.selectedMap.set(value, true)
        }
        this.scope.update()
    }

    private async __onSituacaoCadastralClicked(cdSituacaoCadadtral: SituacaoCadastral) {
        const prp = this.__filterManager.situacaoCadastral
        prp.add(cdSituacaoCadadtral)
        this.__markSelected()
        this.__parent.fetchData(prp.size ? 'totalPorSituacaoCadastral' : '')
    }
}
