import React from 'react'
import { styled } from '@mui/material/styles'

import Paper from '@mui/material/Paper'

const FlexPaper = styled(Paper)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 3,
    paddingRight: 3
})

export const Caption = styled('h3')({
    fontWeight: 'bold',
    fontSize: 13,
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
})

export type TcmCardProps = {
    className?: string
    caption?: React.ReactNode
    style?: React.CSSProperties
    children?: React.ReactNode
}

export default function TcmCard(props: TcmCardProps) {
    return (
        <FlexPaper className={props.className} style={props.style}>
            {props.caption && <Caption>{props.caption}</Caption>}
            {props.children}
        </FlexPaper>
    )
}
