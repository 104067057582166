import * as langUtils from 'lang-utils'

export const onlyNumbers = langUtils.onlyNumbers
/*
export function onlyNumbers(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (ch >= '0' && ch <= '9') {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}
    */

export const onlyLetters = langUtils.onlyLetters
/*
export function onlyLetters(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (
            (ch >= 'A' && ch <= 'Z') ||
            (ch >= 'a' && ch <= 'z') ||
            (ch > 'z' && ch.toLowerCase() !== ch.toUpperCase())
        ) {
            buffer.push(ch)
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}
    */

export const onlyHex = langUtils.onlyHex
/*
export function onlyHex(s: string | null | undefined) {
    if (!s) {
        return undefined
    }

    const buffer: string[] = []
    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if ((ch >= '0' && ch <= '9') || (ch >= 'A' && ch <= 'Z') || (ch >= 'a' && ch <= 'z')) {
            buffer.push(ch.toUpperCase())
        }
    }
    const result = buffer.join('')

    return result ? result : undefined
}
    */

export const splitValues = function() {
    const re = /\s|[,;]/gm
    return (s: string | null | undefined ) => {
        if(s) {
            return s.split(re)
        }
        return []
    }
}()