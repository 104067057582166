import { Places, ParamIds } from '../../../Constants'
import { TheMapKeys } from '../tm_keys'

export class TheMapMarkDetailKeys extends TheMapKeys {
    get place() {
        return Places.theMapMarkDetail
    }

    get requestId() {
        return this._intent.getParameterAsString(ParamIds.tmMarkCacheId)
    }

    set requestId(value: string | undefined) {
        this._intent.setParameter(ParamIds.tmMarkCacheId, value)
    }
}
