import React from 'react'
import lodash from 'lodash'
import * as echarts from 'echarts'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { classToFComponent } from 'src/utils/views'
import { EChartViewClass } from './helpers/base-echart'

import TcmCard from './helpers/tcm-card'
import { formatNumberToCompactShortForm, colors } from './helpers/tcm_view_utils'
import { ChartNaturezaJuridicaScope } from '../tcm_scopes'

const LOG = Logger.get('TCM-View-NaturezaJuridica')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        overflowY: 'visible',
        overflowX: 'hidden',
        '& .chart-holder': {}
    },
    chart: {}
})

type ChartNaturezaJuridicaProps = {
    className?: string
    style?: React.CSSProperties
    scope: ChartNaturezaJuridicaScope
}

class ChartNaturezaJuridicaViewClass extends EChartViewClass<ChartNaturezaJuridicaProps> {
    // :: Fields
    scope!: ChartNaturezaJuridicaScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: ChartNaturezaJuridicaProps) {
        this.scope = props.scope
        this.classes = useStyles().classes
        this.chartData = buildChartData(this.scope)
        this.loaded = this.scope.loaded
        super.onSyncState(props)
    }

    override render({ className, style, scope }: ChartNaturezaJuridicaProps): JSX.Element {
        return (
            <TcmCard caption={scope.caption} className={clsx(className)} style={style}>
                {super.render({ className: this.classes.view, scope })}
            </TcmCard>
        )
    }

    protected override onBindChartEvents(chart: echarts.ECharts) {
        chart.off('click', this.emitClickOnSeries)
        chart.on('click', this.emitClickOnSeries)
    }

    protected override onComputeSize(opts: echarts.ResizeOpts) {
        if (this.scope.dataset.length > 0) {
            if (this.scope.dataset.length > 10) {
                opts.height = Math.max(this.scope.dataset.length * 30, opts.height as number)
            }
        }
    }

    emitClickOnSeries = (evt: echarts.ECElementEvent) => {
        type DataItem = { id: number; value: number }
        let data = evt.data as DataItem
        if (!data && evt.dataIndex >= 0) {
            const serie = (this.chartData.series! as echarts.SeriesOption[])[0]
            if (serie && lodash.isArray(serie.data)) {
                const dataArray = serie.data as DataItem[]
                data = dataArray[evt.dataIndex]
            }
        }

        if (data) {
            this.scope.onItemClicked(data.id).catch(LOG.caught)
        }
    }
}

export default classToFComponent(ChartNaturezaJuridicaViewClass, React)

function buildChartData(scope: ChartNaturezaJuridicaScope): echarts.EChartsOption {
    const yAxisData: {
        value: string
        textStyle: {
            color: string | undefined
        }
    }[] = []

    const xAxisData: {
        id: number
        value: number
        itemStyle: {
            color: string | undefined
        }
    }[] = []

    for (const row of scope.dataset) {
        if (row.descricao.length > 35) {
            yAxisData.push({
                value: `${row.descricao.substring(0, 32)}...`,
                textStyle: {
                    color: row.selected ? colors.selected : undefined
                }
            })
        } else {
            yAxisData.push({
                value: row.descricao,
                textStyle: {
                    color: row.selected ? colors.selected : undefined
                }
            })
        }

        xAxisData.push({
            id: row.codigo,
            value: row.quantidade,
            itemStyle: {
                color: row.selected ? colors.selected : undefined
            }
        })
    }
    return {
        grid: { containLabel: true, top: 0, left: 10, bottom: 5 },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLabel: {
                show: false
            },
            splitLine: { show: false }
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                width: 170,
                fontSize: 8
            },
            data: yAxisData,
            triggerEvent: true
        },
        series: [
            {
                type: 'bar',
                label: {
                    show: true,
                    align: 'left',
                    position: 'right',
                    formatter: (params) => {
                        return formatNumberToCompactShortForm(params.value as number)
                    }
                },
                data: xAxisData
            }
        ]
    }
}
