import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ChartFaixasScope } from './tcm_scopes'
import { TheConsumerMarketService, NumberOperator, JunctionMode, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasFaixaFaturamentoAnual')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasFaixaFaturamentoAnual extends Presenter<
    ChartFaixasScope,
    TheConsumerMarketPresenter
> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.faixaDeFaturamentoAnual)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.faixaDeFaturamentoAnual
        this.scope.onItemClicked = this.__onFaixaDeFaturamentoAnual.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.faturamentoEstimado.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_por_faixa_de_faturamento_anual: true,
                filter: filter
            })

            const data = {
                qtd_0_to_360k: 0,
                qtd_360k_to_4_8Mi: 0,
                qtd_4_8Mi_to_300Mi: 0,
                qtd_300Mi_to_1Bi: 0,
                qtd_1Bi_to_INF: 0
            }

            for (const bucket of resp.total_de_empresas_por_faixa_de_faturamento_anual?.buckets ?? []) {
                switch (bucket.key as keyof typeof data) {
                    case 'qtd_0_to_360k':
                        data.qtd_0_to_360k += bucket.doc_count
                        break
                    case 'qtd_360k_to_4_8Mi':
                        data.qtd_360k_to_4_8Mi += bucket.doc_count
                        break
                    case 'qtd_4_8Mi_to_300Mi':
                        data.qtd_4_8Mi_to_300Mi += bucket.doc_count
                        break
                    case 'qtd_300Mi_to_1Bi':
                        data.qtd_300Mi_to_1Bi += bucket.doc_count
                        break
                    case 'qtd_1Bi_to_INF':
                        data.qtd_1Bi_to_INF += bucket.doc_count
                        break
                }
            }

            this.scope.dataset = [
                [data.qtd_1Bi_to_INF, '1Bi ou mais', false],
                [data.qtd_300Mi_to_1Bi, '300Mi a 1Bi', false],
                [data.qtd_4_8Mi_to_300Mi, '4,8Mi a 300Mi', false],
                [data.qtd_360k_to_4_8Mi, '360Mil a 4,8Mi', false],
                [data.qtd_0_to_360k, 'até 360mil', false]
            ]

            this.__markSelected()
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onFaixaDeFaturamentoAnual(rowIdx: number) {
        const faixaFaturamentoAnual = this.owner.filterPane.faixaFaturamentoAnual
        switch (rowIdx) {
            case 0:
                faixaFaturamentoAnual.toggleFrom(JunctionMode.OR, 1000000000, true)
                break
            case 1:
                faixaFaturamentoAnual.toggleRange(JunctionMode.OR, 300000000, 1000000000)
                break
            case 2:
                faixaFaturamentoAnual.toggleRange(JunctionMode.OR, 4800000, 300000000)
                break
            case 3:
                faixaFaturamentoAnual.toggleRange(JunctionMode.OR, 360000, 4800000)
                break
            case 4:
                faixaFaturamentoAnual.toggleTo(JunctionMode.OR, 360000, false)
                break
        }
        faixaFaturamentoAnual.publish()
        this.__markSelected()

        this.__parent.fetchData(faixaFaturamentoAnual.size ? 'totalPorFaixaDeFaturamentoAnual' : '')
    }

    private __markSelected() {
        const prop = this.__filterManager.faturamentoEstimado

        const markRow = (rowIdx: number, value: boolean) => {
            const row = this.scope.dataset[rowIdx]
            row && (row[2] = value)
        }

        markRow(0, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.gte}-1000000000`))
        markRow(1, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-300000000:1000000000`))
        markRow(2, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-4800000:300000000`))
        markRow(3, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.in_range}-360000:4800000`))
        markRow(4, prop.hasKey(`${JunctionMode.OR}-${NumberOperator.lt}-360000`))

        this.scope.update()
    }
}
