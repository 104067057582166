import { Place } from 'wdc-cube'

export const ParamIds = {
    compliance: 'compliance',
    resetPassword: 'rpwd',
    companyIds: 'companyIds',
    partnerIds: 'partnerId',
    personIds: 'personIds',
    tmRequestGroupId: 'tmgrid',
    tmMarkCacheId: 'tmmdci',
    taSelectedCard: 'tasc'
}

export const AttrIds = {
    parentSlot: 'parent-slot',
    dialogSlot: 'sialog-slot',

    subscriptionsDetail_item: '0001'
}

export const Places = {
    main: Place.ROOT,
    theConnection: Place.UNKNOWN,
    theMap: Place.UNKNOWN,
    theMapMarkDetail: Place.UNKNOWN,
    theActing: Place.UNKNOWN,
    theConsumerMarket: Place.UNKNOWN,
    rdStation: Place.UNKNOWN,
    restricted: Place.UNKNOWN
}
