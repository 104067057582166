import React from 'react'
import { styled } from '@mui/material/styles'
//import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ClickAwayListener }  from '@mui/base'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { StringFilterScope, StringParcelFilterScope, StringOperator, JunctionMode } from '../../tcm_scopes'

const LOG = Logger.get('StringFilterView')

const FilterView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 5
})

const FormBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f4f4f4',
    padding: '3px'
})

const FloatingIconButton = styled(IconButton)({
    marginBottom: 3
})

const DropdownBody = styled(Paper)({
    maxHeight: 300,
    overflowY: 'auto'
})

//const useStyles = makeStyles()({})

type StringFilterViewProps = {
    className?: string
    scope: StringFilterScope
}

class StringFilterViewClass implements FCClassContext<StringFilterViewProps> {
    // :: Fields
    scope!: StringFilterScope
    setAnchorEl!: React.Dispatch<React.SetStateAction<HTMLElement | null>>

    onSyncState({ scope }: StringFilterViewProps) {
        this.scope = scope
    }

    render({ className }: StringFilterViewProps) {
        const { scope } = this

        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
        this.setAnchorEl = setAnchorEl

        const menuAndOrOpened = !!anchorEl

        return (
            <FilterView className={className}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography>{scope.caption}</Typography>
                    <IconButton color="primary" size="small" onClick={this.handlePlusClick}>
                        <AddIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={menuAndOrOpened}
                        onClose={this.handleClose}
                        onClick={this.handleClose}
                    >
                        <MenuItem onClick={this.handleAddANDClicked}>AND</MenuItem>
                        <MenuItem onClick={this.handleAddORClicked}>OR</MenuItem>
                    </Menu>
                </Box>

                {!!scope.conjunction.length &&
                    scope.conjunction.map((parcelScope) => (
                        <StringParcelView key={parcelScope.uid} scope={parcelScope} />
                    ))}

                {!!scope.disjunction.length &&
                    scope.disjunction.map((parcelScope) => (
                        <StringParcelView key={parcelScope.uid} scope={parcelScope} />
                    ))}
            </FilterView>
        )
    } //

    readonly handlePlusClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setAnchorEl(event.currentTarget)
    }

    readonly handleClose = () => {
        this.setAnchorEl(null)
    }

    readonly handleAddANDClicked = () => {
        this.setAnchorEl(null)
        this.scope.onAddFilter(JunctionMode.AND).catch(LOG.caught)
    }

    readonly handleAddORClicked = () => {
        this.setAnchorEl(null)
        this.scope.onAddFilter(JunctionMode.OR).catch(LOG.caught)
    }
}

export const StringFilterView = classToFComponent(StringFilterViewClass, React)
export default StringFilterView

// :: StringParcelViewClass

type StringParcelViewProps = {
    className?: string
    scope: StringParcelFilterScope
}

class StringParcelViewClass implements FCClassContext<StringParcelViewProps> {
    scope!: StringParcelFilterScope

    valueField = new (class {
        scope!: StringParcelFilterScope
        elm?: HTMLDivElement | null
        focused = false

        readonly setRef = (el: HTMLDivElement) => {
            this.elm = el
        }

        readonly handleFocus = () => {
            this.focused = true
            this.scope.update()
        }

        readonly handleBlur = () => {
            this.focused = false
            this.scope.update()
        }

        readonly handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            this.scope.onValueChange(event.target.value).catch(LOG.caught)
        }

        readonly handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                this.scope.onEnterKeyPressed().catch(LOG.caught)
            }
        }

        readonly handleClearClick = () => {
            this.scope.onClear().catch(LOG.caught)
        }
    })()

    popper = new (class {
        scope!: StringParcelFilterScope
        open = false
    })()

    render({ className, scope }: StringParcelViewProps) {
        this.scope = scope
        this.valueField.scope = scope
        this.popper.scope = scope

        if (scope.valueVisible && this.valueField.focused) {
            const searchText = scope.value.trim()
            this.popper.open = scope.options.length > 0 && searchText.length > 0
        } else if(scope.options.length === 0) {
            this.popper.open = false
        }

        return (
            <FormBox className={className}>
                <FormControl variant="standard" size="small">
                    <InputLabel id={scope.uid}>
                        Critério ({scope.junctionMode === JunctionMode.AND ? 'AND' : 'OR'})
                    </InputLabel>
                    <Select
                        labelId={scope.uid}
                        defaultValue={`${StringOperator.contains}`}
                        value={`${scope.operator}`}
                        onChange={this.handleSelectChange}
                    >
                        <MenuItem value={StringOperator.contains}>Contém</MenuItem>
                        <MenuItem value={StringOperator.does_not_contain}>Não contém</MenuItem>
                        <MenuItem value={StringOperator.starts_with}>Começa com</MenuItem>
                        <MenuItem value={StringOperator.does_not_start_with}>Não começa com</MenuItem>
                        <MenuItem value={StringOperator.is}>É igual</MenuItem>
                        <MenuItem value={StringOperator.is_not}>Não é igual</MenuItem>
                        <MenuItem value={StringOperator.is_blank}>Está em branco</MenuItem>
                        <MenuItem value={StringOperator.is_not_blank}>Não está em branco</MenuItem>
                        <MenuItem value={StringOperator.is_empty}>Está vazio</MenuItem>
                        <MenuItem value={StringOperator.is_not_empty}>Não está vazio</MenuItem>
                    </Select>
                </FormControl>
                {scope.valueVisible && (
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                        <div>
                            <TextField
                                ref={this.valueField.setRef}
                                label="Valor"
                                variant="standard"
                                autoComplete="off"
                                value={scope.value}
                                InputProps={{
                                    endAdornment: (
                                        <FloatingIconButton
                                            color="primary"
                                            size="small"
                                            onClick={this.valueField.handleClearClick}
                                        >
                                            <DeleteIcon />
                                        </FloatingIconButton>
                                    )
                                }}
                                onChange={this.valueField.handleChange}
                                onKeyDown={this.valueField.handleKeyDown}
                                onFocus={this.valueField.handleFocus}
                                onBlur={this.valueField.handleBlur}
                            />

                            <Popper
                                open={this.popper.open}
                                anchorEl={this.valueField.elm}
                                placement="bottom-start"
                            >
                                <DropdownBody sx={{ width: this.valueField.elm ? this.valueField.elm.offsetWidth : 184 }}>
                                    <List dense={true}>
                                        {scope.options.map((label, i) => {
                                            return (
                                                <ListItem key={i} disablePadding>
                                                    <ListItemButton
                                                        onClick={() => this.handleListItemClick(label)}
                                                    >
                                                        <ListItemText primary={label} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </DropdownBody>
                            </Popper>
                        </div>
                    </ClickAwayListener>
                )}
            </FormBox>
        )
    }

    readonly handleSelectChange = (event: SelectChangeEvent) => {
        this.scope.onOperatorChange(+event.target.value)
    }

    readonly handleClickAway = () => {
        this.popper.open = false
        this.scope.update()
    }

    readonly handleListItemClick = (label: string): void => {
        this.scope.onOptionSelected(label).catch(LOG.caught)
    }
}

export const StringParcelView = classToFComponent(StringParcelViewClass, React)
