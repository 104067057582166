import React from 'react'
import lodash from 'lodash'
import * as echarts from 'echarts'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { classToFComponent } from 'src/utils/views'
import { EChartViewClass } from './base-echart'

import TcmCard from './tcm-card'
import { formatNumberToCompactShortForm, colors } from './tcm_view_utils'
import { ChartFaixasScope } from '../../tcm_scopes'

const LOG = Logger.get('TCM-FAIXAS')

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0
    },
    chart: {}
})

type ChartFaixasProps = {
    className?: string
    style?: React.CSSProperties
    scope: ChartFaixasScope
}

class ChartFaixasViewClass extends EChartViewClass<ChartFaixasProps> {
    // :: Fields
    scope!: ChartFaixasScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: ChartFaixasProps) {
        this.scope = props.scope
        this.classes = useStyles().classes
        this.chartData = buildChartData(this.scope)
        this.loaded = this.scope.loaded
        super.onSyncState(props)
    }

    override render(props: ChartFaixasProps): JSX.Element {
        const { scope } = this
        return (
            <TcmCard caption={scope.caption} className={props.className} style={props.style}>
                {super.render({ className: this.classes.view, scope: props.scope })}
            </TcmCard>
        )
    }

    protected override onComputeSize(opts: echarts.ResizeOpts) {
        if (this.scope.dataset.length > 0) {
            opts.height = Math.max(this.scope.dataset.length * 30, opts.height as number)
        }
    }

    protected override onBindChartEvents(chart: echarts.ECharts) {
        chart.off('click', this.emitClickOnSeries)
        chart.on('click', this.emitClickOnSeries)
    }

    emitClickOnSeries = (evt: echarts.ECElementEvent) => {
        if (!lodash.isNumber(evt.dataIndex)) {
            return
        }

        this.scope.onItemClicked(evt.dataIndex).catch(LOG.caught)
    }
}

export default classToFComponent(ChartFaixasViewClass, React)

function buildChartData(scope: ChartFaixasScope): echarts.EChartsOption {
    const yAxisData: {
        value: string
        textStyle: {
            color: string | undefined
        }
    }[] = []

    const xAxisData: {
        id: number
        value: number
        itemStyle: {
            color: string | undefined
        }
    }[] = []

    for (let rowIdx = 0; rowIdx < scope.dataset.length; rowIdx++) {
        const row = scope.dataset[rowIdx]
        const quantidade = row[0]
        const descricao = row[1]
        const selected = row[2]
        if (descricao.length > 35) {
            yAxisData.push({
                value: `${descricao.substring(0, 32)}...`,
                textStyle: {
                    color: selected ? colors.selected : undefined
                }
            })
        } else {
            yAxisData.push({
                value: descricao,
                textStyle: {
                    color: selected ? colors.selected : undefined
                }
            })
        }

        xAxisData.push({
            id: rowIdx,
            value: quantidade,
            itemStyle: {
                color: selected ? colors.selected : undefined
            }
        })
    }

    return {
        title: {
            show: false
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: { containLabel: true, top: 0, left: 10, bottom: 5 },
        xAxis: {
            name: '',
            axisLabel: {
                show: false
            },
            triggerEvent: true,
            splitLine: { show: false }
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                width: 200
            },
            triggerEvent: true,
            splitLine: { show: false },
            data: yAxisData
        },
        series: [
            {
                type: 'bar',
                label: {
                    show: true,
                    align: 'left',
                    position: 'right',
                    formatter: (params) => {
                        const amountVal = (params.value as [number, string])[0]
                        return formatNumberToCompactShortForm(amountVal)
                    }
                },
                data: xAxisData
            }
        ]
    }
}
