import { action, CubePresenter, Presenter, FlipIntent, Logger, NOOP_VOID } from 'wdc-cube'

import { MainPresenter } from 'src/main/Main.presenter'

import { 
    RDStationScope, 
    HeaderScope,
    type CompanyRow
} from './rd_scopes'
import RdStationKeys from './rd_keys'
import service from './rd_service'
import { lodash } from 'src/utils'

const LOG = Logger.get('RDStationPresenter')

export class RdStationPresenter extends CubePresenter<MainPresenter, RDStationScope> {
    // :: Fields

    private readonly __headerBar: Presenter<HeaderScope, RdStationPresenter>

    #stopSincronizacao: () => void = NOOP_VOID

    // :: Constructor

    public constructor(app: MainPresenter) {
        super(app, new RDStationScope())
        this.__headerBar = new Presenter(this, this.scope.header)
    }

    // :: Methods

    public override release() {
        this.app.releaseToolbar(this.__headerBar)
        super.release()
        LOG.debug('Finalized')
    }

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new RdStationKeys(this.app, intent)

        this.app.scope.toolbar = this.__headerBar.scope

        keys.parentSlot(this.scope)

        if (initialization) {
            await this.#initialize()
        }

        keys.parentSlot(this.scope)

        return keys.allow
    }

    async #initialize() {
        LOG.debug('initialize')
        this.scope.onKeyPressed = this.handleOnKeyPressed.bind(this)
        this.scope.onAppendCNPJs = this.handleAppendCNPJs.bind(this)
        this.scope.onRemoverMarcados = this.handleRemoverMarcados.bind(this)
        this.scope.onSincronizar = this.handleSincronizar.bind(this)
        this.scope.onPararSincronizacao = this.handlePararSincronizacao.bind(this)
    }

    @action()
    private async handleOnKeyPressed(key: string) {
        if (key === 'Enter') {
            return this.handleAppendCNPJs()
        }
    }

    @action()
    private async handleAppendCNPJs() {
        const selectedMap = new Map<string, boolean>()
        this.scope.companyRows.forEach((row) => selectedMap.set(row.id, true))

        const cnpjs = (() => {
            // Nao inclui registros jah presentes na grade
            const resultMap = new Map<string, boolean>()
            const reqCnpjs = textToCnpjLikeText(this.scope.getCnpjsInText()).match(/\d+/dgm) ?? []
            reqCnpjs.filter((cnpj) => {
                if (cnpj.length < 14) {
                    cnpj = lodash.padStart(cnpj, 14, '0')
                }

                if (cnpj.length === 14 && !selectedMap.has(cnpj)) {
                    resultMap.set(cnpj, true)
                }
            })
            return [...resultMap.keys()]
        })()

        this.scope.setCnpjsInText('')
        if (cnpjs.length > 0) {
            const newCompanyRows = [...this.scope.companyRows]
            const companyRows = (await service.fetchCompanyInfos(cnpjs)).rows
            for (const companyRow of companyRows) {
                newCompanyRows.push(companyRow)
            }
            this.scope.companyRows = newCompanyRows
        }
    }

    @action()
    private async handleRemoverMarcados() {
        this.removeCnpjs(this.scope.cnpjsSelected)
    }

    @action()
    private async handlePararSincronizacao() {
        this.#stopSincronizacao()
    }

    @action()
    private async handleSincronizar() {
        this.#stopSincronizacao()
        this.scope.sinchronazing = true
        try {
            const companyRows = [] as CompanyRow[]
            if (this.scope.cnpjsSelected.length > 0) {
                const cnpjSelectedMap = new Map<string, boolean>()
                this.scope.cnpjsSelected.forEach((cnpj) => cnpjSelectedMap.set(cnpj, true))
                for (const companyRow of this.scope.companyRows) {
                    if (!cnpjSelectedMap.has(companyRow.id)) {
                        continue
                    }
                    companyRows.push(companyRow)
                }
            } else {
                this.scope.cnpjsSelected = this.scope.companyRows.map((row) => row.id)
                companyRows.push(...this.scope.companyRows)
            }

            let i = 0
            while (i < companyRows.length) {
                this.scope.progress = (i / companyRows.length) * 100

                let cnpjs = [] as string[]
                for (const ilast = Math.min(i + 1, companyRows.length); i < ilast; i++) {
                    cnpjs.push(companyRows[i].id)
                }

                this.scope.progressBuffer = this.scope.progress + (cnpjs.length / companyRows.length) * 100

                const syncResp = await Promise.race([
                    service.synchronize(cnpjs),
                    new Promise<boolean>((resolve) => {
                        this.#stopSincronizacao = () => {
                            this.#stopSincronizacao = NOOP_VOID
                            resolve(false)
                        }
                        return false
                    })
                ])

                if (lodash.isBoolean(syncResp)) {
                    return
                }

                if (syncResp.notfound.length > 0) {
                    // não remover os cnpjs não localizados
                    const notFoundMap = new Map<string, boolean>()
                    syncResp.notfound.forEach((item) => notFoundMap.set(item, false))
                    const newCnpjs = [] as string[]
                    for (const cnpj of cnpjs) {
                        if (notFoundMap.has(cnpj)) {
                            continue
                        }
                        newCnpjs.push(cnpj)
                    }
                    cnpjs = newCnpjs
                }

                if (cnpjs.length > 0) {
                    this.removeCnpjs(cnpjs)
                }
            }
        } catch (caught) {
            LOG.error('handleSincronizar', caught)
            throw caught
        } finally {
            this.scope.sinchronazing = false
            this.#stopSincronizacao = NOOP_VOID
        }

        if (this.scope.cnpjsSelected.length > 0) {
            this.app.alert(
                'warning',
                'Aviso',
                'As empresas marcadas que permaneceram na listagem não foram localizadas'
            )
        }
    }

    private removeCnpjs(cnpjs: string[]) {
        const cnpjRemovalMap = new Map<string, boolean>()
        cnpjs.forEach((cnpj) => cnpjRemovalMap.set(cnpj, true))

        const cnpjSelectedMap = new Map<string, boolean>()
        this.scope.cnpjsSelected.forEach((cnpj) => cnpjSelectedMap.set(cnpj, true))

        const newCompanyRows = [] as CompanyRow[]
        for (const companyRow of this.scope.companyRows) {
            if (cnpjRemovalMap.has(companyRow.id)) {
                cnpjSelectedMap.delete(companyRow.id)
                continue
            }
            newCompanyRows.push(companyRow)
        }
        this.scope.companyRows = newCompanyRows
        this.scope.cnpjsSelected = [...cnpjSelectedMap.keys()]
    }
}

export default RdStationPresenter

function textToCnpjLikeText(s: string) {
    const buffer = [] as string[]

    for (let i = 0; i < s.length; i++) {
        const ch = s.charAt(i)
        if (ch >= '0' && ch <= '9') {
            buffer.push(ch)
        } else if (ch === '.' || ch === '/' || ch === '-') {
            continue
        } else {
            buffer.push(' ')
        }
    }

    return buffer.join('')
}
