import { type Locale } from 'date-fns'
type DateFnsType = typeof import('date-fns')

let impl: DateFnsType = {} as unknown as DateFnsType
let localePtBR = {} as unknown as Locale

import('date-fns').then((fns) => {
    impl = fns
})

import('date-fns/locale/pt-BR').then((ptBR) => {
    localePtBR = ptBR.default as unknown as Locale
})

export const DateFns = {
    singleton() {
        return {
            ...impl,
            locales: {
                'pt-BR': localePtBR
            }
        }
    }
}

export default DateFns
