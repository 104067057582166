import React from 'react'
import clsx from 'clsx'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { makeStyles } from 'tss-react/mui'
import { ScoreCardScope } from '../ta_scopes'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        minHeight: 85
        //minWidth: 100
    },
    nowrap: {
        whiteSpace: 'nowrap'
    }
})

export type ScoreViewProps = {
    className?: string
    scope: ScoreCardScope
}

class ScoreViewClass implements FCClassContext<ScoreViewProps> {
    // :: Methods

    render({ className, scope }: ScoreViewProps) {
        const classes = useStyles().classes

        return (
            <Paper className={clsx(classes.view, className)}>
                <Typography className={classes.nowrap} variant="subtitle1" gutterBottom>
                    {scope.label}
                </Typography>
                <Typography className={classes.nowrap} variant="h6">
                    {scope.value} {scope.unit}
                </Typography>
            </Paper>
        )
    }
}
export const ScoreView = classToFComponent(ScoreViewClass, React)
export default ScoreView
