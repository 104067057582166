import { ViewFactory } from 'wdc-cube-react'

import { SearchBoxView } from './sb_view'
import { SearchBoxScope } from '../sb_scopes'

export { SearchBoxView, SearchBoxScope }

export function registerViews(rv = ViewFactory.register) {
    rv(SearchBoxScope, SearchBoxView)
}
