import Hash from 'hash.js'
import { HmacDRBG } from 'hmac-drbg'
import esaLong from 'rsa-long'
import { AppStorage, ServiceClient } from 'src/utils'
import { v4 as uuid } from 'uuid'

// KeyGen: https://cryptotools.net/rsagen

const appStorage = AppStorage.singleton()
const serviceClient = ServiceClient.singleton()
const rememberMeProp = (function () {
    const key = 'WSEGpG'
    return new (class {
        get value(): boolean {
            return 'true' === sessionStorage.getItem(key)
        }

        set value(v: boolean) {
            if (v) {
                sessionStorage.setItem(key, 'true')
            } else {
                sessionStorage.removeItem(key)
            }
        }
    })()
})()

export type SignInResult = {
    accessToken: string
    rdStation?: object
    theMap?: object
    theActing?: {
        print: boolean
    }
    theConsumerMarket?: object
    theConnection?: object
}

// uso criptografado
const sipPubKey =
    '-----BEGIN PUBLIC KEY-----' +
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCS2ASsiygAYg9vW5/w7FJEMr6u' +
    'oQIAOm2PkmR3pkZoqKVq+nEXgMz7UyTbbpxvN1qx9N09U8hvzmuDFNQTi+D6suZJ' +
    'cJS4bVg8bAMsaGmbQG7lIAJLCpyG1wHCQ7F0QRnN2Y2tt6tR66wAQy4J/bhOsOX6' +
    'ryAhjd8hEEeDYL0uwQIDAQAB' +
    '-----END PUBLIC KEY-----'

export class MainService {
    private static INSTANCE = new MainService()

    public static readonly singleton = () => MainService.INSTANCE

    async reloadAuthorizations() {
        try {
            await this.refreshAccessToken(rememberMeProp.value)
            return true
        } catch (e) {
            console.warn('It was not possible to refresh autorizations', e)
            return false
        }
    }

    async signIn(username: string, password: string, rememberMe: boolean) {
        const entropy = uuid()
        const nonce = uuid()

        const drbg = new HmacDRBG({
            hash: Hash.sha256,
            entropy: entropy,
            entropyEnc: 'utf8',
            nonce: nonce,
            nonceEnc: 'utf8',
            pers: password,
            persEnc: 'utf8'
        })

        const encryptedPassword = esaLong.encryptLong(
            sipPubKey,
            JSON.stringify({
                entropy,
                nonce,
                value: drbg.generate(32, 'hex') as string
            })
        )

        const body = await serviceClient.post<SignInResult>('/api/auth/login', {
            username,
            password: encryptedPassword
        })

        rememberMeProp.value = rememberMe
        appStorage.setUserName(username, rememberMe)

        this.updateAccessToken(body, rememberMe)
    }

    async refreshAccessToken(rememberMe: boolean) {
        const body = await serviceClient.authPost<SignInResult>('/api/auth/refresh')
        this.updateAccessToken(body, rememberMe)
    }

    private updateAccessToken(body: SignInResult, rememberMe: boolean) {
        appStorage.setAccessToken(
            {
                token: body.accessToken,
                rdStation: body.rdStation,
                theMap: body.theMap,
                theActing: body.theActing,
                theConsumerMarket: body.theConsumerMarket,
                theConnection: body.theConnection
            },
            rememberMe
        )
    }

    async requestPasswordReset(email: string) {
        await serviceClient.post<SignInResult>('/api/auth/reset/password', { email })
    }
}
