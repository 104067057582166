import type { PagingDTO } from 'src/components/autocompletefield'

import lodash from 'src/utils/lodash'
import { NOOP_VOID } from 'wdc-cube'
import { ServiceClient } from 'src/utils'
import { formatCNPJ } from 'src/utils/formatter-utils'
import { formatCPFOrCNPJ } from 'src/utils/formatter-utils'
import ExecutionSupervisor from 'src/utils/execution-supervisor'
import objectHash from 'object-hash'

import { type TextualSearchRequest, type TextualSearchFilters, type EntityType, EntityTypeID } from './sb_types'

export { type TextualSearchRequest, type TextualSearchFilters}
export type TextualSearchResponse = PagingDTO<RawSearchOption>

export type RawSearchOption = {
    type: number
    id: string
    name: string
    extra?: unknown
}

export type CompanySearchOption = Omit<RawSearchOption, 'extra'>

export type ESaudeSearchOption = Omit<RawSearchOption, 'extra'> & {
    extra: {
        cnpj?: string
    }
}

export type PartnerSearchOption = Omit<RawSearchOption, 'extra'> & {
    extra: {
        cpfcnpj?: string
    }
}

export type PersonSearchOption = Omit<RawSearchOption, 'extra'> & {
    extra: {
        cpf?: string
    }
}

type AdaptRawSearchOptionCallback = (raw: RawSearchOption) => RawSearchOption

const EXECUTOR_SUPERVISOR = new ExecutionSupervisor()
const SERVICE_CLIENT = ServiceClient.singleton()
const ADAPT_RAW_SEARCH_OPTION_CALLBACK_MAP = new Map<number, AdaptRawSearchOptionCallback>()

let lazyInit = () => {
    initAdaptRawSearchOptionCallbackMap()
    lazyInit = NOOP_VOID
}

export class FilterSearchService {
    private static INSTANCE = new FilterSearchService()

    static singleton() {
        lazyInit()
        return FilterSearchService.INSTANCE
    }

    protected _fetchRequestOrder = 0

    async search(request: TextualSearchRequest, fetch?: (request: TextualSearchRequest) => Promise<TextualSearchResponse>) {
        const response: PagingDTO<RawSearchOption> = {
            meta: {
                currentPage: 0,
                itemsPerPage: request.itensPerPage ?? 100,
                totalItems: 0,
                totalPages: 0
            },
            entries: []
        }

        let minChars = 3
        if (request.crmUf) {
            minChars = 2
        }

        if (request.text !== '*' && (!request.text || request.text.length < minChars)) {
            return response
        }

        const requestHash = objectHash(request)
        return EXECUTOR_SUPERVISOR.runTask(requestHash, prv_textualSearch.bind(this, request, response, fetch))
    }

    async describe(request: TextualSearchRequest, labels?: string[]) {
        if(lodash.isEmpty(request.filters)) {
            return {}
        }

        request = await prv_fetch_filter_texts.call(this, request)
        if (labels) {
            prv_buildLabels.call(this, request, labels)
        }
        return request
    }
}

async function prv_fetch_filter_texts(this: FilterSearchService, req: TextualSearchRequest) {
    let endpoint = '/api/map/describe'
    if (req.mode === 'ACTING') {
        endpoint = '/api/acting-es/describe'
    }

    return EXECUTOR_SUPERVISOR.runTask(`describe:${objectHash(req)}`, async () => {
        type DescribeResult = {
            result: TextualSearchRequest
            duration: number
        }
        const data = await SERVICE_CLIENT.authPost<DescribeResult>(endpoint, req)
        return data.result
    })
}

function prv_buildLabels(request: TextualSearchRequest, labels: string[]) {
    const ff = request.filters
    if (ff) {
        const concat = (items: string[]) => items.join(', ')

        if (ff.texts) {
            labels.push(`Livre: ${concat(ff.texts)}`)
        }

        let label = 'Empresa'
        if (ff.empresaTexts || ff.cnpjTexts) {
            const empresaText = [...(ff.empresaTexts ?? []), ...(ff.cnpjTexts ?? [])]
            labels.push(`${label}: ${concat(empresaText)}`)
        }
        if (ff.empEnderecoTexts) {
            labels.push(`${label}-End.: ${concat(ff.empEnderecoTexts)}`)
        }
        if (ff.empCepTexts) {
            labels.push(`${label}-CEP: ${concat(ff.empCepTexts)}`)
        }
        if (ff.empLogradouroTexts) {
            labels.push(`${label}-Rua: ${concat(ff.empLogradouroTexts)}`)
        }
        if (ff.empBairroTexts) {
            labels.push(`${label}-Bairro: ${concat(ff.empBairroTexts)}`)
        }
        if (ff.empMunicipioTexts) {
            labels.push(`${label}-Cidade: ${concat(ff.empMunicipioTexts)}`)
        }

        if (ff.socioTexts || ff.cpfTexts) {
            const socioTexts = [...(ff.socioTexts ?? []), ...(ff.cpfTexts ?? [])]
            labels.push(`Sócio: ${concat(socioTexts)}`)
        }

        label = 'Pessoa'
        if (ff.pessoaTexts) {
            labels.push(`${label}: ${concat(ff.pessoaTexts)}`)
        }
        if (ff.pesEnderecoTexts) {
            labels.push(`${label}-End.: ${concat(ff.pesEnderecoTexts)}`)
        }
        if (ff.pesCepTexts) {
            labels.push(`${label}-CEP: ${concat(ff.pesCepTexts)}`)
        }
        if (ff.pesLogradouroTexts) {
            labels.push(`${label}-Rua: ${concat(ff.pesLogradouroTexts)}`)
        }
        if (ff.pesBairroTexts) {
            labels.push(`${label}-Bairro: ${concat(ff.pesBairroTexts)}`)
        }
        if (ff.pesMunicipioTexts) {
            labels.push(`${label}-Cidade: ${concat(ff.pesMunicipioTexts)}`)
        }
        if (ff.pesCboTexts) {
            labels.push(`${label}-CBO: ${concat(ff.pesCboTexts)}`)
        }
        if (ff.espMedTexts) {
            labels.push(`${label}-Especialidade: ${concat(ff.espMedTexts)}`)
        }

        label = 'Estab.Saúde'
        if (ff.estabelecimentoSaudeTexts) {
            labels.push(`${label}: ${concat(ff.estabelecimentoSaudeTexts)}`)
        }
        if (ff.cnesEnderecoTexts) {
            labels.push(`${label}-End.: ${concat(ff.cnesEnderecoTexts)}`)
        }
        if (ff.cnesCepTexts) {
            labels.push(`${label}-CEP.: ${concat(ff.cnesCepTexts)}`)
        }
        if (ff.cnesLogradouroTexts) {
            labels.push(`${label}-Rua.: ${concat(ff.cnesLogradouroTexts)}`)
        }
        if (ff.cnesBairroTexts) {
            labels.push(`${label}-Bairro.: ${concat(ff.cnesBairroTexts)}`)
        }
        if (ff.cnesMunicipioTexts) {
            labels.push(`${label}-Cidade.: ${concat(ff.cnesMunicipioTexts)}`)
        }

        if (ff.cnaeTexts) {
            labels.push(`CNAE: ${concat(ff.cnaeTexts)}`)
        }
        if (ff.cidTexts) {
            labels.push(`CID10: ${concat(ff.cidTexts)}`)
        }
        if (ff.atoTexts) {
            labels.push(`Ato: ${concat(ff.atoTexts)}`)
        }
        if (ff.atoCboTexts) {
            labels.push(`CBO de Ato: ${concat(ff.atoCboTexts)}`)
        }
    }

    if (labels.length === 0) {
        if (request.empresa || request.cnpj) {
            labels.push('Empresas (sem filtro específico)')
        }

        if (request.pesMunicipio || request.pessoaCpf) {
            labels.push('Pessoas (sem filtro específico)')
        }

        if (request.estabelecimentoSaude) {
            labels.push('Estabelecimento de saúde (sem filtro específico)')
        }
    }
}

async function prv_textualSearch(
    this: FilterSearchService,
    request: TextualSearchRequest,
    response: PagingDTO<RawSearchOption>,
    fetch?: (request: TextualSearchRequest) => Promise<TextualSearchResponse>
) {
    const requestOrder = ++this._fetchRequestOrder

    let data: TextualSearchResponse
    if (fetch) {
        data = await fetch(request)
    } else {
        let endPoint = '/api/graph/company-to-companies/search'
        if (request.mode === 'ACTING') {
            delete request.mode
            endPoint = '/api/acting-es/search'
        }
        data = await SERVICE_CLIENT.authPost<TextualSearchResponse>(endPoint, request)
    }

    if (this._fetchRequestOrder !== requestOrder) {
        return response
    }

    data.entries.sort((a, b) => {
        return lodash.StringCICompare(a.name, b.name)
    })

    response.meta = data.meta

    for (const entry of data.entries) {
        const cb = ADAPT_RAW_SEARCH_OPTION_CALLBACK_MAP.get(entry.type)
        if (cb) {
            const newEntry = cb(entry)
            //console.debug(newEntry)
            response.entries.push(newEntry)
        }
    }

    return response
}

// :: Formatters

function formatCID(s: string) {
    return s.length > 2 ? `${s.substring(0, 3)}.${s.substring(3)}` : s
}

// :: Initializers

function initAdaptRawSearchOptionCallbackMap() {
    const tp = EntityTypeID
    const push = (codeTypes: EntityType[], cb: AdaptRawSearchOptionCallback) => {
        for (const codeType of codeTypes) {
            ADAPT_RAW_SEARCH_OPTION_CALLBACK_MAP.set(codeType.code, cb)
        }
    }

    const defaultAdapter: AdaptRawSearchOptionCallback = (node) => node

    push([tp.company], (node) => ({
        id: node.id,
        type: node.type,
        name: `${node.name} (${formatCNPJ(node.id)})`
    }))

    push([tp.cnesCompany], (node) => ({
        id: node.id,
        type: node.type,
        name: `${node.name} (${node.id})`,
        extra: node.extra
    }))

    push([tp.companyPartner], (entry) => {
        const node = entry as PartnerSearchOption

        let cpfOrCnpjFormatted = ''
        if (node.extra) {
            cpfOrCnpjFormatted = ` ${formatCPFOrCNPJ(node.extra.cpfcnpj)}`
        }
        return {
            id: node.id,
            type: node.type,
            name: `${node.name}${cpfOrCnpjFormatted}`,
            extra: node.extra
        }
    })

    push([tp.person], (entry) => {
        const node = entry as PersonSearchOption

        return {
            id: node.id,
            type: node.type,
            name: `${node.name}`,
            extra: node.extra
        }
    })

    push([tp.CNAE], (node) => ({
        id: node.id,
        type: node.type,
        name: `${node.extra} - ${node.name}`
    }))

    push([tp.CBO], (node) => ({
        id: node.id,
        type: node.type,
        name: `${node.extra} - ${node.name}`
    }))

    push([tp.CID], (node) => ({
        id: node.id,
        type: node.type,
        name: `${formatCID(node.id)} - ${node.name}`
    }))

    push([tp.ATO], (node) => ({
        id: node.id,
        type: node.type,
        name: `[${node.id}] - ${node.name}`
    }))

    push([tp.ATO_CBO], (node) => ({
        id: node.id,
        type: node.type,
        name: `${node.extra} - ${node.name}`
    }))

    push([tp.ESPMED], (node) => ({
        id: node.id,
        type: node.type,
        name: node.name
    }))

    push([tp.MED_CRM], (node) => ({
        id: node.id,
        type: node.type,
        name: node.name
    }))

    push([tp.MED_CRM_UF], (node) => ({
        id: node.id,
        type: node.type,
        name: node.name
    }))

    push([tp.companyAddress, tp.cnesAddress, tp.personAddress], defaultAdapter)
    push([tp.companyZip, tp.cnesZip, tp.personZip], defaultAdapter)
    push([tp.companyStreet, tp.cnesStreet, tp.personStreet], defaultAdapter)
    push([tp.companyNeighborhood, tp.cnesNeighborhood, tp.personNeighborhood], defaultAdapter)
    push([tp.companyCounty, tp.cnesCounty, tp.personCounty], defaultAdapter)

    push([tp.empOps], defaultAdapter)
    push([tp.prfOps], defaultAdapter)
}

export default FilterSearchService.singleton()
