import * as React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

import { HeaderScope } from '../rd_scopes'

const useStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        alignSelf: 'stretch',
        gap: 5,
        marginLeft: 5
    },
    subheader: {
        fontSize: 12
    }
}))

const TitleDivider = styled(Divider)({
    borderRightWidth: 'medium',
    borderColor: 'cornflowerblue',
    marginRight: 5
})

type HeaderProps = {
    className?: string
    scope: HeaderScope
}

class HeaderViewClass implements FCClassContext<HeaderProps> {
    // :: Fields

    scope!: HeaderScope

    // :: Emissors

    // <EMPTY>

    // :: Methods

    onSyncState({ scope }: HeaderProps) {
        this.scope = scope
    }

    render(props: HeaderProps): JSX.Element {
        const classes = useStyles().classes

        return (
            <div className={clsx(classes.view, props.className)}>
                <TitleDivider orientation="vertical" variant="middle" flexItem />
                <Stack justifyContent="center">
                    <Typography variant="h6">
                        RD Station :: Integração
                    </Typography>
                </Stack>
            </div>
        )
    }
}
export const HeaderView = classToFComponent(HeaderViewClass, React)
export default HeaderView
