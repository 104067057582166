import { Presenter, IPresenterOwner } from 'wdc-cube'
import { IFilterScope } from '../tcm_scopes'

export abstract class FilterPresenter<S extends IFilterScope, OWNER extends IPresenterOwner = IPresenterOwner> extends Presenter<S, OWNER> {

    abstract clear(): void
    abstract publish(): void

    protected abstract bindEvents(): Promise<void>

    async prepare(order: number, label: string) {
        this.scope.order = order
        this.scope.visible = true
        this.scope.caption = label
        await this.bindEvents()
    }

}