import { type FetchRequest, type FetchResponse } from './ta_types'
import * as lang from 'lang-utils'
import { Logger } from 'wdc-cube'
import lodash from 'lodash'
import * as DateFnsNs from 'date-fns'
import objectHash from 'object-hash'
import { ServiceClient } from 'src/utils'
import ExecutionSupervisor from 'src/utils/execution-supervisor'
import { type TextualSearchRequest, type TextualSearchResponse } from 'src/components/searchbox/sb_service'

const LOG = Logger.get('ta-service')

export type EnderecoBean = {
    logradouro?: string
    numero?: string
    complemento?: string
    bairro?: string
    uf?: string
    cidade?: string
    cep?: string
    pais?: string
    longitude?: number
    latitude?: number
    text?: string
}

export type MedicoBean = {
    sq: number
    id: string
    nome: string
    email?: string
    celular?: string
    telefones?: string
    especialidades?: string
    socio?: {
        qualificacao?: string
    }
    crm: {
        codigo: string
        codSituacao?: string
        dscSituacao?: string
        inscricao?: string
        dataInscricao?: string
        dataPrimeiraInscricao?: string
    }
    outrosCRMs?: string
    endereco?: EnderecoBean
}

export type EmpresaBean = {
    sq: number
    id: string
    nome: string
    cnpj?: string
    cnes?: string
    cnae?: {
        id: string
        descricao: string
    }
    endereco?: EnderecoBean
    qtdFuncionarios: number
    totFaturamento: number
}

export type ScoreBean = {
    professionals: number
    companies: number
    revenue: number
    healthCo: number
}

const executorSupervisor = new ExecutionSupervisor()
const serviceClient = ServiceClient.singleton()

export class TheActingService {
    static #INSTANCE = new TheActingService()

    static singleton() {
        return TheActingService.#INSTANCE
    }

    async download(request: FetchRequest) {
        const response = await serviceClient.authRawPost('/api/acting-es/export', request)
        if (response.status !== 201 && response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}`)
        }

        return response.blob()
    }

    async fetch(request: FetchRequest): Promise<FetchResponse> {
        const requestHash = `fetch:${objectHash(request)}`
        return executorSupervisor.runTask(requestHash, async () => {
            const result: FetchResponse = {
                scores: {
                    companies: 0,
                    healthCo: 0,
                    professionals: 0,
                    revenue: 0
                },
                companyData: {
                    rows: [],
                    total: 0,
                    limit: request.company?.limit ?? 100,
                    offset: request.company?.offset ?? 0
                },
                professionalData: {
                    rows: [],
                    total: 0,
                    limit: request.professional?.limit ?? 100,
                    offset: request.professional?.offset ?? 0
                }
            }

            type RawResponse = {
                result: {
                    scores: ScoreBean
                    companyData: {
                        rows: EmpresaBean[]
                        total: number
                        offset: number
                        limit: number
                    }
                    professionalData: {
                        rows: MedicoBean[]
                        total: number
                        offset: number
                        limit: number
                    }
                }
                duration: number
            }
            //http://localhost:3000/api/acting-es/query
            const respose = await serviceClient.authPost<RawResponse>('/api/acting-es/query', request)

            if (!respose.result) {
                return result
            }

            result.scores = respose.result.scores

            if (respose.result.companyData) {
                result.companyData.limit = respose.result.companyData.limit
                result.companyData.offset = respose.result.companyData.offset
                result.companyData.total = respose.result.companyData.total

                respose.result.companyData.rows.sort((a, b) => a.sq - b.sq)

                for (const bean of respose.result.companyData.rows) {
                    result.companyData.rows.push({
                        id: bean.id,
                        cnpj: bean.cnpj ?? '',
                        cnes: bean.cnes ?? '',
                        nome: bean.nome,
                        cnae: bean.cnae?.id ?? '',
                        descricaoCnae: bean.cnae?.descricao ?? '',
                        nomeMunicipio: bean.endereco?.cidade ?? '',
                        siglaUF: bean.endereco?.uf ?? '',
                        qtdeFuncionarios: bean.qtdFuncionarios ?? 0,
                        coordenadas: buildCoordenadas(bean.endereco)
                    })
                }
            }

            if (respose.result.professionalData) {
                result.professionalData.limit = respose.result.professionalData.limit
                result.professionalData.offset = respose.result.professionalData.offset
                result.professionalData.total = respose.result.professionalData.total

                respose.result.professionalData.rows.sort((a, b) => a.sq - b.sq)

                for (const bean of respose.result.professionalData.rows) {
                    result.professionalData.rows.push({
                        id: bean.id,
                        nome: bean.nome,
                        crm: bean.crm?.codigo,
                        dataInscricao: parseISODate(bean.crm?.dataInscricao),
                        situacao: bean.crm?.dscSituacao ?? '',
                        especialidade: bean.especialidades,
                        qualificacaoSocio: bean.socio?.qualificacao ?? '',
                        inscricao: bean.crm?.inscricao ?? '',
                        dataPrimeiraInscricaoUF: parseISODate(bean.crm?.dataPrimeiraInscricao),
                        outrosCRMs: bean.outrosCRMs,
                        endereco: buildEndereco(bean.endereco),
                        telefone: bean.telefones ?? '',
                        email: bean.email ?? '',
                        celular: bean.celular ?? '',
                        coordenadas: buildCoordenadas(bean.endereco)
                    })
                }
            }

            return result
        })
    }

    async filterSearch(req: TextualSearchRequest): Promise<TextualSearchResponse> {
        delete req.mode
        const endPoint = '/api/acting-es/search'
        return await serviceClient.authPost<TextualSearchResponse>(endPoint, req)
    }
}

function parseISODate(iso8601Value: string | null | undefined) {
    if (!iso8601Value) {
        return undefined
    }
    return parseISOSafely(iso8601Value, new Date())
}

function buildEndereco(local: EnderecoBean | undefined | null) {
    if (!local) {
        return ''
    }

    if (local.text) {
        return local.text
    }

    const endereco: string[] = []
    local.logradouro && endereco.push(local.logradouro)
    local.numero && endereco.push(`, ${local.numero}`)
    local.complemento && endereco.push(`, ${local.complemento}`)
    local.bairro && endereco.push(`, ${local.bairro}`)

    if (local.cidade && local.uf) {
        endereco.push(`, ${local.cidade}/${local.uf}`)
    } else {
        local.cidade && endereco.push(`, ${local.cidade}`)
        local.uf && endereco.push(`, ${local.uf}`)
    }

    const cep = lang.onlyNumbers(local.cep)
    if (cep) {
        const cep0 = cep.substring(0, 5)
        const cep1 = cep.substring(5)
        endereco.push(` - CEP ${cep0}-${cep1}`)
    }

    return endereco.length > 0 ? endereco.join('') : undefined
}

function buildCoordenadas(local: EnderecoBean | undefined | null): L.LatLngLiteral | undefined {
    if (local && lodash.isNumber(local.longitude) && lodash.isNumber(local.latitude)) {
        return {
            lat: local.latitude,
            lng: local.longitude
        }
    }
    return undefined
}

function parseISOSafely(iso8601Value: string | undefined, defaultValue: Date) {
    if (!iso8601Value) {
        return defaultValue
    }

    const value = DateFnsNs.parseISO(iso8601Value)
    if (DateFnsNs.isValid(value)) {
        return value
    } else {
        LOG.error(`Invalid ISO value ${iso8601Value}`)
        return defaultValue
    }
}