import React from 'react'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { Logger } from 'wdc-cube'

import { styled } from '@mui/material/styles'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { EnchenteRs2024Scope } from '../../tcm_scopes'

const LOG = Logger.get('EnchenteRs2024View')

const FormControlLabel = styled(MuiFormControlLabel)({
    marginLeft: 5
})

export type EnchenteRs2024ViewProps = {
    className?: string
    scope: EnchenteRs2024Scope
}

class EnchenteRs2024ViewClass implements FCClassContext<EnchenteRs2024ViewProps> {
    scope!: EnchenteRs2024Scope

    render({ className, scope }: EnchenteRs2024ViewProps) {
        this.scope = scope
        return (
            <FormControlLabel
                className={className}
                control={<Switch checked={scope.active} 
                onChange={this.emitOnChange}/>}
                label="Enchente RS/2024"
            />
        )
    }

    readonly emitOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.scope.onChange(e.target.checked).catch(LOG.caught)
    }
}

export const EnchenteRs2024View = classToFComponent(EnchenteRs2024ViewClass, React)
export default EnchenteRs2024View
