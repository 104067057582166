export const colors = {
    selected : '#fb8500'
}

export const formatNumberToCompactShortForm = (function buildFormatNumber() {
    const numformatter = Intl.NumberFormat('pt-BR', {
        notation: 'compact',
        compactDisplay: 'short'
    })

    return (n: number) => {
        if (n === undefined || Number.isNaN(n)) {
            return ''
        }
        return numformatter.format(n)
    }
})()

export const formatNumberToCompactLongForm = (function buildFormatNumber() {
    const numformatter = Intl.NumberFormat('pt-BR', {
        notation: 'compact',
        compactDisplay: 'long'
    })

    return (n: number) => {
        if (n === undefined || Number.isNaN(n)) {
            return ''
        }
        return numformatter.format(n)
    }
})()


export const formatNumber = (function buildFormatNumber() {
    const numformatter = Intl.NumberFormat('pt-BR')

    return (n: number) => {
        if (n === undefined || Number.isNaN(n)) {
            return ''
        }
        return numformatter.format(n)
    }
})()