import React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { SearchBoxView } from 'src/components/searchbox/views'
import { TheMapHeaderScope } from '../tm_scopes'

const LOG = Logger.get('TheMapHeaderView')

const useStyles = makeStyles()(() => ({
    view: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        flexBasis: 0,
        gap: 8,
        marginRight: 10,
        marginLeft: 10
    },
    search: {
        flex: 1
    },
    switch: {
        zoom: 0.6
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column'
    }
}))

type TheMapHeaderProps = {
    className?: string
    scope: TheMapHeaderScope
}

export const TheMapHeaderView = function ({ className, ...props }: TheMapHeaderProps) {
    const { classes, scope, handleChangeAtivoCheck } = TheMapHeaderCtx.use(props)

    return (
        <div className={clsx(classes.view, className)}>
            <SearchBoxView
                className={classes.search}
                scope={scope.search}
                endAdornment={
                    <div className={classes.hbox}>
                        <FormGroup className={classes.vbox}>
                            <FormControlLabel
                                className={classes.switch}
                                label="Mostrar ativos"
                                control={<Switch checked={scope.onlyActives} onChange={handleChangeAtivoCheck} />}
                            />
                        </FormGroup>
                    </div>
                }
            />
        </div>
    )
}

class TheMapHeaderCtx {
    static #create = () => new TheMapHeaderCtx()

    static use(props: TheMapHeaderProps) {
        const context = React.useMemo(TheMapHeaderCtx.#create, [])
        context.#update(props)
        return context
    }

    scope!: TheMapHeaderScope
    classes!: ReturnType<typeof useStyles>['classes']

    #update({ scope }: TheMapHeaderProps) {
        bindUpdate(React, scope)
        this.scope = scope
        this.classes = useStyles().classes
    }

    handleChangeAtivoCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.scope.onOnlyActivesChange(e.target.checked).catch(LOG.caught)
    }
}
