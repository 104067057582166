import { Observable, observe, Scope } from 'wdc-cube'

export type CompanyRow = {
    id: string
    razaoSocial: string
    descricaoCnae: string
    website: null
}

@Observable
export class HeaderScope extends Scope {
}

@Observable
export class RDStationScope extends Scope {
    readonly header = new HeaderScope()

    @observe() companyRows = [] as CompanyRow[]
    @observe() cnpjsSelected = [] as string[]
    @observe() sinchronazing = false
    @observe() progress = 0
    @observe() progressBuffer = 0

    getCnpjsInText = () => ''
    setCnpjsInText = Scope.SYNC_ACTION_STRING

    onAppendCNPJs = Scope.ASYNC_ACTION
    onSincronizar = Scope.ASYNC_ACTION
    onPararSincronizacao = Scope.ASYNC_ACTION
    onRemoverMarcados = Scope.ASYNC_ACTION
    onKeyPressed = Scope.ASYNC_ACTION_STRING
}

export default RDStationScope
