import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { bindUpdate } from 'wdc-cube-react'
import { BodyScope } from '../Main.scopes'
import { makeStyles } from 'tss-react/mui'

const LOG = Logger.get('MainView/BodyView')

export const useStyles = makeStyles()({
    view: {
        padding: '10px'
    },
    buttonPane: {
        marginLeft: '10px'
    }
})

type BodyViewProps = {
    className?: string
    scope: BodyScope
}

export function BodyView({ scope, className, ...props }: BodyViewProps) {
    bindUpdate(React, scope)

    LOG.debug('update')

    const { classes } = useStyles()

    return <div className={clsx(className, classes.view)} {...props}></div>
}
