import { Place, CubeBuilder, NOOP_VOID } from 'wdc-cube'

import { RestrictedPresenter } from './modules/restricted/Restricted.presenter'
import { TheConnectionPresenter } from './modules/the-connection'
import { TheMapPresenter } from './modules/the-map'
import { TheMapMarkDetailsPresenter } from './modules/the-map/mark_details/tm_md_details_presenter'
import { TheActingPresenter } from './modules/the-acting'
import { TheConsumerMarketPresenter } from './modules/the-consumer-market'
import { RDStationPresenter } from './modules/rd-station'
import { Places } from './Constants'

let buildOnce = () => {
    CubeBuilder.build({
        theConnection: {
            presenter: Place.creator(TheConnectionPresenter, Places, 'theConnection')
        },
        theMap: {
            presenter: Place.creator(TheMapPresenter, Places, 'theMap'),

            markDetail: {
                presenter: Place.creator(TheMapMarkDetailsPresenter, Places, 'theMapMarkDetail')
            }
        },
        theActing: {
            presenter: Place.creator(TheActingPresenter, Places, 'theActing')
        },
        theConsumerMarket: {
            presenter: Place.creator(TheConsumerMarketPresenter, Places, 'theConsumerMarket')
        },
        rdStation: {
            presenter: Place.creator(RDStationPresenter, Places, 'rdStation')
        },
        restricted: {
            presenter: Place.creator(RestrictedPresenter, Places, 'restricted')
        }
    })

    buildOnce = NOOP_VOID
}

export function buildCube() {
    buildOnce()
    return Places
}
