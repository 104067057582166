import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import TcmCard from './tcm-card'
import TcmProgress from './tcm_progress'
import { formatNumber, formatNumberToCompactShortForm, colors } from './tcm_view_utils'
import { ValueScope } from '../../tcm_scopes'

const LOG = Logger.get('TCM-VAL')

const View = styled('div')({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
})

const useStyles = makeStyles()({
    hand: {
        cursor: 'pointer'
    },
    textSelect: {
        color: colors.selected
    }
})

export type ValueViewProps = {
    className?: string
    style?: React.CSSProperties
    scope: ValueScope
}

export class ValueViewClass<P extends ValueViewProps> implements FCClassContext<P> {
    scope!: ValueScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: P) {
        this.scope = props.scope
        this.classes = useStyles().classes
    }

    render({ className, style, scope }: P): JSX.Element {
        const loaded = scope.loaded
        const loading = !loaded

        return (
            <TcmCard caption={scope.caption} className={className} style={style}>
                <View>
                    {loading && <TcmProgress />}
                    {loaded && (
                        <Tooltip title={formatNumber(scope.value)}>
                            <Typography
                                className={clsx(
                                    this.scope.onClicked ? this.classes.hand : undefined,
                                    scope.selected ? this.classes.textSelect : undefined
                                )}
                                variant="h6"
                                gutterBottom
                                onClick={this.emitClick}
                            >
                                {formatNumberToCompactShortForm(scope.value)}
                            </Typography>
                        </Tooltip>
                    )}
                </View>
            </TcmCard>
        )
    }

    emitClick = () => {
        if (this.scope.onClicked) {
            this.scope.onClicked().catch(LOG.caught)
        }
    }
}

export default classToFComponent(ValueViewClass, React)
