import React from 'react'
import { styled } from '@mui/material/styles'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { NumberFilterScope, NumberParcelFilterScope, NumberOperator, JunctionMode } from '../../tcm_scopes'

const LOG = Logger.get('StringFilterView')

const FilterView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 5
})

const FormBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f4f4f4',
    padding: '3px'
})

const HBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    columnGap: 5,
    alignItems: 'flex-end'
})

const FloatingIconButton = styled(IconButton)({
    marginBottom: 3,
    padding: 0
})

type NumberFilterViewProps = {
    className?: string
    scope: NumberFilterScope
}

class NumberFilterViewClass implements FCClassContext<NumberFilterViewProps> {
    // :: Fields
    scope!: NumberFilterScope
    setAnchorEl!: React.Dispatch<React.SetStateAction<HTMLElement | null>>

    onSyncState({ scope }: NumberFilterViewProps) {
        this.scope = scope
    }

    render({ className }: NumberFilterViewProps) {
        const { scope } = this

        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
        this.setAnchorEl = setAnchorEl

        const menuAndOrOpened = !!anchorEl

        return (
            <FilterView className={className}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography>{scope.caption}</Typography>
                    <IconButton color="primary" size="small" onClick={this.handlePlusClick}>
                        <AddIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={menuAndOrOpened}
                        onClose={this.handleClose}
                        onClick={this.handleClose}
                    >
                        <MenuItem onClick={this.handleAddANDClicked}>AND</MenuItem>
                        <MenuItem onClick={this.handleAddORClicked}>OR</MenuItem>
                    </Menu>
                </Box>

                {!!scope.conjunction.length &&
                    scope.conjunction.map((parcelScope) => (
                        <StringKeywordParcelView
                            key={parcelScope.uid}
                            acceptBlankValues={scope.acceptBlankValues}
                            scope={parcelScope}
                        />
                    ))}

                {!!scope.disjunction.length &&
                    scope.disjunction.map((parcelScope) => (
                        <StringKeywordParcelView
                            key={parcelScope.uid}
                            acceptBlankValues={scope.acceptBlankValues}
                            scope={parcelScope}
                        />
                    ))}
            </FilterView>
        )
    } //

    readonly handlePlusClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setAnchorEl(event.currentTarget)
    }

    readonly handleClose = () => {
        this.setAnchorEl(null)
    }

    readonly handleAddANDClicked = () => {
        this.setAnchorEl(null)
        this.scope.onAddFilter(JunctionMode.AND).catch(LOG.caught)
    }

    readonly handleAddORClicked = () => {
        this.setAnchorEl(null)
        this.scope.onAddFilter(JunctionMode.OR).catch(LOG.caught)
    }
}

export const StringKeywordFilterView = classToFComponent(NumberFilterViewClass, React)
export default StringKeywordFilterView

// :: StringParcelViewClass

type NumberParcelViewProps = {
    className?: string
    acceptBlankValues: boolean
    scope: NumberParcelFilterScope
}

class StringKeywordParcelViewClass implements FCClassContext<NumberParcelViewProps> {
    scope!: NumberParcelFilterScope

    render({ className, acceptBlankValues, scope }: NumberParcelViewProps) {
        this.scope = scope

        const isRange = scope.operator === NumberOperator.in_range || scope.operator === NumberOperator.out_of_range

        return (
            <FormBox className={className}>
                <FormControl variant="standard" size="small">
                    <InputLabel id={scope.uid}>
                        Critério ({scope.junctionMode === JunctionMode.AND ? 'AND' : 'OR'})
                    </InputLabel>
                    <Select
                        labelId={scope.uid}
                        defaultValue={`${NumberOperator.is}`}
                        value={`${scope.operator}`}
                        onChange={this.handleSelectChange}
                    >
                        <MenuItem value={NumberOperator.is_less_then}>Menor que</MenuItem>
                        <MenuItem value={NumberOperator.is_less_then_or_equal_to}>Menor que ou igual</MenuItem>
                        <MenuItem value={NumberOperator.is_greater_then}>Maior que</MenuItem>
                        <MenuItem value={NumberOperator.is_greater_then_or_equal_to}>Maior que ou igual</MenuItem>
                        <MenuItem value={NumberOperator.is}>É igual</MenuItem>
                        <MenuItem value={NumberOperator.is_not}>Não é igual</MenuItem>
                        <MenuItem value={NumberOperator.in_range}>No interválo</MenuItem>
                        <MenuItem value={NumberOperator.out_of_range}>Fora do interválo</MenuItem>
                        {acceptBlankValues && <MenuItem value={NumberOperator.is_blank}>Está em branco</MenuItem>}
                        {acceptBlankValues && (
                            <MenuItem value={NumberOperator.is_not_blank}>Não está em branco</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {scope.valueVisible && !isRange && (
                    <TextField
                        label="Valor"
                        variant="standard"
                        value={scope.value}
                        InputProps={{
                            endAdornment: (
                                <FloatingIconButton color="primary" size="small" onClick={this.handleClearClick}>
                                    <DeleteIcon />
                                </FloatingIconButton>
                            )
                        }}
                        onChange={this.handleFromValueChange}
                        onKeyDown={this.handleKeyDown}
                    />
                )}
                {scope.valueVisible && isRange && (
                    <HBox>
                        <TextField
                            label="De"
                            variant="standard"
                            value={scope.value}
                            sx={{width: 67}}
                            onChange={this.handleFromValueChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <TextField
                            label="A"
                            variant="standard"
                            value={scope.toValue}
                            sx={{width: 67}}
                            onChange={this.handleToValueChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <FloatingIconButton color="primary" size="small" onClick={this.handleClearClick}>
                            <DeleteIcon />
                        </FloatingIconButton>
                    </HBox>
                )}
            </FormBox>
        )
    }

    readonly handleSelectChange = (event: SelectChangeEvent) => {
        this.scope.onOperatorChange(+event.target.value)
    }

    readonly handleFromValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.scope.onValueChange(event.target.value, 'from').catch(LOG.caught)
    }

    readonly handleToValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.scope.onValueChange(event.target.value, 'to').catch(LOG.caught)
    }

    readonly handleClearClick = () => {
        this.scope.onClear().catch(LOG.caught)
    }

    readonly handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            this.scope.onEnterKeyPressed().catch(LOG.caught)
        }
    }
}

export const StringKeywordParcelView = classToFComponent(StringKeywordParcelViewClass, React)
