import { Logger, Presenter, IPresenterOwner } from 'wdc-cube'
import { FilterPaneScope, IFilterScope } from '../tcm_scopes'
import { TheConsumerMarketPresenter } from '../tcm_presenter'

import FilterManager from '../tcm_filter_manager'
import { FilterPresenter } from './tcm_filter_types'
import { TcmPresenterForStringFilter } from './tcm_filter_presenter_for_string'
import { TcmPresenterForStringKeywordFilter } from './tcm_filter_presenter_for_string_keyword'
import { TcmPresenterForNumberFilter } from './tcm_filter_presenter_for_number'

import { TheConsumerMarketService } from '../tcm_service'

const service = TheConsumerMarketService.singleton()

const LOG = Logger.get('TCM-FILTER-PANE')

export class TcmPresenterForFilterPane extends Presenter<FilterPaneScope, TheConsumerMarketPresenter> {
    // Constructor

    // prettier-ignore
    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.filterPane)
        this.filterManager = owner.filterManager

        const mgr = owner.filterManager
        const add = this.filterPresenters.push.bind(this.filterPresenters)
        add(this.nomeRazaoSocial = new TcmPresenterForStringFilter(this, this.scope.nomeEmpresa, mgr.empresaRazaoSocial, this.searchByRazaoSocial.bind(this)))
        add(this.nomeFantasia = new TcmPresenterForStringFilter(this, this.scope.nomeFantasia, mgr.empresaNomeFantasia, this.searchByNomeFantasia.bind(this)))
        add(this.codigo = new TcmPresenterForStringKeywordFilter(this, this.scope.cnpj, mgr.empresaCodigo))

        add(this.naturezaJuridicaCodes = new TcmPresenterForStringKeywordFilter(this, this.scope.naturezaJuridicaCodes, mgr.naturezaJuridicaCodes, true))
        add(this.naturezaJuridicaTexts = new TcmPresenterForStringFilter(this, this.scope.naturezaJuridicaTexts, mgr.naturezaJuridicaTexts, this.searchNaturezaJuridicaByDescricao.bind(this)))
        add(this.cnae = new TcmPresenterForStringKeywordFilter(this, this.scope.cnae, mgr.atividadeEconomicaCodes))
        add(this.cnaeTexts = new TcmPresenterForStringFilter(this, this.scope.cnaeTexts, mgr.atividadeEconomicaTexts, this.searchByCNAEDescricao.bind(this)))

        add(this.uf = new TcmPresenterForStringKeywordFilter(this, this.scope.uf, mgr.enderecoUfs, true))
        add(this.ufTexts = new TcmPresenterForStringFilter(this, this.scope.ufTexts, mgr.enderecoUfTexts, this.searchByUFDescricao.bind(this)))
        add(this.cidadeTexts = new TcmPresenterForStringFilter(this, this.scope.cidadeTexts, mgr.enderecoCidadeTexts, this.searchByCidadeDescricao.bind(this)))
        add(this.regiaoMetropolitanaTexts = new TcmPresenterForStringFilter(this, this.scope.regiaoMetropolitanaTexts, mgr.enderecoRegiaoMetropolitanaTexts, this.searchRegiaoMetroByDescricao.bind(this)))
        add(this.scoreRiscos = new TcmPresenterForStringKeywordFilter(this, this.scope.scoreRiscos, mgr.scoreRiscos))

        add(this.totalEmpresaGrupo = new TcmPresenterForNumberFilter(this, this.scope.totalEmpresaGrupo, mgr.totalEmpresasNoGrupo))
        add(this.totalEmpresaCapSocial = new TcmPresenterForNumberFilter(this, this.scope.totalEmpresaCapSocial, mgr.capitalSocial))
        add(this.totalFuncionarios = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionarios, mgr.totalFuncionarios))
        add(this.totalFuncionarios_0_a_18 = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionarios_0_a_18, mgr.totalFuncionarios_0_a_18))
        add(this.totalFuncionarios_19_a_33 = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionarios_19_a_33, mgr.totalFuncionarios_19_a_33))
        add(this.totalFuncionarios_34_a_48 = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionarios_34_a_48, mgr.totalFuncionarios_34_a_48))
        add(this.totalFuncionarios_49_a_58 = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionarios_49_a_58, mgr.totalFuncionarios_49_a_58))
        add(this.totalFuncionarios_59_ou_mais = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionarios_59_ou_mais, mgr.totalFuncionarios_59_ou_mais))
        add(this.totalFuncionariosFemininos = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionariosFemininos, mgr.totalFuncionariosFemininos))
        add(this.totalFuncionariosMasculinos = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionariosMasculinos, mgr.totalFuncionariosMasculinos))
        add(this.totalFuncionariosDoGrupo = new TcmPresenterForNumberFilter(this, this.scope.totalFuncionariosDoGrupo, mgr.totalFuncionariosDoGrupo))

        add(this.faixaFaturamentoAnual = new TcmPresenterForNumberFilter(this, this.scope.faixaFaturamentoAnual, mgr.faturamentoEstimado))
        add(this.faixaFaturamentoConsolidadoGrupo = new TcmPresenterForNumberFilter(this, this.scope.faixaFaturamentoConsolidadoGrupo, mgr.faturamentoEstimadoGrupo))

        add(this.idadeEmpresa = new TcmPresenterForNumberFilter(this, this.scope.idadeEmpresa, mgr.idadeEmpresaEmAnos))
        add(this.crescimento2021 = new TcmPresenterForNumberFilter(this, this.scope.crescimento2021, mgr.crescimento2021))
        add(this.crescimento2022 = new TcmPresenterForNumberFilter(this, this.scope.crescimento2022, mgr.crescimento2022))
        add(this.mediaCrescimento2021_2022 = new TcmPresenterForNumberFilter(this, this.scope.mediaCrescimento2021_2022, mgr.mediaCrescimento2021_a_2022))

    }

    // :: Getters

    get owner() {
        return super.owner as TheConsumerMarketPresenter
    }

    // :: Fields

    private readonly filterManager: FilterManager
    private readonly filterPresenters: FilterPresenter<IFilterScope, IPresenterOwner>[] = []

    public readonly nomeRazaoSocial: TcmPresenterForStringFilter
    public readonly nomeFantasia: TcmPresenterForStringFilter
    public readonly codigo: TcmPresenterForStringKeywordFilter

    public readonly naturezaJuridicaCodes: TcmPresenterForStringKeywordFilter
    public readonly naturezaJuridicaTexts: TcmPresenterForStringFilter
    public readonly cnae: TcmPresenterForStringKeywordFilter
    public readonly cnaeTexts: TcmPresenterForStringFilter
    public readonly uf: TcmPresenterForStringKeywordFilter
    public readonly ufTexts: TcmPresenterForStringFilter
    public readonly cidadeTexts: TcmPresenterForStringFilter
    public readonly regiaoMetropolitanaTexts: TcmPresenterForStringFilter
    public readonly scoreRiscos: TcmPresenterForStringKeywordFilter

    public readonly totalEmpresaGrupo: TcmPresenterForNumberFilter
    public readonly totalEmpresaCapSocial: TcmPresenterForNumberFilter
    public readonly totalFuncionarios: TcmPresenterForNumberFilter
    public readonly totalFuncionarios_0_a_18: TcmPresenterForNumberFilter
    public readonly totalFuncionarios_19_a_33: TcmPresenterForNumberFilter
    public readonly totalFuncionarios_34_a_48: TcmPresenterForNumberFilter
    public readonly totalFuncionarios_49_a_58: TcmPresenterForNumberFilter
    public readonly totalFuncionarios_59_ou_mais: TcmPresenterForNumberFilter
    public readonly totalFuncionariosFemininos: TcmPresenterForNumberFilter
    public readonly totalFuncionariosMasculinos: TcmPresenterForNumberFilter
    public readonly totalFuncionariosDoGrupo: TcmPresenterForNumberFilter
    public readonly faixaFaturamentoAnual: TcmPresenterForNumberFilter
    public readonly faixaFaturamentoConsolidadoGrupo: TcmPresenterForNumberFilter
    public readonly idadeEmpresa: TcmPresenterForNumberFilter
    public readonly crescimento2021: TcmPresenterForNumberFilter
    public readonly crescimento2022: TcmPresenterForNumberFilter
    public readonly mediaCrescimento2021_2022: TcmPresenterForNumberFilter

    // :: API

    async initialize() {
        this.scope.update = this.update
        this.scope.onToggleExpanded = this.__handleToggleExpantion.bind(this)
        this.scope.onApply = this.__handleApply.bind(this)

        this.scope.enchenteRs2024.update = this.update
        this.scope.enchenteRs2024.onChange = this.__handleEnchenteRs2024ActiveChange.bind(this)

        let orderGen = 1
        await this.nomeRazaoSocial.prepare(orderGen++, 'Nome Empresa')
        await this.nomeFantasia.prepare(orderGen++, 'Nome Fantasia')
        await this.codigo.prepare(orderGen++, 'CNPJ')
        await this.uf.prepare(orderGen++, 'UF - Código')
        await this.ufTexts.prepare(orderGen++, 'UF - Descrição')
        await this.cidadeTexts.prepare(orderGen++, 'Cidade - Nome')
        await this.regiaoMetropolitanaTexts.prepare(orderGen++, 'Região Metropolitana')
        await this.naturezaJuridicaCodes.prepare(orderGen++, 'Natureza Jurídica (Cód.)')
        await this.naturezaJuridicaTexts.prepare(orderGen++, 'Natureza Jurídica')
        await this.cnae.prepare(orderGen++, 'CNAE')
        await this.cnaeTexts.prepare(orderGen++, 'CNAE - Descrição')
        await this.scoreRiscos.prepare(orderGen++, 'Score Risco')
        await this.totalEmpresaGrupo.prepare(orderGen++, 'Total Empresas no grupo')
        await this.totalEmpresaCapSocial.prepare(orderGen++, 'Faixa Capital Social')
        await this.totalFuncionarios.prepare(orderGen++, 'Faixa Funcionários')
        await this.totalFuncionarios_0_a_18.prepare(orderGen++, 'Qtde Func. até 18 anos')
        await this.totalFuncionarios_19_a_33.prepare(orderGen++, 'Qtde Func. 19 a 33 anos')
        await this.totalFuncionarios_34_a_48.prepare(orderGen++, 'Qtde Func. 34 a 48 anos')
        await this.totalFuncionarios_49_a_58.prepare(orderGen++, 'Qtde Func. 49 a 58 anos')
        await this.totalFuncionarios_59_ou_mais.prepare(orderGen++, 'Qtde Func. 59+ anos')
        await this.totalFuncionariosFemininos.prepare(orderGen++, 'Qtde Func. Femininos')
        await this.totalFuncionariosMasculinos.prepare(orderGen++, 'Qtde Func. Masculinos')
        await this.totalFuncionariosDoGrupo.prepare(orderGen++, 'Faixa Funcionários Grupo')
        await this.faixaFaturamentoAnual.prepare(orderGen++, 'Faixa Faturamento Anual')
        await this.faixaFaturamentoConsolidadoGrupo.prepare(orderGen++, 'Faixa Faturamento Grupo')
        await this.idadeEmpresa.prepare(orderGen++, 'Idade Empresa em Anos')
        await this.crescimento2021.prepare(orderGen++, 'Crescimento 2021')
        await this.crescimento2022.prepare(orderGen++, 'Crescimento 2022')
        await this.mediaCrescimento2021_2022.prepare(orderGen++, 'Méd.Crescimento 21/22')

        LOG.debug('initialized')
    }

    override release(): void {
        this.filterPresenters.forEach((filter) => filter.release())
    }

    clear() {
        this.filterPresenters.forEach((filter) => filter.clear())
    }

    publish() {
        const mgr = this.owner.filterManager
        mgr.enchenteRs2024 = this.scope.enchenteRs2024.active

        this.filterPresenters.forEach((filter) => filter.publish())
    }

    private async __handleToggleExpantion() {
        this.scope.expanded = !this.scope.expanded
    }

    private async __handleEnchenteRs2024ActiveChange(value: boolean) {
        this.scope.enchenteRs2024.active = value
        await this.owner.fetchData('')
        this.scope.enchenteRs2024.onNotifyMap(value).catch(LOG.caught)
    }

    private async __handleApply() {
        this.owner.fetchData('')
    }

    private async searchByCNAEDescricao(text: string) {
        const rows = await service.searchCnaeByDescricao(text, this.filterManager.build())
        return rows.map((b) => b.source['pj-ae-subclasse-dsc'])
    }

    private async searchByRazaoSocial(text: string) {
        const rows = await service.searchEmpresaByRazaoSocial(text, this.filterManager.build(), ['pj-razao-social'])
        return [...new Set(rows.map((b) => b.source['pj-razao-social'] ?? ''))]
    }

    private async searchByNomeFantasia(text: string) {
        const rows = await service.searchEmpresaByNomeFantasia(text, this.filterManager.build(), ['pj-nome-fantasia'])
        return [...new Set(rows.map((b) => b.source['pj-nome-fantasia'] ?? ''))]
    }

    private async searchByUFDescricao(text: string) {
        const rows = await service.searchUFByDescricao(text, this.filterManager.build(), [
            'pj-addr-uf',
            'pj-addr-uf-dsc'
        ])
        return [...new Set(rows.map((b) => b.source['pj-addr-uf-dsc'] ?? ''))]
    }

    private async searchByCidadeDescricao(text: string) {
        const rows = await service.searchCidadeByDescricao(text, this.filterManager.build(), [
            'pj-addr-cidade-id',
            'pj-addr-cidade-nme'
        ])
        return [...new Set(rows.map((b) => b.source['pj-addr-cidade-nme'] ?? ''))]
    }

    private async searchRegiaoMetroByDescricao(text: string) {
        const rows = await service.searchRegiaoMetroByDescricao(text, this.filterManager.build(), [
            'pj-addr-regiao-metropolitana-cod',
            'pj-addr-regiao-metropolitana-dsc'
        ])
        return [...new Set(rows.map((b) => b.source['pj-addr-regiao-metropolitana-dsc'] ?? ''))]
    }

    private async searchNaturezaJuridicaByDescricao(text: string) {
        const rows = await service.searchNaturezaJuridicaByDescricao(text, this.filterManager.build(), [
            'pj-nat-jur-cod',
            'pj-nat-jur-nme'
        ])
        return [...new Set(rows.map((b) => b.source['pj-nat-jur-nme'] ?? ''))]
    }

}
