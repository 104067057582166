import { Logger, Presenter } from 'wdc-cube'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { ValueScope } from './tcm_scopes'
import { TheConsumerMarketService, SituacaoCadastral, type EmpresaFilter } from './tcm_service'

const LOG = Logger.get('TcmPresenterForEmpresasTotalAtivas')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForEmpresasTotalAtivas extends Presenter<ValueScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.totalEmpresasAtivas)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.totalEmpresasAtivas
        this.scope.onClicked = this.__onEmpresasAtivasClicked.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.__filterManager.situacaoCadastral.clear()
    }

    async fetch(filter: EmpresaFilter) {
        this.scope.loaded = false
        try {
            const resp = await service.fetch({
                total_de_empresas_ativas: true,
                filter: filter
            })

            this.scope.value = resp.total_de_empresas_ativas?.value ?? 0

            this.__markSelected()
        } finally {
            this.scope.loaded = true
            this.scope.update()
        }
    }

    private __markSelected() {
        const prop = this.__filterManager.situacaoCadastral
        this.scope.selected = prop.has(SituacaoCadastral.ativa)
        this.scope.update()
    }

    private async __onEmpresasAtivasClicked() {
        const prp = this.__filterManager.situacaoCadastral
        this.__filterManager.situacaoCadastral.add(SituacaoCadastral.ativa)
        this.__markSelected()
        this.__parent.fetchData(prp.size ? 'totalDeEmpresaAtivas' : '')
    }
}
